/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { createContext, useContext, useMemo, useState } from "react";
import { ChildrenProps } from "../models/ChildrenProps";
import { ChildrenFunctionProps } from "../models/ChildrenFunctionProps";

interface IErrorContext {
  isUnhandledRejectionHandled: boolean; // True if an error boundary already handled an unhandled rejection
  setUnhandledRejectionHandled: (newValue: boolean) => void;
}

export const ErrorContext = createContext<IErrorContext | null>(null);

export const useErrorContext = () => useContext(ErrorContext);

export const ErrorProvider: React.FC<ChildrenProps> = ({ children }: ChildrenProps) => {
  const [isUnhandledRejectionHandled, setUnhandledRejectionHandled] = useState<boolean>(false);

  const ctx = useMemo(
    () => ({
      isUnhandledRejectionHandled,
      setUnhandledRejectionHandled,
    }),

    [isUnhandledRejectionHandled, setUnhandledRejectionHandled]
  );

  return <ErrorContext.Provider value={ctx}>{children}</ErrorContext.Provider>;
};

export const ErrorConsumer: React.FC<ChildrenFunctionProps> = ({ children }: ChildrenFunctionProps) => (
  <ErrorContext.Consumer>
    {(value: IErrorContext | null) => {
      if (!value) {
        throw new Error("ErrorConsumer must be used within an ErrorProvider");
      }
      return children(value);
    }}
  </ErrorContext.Consumer>
);
