/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export interface LogEntry {
  timestamp: Date;
  level: string;
  message: string;
  meta?: Record<string, unknown>;
  size: number; // Cached size in bytes
}

type LogLevel = "info" | "warn" | "error";

const DISABLE_LOGGER = false;

// A logger that also captures logs in memory, allowing access to logs from the active page session.
class MemoryLogger {
  private logs: LogEntry[] = [];

  private readonly maxSizeBytes: number;

  private readonly maxAgeMs: number;

  private currentSizeBytes = 0;

  constructor(maxSizeMB = 10, maxAgeMs = 3600000) {
    if (maxSizeMB <= 0) {
      throw new Error("maxSizeMB must be a positive number.");
    }
    if (maxAgeMs <= 0) {
      throw new Error("maxAgeMs must be a positive number.");
    }

    this.maxSizeBytes = maxSizeMB * 1024 * 1024;
    this.maxAgeMs = maxAgeMs;
  }

  private cleanup(): void {
    const now = Date.now();

    while (
      this.logs.length > 0 &&
      (this.currentSizeBytes > this.maxSizeBytes || now - this.logs[0].timestamp.getTime() > this.maxAgeMs)
    ) {
      const oldestLog = this.logs.shift();

      if (oldestLog) {
        this.currentSizeBytes -= oldestLog.size;
      }
    }
  }

  private static logToConsole(level: LogLevel, ...args: unknown[]): void {
    const timestamp = new Date().toLocaleString();

    switch (level) {
      case "info":
        console.info(`[${timestamp}]`, ...args);
        break;
      case "warn":
        console.warn(`[${timestamp}]`, ...args);
        break;
      case "error":
        console.error(`[${timestamp}]`, ...args);
        break;
      default:
        console.info(`[${timestamp}]`, ...args);
    }
  }

  private static getByteLength(message: string): number {
    const encoder = new TextEncoder();
    const encodedMessage = encoder.encode(message);
    return encodedMessage.length;
  }

  /**
   * Converts a value to a string representation.
   * - Handles primitive values (`undefined`, `null`, `false`, `0`, `""`, and `NaN`) explicitly.
   * - Stringifies objects and arrays up to a specified depth, avoiding circular references.
   * - For objects, includes a space between the curly braces and key-value pairs (e.g., `{ key: value }`).
   * - For arrays, returns the elements enclosed in square brackets with no spaces between them (e.g., `[elem1, elem2]`).
   * - Replaces circular references with `[Circular]`.
   * - The `depth` parameter controls how deeply nested objects are stringified. It defaults to `2`, where `0` will stop deep stringification.
   *
   * @param value - The value to convert to a string.
   * @param depth - The maximum depth to which nested objects and arrays are stringified. Default is `2`.
   * @param seen - A WeakSet to track circular references. Used internally to avoid infinite recursion.
   * @returns A string representation of the value. For arrays, it returns elements enclosed in square brackets,
   * and for objects, it returns key-value pairs enclosed in curly braces.
   */
  private static stringify(value: unknown, depth = 2, seen: WeakSet<object> = new WeakSet()): string {
    if (value === undefined) return "undefined";
    if (value === null) return "null";
    if (value === false) return "false";
    if (value === 0) return "0";
    if (value === "") return '""';
    if (Number.isNaN(value)) return "NaN";

    if (Array.isArray(value)) {
      if (value.length === 0) return "[]"; // Return [] for empty arrays
      if (depth === 0) return "[Array]"; // Limit depth for arrays
      const entries = value.map((val) =>
        typeof val === "object" && val !== null ? MemoryLogger.stringify(val, depth - 1, seen) : val
      );
      return `[${entries.join(", ")}]`; // Non-empty array with elements
    }

    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) return "[Circular]"; // Prevent circular references
      seen.add(value);

      if (depth === 0) {
        return "[Object]"; // Avoid deep object stringification beyond depth limit
      }

      // Handle object: check if it's empty or not
      const entries = Object.entries(value).map(([key, val]) => [
        key,
        typeof val === "object" && val !== null ? MemoryLogger.stringify(val, depth - 1, seen) : val,
      ]);

      // If no entries exist, it's an empty object
      if (entries.length === 0) return "{}";

      // Return shallow stringified object with key-value pairs
      return `{ ${entries.map(([key, val]) => `${key}: ${val}`).join(", ")} }`;
    }

    return String(value); // Handle primitive types and functions
  }

  private log(level: LogLevel, ...args: unknown[]): void {
    const logMessage = args.map((arg) => MemoryLogger.stringify(arg)).join(" ");
    const logSize = MemoryLogger.getByteLength(logMessage);

    // Create log entry with timestamp and level
    const logEntry: LogEntry = {
      timestamp: new Date(),
      level,
      message: logMessage,
      size: logSize,
    };

    // Store log
    this.logs.push(logEntry);
    this.currentSizeBytes += logSize;

    // Log to console
    MemoryLogger.logToConsole(level, ...args);

    // Clean up old logs
    this.cleanup();
  }

  // Public methods to log at different levels
  error(...args: unknown[]) {
    if (!DISABLE_LOGGER) {
      this.log("error", ...args);
    }
  }

  info(...args: unknown[]) {
    if (!DISABLE_LOGGER) {
      this.log("info", ...args);
    }
  }

  warn(...args: unknown[]) {
    if (!DISABLE_LOGGER) {
      this.log("warn", ...args);
    }
  }

  // Retrieve logs
  getLogs(): LogEntry[] {
    return [...this.logs]; // Return a shallow copy to prevent mutation
  }
}

// 10MB max size, 1 hour max age
const logger = new MemoryLogger(10, 3600000);
export default logger;
