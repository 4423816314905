/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import moment from "moment";
import { strings } from "../../../common/Strings/Strings";
import Modal from "../../../components/Modal/Modal";
import UpdateStockForm from "./UpdateStockForm";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import { InventoryItemResponse } from "../../../models/inventory/item/Response/InventoryItemResponse";
import Button from "../../../components/Button";
import InventoryItemApi from "../../../api/InventoryItemApi";
import { InventoryItemStockRequest } from "../../../models/inventory/item/Request/InventoryItemStockRequest";
import { InventoryItemStockResponse } from "../../../models/inventory/item/Response/InventoryItemStockResponse";
import { getGeneralError, localDateFormat } from "../../../util/helperFunctions";
import AlertBox from "../../../components/AlertBox";
import UpdateSalePriceCheckBox from "./UpdateSalePriceCheckBox";
import { useClinic } from "../../../contexts/ClinicContext";
import { UpdateInventoryItemRequest } from "../../../models/inventory/item/Request/UpdateInventoryItemRequest";
import { calculateSalePriceFromProfitMargin } from "../CreateItem/CreationSteps/InventoryItemSellingStep";
import { calculateProfitMargin } from "../Details/InventoryItemDetails";

interface Props {
  close(shouldReloadItem: boolean, successMessage?: string): void;
  countryDetails?: CountryDetailsResponse;
  inventoryItem?: InventoryItemResponse;
  isOpen: boolean;
  itemStockToEdit?: InventoryItemStockResponse;
}

type StockForm = InventoryItemStockRequest & { isUpdateSalePriceToo: boolean; newSalePrice: number };

const ItemStockModal: React.FC<Props> = ({ inventoryItem, isOpen, close, countryDetails, itemStockToEdit }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const methods = useForm<StockForm>({
    defaultValues: { quantity: 1, isUpdateSalePriceToo: false, newSalePrice: undefined },
  });
  const { setValue, reset, watch } = methods;
  const { clinic } = useClinic();

  useEffect(() => {
    if (itemStockToEdit) {
      setValue("quantity", itemStockToEdit.quantity);
      if (itemStockToEdit.expirationDate) {
        setValue("expirationDate", itemStockToEdit.expirationDate);
      }
      setValue("acquisitionPrice", itemStockToEdit.acquisitionPrice);
      setValue("acquisitionVat", itemStockToEdit.acquisitionVat);
    } else if (inventoryItem) {
      if (inventoryItem.acquisitionPrice) {
        setValue("acquisitionPrice", inventoryItem.acquisitionPrice);
      }
      if (inventoryItem.acquisitionVat) {
        setValue("acquisitionVat", inventoryItem.acquisitionVat);
      }
    }
  }, [itemStockToEdit, inventoryItem, setValue, isOpen]);

  const handleClose = (shouldReloadItem: boolean, successMessage?: string) => {
    setError(null);
    reset();
    close(shouldReloadItem, successMessage);
  };

  const updateSalePrice = async (formValues: StockForm) => {
    if (inventoryItem && inventoryItem?.categoryId && clinic?.id) {
      try {
        const request: UpdateInventoryItemRequest = {
          ...inventoryItem,
          categoryId: inventoryItem.categoryId,
          sellingPrice: formValues.newSalePrice,
        };

        await InventoryItemApi.updateItemInfo(clinic.id, inventoryItem.categoryId, inventoryItem.id, request);
        handleClose(true, strings.stockAddedSuccessfully);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      setError(strings.unableToSetSalePriceError);
    }
  };

  const submit = async (request: StockForm) => {
    if (inventoryItem) {
      setLoading(true);

      try {
        await InventoryItemApi.addItemStock(inventoryItem.id, {
          ...request,
          expirationDate: request.expirationDate ? moment(request.expirationDate).format(localDateFormat()) : undefined,
        });

        if (request.isUpdateSalePriceToo && request.newSalePrice !== inventoryItem.sellingPrice) {
          void updateSalePrice(request);
        } else {
          handleClose(true, itemStockToEdit ? strings.stockUpdatedSuccessfully : strings.stockAddedSuccessfully);
          setLoading(false);
        }
      } catch (e) {
        setError(await getGeneralError(e));
        setLoading(false);
      }
    }
  };

  const submitEdit = async (request: StockForm) => {
    if (inventoryItem && itemStockToEdit) {
      setLoading(true);

      try {
        await InventoryItemApi.updateItemStock(inventoryItem.id, itemStockToEdit.id, {
          ...request,
          expirationDate: request.expirationDate ? moment(request.expirationDate).format(localDateFormat()) : undefined,
        });
        if (request.isUpdateSalePriceToo) {
          void updateSalePrice(request);
        } else {
          handleClose(true, strings.stockUpdatedSuccessfully);
          setLoading(false);
        }
      } catch (e) {
        setError(await getGeneralError(e));
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      handleClose={() => {
        handleClose(false);
      }}
      show={isOpen}
    >
      <Modal.Header title={itemStockToEdit ? strings.editStock : strings.refillStock} />
      <Modal.Body>
        <FormProvider {...methods}>
          {inventoryItem && (
            <form className="space-y-4">
              <UpdateStockForm
                countryDetails={countryDetails}
                inventoryItem={{ name: inventoryItem.name, acquisitionUnit: inventoryItem.acquisitionUnit }}
                isPositive
                isRequired
                setNewSalePrice={(newAcquisitionPrice: number | undefined) => {
                  setValue(
                    "newSalePrice",
                    calculateSalePriceFromProfitMargin(
                      newAcquisitionPrice || 0,
                      calculateProfitMargin(inventoryItem.acquisitionPrice, inventoryItem.sellingPrice)
                    )
                  );
                }}
              />
              <UpdateSalePriceCheckBox inventoryItem={inventoryItem} />
            </form>
          )}
          <AlertBox message={error} />
        </FormProvider>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={loading}
          onClick={itemStockToEdit ? methods.handleSubmit(submitEdit) : methods.handleSubmit(submit)}
          loading={loading}
        >
          {itemStockToEdit ? strings.save : strings.add}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ItemStockModal;
