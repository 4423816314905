/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useCallback, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { chronicDiseases as chronicDiseasesEnum, petSpecies, strings } from "../../../common/Strings/Strings";
import { getGeneralError } from "../../../util/helperFunctions";
import AlertBox from "../../../components/AlertBox";
import UserPetApi from "../../../api/UserPetApi";
import { ChronicDiseaseOption } from "../../../common/ChronicDiseases/ChronicDiseases";
import ClinicPetApi from "../../../api/ClinicPetApi";
import { PersonDetailsResponse } from "../../../models/contact/PersonDetailsResponse";
import PetForm, { PetFormProps } from "../CreateNewPet/PetForm";
import { UpdatePetRequest } from "../../../models/pet/UpdatePetRequest";
import { PetDetailsResponse } from "../../../models/pet/PetDetailsResponse";
import { PageProps } from "../../../models/PageProps";
import { Loader } from "../../../components/Loader";
import PetProfilePictureSettings from "../../../components/Pictures/Pet/PetProfilePictureSettings";
import { useUser } from "../../../contexts/UserContext";
import ChangeOwnerModal from "./ChangeOwnerModal";
import ChangeUserOwnerModal from "../../PetOwner/UserPetOwner/Pets/ChangeUserOwnerModal";
import Button from "../../../components/Button";
import UserProfilePicture from "../../../components/Pictures/User/UserProfilePicture";
import { Card } from "../../../components/Cards/Card";
import { getEstimatedAge } from "../../../components/ReactHookFormFields/Specific/PetBirthDatePicker";

type Params = {
  clinicPetId?: string;
  userPetId?: string;
};

const getDateOfBirthType = (
  dateOfBirth?: string,
  isDateOfBirthEstimated?: boolean
): "known" | "estimated" | "unknown" => {
  if (dateOfBirth && !isDateOfBirthEstimated) {
    return "known";
  }

  if (dateOfBirth && isDateOfBirthEstimated) {
    return "estimated";
  }

  return "unknown";
};

/* Common edit component for user pet and clinic pet */
const EditPet: React.FC<PageProps> = ({ setPageLoading }: PageProps) => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { clinicPetId, userPetId } = useParams<Params>();

  /* This is either clinic pet owner data or user data, depending on the path param (pet owner mode vs. clinic employee mode) */
  const [petOwner, setPetOwner] = useState<PersonDetailsResponse>();

  /* This is always user data (not clinic side), we use user data only for the profile picture */
  const [petOwnerUserData, setPetOwnerUserData] = useState<PersonDetailsResponse>();

  /* This is either user pet or clinic pet data, depending on the path param (pet owner mode vs. clinic employee mode) */
  const [pet, setPet] = useState<PetDetailsResponse>();

  /* This is always user pet, we use user pet data only for the profile picture */
  const [userPet, setUserPet] = useState<PetDetailsResponse>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [changeOwnerModalOpen, setChangeOwnerModalOpen] = useState<boolean>(false);
  const [changeUserOwnerModalOpen, setChangeUserOwnerModalOpen] = useState<boolean>(false);

  const petFormMethods = useForm<PetFormProps>({
    mode: "onChange",
  });
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = petFormMethods;

  const fetchUserPet = useCallback(
    async (id: string) => {
      try {
        const resp = await UserPetApi.getUserPet(id);

        setPet(resp.data.userPetDetails);
        setUserPet(resp.data.userPetDetails);

        setPetOwner(user.details);
        setPetOwnerUserData(user.details);
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setPageLoading(false);
      }
    },
    [setPageLoading, user.details]
  );

  const fetchClinicPet = useCallback(
    async (id: string) => {
      try {
        const resp = await ClinicPetApi.getClinicPet(id);

        setPet(resp.data.pet.clinicPetDetails);
        setUserPet(resp.data.pet.userPetDetails);

        setPetOwner(resp.data.petOwner.petOwnerDetails);
        setPetOwnerUserData(resp.data.petOwner.userDetails);
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setPageLoading(false);
      }
    },
    [setPageLoading]
  );

  const fetchPet = useCallback(() => {
    if (clinicPetId) {
      void fetchClinicPet(clinicPetId);
    } else if (userPetId) {
      void fetchUserPet(userPetId);
    }
  }, [clinicPetId, fetchClinicPet, fetchUserPet, userPetId]);

  useEffect(() => {
    fetchPet();
  }, [fetchPet]);

  const resetForm = useCallback(() => {
    if (pet) {
      reset({
        breed: [{ name: pet.breed }],
        chronicDiseases: pet.chronicDiseases.map((cd) => ({
          id: cd,
          name: chronicDiseasesEnum[cd],
        })),
        chipNumber: pet.chipNumber,
        color: pet.color?.toLowerCase(),
        comment: pet.comment,
        deceased: pet.deceased,
        dateOfBirth: pet.dateOfBirth || undefined,
        dateOfBirthType: getDateOfBirthType(pet.dateOfBirth, pet.isDateOfBirthEstimated),
        estimatedAge: pet.dateOfBirth ? getEstimatedAge(new Date(pet.dateOfBirth)) : undefined,
        gender: pet.gender,
        insuranceCompany: pet.insuranceCompany ? [pet.insuranceCompany] : [],
        insuranceNumber: pet.insuranceNumber,
        name: pet.name,
        passportNumber: pet.passportNumber,
        species: [{ value: pet.species, name: petSpecies[pet.species] }],
        tattooNumber: pet.tattooNumber,
      });
    }
  }, [pet, reset]);

  useEffect(() => {
    if (pet) {
      resetForm();
    }
  }, [pet, resetForm]);

  const putPet = async (p: PetFormProps) => {
    setIsLoading(true);

    const { species: s, breed: b, dateOfBirth, dateOfBirthType, insuranceCompany, chronicDiseases, ...rest } = p;

    if (s.length === 1 && b.length === 1) {
      const request: UpdatePetRequest = {
        species: s[0].value,
        breed: b[0].name,
        dateOfBirth: dateOfBirthType !== "unknown" ? dateOfBirth : undefined,
        isDateOfBirthEstimated: dateOfBirthType === "estimated",
        insuranceCompanyId: insuranceCompany?.length === 1 ? insuranceCompany[0].id : undefined,
        chronicDiseases: chronicDiseases?.map((disease: ChronicDiseaseOption) => disease.id) || [],
        ...rest,
      };

      if (clinicPetId) {
        try {
          await ClinicPetApi.updateClinicPet(clinicPetId, request);
          setIsLoading(false);
          navigate(-1);
        } catch (err) {
          setIsLoading(false);
          setError(await getGeneralError(err));
        }
      } else if (userPetId) {
        try {
          await UserPetApi.updateUserPet(userPetId, request);
          setIsLoading(false);
          navigate(-1);
        } catch (err) {
          setIsLoading(false);
          setError(await getGeneralError(err));
        }
      }
    }
  };

  const changeOwner = () => {
    if (clinicPetId) {
      setChangeOwnerModalOpen(true);
    } else if (userPetId) {
      setChangeUserOwnerModalOpen(true);
    }
  };

  return (
    <main className="main-signed-in">
      <section>
        <div className="px-4 lg:px-6 py-6 space-y-6">
          <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
            {strings.formatString(strings.editProfile, pet?.name || "")}
          </h1>
          <div className="flex flex-col justify-center items-center space-y-6">
            <Card
              actionText={strings.back}
              onAction={() => navigate(-1)}
              size="lg"
              title={strings.basicInfo}
              type="simple"
            >
              <div className="flex flex-wrap justify-center items-center">
                {userPet && <PetProfilePictureSettings pet={userPet} refresh={fetchPet} />}
              </div>
              <FormProvider {...petFormMethods}>
                <form className="space-y-6" onSubmit={handleSubmit(putPet)}>
                  <PetForm />
                  <div className="w-full space-y-6">
                    <AlertBox message={error} />
                    <div className="flex flex-wrap gap-x-4 gap-y-6 md:flex-nowrap justify-between pt-6">
                      <Button disabled={!isDirty} fullWidth onClick={resetForm} variant="secondary">
                        {strings.discardChanges}
                      </Button>
                      <Button fullWidth loading={isLoading} type="submit">
                        {strings.save}
                      </Button>
                    </div>
                  </div>
                </form>
              </FormProvider>
            </Card>
            <Card type="simple" title={strings.petOwner} size="lg">
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-4">
                  <div style={{ width: "100px", height: "100px" }}>
                    <UserProfilePicture border userId={petOwnerUserData?.id} />
                  </div>
                  <span className="flex flex-col">
                    <Link to={clinicPetId ? `/pet-owner-profile/${petOwner?.id}` : `/profile-settings`}>
                      {petOwner?.fullName}
                    </Link>
                    {petOwner?.phones[0]?.value}
                  </span>
                </div>
                <Dropdown className="rounded-dropdown">
                  <Dropdown.Toggle as="div" bsPrefix="toggle-kebab" id="dropdown-pets-more">
                    <span className="material-icons">more_vert</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end" className="mt-1 p-0">
                    <Dropdown.Item
                      bsPrefix="calendar-menu dropdown-item hr-on-bottom"
                      href={clinicPetId ? `/edit-pet-owner-profile/${petOwner?.id}` : `/profile-settings`}
                    >
                      {strings.edit}
                    </Dropdown.Item>
                    <Dropdown.Item bsPrefix="calendar-menu dropdown-item hr-on-bottom" onClick={changeOwner}>
                      {strings.changeOwner}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Card>
          </div>
        </div>
        <ChangeOwnerModal
          close={() => {
            setChangeOwnerModalOpen(false);
          }}
          isOpen={changeOwnerModalOpen}
          pet={pet}
          petOwner={petOwner}
          ownerPictureUserId={petOwnerUserData?.id}
          userPetId={userPet?.id}
        />
        <ChangeUserOwnerModal
          close={() => {
            setChangeUserOwnerModalOpen(false);
          }}
          isOpen={changeUserOwnerModalOpen}
          pet={pet}
          petOwner={petOwner}
        />
      </section>
    </main>
  );
};

export default Loader(EditPet);
