/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import * as Sentry from "@sentry/react";
import React, { ErrorInfo, ReactNode } from "react";
import { getGeneralError } from "./helperFunctions";
import logger from "./logger";
import AlertBox from "../components/AlertBox";
import { strings } from "../common/Strings/Strings";
import Button from "../components/Button";
import { getFromLocalStorage, setInLocalStorage } from "./LocalStorageVariables";

interface Props {
  children: ReactNode;
  isSiteManager: boolean;
  isUnhandledRejectionHandled: boolean;
  setUnhandledRejectionHandled?: (newValue: boolean) => void;
}

interface State {
  errorMessage: string | null;
  isDetailsShown: boolean;
  errorStack: string | null;
  componentStack: string | null;
}

const sendErrorToSentry = (error: any) => {
  const userId = localStorage.getItem("userId");
  if (userId) {
    Sentry.setContext("user", { userId });
  }
  Sentry.captureException(error);
};

type StackType = "ErrorStack" | "ComponentStack";

const formatStackTrace = (stack: string, type: StackType) => {
  const stackLines = stack.split("\n");

  return stackLines.map((line, index) => {
    if (index === 0) {
      // For the first line, return it as is
      return (
        <p className="text-red-700 dark:text-red-400" key={index}>
          {type === "ErrorStack" ? line : "Error Component Stack:"}
        </p>
      );
    }
    // For subsequent lines, use a margin to indent
    return (
      <p className="text-red-700 dark:text-red-400" key={index} style={{ marginLeft: "20px" }}>
        {line}
      </p>
    );
  });
};

// Catches an unhandled error that happened during render, including unhandled rejections
// Also provides a detailed visual view for site managers (error stack & component stack) on top of logging to the console
/* Note: For testing consider using the util/ErrorTestComponent (to throw errors either during render or during runtime) */
class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { errorMessage: null, isDetailsShown: false, errorStack: null, componentStack: null };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { errorMessage: error.message };
  }

  componentDidMount(): void {
    window.addEventListener("unhandledrejection", this.promiseRejectionHandler);
    setInLocalStorage("isUnhandledRejectionHandled", String(false));
  }

  // Regular error handling
  componentDidCatch(error: Error, info: ErrorInfo): void {
    const data = { error, info };
    logger.error(data);

    sendErrorToSentry(error);

    console.log(info.componentStack);

    // Store the error message and stack trace
    getGeneralError(error, this.props.isSiteManager).then((msg) => {
      this.setState({
        errorMessage: msg,
        errorStack: error.stack || null,
        componentStack: info.componentStack || null,
      });
    });
  }

  componentWillUnmount(): void {
    window.removeEventListener("unhandledrejection", this.promiseRejectionHandler);
  }

  promiseRejectionHandler = async (event: PromiseRejectionEvent) => {
    // Also use local storage (next to context) for immediate feedback to other error boundaries
    const isUnhandledRejectionHandled =
      getFromLocalStorage("isUnhandledRejectionHandled") === "true" || this.props.isUnhandledRejectionHandled;
    if (isUnhandledRejectionHandled) return;

    const error = event.reason;
    logger.error({ error });

    sendErrorToSentry(error);

    // Store rejection message and stack trace (if available)
    this.setState({
      errorMessage: error.message || "Unhandled promise rejection",
      errorStack: error.stack || null,
    });

    this.props.setUnhandledRejectionHandled?.(true);
    setInLocalStorage("isUnhandledRejectionHandled", String(true));
  };

  render(): ReactNode {
    const { state, props } = this;

    const message = (
      <div className="flex flex-col gap-2 justify-start">
        <p className="text-red-700 dark:text-red-400">{state.errorMessage}</p>
        {this.props.isSiteManager && (
          <>
            <Button
              className="flex font-bold"
              onClick={() => {
                this.setState((prevState) => ({
                  isDetailsShown: !prevState.isDetailsShown,
                }));
              }}
              variant="basic"
            >
              {state.isDetailsShown ? strings.lessDetails : strings.moreDetails}...
            </Button>
            {state.isDetailsShown && (
              <>
                {state.errorStack && formatStackTrace(state.errorStack, "ErrorStack")}
                {state.componentStack && formatStackTrace(state.componentStack, "ComponentStack")}
              </>
            )}
          </>
        )}
      </div>
    );

    if (state.errorMessage) {
      return (
        <div className="fix-container">
          <div className="background-rectangle">
            <AlertBox message={message} closeAble={false} className="m-3" />
          </div>
        </div>
      );
    }

    return props.children;
  }
}

export default ErrorBoundary;
