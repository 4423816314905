/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { quantityUnits, strings } from "../../../common/Strings/Strings";
import CloseButton from "../../../components/CloseButton";
import SimpleCheckBox from "../../../components/InputFieldsSimple/SimpleCheckBox";
import { TreatmentResponse } from "../../../models/treatment/TreatmentResponse";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import Modal from "../../../components/Modal/Modal";
import Button from "../../../components/Button";

interface Props {
  close: () => void;
  deleteTreatment: (isDeleteAllSubItemsToo: boolean, resetDescriptions: boolean) => void;
  isOpen: boolean;
  treatmentToDelete?: TreatmentResponse & { contentNotAvailable?: boolean };
}

const DeleteTreatmentModal: React.FC<Props> = ({ close, deleteTreatment, isOpen, treatmentToDelete }: Props) => {
  const [deleteRelatedItems, setDeleteRelatedItems] = useState<boolean>(true);
  const [resetDescriptions, setResetDescriptions] = useState<boolean>(false);

  useEffect(() => {
    if (treatmentToDelete?.contentNotAvailable) {
      setResetDescriptions(false);
    } else if (
      (!treatmentToDelete?.description || treatmentToDelete?.description === "") &&
      (!treatmentToDelete?.advices || treatmentToDelete?.advices === "")
    ) {
      setResetDescriptions(false);
    } else {
      setResetDescriptions(false);
    }
  }, [treatmentToDelete]);

  const deleteSelectedTreatment = () => {
    if (treatmentToDelete) {
      deleteTreatment(deleteRelatedItems, resetDescriptions);
    }
  };

  return (
    <Modal handleClose={close} show={isOpen}>
      <Modal.Header title={`${strings.remove} (${treatmentToDelete?.name})`} />
      <Modal.Body>
        <div>
          <SimpleCheckBox
            checked={resetDescriptions}
            name="resetDescriptions"
            label={strings.resetTreatmentDescriptions}
            onChange={(value: boolean) => {
              setResetDescriptions(value);
            }}
          />
        </div>
        <div className="border-t pt-6">
          <SimpleCheckBox
            className={treatmentToDelete?.contentNotAvailable ? "opacity-50" : ""}
            checked={deleteRelatedItems}
            name="deleteRelatedItems"
            label={strings.deleteRelatedItems}
            onChange={(value: boolean) => {
              setDeleteRelatedItems(value);
            }}
            readOnly={treatmentToDelete?.contentNotAvailable}
          />
        </div>
        <div>
          <table
            className="tw-table"
            style={{
              opacity: !deleteRelatedItems || treatmentToDelete?.contentNotAvailable ? 0.4 : 1,
            }}
          >
            <thead className="tw-thead">
              <tr>
                <th className="tw-th">{strings.name}</th>
                <th className="tw-th">{strings.quantity}</th>
              </tr>
            </thead>
            <tbody className="tw-tbody">
              {(treatmentToDelete?.usedItems?.length || 0) > 0 && (
                <tr>
                  <td className="px-4 py-3 text-sm uppercase font-medium" colSpan={2}>
                    {strings.inventoryItems} ({treatmentToDelete?.usedItems?.length})
                  </td>
                </tr>
              )}
              {treatmentToDelete?.usedItems?.map((item) => (
                <tr key={item.id}>
                  <td className="px-4 py-3 text-sm font-medium">{item.item.name}</td>
                  <td className="px-4 py-3 text-sm font-medium">
                    {item.quantity} {quantityUnits[item.item.acquisitionUnit || "PIECES"]}
                  </td>
                </tr>
              ))}
              {(treatmentToDelete?.fees?.length || 0) > 0 && (
                <tr>
                  <td className="px-4 py-3 text-sm uppercase font-medium" colSpan={2}>
                    {strings.serviceFees} ({treatmentToDelete?.fees?.length})
                  </td>
                </tr>
              )}
              {treatmentToDelete?.fees?.map((item) => (
                <tr key={item.id}>
                  <td className="px-4 py-3 text-sm font-medium">{item.fee.name}</td>
                  <td className="px-4 py-3 text-sm font-medium">{item.quantity}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <AlertBox
            hidden={!treatmentToDelete?.contentNotAvailable}
            message={strings.contentNotAvailable}
            type={AlertType.WARNING}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="modal-main-button" onClick={deleteSelectedTreatment} variant="danger">
          {strings.remove}
        </Button>
        <CloseButton onClick={close} text={strings.cancel} />
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteTreatmentModal;
