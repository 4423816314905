/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ITokenResponse from "js-pkce/dist/ITokenResponse";
import { useUser } from "../../contexts/UserContext";
import { setUpAuthToken } from "../../util/axiosSetup";
import AuthApi from "../../api/AuthApi";
import { PageProps } from "../../models/PageProps";
import { Loader } from "../../components/Loader";

const Login: React.FC<PageProps> = ({ setPageLoading }: PageProps) => {
  const navigate = useNavigate();
  const { fetchUser } = useUser();

  useEffect(() => {
    const onAuthSuccess = (data: ITokenResponse) => {
      setUpAuthToken(data);
      fetchUser().then(() => {
        const loginRedirectPath = sessionStorage.getItem("loginRedirectPath");
        sessionStorage.removeItem("loginRedirectPath");
        navigate(loginRedirectPath || "/", { replace: true });
      });
    };

    const authenticate = async () => {
      setPageLoading(true);
      const url = window.location.href;
      try {
        const response = await AuthApi.exchangeForAccessToken(url);
        onAuthSuccess(response);
      } catch (e) {
        console.error("Authentication error:", e);
        window.location.replace(AuthApi.authorizeUrl());
      }
    };

    void authenticate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default Loader(Login);
