/*
 * Copyright © 2018-2022, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PageProps } from "../../../models/PageProps";
import { SiteManagerPage } from "../SiteManagerPage";
import { strings } from "../../../common/Strings/Strings";
import BroadcastTargetsTable from "./BroadcastTargetsTable";
import { getGeneralError } from "../../../util/helperFunctions";
import BroadcastNotificationApi from "../../../api/BroadcastNotificationApi";
import { BroadcastNotificationResponse } from "../../../models/notification/BroadcastNotificationResponse";
import AlertBox from "../../../components/AlertBox";
import BroadcastNotificationDetails from "./BroadcastNotificationDetails";

type Params = {
  broadcastId: string;
};

const BroadcastNotificationDetailsPage = (props: PageProps) => {
  const { setPageLoading } = props;
  const { broadcastId } = useParams<Params>();

  const [notification, setNotification] = useState<BroadcastNotificationResponse>();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getNotification = async () => {
      try {
        if (!broadcastId) return;
        setPageLoading(true);
        const resp = await BroadcastNotificationApi.getNotification(broadcastId);
        setNotification(resp.data);
        setError(null);
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setPageLoading(false);
      }
    };

    void getNotification();
  }, [broadcastId]);

  if (error) {
    return <AlertBox message={error} />;
  }

  if (!broadcastId || !notification) return;

  return (
    <div className="space-y-3">
      <BroadcastNotificationDetails notification={notification} />
      <BroadcastTargetsTable broadcastId={broadcastId} />
    </div>
  );
};

export default SiteManagerPage(BroadcastNotificationDetailsPage, strings.notification);
