/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ClinicCost } from "../../../models/cost/ClinicCost";
import Field from "../../../components/ReactHookFormFields/General/Field";
import { getStringFromEnumsOrReturn, strings } from "../../../common/Strings/Strings";
import CountryApi from "../../../api/CountryApi";
import { getGeneralError } from "../../../util/helperFunctions";
import { CountryPriceSettingsResponse } from "../../../models/management/CountryPriceSettingsResponse";
import AlertBox from "../../../components/AlertBox";
import NumberFormatter from "../../../util/NumberFormatter";
import CostCalculator from "../../../util/CostCalculator";

interface Props {
  countryCode?: string;
  currency?: string;
}

interface FeeTypeMap {
  [key: string]: number;
}

const PricingSection = ({ countryCode, currency }: Props): ReactElement => {
  const [chargeA] = useState<FeeTypeMap>({
    APPOINTMENT_FEE: 110,
    BASE_CLINIC_FEE: 1,
    MEDICAL_RECORD_FEE: 121,
    ASSISTANT_FEE: 1,
    VET_FEE: 1,
    RECEPTIONIST_FEE: 0,
    EMPLOYEE_FEE: 0,
    SMS_FEE: 0,
    INVENTORY_FEE: 0,
    PRESCRIPTION_FEE: 0,
    VIDEO_CONSULTATION_FEE: 0,
  });
  const [chargeB] = useState<FeeTypeMap>({
    APPOINTMENT_FEE: 203,
    BASE_CLINIC_FEE: 1,
    MEDICAL_RECORD_FEE: 257,
    ASSISTANT_FEE: 2,
    VET_FEE: 1,
    RECEPTIONIST_FEE: 1,
    EMPLOYEE_FEE: 0,
    SMS_FEE: 0,
    INVENTORY_FEE: 0,
    PRESCRIPTION_FEE: 0,
    VIDEO_CONSULTATION_FEE: 0,
  });
  const [chargeC] = useState<FeeTypeMap>({
    APPOINTMENT_FEE: 249,
    BASE_CLINIC_FEE: 1,
    MEDICAL_RECORD_FEE: 250,
    ASSISTANT_FEE: 1,
    VET_FEE: 2,
    RECEPTIONIST_FEE: 0,
    EMPLOYEE_FEE: 2,
    SMS_FEE: 0,
    INVENTORY_FEE: 0,
    PRESCRIPTION_FEE: 0,
    VIDEO_CONSULTATION_FEE: 0,
  });
  const [priceSettings, setPriceSettings] = useState<CountryPriceSettingsResponse[]>();
  const [error, setError] = useState<string | null>(null);
  const [suffix, setSuffix] = useState<string>("");

  const { register, watch, reset } = useForm();

  useEffect(() => {
    const getPriceSettings = async () => {
      if (countryCode) {
        try {
          const resp = await CountryApi.getCountryPriceSettings(countryCode);
          setPriceSettings(resp.data);
        } catch (err) {
          setError(await getGeneralError(err));
        }
      }
    };

    void getPriceSettings();
  }, [countryCode]);

  useEffect(() => {
    if (priceSettings) {
      const prices = priceSettings[0].clinicFees;
      const appointmentPrice = prices.find((p) => p.name === ClinicCost.APPOINTMENT_FEE)?.price || 0;
      const clinicPrice = prices.find((p) => p.name === ClinicCost.BASE_CLINIC_FEE)?.price || 0;
      const medicalRecordFee = prices.find((p) => p.name === ClinicCost.MEDICAL_RECORD_FEE)?.price || 0;
      const assistantFee = prices.find((p) => p.name === ClinicCost.ASSISTANT_FEE)?.price || 0;
      const vetFee = prices.find((p) => p.name === ClinicCost.VET_FEE)?.price || 0;
      const receptionistFee = prices.find((p) => p.name === ClinicCost.RECEPTIONIST_FEE)?.price || 0;
      const employeeFee = prices.find((p) => p.name === ClinicCost.EMPLOYEE_FEE)?.price || 0;

      const smsFee = prices.find((p) => p.name === ClinicCost.SMS_FEE)?.price || 0;
      const inventoryFee = prices.find((p) => p.name === ClinicCost.INVENTORY_FEE)?.price || 0;
      const prescriptionFee = prices.find((p) => p.name === ClinicCost.PRESCRIPTION_FEE)?.price || 0;
      const videoFee = prices.find((p) => p.name === ClinicCost.VIDEO_CONSULTATION_FEE)?.price || 0;

      reset({
        APPOINTMENT_FEE: appointmentPrice,
        BASE_CLINIC_FEE: clinicPrice,
        MEDICAL_RECORD_FEE: medicalRecordFee,
        ASSISTANT_FEE: assistantFee,
        VET_FEE: vetFee,
        RECEPTIONIST_FEE: receptionistFee,
        EMPLOYEE_FEE: employeeFee,
        SMS_FEE: smsFee,
        INVENTORY_FEE: inventoryFee,
        PRESCRIPTION_FEE: prescriptionFee,
        VIDEO_CONSULTATION_FEE: videoFee,
      });
    }
  }, [priceSettings, reset]);

  useEffect(() => {
    if (currency) {
      setSuffix(`${currency}/${strings.month.toLowerCase()}`);
    }
  }, [currency]);

  const getTotalPrice = (clinic: FeeTypeMap) => {
    let sum = 0;

    Object.keys(ClinicCost).forEach((feeType: string) => {
      sum += (clinic[feeType] || 0) * (watch(feeType) || 0);
    });

    return sum;
  };

  const getAvarage = (list: Array<number>) => list.reduce((sum, currentValue) => sum + currentValue, 0) / list.length;

  return (
    <>
      <div>
        <div className="flex flex-col items-start md:items-center justify-center space-y-6 mx-auto px-4 md:px-0">
          <div className="w-full space-y-6 tw-card">
            <div className="p-4 space-y-6">
              <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex">
                {strings.pricingExamples}
              </h2>
              <AlertBox message={error} />
              <form>
                <table className="tw-table">
                  <thead className="tw-thead">
                    <tr>
                      <th className="tw-th">{strings.feeName}</th>
                      <th className="tw-th">
                        {strings.unitPrice} ({strings.net})
                      </th>
                      <th className="tw-th">{strings.vatAsTax}</th>
                      <th className="tw-th">
                        {strings.example} {strings.clinic} 1
                      </th>
                      <th className="tw-th">
                        {strings.example} {strings.clinic} 2
                      </th>
                      <th className="tw-th">
                        {strings.example} {strings.clinic} 3
                      </th>
                      <th className="tw-th">{strings.avarage}</th>
                    </tr>
                  </thead>
                  <tbody className="tw-tbody">
                    {Object.keys(ClinicCost).map((feeType: string) => {
                      const vat = priceSettings?.[0]?.clinicFees?.find((p) => p.name === feeType)?.vat;
                      return (
                        <tr>
                          <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
                            {getStringFromEnumsOrReturn(feeType)}
                          </td>
                          <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
                            <Field name={feeType} register={register} suffix={suffix} />
                          </td>
                          <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">{vat} %</td>
                          <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
                            <b>{chargeA[feeType] || 0}</b> * {watch(feeType) || 0} =
                            <div>
                              {(chargeA[feeType] || 0) * (watch(feeType) || 0)} {suffix} ({strings.net})
                            </div>
                            <div>
                              {CostCalculator.getGrossPrice((chargeA[feeType] || 0) * (watch(feeType) || 0), vat)}{" "}
                              {suffix} ({strings.gross})
                            </div>
                          </td>
                          <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
                            <b>{chargeB[feeType] || 0}</b> * {watch(feeType) || 0} =
                            <div>
                              {(chargeB[feeType] || 0) * (watch(feeType) || 0)} {suffix} ({strings.net})
                            </div>
                            <div>
                              {CostCalculator.getGrossPrice((chargeB[feeType] || 0) * (watch(feeType) || 0), vat)}{" "}
                              {suffix} ({strings.gross})
                            </div>
                          </td>
                          <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
                            <b>{chargeC[feeType] || 0}</b> * {watch(feeType) || 0} =
                            <div>
                              {(chargeC[feeType] || 0) * (watch(feeType) || 0)} {suffix} ({strings.net})
                            </div>
                            <div>
                              {CostCalculator.getGrossPrice((chargeC[feeType] || 0) * (watch(feeType) || 0), vat)}{" "}
                              {suffix} ({strings.gross})
                            </div>
                          </td>
                          <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
                            {NumberFormatter.formatPrice(
                              getAvarage([
                                (chargeA[feeType] || 0) * (watch(feeType) || 0),
                                (chargeB[feeType] || 0) * (watch(feeType) || 0),
                                (chargeC[feeType] || 0) * (watch(feeType) || 0),
                              ])
                            )}{" "}
                            {suffix} ({strings.net})
                            <div>
                              {NumberFormatter.formatPrice(
                                CostCalculator.getGrossPrice(
                                  getAvarage([
                                    (chargeA[feeType] || 0) * (watch(feeType) || 0),
                                    (chargeB[feeType] || 0) * (watch(feeType) || 0),
                                    (chargeC[feeType] || 0) * (watch(feeType) || 0),
                                  ]),
                                  vat
                                )
                              )}{" "}
                              {suffix} ({strings.gross})
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td className="px-4 py-2 text-sm font-bold whitespace-nowrap">{strings.total} ({strings.net})</td>
                      <td />
                      <td />
                      <td className="px-4 py-2 text-sm font-bold whitespace-nowrap">
                        {NumberFormatter.formatPrice(getTotalPrice(chargeA), suffix)}
                      </td>
                      <td className="px-4 py-2 text-sm font-bold whitespace-nowrap">
                        {NumberFormatter.formatPrice(getTotalPrice(chargeB), suffix)}
                      </td>
                      <td className="px-4 py-2 text-sm font-bold whitespace-nowrap">
                        {NumberFormatter.formatPrice(getTotalPrice(chargeC), suffix)}
                      </td>
                      <td className="px-4 py-2 text-sm font-bold whitespace-nowrap">
                        {NumberFormatter.formatPrice(
                          getAvarage([getTotalPrice(chargeA), getTotalPrice(chargeB), getTotalPrice(chargeC)]),
                          suffix
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
                        {strings.medicalRecord}/{strings.vet}/{strings.month}
                      </td>
                      <td />
                      <td />
                      <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
                        {chargeA[ClinicCost.MEDICAL_RECORD_FEE] / chargeA[ClinicCost.VET_FEE]}
                      </td>
                      <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
                        {chargeB[ClinicCost.MEDICAL_RECORD_FEE] / chargeB[ClinicCost.VET_FEE]}
                      </td>
                      <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
                        {chargeC[ClinicCost.MEDICAL_RECORD_FEE] / chargeC[ClinicCost.VET_FEE]}
                      </td>
                      <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
                        {NumberFormatter.format(
                          getAvarage([
                            chargeA[ClinicCost.MEDICAL_RECORD_FEE] / chargeA[ClinicCost.VET_FEE],
                            chargeB[ClinicCost.MEDICAL_RECORD_FEE] / chargeB[ClinicCost.VET_FEE],
                            chargeC[ClinicCost.MEDICAL_RECORD_FEE] / chargeC[ClinicCost.VET_FEE],
                          ])
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
                        {strings.medicalRecord}/{strings.vet}/{strings.month} ({strings.price})
                      </td>
                      <td />
                      <td />
                      <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
                        {NumberFormatter.formatPrice(chargeA[ClinicCost.MEDICAL_RECORD_FEE] / chargeA[ClinicCost.VET_FEE] * watch(ClinicCost.MEDICAL_RECORD_FEE), suffix)}
                      </td>
                      <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
                        {NumberFormatter.formatPrice(chargeB[ClinicCost.MEDICAL_RECORD_FEE] / chargeB[ClinicCost.VET_FEE] * watch(ClinicCost.MEDICAL_RECORD_FEE), suffix)}
                      </td>
                      <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
                        {NumberFormatter.formatPrice(chargeC[ClinicCost.MEDICAL_RECORD_FEE] / chargeC[ClinicCost.VET_FEE] * watch(ClinicCost.MEDICAL_RECORD_FEE), suffix)}
                      </td>
                      <td className="px-4 py-2 text-sm font-medium whitespace-nowrap">
                        {NumberFormatter.formatPrice(
                          getAvarage([
                            chargeA[ClinicCost.MEDICAL_RECORD_FEE] / chargeA[ClinicCost.VET_FEE] * watch(ClinicCost.MEDICAL_RECORD_FEE),
                            chargeB[ClinicCost.MEDICAL_RECORD_FEE] / chargeB[ClinicCost.VET_FEE] * watch(ClinicCost.MEDICAL_RECORD_FEE),
                            chargeC[ClinicCost.MEDICAL_RECORD_FEE] / chargeC[ClinicCost.VET_FEE] * watch(ClinicCost.MEDICAL_RECORD_FEE),
                          ]), suffix
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingSection;
