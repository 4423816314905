/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { setLocale, SupportedLanguages } from "../../../Strings/Strings";
import { useUser } from "../../../../contexts/UserContext";
import UserApi from "../../../../api/UserApi";
import { getLocaleForActiveUser, setLocaleForActiveUser } from "../../../../util/LocalStorageVariables";
import { getGeneralError } from "../../../../util/helperFunctions";
import logger from "../../../../util/logger";
import ChooseLanguageModalBase from "../../CommonModals/ChooseLanguageModalBase";

interface Props {
  closeModal(): void;
  showModal: boolean;
}

const ChooseLanguageModal: React.FC<Props> = ({ closeModal, showModal }: Props) => {
  const { user, setUser } = useUser();

  const userLocale = user.locale ?? getLocaleForActiveUser();
  const currentLanguageCode = SupportedLanguages.find((lan) => lan.code === userLocale)?.code;

  const changeLanguage = async (languageCode: string) => {
    try {
      const request = {
        locale: languageCode,
      };
      const response = await UserApi.updateLocale(request);
      const { data } = response;
      setUser(data);
      closeModal();
      if (data.locale) {
        setLocaleForActiveUser(data.locale);
        setLocale(data.locale);
        window.location.reload();
      }
    } catch (err) {
      logger.error(await getGeneralError(err));
    }
  };

  return (
    <ChooseLanguageModalBase
      changeLanguage={changeLanguage}
      close={closeModal}
      currentLanguageCode={currentLanguageCode}
      isOpen={showModal}
    />
  );
};

export default ChooseLanguageModal;
