/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import Params from "../common/Params";
import { ApiPromise } from "../models/ApiPromise";
import { SearchClinics } from "../models/apiparams/SearchClinics";
import { BasicClinicResponse } from "../models/clinic/BasicClinicResponse";
import { ClinicFeeRequest } from "../models/clinic/ClinicFeeRequest";
import { ClinicResponse } from "../models/clinic/ClinicResponse";
import { ClinicUpdateRequest } from "../models/clinic/ClinicUpdateRequest";
import { GiroAccountResponse } from "../models/clinic/GiroAccountResponse";
import { GiroAccountsRequest } from "../models/clinic/GiroAccountsRequest";
import { UserClinicWithRolesResponse } from "../models/clinic/UserClinicWithRolesResponse";
import { axiosCommon } from "../util/axiosSetup";
import { RoundingStrategyRequest } from "../models/clinic/RoundingStrategyRequest";

export default class ClinicApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static getClinic(clinicId: string): ApiPromise<ClinicResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}`;
    return axiosCommon.get(url);
  }

  static getEmployeeClinics(): ApiPromise<UserClinicWithRolesResponse[]> {
    return axiosCommon.get(`${this.baseUrl}/employee-clinics`);
  }

  static getClinicsForPayment(clinicIds: string[]): ApiPromise<BasicClinicResponse[]> {
    const url = `${this.baseUrl}/clinics-for-payment?ids=${clinicIds}`;
    return axiosCommon.get(url);
  }

  static getClinics(requestParams: SearchClinics = {}): ApiPromise<BasicClinicResponse[]> {
    const url = `${this.baseUrl}/clinics`;
    return axiosCommon.get(url, { params: { ...requestParams } });
  }

  static updateClinic(clinicId: string, clinic: ClinicUpdateRequest): ApiPromise<ClinicResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}`;
    return axiosCommon.put(url, clinic);
  }

  static updateClinicFees(clinicId: string, request: ClinicFeeRequest[]): ApiPromise<ClinicResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/fees`;
    return axiosCommon.put(url, request);
  }

  static sendClinicDeletionMail(clinicId: string, userPassword: string): ApiPromise<void> {
    const url = `${this.baseUrl}/clinics/${clinicId}/send-deletion-mail`;
    return axiosCommon.post(url, { userPassword });
  }

  static finalizeClinicDeletion(clinicId: string, deletionToken: string): ApiPromise<void> {
    const url = `${this.baseUrl}/clinics/${clinicId}?deletionToken=${deletionToken}`;
    return axiosCommon.delete(url);
  }

  static updateGiroAccounts(clinicId: string, request: GiroAccountsRequest): ApiPromise<GiroAccountResponse[]> {
    const url = `${this.baseUrl}/clinics/${clinicId}/giro`;
    return axiosCommon.put(url, request);
  }

  static updateRoundingStrategy(clinicId: string, request: RoundingStrategyRequest): ApiPromise<ClinicResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/rounding`;
    return axiosCommon.put(url, request);
  }
}
