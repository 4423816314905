/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getStringFromEnumsOrReturn, strings, SupportedLanguages } from "../../../common/Strings/Strings";
import { getGeneralError } from "../../../util/helperFunctions";
import { PageProps } from "../../../models/PageProps";
import { SiteManagerPage } from "../SiteManagerPage";
import { UserManagementItemResponse } from "../../../models/management/UserManagementItemResponse";
import { UserDetailsResponse } from "../../../models/management/UserDetailsResponse";
import { CountryNameResponse } from "../../../models/management/CountryNameResponse";
import { FormProvider, useForm } from "react-hook-form";
import { TargetGroup } from "../../../models/notification/TargetGroup";
import CountryApi from "../../../api/CountryApi";
import { DeliveryType } from "../../../models/notification/DeliveryType";
import BroadcastNotificationApi from "../../../api/BroadcastNotificationApi";
import { BroadcastNotificationType } from "../../../models/notification/BroadcastNotificationType";
import CheckBox from "../../../components/ReactHookFormFields/General/CheckBox";
import Field from "../../../components/ReactHookFormFields/General/Field";
import Textarea from "../../../components/ReactHookFormFields/General/Textarea";
import Select from "../../../components/ReactHookFormFields/General/Select/Select";
import UserSearchInput from "../Users/UserSearchInput";
import UserProfilePicture from "../../../components/Pictures/User/UserProfilePicture";
import Button from "../../../components/Button";
import { Cross } from "../../../common/Icons/Cross";
import AlertBox from "../../../components/AlertBox";
import InAppNotificationPreview from "./InAppNotificationPreview";
import SmsNotificationPreview from "./SmsNotificationPreview";
import PushNotificationPreview from "./PushNotificationPreview";
import EmailNotificationPreview from "./EmailNotificationPreview";

interface NotificationForm {
  targetGroup: TargetGroup;
  countryCode?: string;
  locale?: string;
  title: string;
  message: string;
  path: string;
  type: BroadcastNotificationType;
  push: boolean;
  sms: boolean;
  inApp: boolean;
  email: boolean;
  emailMessage: string;
  emailTitle: string;
  actionButton?: string;
}

function BroadcastNotificationEditorPage(props: PageProps): ReactElement {
  const { setPageLoading } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const preselectedUser: UserDetailsResponse | undefined = location.state?.preselectedUser;

  const [error, setError] = useState<string | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [emailEnabled, setEmailEnabled] = useState<boolean>(false);
  const [customTargetSelected, setCustomTargetSelected] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<(UserManagementItemResponse | UserDetailsResponse)[]>([]);

  const [countries, setCountries] = useState<CountryNameResponse[]>([]);

  const notificationForm = useForm<NotificationForm>({
    mode: "onChange",
    defaultValues: {
      title: "GlobalVet",
      path: "/",
      inApp: true,
      targetGroup: preselectedUser ? TargetGroup.SELECTED_USERS : undefined,
    },
  });
  const { formState, register, handleSubmit, reset, watch } = notificationForm;

  const { targetGroup, title, message, push, sms, inApp, email, path, emailMessage, emailTitle, actionButton } =
    watch();

  useEffect(() => {
    const getCountries = async () => {
      setPageLoading(true);
      try {
        const response = await CountryApi.getCountryNames();
        setCountries(response.data);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setPageLoading(false);
      }
    };

    void getCountries();
    reset();
    setEmailEnabled(false);
    setCustomTargetSelected(!!preselectedUser);
    setSelectedUsers(preselectedUser ? [preselectedUser] : []);
    setError(null);
  }, []);

  const submit = async (data: NotificationForm) => {
    try {
      setLoading(true);

      const deliveryTypes: DeliveryType[] = [];
      if (data.email) deliveryTypes.push(DeliveryType.EMAIL);
      if (data.inApp) deliveryTypes.push(DeliveryType.IN_APP);
      if (data.push) deliveryTypes.push(DeliveryType.PUSH);
      if (data.sms) deliveryTypes.push(DeliveryType.SMS);

      const emailRequest = {
        title: data.emailTitle,
        message: data.emailMessage,
        actionButton: data.actionButton,
      };

      const request = {
        targetGroup: data.targetGroup,
        countryCode: !customTargetSelected ? data.countryCode || undefined : undefined,
        locale: !customTargetSelected ? data.locale || undefined : undefined,
        selectedUserIds: customTargetSelected ? selectedUsers.map((i) => i.userId) : undefined,
        title: data.title,
        message: data.message,
        path: data.path,
        email: data.email ? emailRequest : undefined,
        type: data.type,
        deliveryTypes: deliveryTypes,
      };

      await BroadcastNotificationApi.sendNotification(request);
      navigate("/", { replace: true });
    } catch (e) {
      const err: any = e;
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <FormProvider {...notificationForm}>
        <div className="grid lg:grid-cols-2 gap-4">
          <div className="space-y-3">
            <div className="tw-card space-y-3 px-3 py-3">
              <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex">
                {strings.newNotificationTarget}
              </h2>
              <div className="space-y-4">
                <Select
                  label={strings.notificationTargetGroup}
                  name="targetGroup"
                  options={Object.keys(TargetGroup).map((i) => ({
                    title: getStringFromEnumsOrReturn(i),
                    value: i,
                  }))}
                  register={register}
                  error={formState.errors.targetGroup}
                  onChange={(e) => setCustomTargetSelected(e.target.value == TargetGroup.SELECTED_USERS)}
                  required
                />
                {customTargetSelected && (
                  <>
                    <UserSearchInput
                      onSelect={(user) => setSelectedUsers((prev) => [...prev, user])}
                      onError={(e) => setError(e)}
                    />
                    <div hidden={selectedUsers.length <= 0} className="tw-card divide-y dark:divide-gray-900 text-sm">
                      {selectedUsers.map((user: UserManagementItemResponse, index: number) => (
                        <div className="px-4 py-3" key={index}>
                          <div className="flex flex-wrap items-center space-x-2">
                            <div>
                              <div style={{ width: "30px", height: "30px" }}>
                                <UserProfilePicture userId={user.userId} />
                              </div>
                            </div>
                            <div className="flex-grow-1">{user.fullName}</div>
                            <Button
                              onClick={() => setSelectedUsers((prew) => prew.filter((i) => i.userId != user.userId))}
                              variant="icon"
                            >
                              <Cross />
                            </Button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
                {targetGroup && !customTargetSelected && (
                  <>
                    <Select
                      label={strings.country}
                      name="countryCode"
                      options={countries.map((country) => ({
                        title: country.name,
                        value: country.code,
                      }))}
                      register={register}
                    />
                    <Select
                      label={strings.language}
                      name="locale"
                      options={SupportedLanguages.map((lan) => ({
                        title: lan.title,
                        value: lan.code,
                      }))}
                      register={register}
                    />
                  </>
                )}
              </div>
            </div>

            <div className="tw-card space-y-3 px-3 py-3">
              <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex">
                {strings.deliveryType}
              </h2>
              <div>
                <CheckBox className="mt-2" name="inApp" register={register} label={strings.deliveryTypeInApp} />
                <CheckBox className="mt-2" name="push" register={register} label={strings.deliveryTypePush} />
                <CheckBox className="mt-2" name="sms" register={register} label={strings.deliveryTypeSms} />
                <CheckBox
                  className="mt-2"
                  name="email"
                  register={register}
                  label={strings.deliveryTypeEmail}
                  onChange={(selected) => setEmailEnabled(selected)}
                />
              </div>
            </div>

            {(inApp || push || sms) && (
              <div className="tw-card space-y-3 px-3 py-3">
                <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex">
                  {strings.newNotificationContent}
                </h2>
                <div className="space-y-4">
                  <Field
                    label={strings.title}
                    name="title"
                    register={register}
                    error={formState.errors.title}
                    required
                  />
                  <Textarea
                    label={strings.message}
                    name="message"
                    minRows={3}
                    register={register}
                    error={formState.errors.message}
                    required
                  />
                </div>
              </div>
            )}

            {emailEnabled && (
              <div className="tw-card space-y-3 px-3 py-3">
                <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex">
                  {strings.newNotificationEmailContent}
                </h2>
                <div className="space-y-4">
                  <Field
                    label={strings.emailNotificationSubject}
                    name="emailTitle"
                    register={register}
                    error={formState.errors.emailTitle}
                    required
                  />
                  <Textarea
                    label={strings.emailNotificationMessage}
                    name="emailMessage"
                    minRows={3}
                    register={register}
                    error={formState.errors.emailMessage}
                    required
                  />
                  <Field
                    label={strings.emailActionButton}
                    name="actionButton"
                    register={register}
                    error={formState.errors.actionButton}
                  />
                </div>
              </div>
            )}

            <div className="tw-card space-y-3 px-3 py-3">
              <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex">
                {strings.newNotificationSettings}
              </h2>
              <div className="space-y-4">
                <Select
                  label={strings.type}
                  name="type"
                  options={Object.keys(BroadcastNotificationType).map((i) => ({
                    title: getStringFromEnumsOrReturn(i),
                    value: i,
                  }))}
                  register={register}
                  error={formState.errors.type}
                  required
                />
                <Field
                  label={strings.notificationPath}
                  name="path"
                  register={register}
                  error={formState.errors.path}
                  required
                />
              </div>
            </div>

            <AlertBox message={error} />

            <div className="text-right">
              <Button
                className="modal-main-button"
                variant="primary"
                type="submit"
                disabled={!formState.isValid || isLoading}
                loading={formState.isValid && isLoading}
              >
                {strings.emailSend}
              </Button>
            </div>
          </div>

          <div>
            <div className="tw-card space-y-3 px-3 py-3">
              <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex">
                {strings.newNotificationPreview}
              </h2>
              <div className="space-y-4">
                <div hidden={!inApp} className="space-y-2">
                  <label>{strings.deliveryTypeInApp}</label>
                  <InAppNotificationPreview title={title} message={message} />
                </div>
                <div hidden={!push} className="space-y-2">
                  <label>{strings.deliveryTypePush}</label>
                  <PushNotificationPreview title={title} message={message} />
                </div>
                <div hidden={!sms} className="space-y-2">
                  <label>{strings.deliveryTypeSms}</label>
                  <SmsNotificationPreview title={title} message={message} />
                </div>
                <div hidden={!email} className="space-y-2">
                  <label>{strings.deliveryTypeEmail}</label>
                  <EmailNotificationPreview
                    title={emailTitle}
                    message={emailMessage}
                    actionButton={actionButton}
                    path={path}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </FormProvider>
    </form>
  );
}

export default SiteManagerPage(BroadcastNotificationEditorPage, strings.newNotification);
