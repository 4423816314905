/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import qs from "query-string";
import Params from "../common/Params";
import { ApiPromise } from "../models/ApiPromise";
import { ClinicPetResponse } from "../models/pet/ClinicPetResponse";
import { NewPetRequest } from "../models/pet/NewPetRequest";
import { UpdatePetRequest } from "../models/pet/UpdatePetRequest";
import { axiosCommon } from "../util/axiosSetup";
import { ClinicPetAndOwnerResponse } from "../models/pet/ClinicPetAndOwnerResponse";
import { SendMedicalRecordsMailRequest } from "../models/pet/SendMedicalRecordsMailRequest";

export default class ClinicPetApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static createClinicPet(petOwnerId: string, petRequest: NewPetRequest): ApiPromise<ClinicPetResponse> {
    const url = `${this.baseUrl}/pet-owners/${petOwnerId}/pets`;
    return axiosCommon.post(url, petRequest);
  }

  static getClinicPet(petId: string): ApiPromise<ClinicPetAndOwnerResponse> {
    const url = `${this.baseUrl}/clinic-pets/${petId}`;
    return axiosCommon.get(url);
  }

  static searchClinicPet(
    clinicId: string,
    searchQuery: string,
    limit?: number
  ): ApiPromise<ClinicPetAndOwnerResponse[]> {
    const query = qs.stringify({ query: searchQuery, limit });
    const url = `${this.baseUrl}/clinics/${clinicId}/pets?${query}`;
    return axiosCommon.get(url);
  }

  static updateClinicPet(petId: string, updateRequest: UpdatePetRequest): ApiPromise<ClinicPetResponse> {
    const url = `${this.baseUrl}/clinic-pets/${petId}`;
    return axiosCommon.put(url, updateRequest);
  }

  static archiveClinicPet(petId: string): ApiPromise<ClinicPetResponse> {
    const url = `${this.baseUrl}/clinic-pets/${petId}`;
    return axiosCommon.delete(url);
  }

  static emailPetDocuments(petId: string, mailRequest: SendMedicalRecordsMailRequest): ApiPromise<void> {
    const url = `${this.baseUrl}/clinic-pets/${petId}/send-medical-records-mail`;
    return axiosCommon.post(url, mailRequest);
  }
}
