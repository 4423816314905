/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { getGeneralError } from "../../../util/helperFunctions";
import { getStringFromEnumsOrReturn, strings } from "../../../common/Strings/Strings";
import { PageResponse } from "../../../models/PageResponse";
import { usePager } from "../../../hooks/hooks";
import PageableTable from "../../../components/PageableTable/PageableTable";
import PageableTableHead from "../../../components/PageableTable/PageableTableHead";
import PageableTableBody from "../../../components/PageableTable/PageableTableBody";
import { ChevronUp } from "../../../common/Icons/ChevronUp";
import { ChevronDown } from "../../../common/Icons/ChevronDown";
import Button from "../../../components/Button";
import { NotificationManagementResponse } from "../../../models/notification/NotificationManagementResponse";
import NotificationApi from "../../../api/NotificationApi";
import { DeliveryType } from "../../../models/notification/DeliveryType";
import { CheckCircle } from "../../../common/Icons/CheckCircle";
import { XCircle } from "../../../common/Icons/XCircle";

interface Props {
  userId: string;
}

const UserNotificationsTab: React.FC<Props> = (props: Props) => {
  const { userId } = props;

  const [notifications, setNotifications] = useState<PageResponse<NotificationManagementResponse>>();
  const [isCollapseOpenList, setIsCollapseOpenList] = useState<{
    [key: string]: boolean;
  }>({});

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const pager = usePager(50);

  const getNotifications = async () => {
    setLoading(true);

    try {
      const response = await NotificationApi.getNotificationsOfUser(userId, pager);
      setNotifications(response.data);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void getNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, pager.pageNumber, pager.pageSize]);

  const getDeliveryTypeState = (notification: NotificationManagementResponse, deliveryType: DeliveryType) => {
    return (
      <div className="inline-block">
        <div hidden={!notification.deliveryTypes.includes(deliveryType)} className="color-green">
          <CheckCircle />
        </div>
        <div hidden={notification.deliveryTypes.includes(deliveryType)} className="color-light-grey">
          <XCircle />
        </div>
      </div>
    );
  };

  return (
    <div>
      <PageableTable pager={pager} isLoading={loading} content={notifications} error={error}>
        <PageableTableHead>
          <tr>
            <th className="tw-th" />
            <th className="tw-th">{strings.notification}</th>
            <th className="tw-th text-center">{strings.notificationSettingsEmail}</th>
            <th className="tw-th text-center">{strings.notificationSettingsInApp}</th>
            <th className="tw-th text-center">{strings.notificationSettingsPush}</th>
            <th className="tw-th text-center">{strings.notificationSettingsSms}</th>
            <th className="tw-th text-right">{strings.notificationSendDate}</th>
            <th className="tw-th text-right">{strings.notificationCancelDate}</th>
            <th className="tw-th text-right">{strings.notificationReadDate}</th>
          </tr>
        </PageableTableHead>
        <PageableTableBody>
          {notifications &&
            notifications.elements.map((notification, index: number) => (
              <Fragment key={index}>
                <tr
                  onClick={() => {
                    setIsCollapseOpenList({
                      ...isCollapseOpenList,
                      [notification.id]: !isCollapseOpenList[notification.id],
                    });
                  }}
                >
                  <td className="px-4 py-4 text-sm">
                    <div className="flex">
                      <Button variant="basic">
                        {isCollapseOpenList[notification.id] ? <ChevronUp /> : <ChevronDown />}
                      </Button>
                    </div>
                  </td>
                  <td className="px-4 py-4 text-sm">
                    <strong>{getStringFromEnumsOrReturn(notification.type)}</strong>
                  </td>
                  <td className="px-4 py-4 text-sm text-center">
                    {getDeliveryTypeState(notification, DeliveryType.EMAIL)}
                  </td>
                  <td className="px-4 py-4 text-sm text-center">
                    {getDeliveryTypeState(notification, DeliveryType.IN_APP)}
                  </td>
                  <td className="px-4 py-4 text-sm text-center">
                    {getDeliveryTypeState(notification, DeliveryType.PUSH)}
                  </td>
                  <td className="px-4 py-4 text-sm text-center">
                    {getDeliveryTypeState(notification, DeliveryType.SMS)}
                  </td>
                  <td className="px-4 py-4 text-sm whitespace-nowrap text-right">
                    <span title={moment(notification.sent).format("LLL")}>{moment(notification.sent).fromNow()}</span>
                  </td>
                  <td className="px-4 py-4 text-sm whitespace-nowrap text-right">
                    <span hidden={!notification.cancelled} title={moment(notification.cancelled).format("LLL")}>
                      {moment(notification.cancelled).fromNow()}
                    </span>
                  </td>
                  <td className="px-4 py-4 text-sm whitespace-nowrap text-right">
                    <span hidden={!notification.read} title={moment(notification.read).format("LLL")}>
                      {moment(notification.read).fromNow()}
                    </span>
                  </td>
                </tr>
                <tr className="border-t-0" hidden={!isCollapseOpenList[notification.id]}>
                  <td />
                  <td colSpan={9} className="px-4 pb-4 text-sm">
                    <div>
                      <strong>{strings.title}: </strong>
                      {notification.title}
                    </div>
                    <div>
                      <strong>{strings.message}: </strong>
                      {notification.message}
                    </div>
                    <div hidden={!notification.email}>
                      <strong>{strings.emailNotificationSubject}: </strong>
                      {notification.email?.title}
                    </div>
                    <div hidden={!notification.email}>
                      <strong>{strings.emailNotificationMessage}: </strong>
                      {notification.email?.message}
                    </div>
                  </td>
                </tr>
              </Fragment>
            ))}
        </PageableTableBody>
      </PageableTable>
    </div>
  );
};

export default UserNotificationsTab;
