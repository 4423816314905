/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { Dropdown } from "flowbite-react";
import { NotificationResponse } from "../../../models/notification/NotificationResponse";
import { strings } from "../../Strings/Strings";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../../contexts/NotificationContext";
import moment from "moment";

interface Props {
  notifications: NotificationResponse[];
}

export const NotificationList: React.FC<Props> = ({ notifications }: Props) => {
  const { read } = useNotification();
  const navigate = useNavigate();

  const open = (
    event: React.MouseEvent<HTMLAnchorElement>,
    notification: NotificationResponse
  ) => {
    void read(notification.id);
    navigate(notification.path);
    event.preventDefault();
  };

  const getPathWithId = (notification: NotificationResponse): string => {
    let targetUrl = notification.path || "/";
    targetUrl += targetUrl.includes("?") ? "&" : "?";
    targetUrl += `nId=${notification.id}`;
    return targetUrl;
  };

  return (
    <>
      <Dropdown.Header className="flex justify-center items-center font-bold">
        {strings.notifications}
      </Dropdown.Header>
      {!notifications.length && (
        <div className="flex justify-center items-center text-center my-4 text-sm">
          <i>{strings.noUnreadNotifications}</i>
        </div>
      )}
      {notifications.map((n: NotificationResponse, i) => (
        <div key={i}>
          <Dropdown.Item className="cursor-pointer flex justify-center items-center">
            <div className="w-full flex flex-col py-2 px-3 text-left">
              <a
                href={getPathWithId(n)}
                onClick={(event) => open(event, n)}
                className="text-decoration-none"
              >
                <p className="text-sm font-normal">{n.message}</p>
                <p className="text-xs font-light mt-1">
                  {moment(n.sent).fromNow()}
                </p>
              </a>
            </div>
          </Dropdown.Item>
          {notifications[i + 1] ? <Dropdown.Divider className="h-0.5" /> : null}
        </div>
      ))}
    </>
  );
};

export default NotificationList;
