/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import * as Sentry from "@sentry/react";
import React, { ReactNode } from "react";
import { strings } from "../../common/Strings/Strings";
import Modal from "../../components/Modal/Modal";
import AlertBox from "../../components/AlertBox";
import CloseButton from "../../components/CloseButton";
import logger from "./Logger";
import { getGeneralError } from "../../util/helperFunctions";
import Button from "../../components/Button";
import { getFromLocalStorage, setInLocalStorage } from "../../util/LocalStorageVariables";

interface Props {
  children: ReactNode;
  isSiteManager: boolean;
  isUnhandledRejectionHandled: boolean;
  setUnhandledRejectionHandled?: (newValue: boolean) => void;
}

interface State {
  errorMessage: string | null;
  isDetailsShown: boolean;
  errorStack: string | null;
  componentStack: string | null;
}

const sendErrorToSentry = (error: any) => {
  const userId = localStorage.getItem("userId");
  if (userId) {
    Sentry.setContext("user", { userId });
  }
  Sentry.captureException(error);
};

type StackType = "ErrorStack" | "ComponentStack";

const formatStackTrace = (stack: string, type: StackType) => {
  const stackLines = stack.split("\n");

  return stackLines.map((line, index) => {
    if (index === 0) {
      // For the first line, return it as is
      return (
        <p className="text-red-700 dark:text-red-400" key={index}>
          {type === "ErrorStack" ? line : "Error Component Stack:"}
        </p>
      );
    }
    // For subsequent lines, use a margin to indent
    return (
      <p className="text-red-700 dark:text-red-400" key={index} style={{ marginLeft: "20px" }}>
        {line}
      </p>
    );
  });
};

class VideoConsultationErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorMessage: "",
      isDetailsShown: false,
      errorStack: null,
      componentStack: null,
    };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { errorMessage: error.message };
  }

  componentDidMount(): void {
    window.addEventListener("unhandledrejection", this.promiseRejectionHandler);
    setInLocalStorage("isUnhandledRejectionHandled", String(false));
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    const data = { error, errorInfo };
    logger.error(data);

    sendErrorToSentry(error);

    // Store the error message, stack trace, and component stack
    getGeneralError(error, this.props.isSiteManager).then((msg) => {
      this.setState({
        errorMessage: msg,
        errorStack: error.stack || null,
        componentStack: errorInfo.componentStack || null,
      });
    });
  }

  componentWillUnmount(): void {
    window.removeEventListener("unhandledrejection", this.promiseRejectionHandler);
  }

  handleModalClose = () => {
    this.setState({ errorMessage: "", errorStack: null, componentStack: null });
  };

  toggleDetails = () => {
    this.setState((prevState) => ({
      isDetailsShown: !prevState.isDetailsShown,
    }));
  };

  promiseRejectionHandler = async (event: PromiseRejectionEvent) => {
    // Also use local storage (next to context) for immediate feedback to other error boundaries
    const isUnhandledRejectionHandled =
      getFromLocalStorage("isUnhandledRejectionHandled") === "true" || this.props.isUnhandledRejectionHandled;
    if (isUnhandledRejectionHandled) return;

    const error = event.reason;
    logger.error({ error });

    sendErrorToSentry(error);

    // Store rejection message and stack trace (if available)
    this.setState({
      errorMessage: error.message || "Unhandled promise rejection",
      errorStack: error.stack || null,
    });

    this.props.setUnhandledRejectionHandled?.(true);
    setInLocalStorage("isUnhandledRejectionHandled", String(true));
  };

  render() {
    const { errorMessage, isDetailsShown, errorStack, componentStack } = this.state;

    if (errorMessage) {
      const message = (
        <div className="flex flex-col gap-2 justify-start">
          <p className="text-red-700 dark:text-red-400">{errorMessage}</p>
          {this.props.isSiteManager && (
            <>
              <Button className="flex font-bold" onClick={this.toggleDetails} variant="basic">
                {isDetailsShown ? strings.lessDetails : strings.moreDetails}...
              </Button>
              {isDetailsShown && (
                <>
                  {errorStack && formatStackTrace(errorStack, "ErrorStack")}
                  {componentStack && formatStackTrace(componentStack, "ComponentStack")}
                </>
              )}
            </>
          )}
        </div>
      );

      return (
        <Modal handleClose={this.handleModalClose} show={!!errorMessage} size="modal-lg">
          <Modal.Header title={strings.videoConsultationError} />
          <Modal.Body>
            <AlertBox closeAble={false} message={message} />
          </Modal.Body>
          <Modal.Footer extraClassName="justify-end">
            <CloseButton asMain onClick={this.handleModalClose} text={strings.ok} />
          </Modal.Footer>
        </Modal>
      );
    }

    return this.props.children;
  }
}

export default VideoConsultationErrorBoundary;
