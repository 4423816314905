/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { useEffect, useState } from "react";
import logger from "../pages/VideoConsultation/Logger";
import { strings } from "../common/Strings/Strings";

/**
 * Custom React hook to manage fullscreen mode for elements.
 * Handles entering fullscreen, exiting fullscreen, and detecting fullscreen change events.
 */
export const useFullscreen = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    const fullscreenChangeHandler = () => {
      setIsFullscreen(
        !!document.fullscreenElement ||
          ("webkitFullscreenElement" in document && !!document.webkitFullscreenElement) ||
          ("mozFullScreen" in document && !!document.mozFullScreen) ||
          ("msFullscreenElement" in document && !!document.msFullscreenElement)
      );
    };

    document.addEventListener("fullscreenchange", fullscreenChangeHandler);
    document.addEventListener("webkitfullscreenchange", fullscreenChangeHandler);
    document.addEventListener("mozfullscreenchange", fullscreenChangeHandler);
    document.addEventListener("MSFullscreenChange", fullscreenChangeHandler);

    return () => {
      document.removeEventListener("fullscreenchange", fullscreenChangeHandler);
      document.removeEventListener("webkitfullscreenchange", fullscreenChangeHandler);
      document.removeEventListener("mozfullscreenchange", fullscreenChangeHandler);
      document.removeEventListener("MSFullscreenChange", fullscreenChangeHandler);
    };
  }, []);

  /**
   * Attempt to enter fullscreen mode for the specified element.
   * Handles different browser implementations of the Fullscreen API.
   */
  const enterFullscreen = async (element: HTMLElement) => {
    if (element && element.requestFullscreen) {
      await element.requestFullscreen();
    } else if ("mozRequestFullScreen" in element) {
      (element as any).mozRequestFullScreen(); /* Firefox */
    } else if ("webkitRequestFullscreen" in element) {
      (element as any).webkitRequestFullscreen(); /* Safari */
    } else if ("msRequestFullscreen" in element) {
      (element as any).msRequestFullscreen(); /* IE11 */
    } else {
      alert(strings.fullScreenNotSupported);
      logger.warn("[📹VideoChat] Fullscreen mode is not supported by this browser!");
    }
  };

  /**
   * Attempt to exit fullscreen mode if an element is currently in fullscreen.
   * Handles different browser implementations of the Fullscreen API.
   */
  const exitFullscreen = async () => {
    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        await document.exitFullscreen();
      } else if ("mozCancelFullScreen" in document) {
        (document as any).mozCancelFullScreen(); /* Firefox */
      } else if ("webkitExitFullscreen" in document) {
        (document as any).webkitExitFullscreen(); /* Safari */
      } else if ("msExitFullscreen" in document) {
        (document as any).msExitFullscreen(); /* IE11 */
      }
    }
  };

  return { isFullscreen, enterFullscreen, exitFullscreen };
};
