/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { Dropdown, Flowbite } from "flowbite-react";
import { useLocalStorage } from "@uidotdev/usehooks";
import NotificationList from "./NotificationList";
import NotificationsButton from "./NotificationsButton";
import { dropdownTheme } from "../Themes/DropdownTheme";
import { useNotification } from "../../../contexts/NotificationContext";

interface Props {
  visible?: boolean;
}

export const Notifications: React.FC<Props> = ({ visible }: Props) => {
  const { notifications } = useNotification();

  const [darkMode] = useLocalStorage<boolean | undefined>("darkMode");
  if (!visible) {
    return null;
  }

  return (
    <Flowbite theme={{ theme: dropdownTheme, dark: darkMode }}>
      <Dropdown
        color={darkMode ? "dark" : "light"}
        dismissOnClick={true}
        label={<NotificationsButton notificationCount={notifications.length} />}
        placement="bottom"
        size="lg"
        className="overflow-auto max-h-96"
      >
        <NotificationList notifications={notifications} />
      </Dropdown>
    </Flowbite>
  );
};

export default Notifications;
