/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useRef, useState } from "react";
import SearchResultRow from "./SearchResultRow";
import { ClinicPetAndOwnerResponse } from "../../../../../models/pet/ClinicPetAndOwnerResponse";
import AlertBox from "../../../../../components/AlertBox";
import { strings } from "../../../../Strings/Strings";
import SearchInput from "../../../../../components/InputFieldsSimple/SearchInput";
import { AutoCompleteOptions } from "../../../../../models/AutoCompleteOptions";
import ClinicPetApi from "../../../../../api/ClinicPetApi";
import { getGeneralError } from "../../../../../util/helperFunctions";
import { useDebouncedState } from "../../../../../hooks/hooks";
import { useClinic } from "../../../../../contexts/ClinicContext";
import PetOwnerApi from "../../../../../api/PetOwnerApi";
import { AxiosResponse } from "axios";
import { PageResponse } from "../../../../../models/PageResponse";
import { PetOwnerResponse } from "../../../../../models/pet/PetOwnerResponse";
import PetlessOwnerResultRow from "./PetlessOwnerResultRow";
import EmptyListText from "../../../../../components/EmptyListText";
import LoaderInline from "../../../../../components/LoaderInline";

const MainPetSearchSection: React.FC = () => {
  const reloadTimeInSec = 10;

  const { fetchClinicsError, clinic } = useClinic();

  const [searchResult, setSearchResult] = useState<ClinicPetAndOwnerResponse[]>(
    []
  );
  const [petlessOwners, setPetlessOwners] = useState<PetOwnerResponse[]>([]);
  const [dropdown, setDropdown] = useState<boolean>(false);
  const [searchError, setSearchError] = useState<string | null>(null);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [petlessSearchLoading, setPetlessSearchLoading] =
    useState<boolean>(false);
  const petSearchState = useDebouncedState<string>("", 400);
  const [localStorageUpdated, setLocalStorageUpdated] =
    useState<boolean>(false);
  const [reloadRequests, setReloadRequests] = useState<boolean>(true);

  const ref = useRef(null);

  useEffect(() => {
    if (!reloadRequests)
      setTimeout(
        () => {
          setReloadRequests(true);
        },
        reloadTimeInSec * 1000 * 60
      );
  }, [reloadRequests]);

  useEffect(() => {
    if (localStorageUpdated) {
      setSearchResult(searchResult);
      setLocalStorageUpdated(false);
    }
  }, [localStorageUpdated, searchResult]);

  const onSearchInputChange = (search: string) => {
    setDropdown(false);
    setSearchResult([]);
    setPetlessOwners([]);
    petSearchState.setValue(search);
  };

  useEffect(() => {
    const findPets = async () => {
      setSearchLoading(true);
      if (clinic?.id) {
        setDropdown(true);
        try {
          const response = await ClinicPetApi.searchClinicPet(
            clinic.id,
            petSearchState.value
          );
          setSearchResult(response.data);
        } catch (err) {
          setSearchError(await getGeneralError(err));
        } finally {
          setSearchLoading(false);
        }
      }
    };

    const findPetlessOwners = async () => {
      setPetlessSearchLoading(true);
      if (clinic?.id) {
        try {
          const response: AxiosResponse<PageResponse<PetOwnerResponse>> =
            await PetOwnerApi.getPetOwners(clinic.id, {
              pageSize: 5,
              pageNumber: 0,
              query: petSearchState.value,
              hasNoPet: true,
            });
          setPetlessOwners(response.data.elements);
        } catch (err) {
          setSearchError(await getGeneralError(err));
        } finally {
          setPetlessSearchLoading(false);
        }
      }
    };

    if (petSearchState.value.length >= 3) {
      setSearchError(null);
      void findPets();
      void findPetlessOwners();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petSearchState.value]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      // eslint-disable-next-line
      /* @ts-ignore */
      if (ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <>
      <div className="relative w-full lg:w-80">
        <SearchInput
          autoComplete={AutoCompleteOptions.off}
          clear={() => {
            petSearchState.setValue("");
          }}
          filled={petSearchState.value.length > 0}
          loading={searchLoading || petlessSearchLoading}
          onChange={onSearchInputChange}
          onEnterDown={() => {
            petSearchState.sync();
            setDropdown(true);
          }}
        />
      </div>
      {dropdown ? (
        <div className="mb-2" style={{ position: "relative" }} ref={ref}>
          <div className="dropdown-search bg-white dark:bg-gray-900">
            <AlertBox message={fetchClinicsError} className="m-4" />
            <AlertBox message={searchError} className="m-4" />
            {searchResult.length === 0 &&
            petlessOwners.length > 0 &&
            !searchLoading &&
            !petlessSearchLoading ? (
              <div className="p-4">
                <EmptyListText text={strings.noPetResult} />
              </div>
            ) : null}
            {searchResult.length === 0 &&
            petlessOwners.length === 0 &&
            !searchLoading &&
            !petlessSearchLoading ? (
              <div className="p-4">
                <EmptyListText text={strings.noResult} />
              </div>
            ) : null}
            <div
              className="divide-y dark:divide-gray-700"
              style={{
                maxHeight: "60vh",
                overflowY: searchResult.length > 5 ? "scroll" : "unset",
                cursor: "default",
              }}
            >
              {searchLoading ? (
                <div className="p-4 flex spece-x-3 items-center">
                  <LoaderInline text={strings.searchingPets} textVisible />
                </div>
              ) : (
                searchResult.map(
                  (line: ClinicPetAndOwnerResponse, index: number) => (
                    <SearchResultRow
                      dropDirection={
                        searchResult.length > 5 &&
                        index > searchResult.length - 4
                          ? "up"
                          : "down"
                      }
                      line={line}
                      query={petSearchState.value}
                    />
                  )
                )
              )}
              {petlessSearchLoading ? (
                <div className="p-4 flex spece-x-3 items-center">
                  <LoaderInline
                    text={strings.searchingOwnersWithoutPets}
                    textVisible
                  />
                </div>
              ) : (
                petlessOwners.map((petlessOwner) => (
                  <PetlessOwnerResultRow
                    petlessOwner={petlessOwner}
                    query={petSearchState.value}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default MainPetSearchSection;
