/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { autoUpdate, useFloating } from "@floating-ui/react";
import { Check } from "../../../Icons/Check";
import { SupportedLanguages } from "../../../Strings/Strings";
import { getSimpleLanguage, setSimpleLanguage } from "../../../../util/LocalStorageVariables";
import { Globe } from "../../../Icons/Globe";
import ClickOutsideDetector from "../../../../components/ClickOutsideDetector";

const ChooseLanguageMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleMenu = () => {
    setIsOpen((prevState) => !prevState);
  };

  const languages = SupportedLanguages.map((lan) => ({
    title: lan.title,
    value: lan.code,
  }));

  const currentLanguageCode = getSimpleLanguage();

  const { refs, floatingStyles } = useFloating({
    placement: "bottom-end",
    whileElementsMounted: autoUpdate,
  });

  const changeLanguage = async (languageCode: string) => {
    setSimpleLanguage(languageCode);
    window.location.reload();
  };

  return (
    <ClickOutsideDetector
      listen
      onClickOutside={() => {
        setIsOpen(false);
      }}
    >
      <button
        className="relative flex items-center space-x-1 w-full lg:w-auto lg:py-2 lg:pl-3 lg:pr-4 lg:p-0 text-gray-100 hover:text-white focus:text-white dark:text-gray-300 dark:hover:text-white dark:focus:text-white transition-all duration-300"
        onClick={toggleMenu}
        type="button"
        ref={refs.setReference}
      >
        <Globe className="w-6 h-6 flex-shrink-0" variant="outline" />
        <span>{currentLanguageCode?.toUpperCase()}</span>
      </button>
      {isOpen && (
        <div
          id="dropdownNavbarLanguage"
          className="z-30 shadow rounded-lg w-44 bg-white font-normal divide-y divide-gray-100 dark:bg-gray-700 dark:divide-gray-600 block"
          ref={refs.setFloating}
          style={floatingStyles}
        >
          <ul className="py-2 font-normal text-gray-700 dark:text-gray-400">
            {languages.map((lang) => (
              <li
                className={currentLanguageCode !== lang.value ? "cursor-pointer" : "cursor-default"}
                key={lang.value}
                onClick={() => {
                  if (currentLanguageCode === lang.value) return;
                  void changeLanguage(lang.value);
                }}
              >
                <div className="active flex justify-between items-center px-4 py-2 hover:bg-gray-50 hover:text-gray-800 dark:hover:bg-gray-600 dark:hover:text-gray-300 transition-all duration-300">
                  {lang.title} {lang.value === currentLanguageCode && <Check />}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </ClickOutsideDetector>
  );
};

export default ChooseLanguageMenu;
