/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import moment from "moment";
import { strings } from "../../../../common/Strings/Strings";
import Tag, { TagType } from "../../../../components/Tag";
import InvoiceItems from "./InvoiceItems";
import AlertBox from "../../../../components/AlertBox";
import { getGVInvoicingErrorMessage, getGVInvoicingSuccessMessage } from "../../../../util/InvoiceSystemUtils";
import { ClinicChargeResponse } from "../../../../models/charge/ClinicChargeResponse";
import { ChargeStatus } from "../../../../models/charge/ChargeStatus";
import ClinicChargeActions from "../ClinicChargeActions";

interface Props {
  error: string | null;
  charge: ClinicChargeResponse;
  reload(): void;
  setError(message: string | null): void;
  onLoad: (loading: boolean) => void;
}

const InvoiceInfos = ({ error, charge, reload, setError, onLoad }: Props): ReactElement => {
  const invoice = charge.invoice;
  const getChargeStatus = (status: ChargeStatus): ReactElement | undefined => {
    if (status === ChargeStatus.INVOICED) {
      return <Tag type={TagType.danger} text={strings.pendingInvoice} />;
    }
    if (status === ChargeStatus.NO_CARD || status === ChargeStatus.PENDING) {
      return <Tag type={TagType.warning} text={strings.notPaid} />;
    }
    if (status === ChargeStatus.COMPLETED) {
      return <Tag type={TagType.success} text={strings.paid} />;
    }
    if (status === ChargeStatus.CANCELLED) {
      return <Tag type={TagType.secondary} text={strings.withdrawn} />;
    }
    return undefined;
  };

  return (
    <div className="w-full md:tw-card space-y-6 md:p-8 my-6">
      <div className="space-y-1">
        <div className="flex">
          <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white">
            {strings.invoice}
          </h2>
          <div className="ml-3">{getChargeStatus(charge.status)}</div>
          <div className="ml-auto">
            <ClinicChargeActions charge={charge} reload={reload} onError={(e) => setError(e)} onLoad={onLoad} />
          </div>
        </div>
        <div>
          <>
            <div hidden={charge.invoice?.valid === false}>
              {charge.invoice && getGVInvoicingSuccessMessage(charge.invoice)}
            </div>
            <AlertBox
              hidden={invoice?.valid !== false}
              closeAble={false}
              message={
                <div>
                  <div className="font-bold">{charge.invoice && getGVInvoicingErrorMessage(charge.invoice)}</div>
                  <div className="mt-1">{invoice?.validationError}</div>
                </div>
              }
            />
          </>
        </div>
      </div>
      <AlertBox message={error} />
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 md:col-span-6">
          <div className="font-bold">{invoice?.customer?.fullName || invoice?.customer?.organizationName} </div>
          <div>{invoice?.customer?.phoneNumber}</div>
          <div>{invoice?.customer?.email}</div>
          <div>{invoice?.customer?.taxNumber}</div>
          <div>
            {invoice?.customer?.countryCode} {invoice?.customer?.zip} {invoice?.customer?.city}{" "}
            {invoice?.customer?.street} {invoice?.customer?.houseDetails}
          </div>
        </div>
        <div className="col-span-12 md:col-span-6">
          <div>
            {strings.invoiceNumber}: <b>{invoice?.invoiceNumber}</b>
          </div>
          <div hidden={!invoice?.creationDate}>
            {strings.invoiceCreationDate}: <b>{invoice?.creationDate && moment(invoice?.creationDate).format("LLL")}</b>
          </div>
          <div hidden={!invoice?.dueDate}>
            {strings.dueDateForPayment}: <b>{invoice?.dueDate && moment(invoice?.dueDate).format("LL")}</b>
          </div>
          <div hidden={!charge.paymentDate}>
            {strings.paymentDate}: <b>{charge.paymentDate && moment(charge.paymentDate).format("LL")}</b>
          </div>
        </div>
      </div>
      <div>{invoice && <InvoiceItems invoice={invoice} />}</div>
    </div>
  );
};

export default InvoiceInfos;
