/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { createContext, useContext, useMemo, useState } from "react";
import { ChildrenProps } from "../models/ChildrenProps";

interface LayoutVisibilityContext {
  isHeaderVisible: boolean;
  isFooterVisible: boolean;
  setHeaderVisible: (visible: boolean) => void;
  setFooterVisible: (visible: boolean) => void;
}

const LayoutVisibility = createContext<LayoutVisibilityContext | null>(null);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const useLayoutVisibility = (): LayoutVisibilityContext => useContext(LayoutVisibility)!;

export const LayoutVisibilityProvider: React.FC<ChildrenProps> = ({ children }: ChildrenProps) => {
  const [isHeaderVisible, setHeaderVisible] = useState(true);
  const [isFooterVisible, setFooterVisible] = useState(true);

  const ctx = useMemo(
    () => ({
      isHeaderVisible,
      isFooterVisible,
      setHeaderVisible,
      setFooterVisible,
    }),
    [isFooterVisible, isHeaderVisible]
  );

  return <LayoutVisibility.Provider value={ctx}>{children}</LayoutVisibility.Provider>;
};
