/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { strings } from "../../../common/Strings/Strings";

interface Props {
  title: string;
  message: string;
  actionButton?: string;
  path: string;
}

export default function EmailNotificationPreview(props: Props): ReactElement {
  const { title, message, actionButton, path } = props;

  return (
    <div style={{ backgroundColor: "#F5F6F6", padding: "10px", display: "flex", justifyContent: "center" }}>
      <table
        style={{
          borderSpacing: 0,
          padding: 0,
          borderRadius: "10px",
          backgroundColor: "#ffffff",
          fontFamily: "'Nunito Sans', Arial, sans-serif",
          color: "#2E2E2F",
          fontSize: "16px",
          lineHeight: "150%",
          width: "90%",
          boxSizing: "border-box",
        }}
      >
        <tbody>
          <tr>
            <td
              style={{
                padding: "25px",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                backgroundColor: "#465364",
                color: "#ffffff",
              }}
            >
              <h1 style={{ fontSize: "30px", lineHeight: "42px", fontWeight: 600 }}>
                {title || strings.emailNotificationSubject}
              </h1>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "25px" }}>
              <p style={{ color: "#2E2E2F" }}>{message || strings.emailNotificationMessage}</p>
            </td>
          </tr>
          {actionButton && (
            <tr>
              <td style={{ paddingTop: "30px", paddingBottom: "30px", textAlign: "center" }}>
                <a
                  href={path}
                  style={{
                    display: "inline-block",
                    padding: "10px 25px",
                    borderRadius: "4px",
                    backgroundColor: "#0CA2FE",
                    color: "#ffffff",
                    fontWeight: 600,
                    textDecoration: "none",
                    textAlign: "center",
                  }}
                >
                  {actionButton}
                </a>
              </td>
            </tr>
          )}
          <tr>
            <td
              style={{
                marginTop: "40px",
                backgroundColor: "#D0D4D8",
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
                paddingTop: "10px",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <img
                alt="GlobalVet AB"
                title="GlobalVet AB"
                style={{
                  width: "8rem",
                  display: "block",
                  margin: "1rem auto",
                }}
                src="/email-footer.png"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
