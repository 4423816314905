/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import Button from "../../../components/Button";
import Field from "../../../components/ReactHookFormFields/General/Field";
import PhoneNumber from "../../../components/ReactHookFormFields/Specific/PhoneNumber";
import Select from "../../../components/ReactHookFormFields/General/Select/Select";
import { PhoneType, phoneTypeOptions } from "../../../models/contact/PhoneType";
import AlertBox from "../../../components/AlertBox";
import { PendingClinicRegistrationResponse } from "../../../models/clinic/PendingClinicRegistrationResponse";
import { ContactsRequest } from "../../../models/clinic/ContactsRequest";
import ClinicRegistrationApi from "../../../api/ClinicRegistrationApi";
import { getGeneralError } from "../../../util/helperFunctions";

interface Props {
  registration?: PendingClinicRegistrationResponse;
  onNext(registration: PendingClinicRegistrationResponse): void;
  onError(error: string | null): void;
}

const ContactsTab: React.FC<Props> = (props: Props) => {
  const { registration, onNext, onError } = props;
  const [isLoading, setLoading] = useState<boolean>(false);
  const [contactResponse, setContactResponse] = useState<PendingClinicRegistrationResponse>();

  const defaultFormValues = (
    pending?: PendingClinicRegistrationResponse
  ): ContactsRequest => ({
    email: pending?.emails[0]?.value || "",
    phoneNumber: pending?.phones[0]?.value || "",
    phoneType: pending?.phones[0]?.phoneType || PhoneType.MOBILE,
  });

  const form = useForm<ContactsRequest>({
    mode: "onSubmit",
    defaultValues: defaultFormValues(registration),
  });

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = form;

  useEffect(() => {
    reset(defaultFormValues(registration));
  }, [registration]);

  const submit = async (request: ContactsRequest) => {
    if (!registration) return;
    setLoading(true);
    onError(null);
    try {
      const response = await ClinicRegistrationApi.addContacts(
        registration.id,
        request
      );
      setContactResponse(response.data);
    } catch (e) {
      onError(await getGeneralError(e));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if(contactResponse){
      onNext(contactResponse);
    }
  }, [contactResponse]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(submit)}>
        <div className="space-y-6">
          <Field
            autoComplete={AutoCompleteOptions.email}
            error={errors.email}
            label={strings.email}
            name="email"
            placeholder={strings.emailExample}
            register={register}
            required
            showRequired
            type="email"
          />
          <PhoneNumber
            autocomplete={AutoCompleteOptions.telNational}
            control={control}
            country={registration?.countryCode}
            id="phoneNumber"
            label={strings.phone}
            name="phoneNumber"
            required
            showRequired
          />
          <Select
            error={errors.phoneType}
            isChooseOption={false}
            label={strings.phoneType}
            name="phoneType"
            options={phoneTypeOptions}
            register={register}
            required
            showRequired
          />
          <AlertBox
            hidden={
              errors.email?.type !== "required" &&
              errors.phoneNumber?.type !== "required"
            }
            message={strings.pleaseFillRequiredFields}
          />
          <Button disabled={isLoading} type="submit" loading={isLoading}>
            {strings.saveAndContinue}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default ContactsTab;
