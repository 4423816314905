/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { ClinicResponse } from "../../../../models/clinic/ClinicResponse";
import ClinicDiscountApi from "../../../../api/ClinicDiscountApi";
import logger from "../../../../util/logger";
import { DiscountsResponse } from "../../../../models/discount/DiscountsResponse";
import moment from "moment";
import { strings } from "../../../../common/Strings/Strings";
import { getDiscountType } from "../../../Manager/Discounts/DiscountCard";

interface Props {
  clinic: ClinicResponse;
}
const ClinicDiscount: React.FC<Props> = ({ clinic }: Props) => {
  const [discounts, setDiscounts] = useState<DiscountsResponse>();

  useEffect(() => {
    const getDiscounts = async () => {
      if (!clinic.id) {
        return;
      }

      try {
        const response = await ClinicDiscountApi.getDiscountsOfClinic(clinic.id);
        setDiscounts(response.data);
      } catch (err) {
        logger.error(err);
      }
    };

    void getDiscounts();
  }, [clinic.id]);

  return (
    <div
      className="grid md:grid-cols-2 gap-6"
      hidden={discounts?.discounts.length === 0 && discounts.trialDiscounts.length === 0}
    >
      {discounts?.discounts.map((d) => (
        <div className="tw-card shadow p-4">
          <div className="flex">
            <div className="font-bold">{strings.discount}</div>
            <div className="ml-auto">{getDiscountType(undefined, d)}</div>
          </div>
          <div>
            {moment(d.validityStart).format("LL")} -{" "}
            {d.validityEnd ? moment(d.validityEnd).format("LL") : strings.untilWithdrawn}
          </div>
        </div>
      ))}
      {discounts?.trialDiscounts.map((d) => (
        <div className="tw-card shadow p-4">
          <div className="flex">
            <div className="font-bold">{strings.discount}</div>
            <div className="ml-auto">{getDiscountType(d, undefined)}</div>
          </div>
          <div>
            {moment(d.validityStart).format("LL")} -{" "}
            {d.validityEnd ? moment(d.validityEnd).format("LL") : strings.untilWithdrawn}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ClinicDiscount;
