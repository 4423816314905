/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { Flowbite, Navbar } from "flowbite-react";
import { useLocalStorage } from "@uidotdev/usehooks";
import { simpleLightText } from "../../assets/AssetConfig";
import { useMaintenance } from "../../contexts/MaintenanceContext";
import { Environments, getEnvironment } from "../../models/Environments";
import { getAccessToken, getValueOfActiveUser, UserRole } from "../../util/LocalStorageVariables";
import { strings } from "../Strings/Strings";
import EnvironmentBadge from "./EnvironmentBadge";
import { DoctorMenuItem, PetOwnerMenuItem, SiteManagerMenuItem } from "./MenuItem/MenuItemTypes";
import NavDoctor from "./NavBars/NavDoctor/NavDoctor";
import NavPetOwner from "./NavBars/NavPetOwner/NavPetOwner";
import UserMenu from "./UserMenu/UserMenu";
import NavSiteManager from "./NavBars/NavSiteManager/NavSiteManager";
import { customTheme } from "./Themes/NavTheme";
import NavUnauthenticated from "./NavBars/NavUnauthenticated/NavUnauthenticated";

interface Props {
  activeDoctorMenu?: DoctorMenuItem;
  activePetOwnerMenu?: PetOwnerMenuItem;
  activeSiteManagerMenu?: SiteManagerMenuItem;
  isSecondNavbarDisabled?: boolean;
}

const globalVetData = {
  startYear: 2019,
  currentYear: new Date().getFullYear(),
  fullName: "GlobalVET AB",
};

// International copyright statement
export const copyrightStatement = `${strings.copyright} ${globalVetData.startYear}-${globalVetData.currentYear} ${globalVetData.fullName}`;

const NavBar: React.FC<Props> = (props: Props) => {
  const [environment, setEnvironment] = useState<Environments>(Environments.Prod);

  const [darkMode] = useLocalStorage<boolean | undefined>("darkMode");

  const maintenance = useMaintenance();

  useEffect(() => {
    setEnvironment(getEnvironment());
  }, []);

  const role: UserRole | null = getValueOfActiveUser("role");
  const authenticated = getAccessToken();

  if (maintenance?.maintenanceModeEnabled) {
    return null;
  }

  if (authenticated && role === "doctor") {
    return (
      <NavDoctor
        environment={environment}
        activeMenu={props.activeDoctorMenu}
        isSecondNavbarDisabled={props.isSecondNavbarDisabled}
      />
    );
  }
  if (authenticated && role === "owner") {
    return <NavPetOwner environment={environment} activeMenu={props.activePetOwnerMenu} />;
  }
  if (authenticated && role === "manager") {
    return <NavSiteManager environment={environment} activeMenu={props.activeSiteManagerMenu} />;
  }

  // Navbar without chosen role
  if (authenticated) {
    return (
      <div
        className="fixed z-30 top-0 left-0 w-full h-14 px-4 lg:px-6 flex items-center bg-slate-500 dark:bg-gray-800 dark:border-b dark:border-gray-700"
        id="navbar-no-role"
      >
        <Flowbite theme={{ theme: customTheme, dark: darkMode }}>
          <Navbar fluid>
            <Navbar.Brand href="/">
              <img alt="GlobalVet" className="w-24" src={simpleLightText} />
              <EnvironmentBadge className="ml-5" environment={environment} />
            </Navbar.Brand>
            <UserMenu />
          </Navbar>
        </Flowbite>
      </div>
    );
  }

  // Navbar that shows up for unauthenticated users
  return <NavUnauthenticated />;
};

export default NavBar;
