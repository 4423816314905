/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced; copied; distributed; disseminated to the public; adapted or transmitted
 * in any form or by any means; including photocopying; recording; or other electronic or
 * mechanical methods; without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactNode } from "react";
import { LocalVideo } from "./LocalVideo";
import { VideoChatUserConnection, VideoLayout } from "./Utils";
import VideoItem from "./VideoItem";
import { TestVideo } from "./TestVideo";
import { RemoteVideo } from "./RemoteVideo";
import { HiddenVideosCollectorCard } from "./HiddenVideosCollectorCard";
import { VideoConsultationRole } from "../../models/videoConsultation/VideoConsultationRole";

interface Props {
  callControls: any;
  camEnabled: boolean;
  canSwitchCameraFacing: boolean;
  containerRef: React.RefObject<HTMLDivElement>;
  debugMode: boolean;
  facingMode: "user" | "environment";
  getVideosContainerHeight: () => number;
  joinRoom: () => void;
  layout: VideoLayout;
  localUser: any;
  mediaFeaturesRef: React.MutableRefObject<any>;
  micEnabled: boolean;
  ping: number | null;
  remoteUsers: VideoChatUserConnection[];
  roles: VideoConsultationRole[];
  streamRef: React.MutableRefObject<MediaStream | null>;
  testVideoCount: number;
  toggleCam: () => void;
  toggleCameraFacing: () => void;
  toggleMic: () => void;
  videoRef: React.RefObject<HTMLVideoElement>;
}

const VideosContainer: React.FC<Props> = ({
  callControls,
  camEnabled,
  canSwitchCameraFacing,
  containerRef,
  debugMode,
  facingMode,
  getVideosContainerHeight,
  joinRoom,
  layout,
  localUser,
  mediaFeaturesRef,
  micEnabled,
  ping,
  remoteUsers,
  roles,
  streamRef,
  testVideoCount,
  toggleCam,
  toggleCameraFacing,
  toggleMic,
  videoRef,
}: Props) => {
  const collectorCardVisible = layout.remainingVideos >= 2;
  const videosToRender: ReactNode[] = [];
  let visibleVideoCount = 0;
  const invisibleVideoIds: Array<string> = [];

  const canRenderMore = () => visibleVideoCount < layout.visibleVideos;

  // 1. Render Local User first
  if (canRenderMore()) {
    videosToRender.push(
      <VideoItem key="local-user" width={layout.width} height={layout.height}>
        <LocalVideo
          callControls={callControls}
          camEnabled={camEnabled}
          canSwitchCameraFacing={canSwitchCameraFacing}
          facingMode={facingMode}
          joinRoom={joinRoom}
          mediaFeatures={mediaFeaturesRef.current}
          micEnabled={micEnabled}
          ping={ping}
          roles={roles}
          toggleCam={toggleCam}
          toggleCameraFacing={toggleCameraFacing}
          toggleMic={toggleMic}
          updateVideoRef={() => {
            if (videoRef.current) {
              videoRef.current.srcObject = streamRef.current;
            }
          }}
          userId={localUser.userId}
          userName={localUser.details.fullName}
          videoHeight={layout.height}
          videoWidth={layout.width}
          videoRef={videoRef}
        />
      </VideoItem>
    );
    visibleVideoCount += 1;
  }

  // 2. Render Remote Users
  remoteUsers.slice(0, layout.visibleVideos - 1).forEach((remoteUser) => {
    if (canRenderMore()) {
      videosToRender.push(
        <VideoItem key={remoteUser.id} width={layout.width} height={layout.height}>
          <RemoteVideo
            callControls={callControls}
            remoteUser={remoteUser}
            videoHeight={layout.height}
            videoWidth={layout.width}
          />
        </VideoItem>
      );
      visibleVideoCount += 1;
    } else {
      invisibleVideoIds.push(remoteUser.user?.profilePictureId ?? "");
    }
  });

  // 3. Debug Mode: Render Test Videos
  if (debugMode) {
    Array.from({ length: testVideoCount }).forEach((_, index) => {
      if (canRenderMore()) {
        videosToRender.push(
          <VideoItem key={`test-video-${index}`} width={layout.width} height={layout.height}>
            <TestVideo callControls={callControls} videoHeight={layout.height} videoWidth={layout.width} />
          </VideoItem>
        );
        visibleVideoCount += 1;
      } else {
        invisibleVideoIds.push("");
      }
    });
  }

  // 4. Show the collector card
  if (collectorCardVisible) {
    videosToRender.push(
      <VideoItem key="summary-card" width={layout.width} height={layout.height}>
        <HiddenVideosCollectorCard
          cardHeight={layout.height}
          hiddenVideosCount={layout.remainingVideos}
          invisibleVideoIds={invisibleVideoIds}
        />
      </VideoItem>
    );
  }

  return (
    <div
      className={`${
        roles.length === 0 ? "hidden" : "flex"
      } flex-wrap justify-center items-center content-center w-full pt-3 pb-2 px-2 gap-2`}
      id="videos-container"
      ref={containerRef}
      style={{ height: getVideosContainerHeight() }}
    >
      {videosToRender.map((item: ReactNode) => item)}
    </div>
  );
};

export default VideosContainer;
