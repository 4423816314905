/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../contexts/UserContext";
import { doLogout } from "../../util/axiosSetup";
import { PageProps } from "../../models/PageProps";
import { Loader } from "../../components/Loader";
import logger from "../../util/logger";
import { getAccessToken, getPushToken } from "../../util/LocalStorageVariables";
import NotificationApi from "../../api/NotificationApi";
import { useClinic } from "../../contexts/ClinicContext";

const Logout: React.FC<PageProps> = (props: PageProps) => {
  const { setPageLoading } = props;
  const navigate = useNavigate();
  const { setUser } = useUser();
  const { clearClinic } = useClinic();

  const logout = async () => {
    setPageLoading(true);
    try {
      const pushToken = getPushToken();
      if (pushToken && getAccessToken()) await NotificationApi.deletePushToken(pushToken);
      void doLogout(true);
      clearClinic();
      setUser(undefined);
    } catch (e) {
      logger.error(e);
    } finally {
      navigate("/login", { replace: true });
    }
  };

  useEffect(() => {
    void logout();
  }, []);

  return <></>;
};

export default Loader(Logout);
