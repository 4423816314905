/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useCallback } from "react";
import { Control, useFormContext } from "react-hook-form";
import moment from "moment/moment";
import { strings } from "../../../common/Strings/Strings";
import EstimatedBirthDatePicker from "./EstimatedBirthDatePicker";
import DatePickerInput from "../ReactDateAndTimePickers/DatePickerInput";
import RadioButtonGroup from "../General/RadioButton/RadioButtonGroup";
import { Colors } from "../../../models/Colors";
import { PetFormProps } from "../../../pages/Pet/CreateNewPet/PetForm";

interface Props {
  control: Control<any>;
  dateOfBirthType: "known" | "estimated" | "unknown";
  name: string;
  label?: string;
  optional?: boolean;
  required?: boolean;
  showRequired?: boolean;
}

// Calculates the estimated age in years and months based on the estimated date of birth
export const getEstimatedAge = (date?: Date): { years: number; months: number } => {
  if (!date) {
    return { years: 0, months: 0 };
  }

  const birthDate = moment(date);
  const now = moment();

  // Calculate the difference in years and months
  let years = now.diff(birthDate, "years");
  let months = now.diff(birthDate, "months") % 12;

  // Ensure years are between 0-999 and months are between 0-11
  years = Math.min(Math.max(years, 0), 999);
  months = Math.min(Math.max(months, 0), 11);

  return { years, months };
};

const PetBirthDatePicker = ({
  control,
  dateOfBirthType,
  name,
  label,
  optional,
  required = true,
  showRequired = true,
}: Props): ReactElement => {
  const {
    formState: { errors },
    setValue,
  } = useFormContext<PetFormProps>();

  // Presets the estimated age field value based on the date picked in this component
  const handleDatePickerValueChange = useCallback(
    (date: Date | null) => {
      // Check if the DatePicker value is a valid date
      if (date && !Array.isArray(date) && moment(date).isValid()) {
        const { years, months } = getEstimatedAge(date);
        setValue("estimatedAge", { years, months });
      } else {
        setValue("estimatedAge", { years: 0, months: 0 });
      }
    },
    [setValue]
  );

  return (
    <div className="flex flex-col space-y-2">
      <label htmlFor={name}>
        {label}
        {showRequired && " *"}
        {optional && <span style={{ color: Colors.INPUTBORDER }}>{` (${strings.optional})`}</span>}
      </label>
      <RadioButtonGroup
        control={control}
        name={name}
        options={[
          {
            value: "known",
            content: (
              <div className="flex items-center">
                <DatePickerInput
                  classInput="tw-input"
                  className="w-40"
                  control={control}
                  fieldOptions={{
                    validate: {
                      isInTheFuture: (date: string | undefined) =>
                        date === undefined || date === "" || moment().isAfter(date) || strings.invalidDate,
                    },
                  }}
                  labelOff
                  name="dateOfBirth"
                  onChange={handleDatePickerValueChange}
                  readOnly={dateOfBirthType !== "known"}
                  required={dateOfBirthType === "known"}
                  showError={false}
                />
              </div>
            ),
            error: errors.dateOfBirth?.message,
            label: strings.known,
          },
          {
            value: "estimated",
            content: (
              <div className="flex items-center">
                <EstimatedBirthDatePicker
                  name="estimatedAge"
                  control={control}
                  readOnly={dateOfBirthType !== "estimated"}
                  required={dateOfBirthType === "estimated"}
                  showError={false}
                />
              </div>
            ),
            error: errors.estimatedAge?.message,
            label: strings.estimated,
          },
          {
            value: "unknown",
            label: strings.unknown,
          },
        ]}
        required={required}
        verticalCenter
      />
    </div>
  );
};

export default PetBirthDatePicker;
