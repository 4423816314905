/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import moment from "moment";
import { quantityUnits, strings } from "../../../../common/Strings/Strings";
import { InventoryItemDetailsResponse } from "../../../../models/inventory/item/Response/InventoryItemDetailsResponse";
import { CountryDetailsResponse } from "../../../../models/management/CountryDetailsResponse";
import { InventoryItemUsageResponse } from "../../../../models/inventory/item/Response/InventoryItemUsageResponse";
import NumberFormatter from "../../../../util/NumberFormatter";
import MedicalRecordButtons from "../../../../components/MedicalRecordActions/MedicalRecordButtons";
import LowLevelIndicator from "../../LowLevelIndicator";
import EmptyListText from "../../../../components/EmptyListText";
import PriceWithVat from "../../../../components/PriceWithVat";

interface Props {
  countryDetails?: CountryDetailsResponse;
  inventoryItem: InventoryItemDetailsResponse;
}

const UsageHistory: React.FC<Props> = ({ countryDetails, inventoryItem }) => (
    <div className="w-full md:tw-card space-y-6 md:p-8 my-6">
      <div className="flex items-center">
        <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex">
          {strings.stockUsage}
        </h2>
      </div>
      <div className="flex items-center">
        <div className="mr-2">
          {strings.stock}:{" "}
          <span className="font-bold">
            {NumberFormatter.format(inventoryItem?.stock || 0)} {quantityUnits[inventoryItem?.acquisitionUnit || ""]}
          </span>
        </div>
        <LowLevelIndicator lowLevel={inventoryItem?.lowLevel} stock={inventoryItem?.stock} />
      </div>
      <div className="tw-table-container">
        <table className="tw-table">
          <thead className="tw-thead">
            <tr>
              <th className="tw-th">{strings.date}</th>
              <th className="tw-th">{strings.quantity}</th>
              <th className="tw-th">{strings.sellingVat}</th>
              <th className="tw-th">
                {strings.salePrice} ({strings.unitPrice.toLowerCase()})
              </th>
              <th className="tw-th">
                {strings.salePrice} ({strings.subtotal.toLowerCase()})
              </th>
              <th className="tw-th">{strings.medicalRecord}</th>
            </tr>
          </thead>
          <tbody className="tw-tbody">
            {inventoryItem?.usages?.length === 0 ? (
              <tr>
                <td colSpan={6} className="py-3.5 px-4 text-sm font-medium whitespace-nowrap">
                  <EmptyListText />
                </td>
              </tr>
            ) : (
              <></>
            )}
            {inventoryItem?.usages
              ?.sort((usageA, usageB) => moment(usageB.dateTime).valueOf() - moment(usageA.dateTime).valueOf())
              .map((usage: InventoryItemUsageResponse) => (
                <tr key={usage.id}>
                  <td className="py-3.5 px-4 text-sm font-medium whitespace-nowrap">
                    {usage.dateTime && moment(usage.dateTime).format("LL")}
                  </td>
                  <td className="py-3.5 px-4 text-sm font-medium whitespace-nowrap">
                    {NumberFormatter.format(usage.quantity || 0)} {quantityUnits[inventoryItem?.acquisitionUnit || ""]}
                  </td>
                  <td className="py-3.5 px-4 text-sm font-medium whitespace-nowrap">{usage.sellingVat} %</td>
                  <td className="py-3.5 px-4 text-sm font-medium whitespace-nowrap">
                    <PriceWithVat
                      countryDetails={countryDetails}
                      netPrice={usage.sellingPrice ? usage.sellingPrice / usage.quantity : 0}
                      vat={usage.sellingVat || 0}
                    />
                  </td>
                  <td className="py-3.5 px-4 text-sm font-medium whitespace-nowrap">
                    <PriceWithVat
                      countryDetails={countryDetails}
                      netPrice={usage.sellingPrice || 0}
                      vat={usage.sellingVat || 0}
                    />
                  </td>
                  <td className="py-3.5 px-4 text-sm font-medium whitespace-nowrap">
                    <MedicalRecordButtons lastMedicalRecordId={usage.medicalRecordId} />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );

export default UsageHistory;
