/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { getSimpleLanguage, setSimpleLanguage } from "../../../../util/LocalStorageVariables";
import ChooseLanguageModalBase from "../../CommonModals/ChooseLanguageModalBase";

interface Props {
  close(): void;
  isOpen: boolean;
}

const ChooseLanguageMenu: React.FC<Props> = ({ close, isOpen }: Props) => {
  const currentLanguageCode = getSimpleLanguage();

  const changeLanguage = async (languageCode: string) => {
    setSimpleLanguage(languageCode);
    window.location.reload();
  };

  return (
    <ChooseLanguageModalBase
      changeLanguage={changeLanguage}
      close={close}
      currentLanguageCode={currentLanguageCode}
      isOpen={isOpen}
    />
  );
};

export default ChooseLanguageMenu;
