/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import moment from "moment";
import jwtDecode from "jwt-decode";
import { strings } from "../common/Strings/Strings";
import { UserResponse } from "../models/user/UserResponse";
import { Option } from "../models/Option";
import { AddressResponse } from "../models/contact/AddressResponse";
import { InputModeEnum } from "../models/InputModeEnum";
import { getAccessToken, getActiveUserId, UserRole } from "./LocalStorageVariables";
import { PublicAddressResponse } from "../models/contact/PublicAddressResponse";
import { ReservationResponse } from "../models/reservation/ReservationResponse";
import { ClinicResponse } from "../models/clinic/ClinicResponse";
import { ClinicFeature } from "../models/clinic/ClinicFeature";
import { SiteManagerAuthority } from "../models/management/SiteManagerAuthority";

export const LightenDarkenColor = (col: string | undefined | null, amt: number) => {
  if (col === null || col === undefined) return "#ccceee";

  let usePound = false;

  if (col[0] === "#") {
    col = col.slice(1);
    usePound = true;
  }

  const num = parseInt(col, 16);
  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
};

export const getMonday = (inputDate: string | Date): Date => {
  const d = new Date(inputDate);
  const day = d.getDay();
  const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
};

export const getSunday = (inputDate: string | Date): Date => {
  const d = new Date(inputDate);
  const day = d.getDay();
  const diff = d.getDate() - day + (day === 0 ? 0 : 7); // adjust when day is sunday
  return new Date(d.setDate(diff));
};

export const getFullName = (collab: UserResponse) =>
  (collab.details.prefix ? `${collab.details.prefix} ` : "") +
  (collab.details.firstName ? `${collab.details.firstName} ` : "") +
  (collab.details.middleName ? `${collab.details.middleName} ` : "") +
  (collab.details.lastName ? collab.details.lastName : "");

export const isCancellable = (reservation: ReservationResponse, role?: UserRole): boolean => {
  if (role === "owner") {
    return !reservation.lastMedicalRecordId;
  }
  if (role === "doctor") {
    return !reservation.lastSignedMedicalRecordId;
  }
  return false;
};

export const howOld = (date: any, granularity: string) => {
  const momentDate = moment(date);

  if (granularity === "year" || granularity === "years") {
    return moment().diff(momentDate, "year");
  }

  if (granularity === "month" || granularity === "months") {
    return moment().diff(momentDate, "month") % 12;
  }

  return 0;
};

export const localDateFormat = (): string => "yyyy-MM-DDTHH:mm:ss";

export const localDateTimeStringOf = (date: Date) => moment(date).format(localDateFormat());

export const mergeTimeAndDate = (time: string, date: Date): Date => {
  let t = time;

  if (time[time.length] === "Z") {
    t = time.slice(0, -1);
  }

  const hours = moment(t, "HH:mm").hours();
  const minutes = moment(t, "HH:mm").minutes();
  const dateTime = moment(date).hour(hours).minute(minutes);

  return new Date(dateTime.format("YYYY-MM-DD HH:mm"));
};

export const roundToNearestTime = (dateTime: string, range: number, border = 2): number => {
  const momentDateTime = moment(dateTime, localDateFormat());
  const differenceFormRange = momentDateTime.valueOf() % range;

  let result: number;

  if (differenceFormRange <= range / border) {
    result = momentDateTime.valueOf() - differenceFormRange;
  } else {
    result = momentDateTime.valueOf() + (range - differenceFormRange);
  }

  return result;
};

export const getGeneralError = async (error: any, allowDebug = false): Promise<string | null> => {
  if (!error) return null;

  try {
    // Handle API response errors
    if (error.response) {
      const { data } = error.response;

      if (data instanceof ArrayBuffer) {
        const str = await new Blob([data]).text();
        try {
          const body = JSON.parse(str);
          return body.message || strings.simpleError;
        } catch {
          return strings.simpleError;
        }
      }

      if (Array.isArray(data)) {
        const messages = data.map((e: { message?: string }) => e.message).filter((msg) => msg?.trim());
        return messages.length ? messages.join("\n") : strings.simpleError;
      }

      if (typeof data === "object" && data !== null) {
        return data.message || data.error_description || strings.simpleError;
      }

      return strings.simpleError;
    }

    // Handle network issues (no response)
    if (error.request) {
      return strings.serverNotResponding;
    }

    // Handle unexpected errors in debug mode
    if (allowDebug && error instanceof Error) {
      return error.message;
    }

    return strings.simpleError;
  } catch (e) {
    console.error("Error parsing general error:", e);
    return strings.simpleError;
  }
};

export const getFieldError = (error: any, fieldName: string): null | string => {
  if (error === null || error === undefined) {
    return null;
  }

  const data = error.response?.data;

  if (data === null || data === undefined) {
    return null;
  }

  if (!Array.isArray(data)) {
    return null;
  }

  const fieldError = data.find((err) => err.field === fieldName);
  if (fieldError === undefined) {
    return null;
  }

  return fieldError.message;
};

export const createListOfLists = (array?: any[]) => {
  if (array === null || array === undefined) {
    return [];
  }

  if (!Array.isArray(array)) {
    return [];
  }

  const res = [];
  const size = array.length;
  if (size % 2 === 0) {
    for (let i = 0; i < size; i += 2) {
      res.push([array[i], array[i + 1]]);
    }
  } else {
    for (let i = 0; i < size - 1; i += 2) {
      res.push([array[i], array[i + 1]]);
    }
    res.push([array[size - 1], null]);
  }
  return res;
};

export const generateOptions = (options: any[], paramTitle = "name", paramValue = "value") => {
  const newOptions: Option[] = [];

  options.forEach((item) => {
    const temp = { title: item[paramTitle], value: item[paramValue] };
    newOptions.push(temp);
  });

  return newOptions;
};

export const getCurrentDate = (date: Date): string => {
  if (!date) {
    return "";
  }
  return `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${`0${date.getDate()}`.slice(-2)}`;
};

export const makeListSentenceFromWords = (input: Array<string | undefined>): string => {
  let sentence = "";

  input.forEach((word) => {
    if (word && word !== "") {
      sentence += word;
      sentence += ", ";
    }
  });

  return sentence.slice(0, -2);
};

export const capitalize = (s: string): string => s.charAt(0).toUpperCase() + s.slice(1);

export const getStreetAndHouseDetails = (address: AddressResponse | PublicAddressResponse): string =>
  address.street + (address.houseDetails ? ` ${address.houseDetails}` : "");

export const removeTrailingDot = (str?: string): string => {
  if (str === undefined) return "";

  if (str.length > 0) {
    const lastChar = str.slice(-1);

    if (lastChar === ".") {
      return str.slice(0, -1);
    }
  }

  return str;
};

export const getAddressString = (address: AddressResponse | PublicAddressResponse, showCountry = false): string =>
  `${address.zip} ${address.city}, ${getStreetAndHouseDetails(address)} ${
    showCountry ? ` (${address.countryCode})` : ""
  }`;

export function groupBy<T, V>(list: T[], keyGetter: (item: T) => V): Map<V, T[]> {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export const inputModeTypeFromInputType = (type: string): string => {
  switch (type) {
    case "number":
      return InputModeEnum.numeric;
    case "email":
      return InputModeEnum.email;
    case "tel":
      return InputModeEnum.tel;
    default:
      return InputModeEnum.text;
  }
};

export const getVatOptions = (vatRates: number[] | undefined): Option[] =>
  [...(vatRates || [])]?.map((i) => ({ title: `${i}%`, value: i }));

export const getDefaultVat = (vatRates: number[] | undefined): number | undefined => (vatRates ? vatRates[0] : 0);

export const enumKeys = (enumObject: object) => Object.keys(enumObject).filter((item) => Number.isNaN(Number(item)));

export const getEnumName = (enumObject: any, value: any) =>
  Object.keys(enumObject).find((key) => enumObject[key] === value) || "Not found";

export const canManageClinic = (clinic: ClinicResponse): boolean => {
  const currentUserId = getActiveUserId();
  return (
    clinic.owner?.userId === currentUserId || clinic.managers.find((i) => i.userId === currentUserId) !== undefined
  );
};

export const hasAuthority = (authority: SiteManagerAuthority) => {
  const authorities: string[] = jwtDecode(getAccessToken())?.authorities || [];

  return authorities.includes("MANAGER") && (authorities.includes("MANAGER_FULL") || authorities.includes(authority));
};

export const canAccessClinic = (clinic: ClinicResponse): boolean => {
  const currentUserId = getActiveUserId();
  return (
    clinic.owner?.userId === currentUserId ||
    clinic.managers.find((i) => i.userId === currentUserId) !== undefined ||
    clinic.vets.find((i) => i.userId === currentUserId) !== undefined ||
    clinic.authorizedAssistants.find((i) => i.userId === currentUserId) !== undefined ||
    clinic.assistants.find((i) => i.userId === currentUserId) !== undefined ||
    clinic.receptionists.find((i) => i.userId === currentUserId) !== undefined ||
    hasAuthority(SiteManagerAuthority.MANAGER_CLINIC_REMOTE_ACCESS)
  );
};

export const isFeatureAvailable = (clinic: ClinicResponse, feature: ClinicFeature): boolean => {
  const features = clinic?.availableFeatures || [];
  return features.includes(feature) || features.includes(ClinicFeature.ALL);
};

export default {
  LightenDarkenColor,
  isCancellable,
  isFeatureAvailable,
  getMonday,
  getSunday,
  getCurrentDate,
  getFullName,
  getGeneralError,
  getFieldError,
  createListOfLists,
  generateOptions,
  makeListSentenceFromWords,
  capitalize,
  getAddressString,
  inputModeTypeFromInputType,
  getVatOptions,
  getDefaultVat,
  getStreetAndHouseDetails,
  roundToNearestTime,
};
