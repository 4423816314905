/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { Control, Controller } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import EstimatedAgeComponent from "./EstimatedAgeComponent";

interface Props {
  control: Control<any>;
  defaultValue?: string | Date;
  label?: string;
  name?: string;
  optional?: boolean;
  readOnly?: boolean;
  required?: boolean;
  showError?: boolean;
  showRequired?: boolean;
}

const EstimatedBirthDatePicker: React.FC<Props> = ({
  control,
  defaultValue,
  label = strings.dateOfBirth,
  name = "estimatedAge",
  optional = false,
  readOnly = false,
  required = false,
  showError = false,
  ...rest
}: Props) => (
  <Controller
    control={control}
    name={name}
    rules={{
      required,
      validate: {
        isInvalidAge: (estimatedAge?: { years: number; months: number }) =>
          (estimatedAge &&
            estimatedAge.years >= 0 &&
            estimatedAge.years < 1000 &&
            estimatedAge.months >= 0 &&
            estimatedAge.months < 12) ||
          strings.invalidAge,
      },
    }}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <EstimatedAgeComponent
        defaultMonth={0}
        defaultYear={0}
        error={error}
        onChange={onChange}
        readOnly={readOnly}
        required={required}
        showError={showError}
        value={value}
        {...rest}
      />
    )}
  />
);

export default EstimatedBirthDatePicker;
