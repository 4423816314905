/*
 * Copyright © 2018-2022, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../common/Strings/Strings";
import Select from "./ReactHookFormFields/General/Select/Select";
import { Option } from "../models/Option";

interface Props {
  defaultValue: string;
  options: Option[];
  onChange(option: any): void;
}

const SortingSelect = (props: Props): ReactElement => {
  const { defaultValue, options, onChange } = props;
  const { register, setValue } = useForm<{ sortingOption: any }>({
    mode: "onChange",
    defaultValues: {
      sortingOption: defaultValue,
    },
  });

  useEffect(() => {
    setValue("sortingOption", defaultValue);
  }, [defaultValue]);

  const onSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const sortingOption = e.target.value;
    onChange(sortingOption);
  };

  return (
    <form>
      <div className="flex items-center space-x-4">
        <Select
          isChooseOption={false}
          register={register}
          label={strings.sortByLabel}
          name="sortingOption"
          onChange={onSelectionChange}
          options={options}
        />
      </div>
    </form>
  );
};

export default SortingSelect;
