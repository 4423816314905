/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { Link } from "react-router-dom";
import { NavMenuLinkClasses } from "../Themes/NavTheme";
import { strings } from "../../Strings/Strings";

interface Props {
  menu: string;
  href: string;
  isActive: boolean;
}

const NavItem: React.FC<Props> = ({ menu, href, isActive }: Props) => {
  const activeMenuClass = NavMenuLinkClasses.normal.active;
  const simpleMenuClass = NavMenuLinkClasses.normal.simple;
  const menuClass = isActive ? activeMenuClass : simpleMenuClass;

  return (
    <Link to={href} style={{ textDecoration: "none", color: "inherit", fontWeight: "normal" }}>
      <button className={`${menuClass}${isActive ? " tw-menu-active" : ""}`} type="button">
        {strings[menu]}
      </button>
    </Link>
  );
};

export default NavItem;
