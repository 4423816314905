/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import { useFormContext } from "react-hook-form";
import { strings } from "../../../common/Strings/Strings";
import { generateOptions } from "../../../util/helperFunctions";
import { PetGenders } from "../../../models/PetAttributes";
import Field from "../../../components/ReactHookFormFields/General/Field";
import CheckBox from "../../../components/ReactHookFormFields/General/CheckBox";
import Select from "../../../components/ReactHookFormFields/General/Select/Select";
import SelectInsuranceCompany from "../../../components/ReactHookFormFields/Specific/SelectInsuranceCompany";
import SelectSpeciesAndBreed, {
  PetSpeciesOption,
} from "../../../components/ReactHookFormFields/Specific/SelectSpeciesAndBreed";
import Textarea from "../../../components/ReactHookFormFields/General/Textarea";
import { PetGender } from "../../../models/pet/PetGender";
import { InsuranceCompanyResponse } from "../../../models/insurance/InsuranceCompanyResponse";
import { ChronicDiseaseOption, chronicDiseasesList } from "../../../common/ChronicDiseases/ChronicDiseases";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import CombinedSelect from "../../../components/ReactHookFormFields/General/Select/CombinedSelect";
import Button from "../../../components/Button";
import PetBirthDatePicker from "../../../components/ReactHookFormFields/Specific/PetBirthDatePicker";

export interface PetFormProps {
  breed: Array<{ name: string }>;
  color?: string;
  dateOfBirth?: string;
  dateOfBirthType: "known" | "estimated" | "unknown";
  estimatedAge?: {
    months: number;
    years: number;
  };
  gender: PetGender;
  name: string;
  species: Array<PetSpeciesOption>;
  chipNumber?: string;
  chronicDiseases?: ChronicDiseaseOption[];
  comment?: string;
  deceased: boolean;
  insuranceCompany?: InsuranceCompanyResponse[];
  insuranceNumber?: string;
  passportNumber?: string;
  tattooNumber?: string;
}

interface Props {
  showOnlyRequired?: boolean;
}

const PetForm = ({ showOnlyRequired = false }: Props): ReactElement => {
  const {
    control,
    register,
    formState: { errors },
    watch,
  } = useFormContext<PetFormProps>();

  const { dateOfBirthType } = watch();

  const [more, setMore] = useState<boolean>(showOnlyRequired);

  return (
    <div className="space-y-6">
      <div className="grid md:grid-cols-2 gap-x-4 gap-y-6">
        <Field
          autoComplete={AutoCompleteOptions.off}
          name="name"
          register={register}
          label={strings.name}
          error={errors.name}
          required
          showRequired
        />
        <SelectSpeciesAndBreed classNameSpecies="" classNameBreed="" control={control} required showRequired />
        <Select
          autoComplete={AutoCompleteOptions.off}
          name="gender"
          options={generateOptions(PetGenders)}
          register={register}
          error={errors.gender}
          label={strings.gender}
          required
          showRequired
        />
        <PetBirthDatePicker
          control={control}
          dateOfBirthType={dateOfBirthType}
          name="dateOfBirthType"
          label={strings.dateOfBirth}
          required
          showRequired
        />
        <Field autoComplete={AutoCompleteOptions.off} name="color" register={register} label={strings.color} />
      </div>
      {more ? (
        <Button
          onClick={() => {
            setMore(false);
          }}
          variant="link"
        >
          {strings.showMore}
        </Button>
      ) : (
        <>
          <div className="grid md:grid-cols-3 gap-x-4 gap-y-6">
            <Field
              autoComplete={AutoCompleteOptions.off}
              name="chipNumber"
              register={register}
              label={strings.chipNumber}
            />
            <Field
              autoComplete={AutoCompleteOptions.off}
              name="tattooNumber"
              register={register}
              label={strings.tattooNumber}
            />
            <Field
              autoComplete={AutoCompleteOptions.off}
              name="passportNumber"
              register={register}
              label={strings.passportNumber}
            />
          </div>
          <div className="grid md:grid-cols-2 gap-x-4 gap-y-6">
            <SelectInsuranceCompany control={control} name="insuranceCompany" />
            <Field
              autoComplete={AutoCompleteOptions.off}
              name="insuranceNumber"
              register={register}
              label={strings.insuranceNumber}
            />
          </div>
          <div className="grid gap-x-4 gap-y-6">
            <CombinedSelect
              allowNew={false}
              control={control}
              label={strings.chronicDiseases}
              labelKey="name"
              multiple
              name="chronicDiseases"
              options={chronicDiseasesList}
            />
            <Textarea
              autoComplete={AutoCompleteOptions.off}
              name="comment"
              register={register}
              label={strings.comment}
              minRows={2}
            />
            <CheckBox name="deceased" register={register} label={strings.deceased} defaultChecked={false} />
          </div>
        </>
      )}
    </div>
  );
};

export default PetForm;
