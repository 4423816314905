/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import qs from "query-string";
import { axiosCommon } from "../util/axiosSetup";
import { ApiPromise } from "../models/ApiPromise";
import Params from "../common/Params";
import { NotificationResponse } from "../models/notification/NotificationResponse";
import { PageResponse } from "../models/PageResponse";
import { NotificationTokenRequest } from "../models/notification/NotificationTokenRequest";
import { NotificationManagementResponse } from "../models/notification/NotificationManagementResponse";

export default class NotificationApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static getUnreadNotifications(): ApiPromise<NotificationResponse[]> {
    const url = `${this.baseUrl}/notifications/unread`;
    return axiosCommon.get(url);
  }

  static getNotifications(queryParams: {
    pageNumber?: number;
    pageSize?: number;
  }): ApiPromise<PageResponse<NotificationResponse>> {
    const query = qs.stringify(queryParams);
    const url = `${this.baseUrl}/notifications?${query}`;
    return axiosCommon.get(url);
  }

  static readNotification(notificationId: string): ApiPromise<NotificationResponse> {
    const url = `${this.baseUrl}/notifications/${notificationId}/read`;
    return axiosCommon.post(url);
  }

  static unreadNotification(notificationId: string): ApiPromise<NotificationResponse> {
    const url = `${this.baseUrl}/notifications/${notificationId}/unread`;
    return axiosCommon.post(url);
  }

  static savePushToken(token: string): ApiPromise<void> {
    const request: NotificationTokenRequest = { token };
    const url = `${this.baseUrl}/notifications/tokens`;
    return axiosCommon.post(url, request);
  }

  static deletePushToken(token: string): ApiPromise<void> {
    const url = `${this.baseUrl}/notifications/tokens?token=${token}`;
    return axiosCommon.delete(url);
  }

  static getNotificationsOfUser(
    userId: string,
    queryParams: {
      pageNumber?: number;
      pageSize?: number;
    }
  ): ApiPromise<PageResponse<NotificationManagementResponse>> {
    const query = qs.stringify(queryParams);
    const url = `${this.baseUrl}/management/users/${userId}/notifications?${query}`;
    return axiosCommon.get(url);
  }
}
