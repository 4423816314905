/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocalStorage } from "@uidotdev/usehooks";
import UserDropdownMenuItem from "./UserDropdownMenuItem";
import { ArrowPathSquare } from "../../Icons/ArrowPathSquare";
import { strings, SupportedLanguages } from "../../Strings/Strings";
import { doctorPlaceholder, dogMainPlaceholder } from "../../../assets/AssetConfig";
import { WrenchScrewdriver } from "../../Icons/WrenchScrewdriver";
import { Moon } from "../../Icons/Moon";
import { LightBulb } from "../../Icons/LightBulb";
import { UserCircle } from "../../Icons/UserCircle";
import { Globe } from "../../Icons/Globe";
import { QuestionMarkCircle } from "../../Icons/QuestionMarkCircle";
import { Power } from "../../Icons/Power";
import {
  getLocaleForActiveUser,
  getValueOfActiveUser,
  setRoleOfActiveUser,
  UserRole,
} from "../../../util/LocalStorageVariables";
import { useUser } from "../../../contexts/UserContext";
import { Bug } from "../../Icons/Bug";

export type ItemType = "dropdown-item" | "modal-item";

interface Props {
  itemType: ItemType;
  onMenuItemClick?(): void;
  showChooseLanguageModal(): void;
  showSwitchAccountModal(): void;
}

// Paths where the debug mode should be visible
const debugModePaths = ["/meet", "/management/videochat-monitor"];

const isDebugModeAvailable = () => debugModePaths.some((path) => window.location.pathname.includes(path));

const UserDropdownMenuItems: React.FC<Props> = ({
  itemType,
  onMenuItemClick,
  showChooseLanguageModal,
  showSwitchAccountModal,
}: Props) => {
  const [debugMode, setDebugMode] = useLocalStorage<boolean>("debugMode", false);
  const [darkMode, setDarkMode] = useLocalStorage<boolean | undefined>("darkMode");

  const location = useLocation();
  const navigate = useNavigate();

  const { user } = useUser();
  const role: UserRole | null = getValueOfActiveUser("role");
  const userLocale = user.locale ?? getLocaleForActiveUser();

  const language = SupportedLanguages.find((lan) => lan.code === userLocale)?.title;

  const logout = () => {
    navigate("/logout", { replace: true });
  };

  const setRole = (newRole: UserRole, path: string) => {
    setRoleOfActiveUser(newRole);
    navigate(path);
  };

  const toggleDebugMode = () => {
    setDebugMode((prev) => !prev);
  };

  const toggleDarkMode = () => {
    document.documentElement.classList.toggle("dark", !darkMode);
    setDarkMode(!darkMode);
  };

  return (
    <ul className="py-4 text-md text-gray-700 dark:text-gray-400 focus:outline-none">
      {/* TODO Display switch account menu item after fast user switch is fixed */}
      <UserDropdownMenuItem
        image={<ArrowPathSquare className={`inline ${itemType === "dropdown-item" ? "mr-2" : "mx-1"}`} />}
        itemType={itemType}
        text={strings.switchAccount}
        onClick={showSwitchAccountModal}
        withArrow
        visible={false}
      />
      <UserDropdownMenuItem
        image={
          <img
            src={doctorPlaceholder}
            className={`w-6 h-6 ${itemType === "dropdown-item" ? "mr-2" : "mx-1"}`}
            alt="Doctor"
          />
        }
        itemType={itemType}
        text={strings.employeeMode}
        onClick={() => {
          setRole("doctor", "/vet");
          onMenuItemClick && onMenuItemClick();
        }}
        visible={role !== "doctor" && location.pathname !== "/profile/setup"}
      />
      <UserDropdownMenuItem
        image={
          <img
            src={dogMainPlaceholder}
            className={`w-6 h-6 ${itemType === "dropdown-item" ? "mr-2" : "mx-1"}`}
            alt="Pet owner"
          />
        }
        itemType={itemType}
        text={strings.petOwnerMode}
        onClick={() => {
          setRole("owner", "/pet-owner");
          onMenuItemClick && onMenuItemClick();
        }}
        visible={role !== "owner" && location.pathname !== "/profile/setup"}
      />
      <UserDropdownMenuItem
        image={<WrenchScrewdriver className={`${itemType === "dropdown-item" ? "mr-2" : "mx-1"}`} variant="outline" />}
        itemType={itemType}
        text={strings.siteManagerMode}
        onClick={() => {
          setRole("manager", "/management");
          onMenuItemClick && onMenuItemClick();
        }}
        visible={role !== "manager" && user.isSiteManager && location.pathname !== "/profile/setup"}
      />
      <UserDropdownMenuItem
        image={
          darkMode ? (
            <Moon className={`${itemType === "dropdown-item" ? "mr-2" : "mx-1"}`} variant="outline" />
          ) : (
            <LightBulb className={`${itemType === "dropdown-item" ? "mr-2" : "mx-1"}`} variant="outline" />
          )
        }
        itemType={itemType}
        text={`${strings.energySaveMode}: ${darkMode ? strings.on : strings.off}`}
        onClick={() => {
          toggleDarkMode();
          onMenuItemClick && onMenuItemClick();
        }}
      />
      <UserDropdownMenuItem
        image={<UserCircle className={`${itemType === "dropdown-item" ? "mr-2" : "mx-1"}`} variant="outline" />}
        itemType={itemType}
        text={strings.profileSettings}
        onClick={() => {
          navigate("/profile-settings");
          onMenuItemClick && onMenuItemClick();
        }}
        visible={location.pathname !== "/profile/setup"}
      />
      <UserDropdownMenuItem
        image={<Globe className={`${itemType === "dropdown-item" ? "mr-2" : "mx-1"}`} variant="outline" />}
        itemType={itemType}
        text={`${strings.language}: ${language}`}
        onClick={() => {
          showChooseLanguageModal();
          itemType !== "modal-item" && onMenuItemClick && onMenuItemClick();
        }}
        withArrow
      />
      <UserDropdownMenuItem
        image={<QuestionMarkCircle className={`${itemType === "dropdown-item" ? "mr-2" : "mx-1"}`} />}
        itemType={itemType}
        text={strings.helpCenter}
        onClick={() => {
          navigate("/help");
          onMenuItemClick && onMenuItemClick();
        }}
        visible={location.pathname !== "/profile/setup"}
      />
      <UserDropdownMenuItem
        image={<Bug className={`${itemType === "dropdown-item" ? "mr-2" : "mx-1"}`} />}
        itemType={itemType}
        text={`${strings.debugMode}: ${debugMode ? strings.on : strings.off}`}
        onClick={() => {
          toggleDebugMode();
          onMenuItemClick && onMenuItemClick();
        }}
        visible={user.isSiteManager && isDebugModeAvailable()}
      />
      <UserDropdownMenuItem
        image={<Power className={`${itemType === "dropdown-item" ? "mr-2" : "mx-1"}`} />}
        itemType={itemType}
        text={strings.signOut}
        onClick={() => {
          logout();
          onMenuItemClick && onMenuItemClick();
        }}
      />
    </ul>
  );
};

export default UserDropdownMenuItems;
