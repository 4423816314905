/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import moment from "moment/moment";
import { VersionInfo } from "../../../models/version/VersionInfo";
import VersionApi, { serviceHasPublicVersionEndpoint, VersionedService } from "../../../api/VersionApi";
import { getGeneralError } from "../../../util/helperFunctions";

interface Props {
  name: VersionedService;
  baseUrl: string;
  repositoryUrl: string;
}

export const Service = ({ name, baseUrl, repositoryUrl }: Props): ReactElement => {
  const [versionInfo, setVersionInfo] = useState<VersionInfo>();
  const [error, setError] = useState<string | null>();

  useEffect(() => {
    const getVersionInfo = async () => {
      try {
        setError(null);

        let response;

        if (serviceHasPublicVersionEndpoint(name)) {
          response = await VersionApi.getPublicVersionInfo(baseUrl);
        } else {
          response = await VersionApi.getVersionInfo(baseUrl);
        }

        setVersionInfo(response.data);
      } catch (e) {
        setError(await getGeneralError(e));
      }
    };

    const getFrontendVersion = () => {
      const version: VersionInfo = {
        version: process.env.REACT_APP_VERSION || "",
        build: process.env.REACT_APP_LAST_COMMIT_HASH || "",
        lastModified: process.env.REACT_APP_LAST_COMMIT_TIME || "",
      };
      setVersionInfo(version);
    };

    if (name === "frontend") {
      getFrontendVersion();
    } else {
      void getVersionInfo();
    }
  }, [baseUrl, name]);

  return (
    <tr key={name}>
      <td className="px-4 py-3 text-sm font-bold whitespace-nowrap">
        <a href={repositoryUrl} className="text-current" rel="noreferrer" target="_blank">
          {name}
        </a>
      </td>
      <td hidden={!!error} className="px-4 py-3 text-sm font-medium whitespace-nowrap">
        {versionInfo?.version}
      </td>
      <td hidden={!!error} className="px-4 py-3 text-sm font-medium whitespace-nowrap">
        <a
          className="text-current font-medium"
          href={`${repositoryUrl}/-/commits/${versionInfo?.build}`}
          rel="noreferrer"
          target="_blank"
        >
          {versionInfo?.lastModified && moment(versionInfo?.lastModified).format("LLL")}
        </a>
      </td>
      <td colSpan={2} hidden={!error} className="px-4 py-3 text-sm font-medium whitespace-nowrap text-danger">
        {error}
      </td>
    </tr>
  );
};
