/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { Control, RegisterOptions, useController } from "react-hook-form";
import React, { ReactElement, useEffect, useState } from "react";
import type { Moment } from "moment";
import moment from "moment";
import momentGenerateConfig from "rc-picker/lib/generate/moment";
import { useLocalStorage } from "@uidotdev/usehooks";
import { ConfigProvider, DatePicker as AntDatePicker, theme } from "antd";
import enUS from "antd/locale/en_US";
import svSE from "antd/locale/sv_SE";
import huHU from "antd/locale/hu_HU";
import { Colors } from "../../../models/Colors";
import { dateAndTime, strings } from "../../../common/Strings/Strings";
import Tooltip from "../../Tooltip";
import { InfoFilled } from "../../../common/Icons/InfoFilled";
import { combineClassNames } from "../../../util/HtmlUtils";
import { CalendarIcon } from "../../../common/Icons/Calendar";
import { Cross } from "../../../common/Icons/Cross";
import { useUser } from "../../../contexts/UserContext";
import { getLocaleForActiveUser } from "../../../util/LocalStorageVariables";

// The locale map for the date picker, only works with dayjs since antd 4.0
// We only use dayjs for locale in this component
const localeMap: Record<string, any> = {
  en: enUS,
  sv: svSE,
  hu: huHU,
};

// Generate a date picker that works with Moment dates
const DatePicker = AntDatePicker.generatePicker<Moment>(momentGenerateConfig);

interface FieldProps {
  control: Control<any>;
  name: string;
  classInput?: string;
  classLabel?: string;
  className?: string;
  defaultValue?: Date | Date[] | null;
  fieldOptions?: Exclude<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs">;
  format?: string;
  label?: string;
  labelIcon?: string | ReactElement;
  labelOff?: boolean;
  max?: Moment;
  min?: Moment;
  onChange?(date: Date | null): void;
  optional?: boolean;
  readOnly?: boolean;
  required?: boolean;
  showError?: boolean;
  showIcon?: boolean;
  showRequired?: boolean;
  suffix?: string;
  toolTipText?: string | ReactElement;
  width?: string;
}

const DatePickerInput = ({
  control,
  name: n,
  classInput,
  classLabel,
  className,
  defaultValue,
  fieldOptions,
  format,
  label,
  labelIcon,
  labelOff = false,
  max,
  min,
  onChange: customOnChange,
  optional = false,
  readOnly = false,
  required = false,
  showError = true,
  showIcon = true,
  showRequired = false,
  suffix,
  toolTipText,
  width,
}: FieldProps): ReactElement => {
  const [darkMode] = useLocalStorage<boolean | undefined>("darkMode");

  const { user } = useUser();
  const userLocale = user.locale ?? getLocaleForActiveUser();
  const selectedLocale = userLocale ? localeMap[userLocale] : enUS;

  const [space, setSpace] = useState<number>(0);
  const [registerOptions, setRegisterOptions] = useState<
    Exclude<RegisterOptions, "valueAsNumber" | "valueAsDate" | "setValueAs">
  >(fieldOptions || {});
  const {
    field: { name, value, onChange },
    fieldState: { error },
  } = useController({ name: n, control, rules: registerOptions, defaultValue });

  useEffect(() => {
    if (suffix) {
      if (toolTipText) {
        setSpace(2);
      } else {
        setSpace(1);
      }
    } else if (toolTipText) {
      setSpace(1);
    }
  }, [suffix, toolTipText]);

  useEffect(() => {
    if (required) {
      setRegisterOptions({ ...registerOptions, required: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [required]);

  return (
    <div className={combineClassNames(className, error ? "has-error" : "")} style={{ width }}>
      {labelOff ? (
        <></>
      ) : (
        <label htmlFor={name} className={classLabel}>
          {labelIcon ? (
            typeof labelIcon === "string" ? (
              <i className={labelIcon} />
            ) : (
              <span style={{ verticalAlign: "text-bottom" }}>{labelIcon}</span>
            )
          ) : (
            ""
          )}{" "}
          {label}
          {showRequired ? " *" : ""}
          {optional ? <span style={{ color: Colors.INPUTBORDER }}> ({strings.optional})</span> : <></>}
        </label>
      )}
      <div className="relative w-full">
        <ConfigProvider
          locale={selectedLocale}
          theme={{
            algorithm: darkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
            token: {
              colorTextPlaceholder: "rgb(212, 212, 216)", // Placeholder color text
              colorBgElevated: darkMode ? "rgb(55, 65, 81)" : "rgb(249, 250, 251)", // Calendar background color
              controlOutlineWidth: 1,
              controlOutline: "rgb(12 162 254)", // Border color on focus
              colorPrimaryHover: darkMode ? "rgb(75, 85, 99)" : "rgb(209, 213, 219)", // Prevents hover color change effect
            },
          }}
        >
          <div
            className={combineClassNames(
              "font-sm rounded-lg focus-within:ring-2 focus-within:ring-blue-700",
              readOnly ? "opacity-50 pointer-events-none" : ""
            )}
          >
            <DatePicker
              allowClear={{ clearIcon: <Cross className="w-6 h-6 flex-shrink-0 dark:text-white" /> }}
              className={classInput || `tw-input ${space === 1 && "pr-10"} ${space === 2 && "pr-20"}`}
              disabled={readOnly}
              format={format || dateAndTime.momentDateFormat}
              maxDate={max}
              minDate={min}
              name={name}
              onChange={(moment: Moment | null) => {
                const date = moment ? moment.toDate() : null;
                onChange(date);
                if (customOnChange) {
                  customOnChange(date);
                }
              }}
              placeholder="dd/mm/yyyy"
              suffixIcon={
                showIcon ? (
                  <CalendarIcon className="w-5 h-5 flex-shrink-0 text-sky-600 dark:text-sky-400" strokeWidth={1.5} />
                ) : null
              }
              value={value ? moment(value) : undefined}
            />
          </div>
        </ConfigProvider>
        <div className="absolute inset-y-0 right-0 flex items-center pr-2 space-x-2">
          {suffix ? (
            <div>
              <span>{suffix}</span>
            </div>
          ) : (
            <></>
          )}
          {toolTipText ? (
            <div>
              <Tooltip content={toolTipText} placement="top">
                <span>
                  <InfoFilled className="text-sky-500" />
                </span>
              </Tooltip>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {showError && error?.message && <p>{error.message}</p>}
    </div>
  );
};

export default DatePickerInput;
