/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { strings } from "../../../common/Strings/Strings";
import { ReservationAccessScope } from "../../../models/reservation/ReservationAccessScope";
import Prices from "../../../components/InputFieldsSimple/Prices";
import CostCalculator from "../../../util/CostCalculator";
import NumberFormatter from "../../../util/NumberFormatter";
import Button from "../../../components/Button";
import { XMark } from "../../../common/Icons/XMark";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import { ClinicResponse } from "../../../models/clinic/ClinicResponse";
import { VideoConsultation } from "../../../models/medical/VideoConsultation";

interface Props {
  accessScope: ReservationAccessScope;
  canEdit: boolean;
  clinic?: ClinicResponse;
  countryDetails?: CountryDetailsResponse;
  deleteCurrent?: () => void;
  readonly?: boolean;
  priceChange: (value: number) => void;
  videoConsultation: VideoConsultation;
}

// Currently the back-end does not allow the deletion of video consultations
// Only price modifications are allowed for now
const IS_VIDEO_CONSULTATION_DELETABLE = false;

const VideoConsultationRow: React.FC<Props> = ({
  accessScope,
  canEdit,
  clinic,
  countryDetails,
  deleteCurrent,
  readonly,
  priceChange,
  videoConsultation,
}: Props) => (
  <div>
    <div className="flex items-center">
      <div className="font-bold mr-3">
        {/* Replace spaces inside parentheses with non-breaking spaces */}
        {videoConsultation.description.replace(/\(([^)]+)\)/, (match) => match.replace(/ /g, "\u00A0"))}
      </div>
      <div className="flex items-center ml-auto space-x-3">
        <div>
          <div hidden={accessScope === ReservationAccessScope.PEEK}>
            {readonly ? (
              <div className="whitespace-nowrap">
                {NumberFormatter.formatPrice(
                  CostCalculator.getGrossPrice(
                    videoConsultation.price || 0,
                    videoConsultation.vat,
                    countryDetails,
                    clinic
                  ),
                  countryDetails?.currency
                )}
              </div>
            ) : (
              <Prices
                currency={countryDetails?.currency}
                label={strings.price}
                onChange={(p) => priceChange(p || 0)}
                readOnly={!canEdit}
                roundingStrategy={clinic?.roundingStrategyItem || countryDetails?.roundingStrategyItem}
                value={videoConsultation.price || 0}
                vat={videoConsultation.vat}
                width="110px"
              />
            )}
          </div>
        </div>
        {canEdit && !readonly && IS_VIDEO_CONSULTATION_DELETABLE && (
          <div>
            <Button
              variant="basic"
              onClick={() => {
                deleteCurrent?.();
              }}
            >
              <XMark className="w-5 h-5" />
            </Button>
          </div>
        )}
      </div>
    </div>
    <div className="flex items-center text-gray-500">
      <div>
        {strings.subtotal} ({strings.gross})
      </div>
      <div className="ml-auto">
        {NumberFormatter.formatPrice(
          CostCalculator.getGrossPrice(videoConsultation.price, videoConsultation.vat, countryDetails, clinic),
          countryDetails?.currency
        )}
      </div>
    </div>
  </div>
);

export default VideoConsultationRow;
