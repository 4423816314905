/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import moment from "moment/moment";
import { InfoFilled } from "../../../../common/Icons/InfoFilled";
import { ExclamationMark } from "../../../../common/Icons/ExclamationMark";
import { SignalServerLogEntry } from "../../../../models/videoConsultation/SignalServerLogEntry";
import { dateAndTime } from "../../../../common/Strings/Strings";
import { LogLevel } from "./LogLevel";

const getIcon = (level: string): ReactElement => {
  switch (level.toLowerCase()) {
    case LogLevel.INFO:
      return <InfoFilled className="w-6 h-6 flex-shrink-0 text-blue-600 dark:text-blue-400" />;
    case LogLevel.WARNING:
      return <ExclamationMark className="w-6 h-6 flex-shrink-0 text-orange-600 dark:text-orange-400" />;
    case LogLevel.ERROR:
      return <ExclamationMark className="w-6 h-6 flex-shrink-0 text-red-600 dark:text-red-400" />;
    default:
      return <ExclamationMark className="w-6 h-6 flex-shrink-0 text-red-600 dark:text-red-400" />;
  }
};

interface Props {
  entry: SignalServerLogEntry;
}

const LogEntry: React.FC<Props> = ({ entry }: Props) => {
  const icon = getIcon(entry.level);
  const formattedTimestamp = moment(entry.timestamp).format(dateAndTime.momentDateTimeWithSecondsFormat);
  const message = entry.message;

  return (
    <div className="flex flex-row gap-2">
      {icon}
      <p className="font-mono">{`[${formattedTimestamp}] ${message}`}</p>
    </div>
  );
};

export default LogEntry;
