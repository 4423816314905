/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useCallback, useEffect, useState } from "react";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import { getGeneralError } from "../../../util/helperFunctions";
import { strings } from "../../../common/Strings/Strings";
import { BroadcastNotificationResponse } from "../../../models/notification/BroadcastNotificationResponse";
import BroadcastNotificationApi from "../../../api/BroadcastNotificationApi";
import { usePager } from "../../../hooks/hooks";
import PageableTableHead from "../../../components/PageableTable/PageableTableHead";
import PageableTableBody from "../../../components/PageableTable/PageableTableBody";
import PageableTable from "../../../components/PageableTable/PageableTable";
import { PageResponse } from "../../../models/PageResponse";

function Notifications(): ReactElement {
  const [notifications, setNotifications] = useState<PageResponse<BroadcastNotificationResponse>>();

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const pager = usePager(5);

  const getNotifications = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await BroadcastNotificationApi.getNotifications(pager);
      setNotifications(response.data);
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    void getNotifications();
  }, [getNotifications, pager.pageNumber, pager.pageSize]);

  return (
    <>
      <div className="tw-card p-4">
        <div className="d-flex mb-4">
          <div className="flex-grow-1 font-bold">{strings.notifications}</div>
          <div>
            <Link to="/management/notifications/create">+ {strings.new}</Link>
          </div>
        </div>
        <PageableTable
          pager={pager}
          isLoading={loading}
          content={notifications}
          error={error}
          sizeSelectVisible={false}
        >
          <PageableTableHead>
            <tr>
              <th className="tw-th">{strings.message}</th>
              <th className="tw-th text-right">{strings.notificationsSent}</th>
              <th className="tw-th text-right">{strings.notificationsRead}</th>
            </tr>
          </PageableTableHead>
          <PageableTableBody>
            {notifications &&
              notifications.elements.map((notification) => (
                <tr key={notification.id}>
                  <td className="px-4 py-3">
                    <p className="text-sm font-normal line-clamp-2">
                      <Link
                        to={`/management/notifications/${notification.id}`}
                        className="tw-link-dark font-normal text-decoration-none"
                      >
                        {notification.message || notification.email?.message}
                      </Link>
                    </p>
                    <p className="text-xs font-light mt-1">{moment(notification.sent).fromNow()}</p>
                  </td>
                  <td className="px-4 py-3 text-sm text-right">{notification.count}</td>
                  <td className="px-4 py-3 text-sm text-right">{notification.read}</td>
                </tr>
              ))}
          </PageableTableBody>
        </PageableTable>
      </div>
    </>
  );
}

export default Notifications;
