/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { clinicFeeUnits, strings } from "../../../../common/Strings/Strings";
import { canManageClinic, getGeneralError, isFeatureAvailable } from "../../../../util/helperFunctions";
import { ClinicResponse } from "../../../../models/clinic/ClinicResponse";
import { ClinicFeeType } from "../../../../models/clinic/ClinicFeeType";
import CountryApi from "../../../../api/CountryApi";
import { CountryDetailsResponse } from "../../../../models/management/CountryDetailsResponse";
import { ClinicFeeResponse } from "../../../../models/clinic/ClinicFeeResponse";
import NumberFormatter from "../../../../util/NumberFormatter";
import { Pencil } from "../../../../common/Icons/Pencil";
import Button from "../../../../components/Button";
import CostCalculator from "../../../../util/CostCalculator";
import { getActiveUserId } from "../../../../util/LocalStorageVariables";
import PrescriptionFeeModal from "./PrescriptionFeeModal";
import AlertBox from "../../../../components/AlertBox";
import { ClinicFeature } from "../../../../models/clinic/ClinicFeature";
import VideoConsultationFeeModal from "./VideoConsultationFeeModal";
import LoaderInline from "../../../../components/LoaderInline";

interface Props {
  clinicResponse: ClinicResponse;
  isActiveTab: boolean;
}

const FeesTab: React.FC<Props> = ({ clinicResponse, isActiveTab }: Props) => {
  const [clinic, setClinic] = useState<ClinicResponse>(clinicResponse);
  const [prescriptionFee, setPrescriptionFee] = useState<ClinicFeeResponse>();
  const [videoConsultationFee, setVideoConsultationFee] = useState<ClinicFeeResponse>();
  const [countryDetails, setCountryDetails] = useState<CountryDetailsResponse>();
  const [isPrescriptionModalOpen, setPrescriptionModalOpen] = useState<boolean>(false);
  const [isVideoConsultationModalOpen, setVideoConsultationModalOpen] = useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const getCountryDetails = async (clinicId: string) => {
      try {
        setLoading(true);
        const response = await CountryApi.getCountryDetailsOfClinic(clinicId);
        setCountryDetails(response.data);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    };

    if (!isActiveTab || !clinic?.id) return;

    void getCountryDetails(clinic.id);
  }, [clinic, isActiveTab]);

  useEffect(() => {
    const fees = clinic?.fees || [];
    setVideoConsultationFee(fees.find((i) => i.type === ClinicFeeType.VIDEO_CONSULTATION_FEE));
    setPrescriptionFee(fees.find((i) => i.type === ClinicFeeType.PRESCRIPTION_FEE));
  }, [clinic]);

  const currentUserId = getActiveUserId();
  const canEdit =
    canManageClinic(clinic) ||
    clinic.vets.find((i) => i.userId === currentUserId) !== undefined ||
    clinic.assistants.find((i) => i.userId === currentUserId) !== undefined ||
    clinic.authorizedAssistants.find((i) => i.userId === currentUserId) !== undefined;

  if (
    !isFeatureAvailable(clinic, ClinicFeature.PRESCRIPTION) &&
    !isFeatureAvailable(clinic, ClinicFeature.VIDEO_CONSULTATION)
  ) {
    return <></>;
  }

  return (
    <div className="tw-card divide-y divide-gray-200 dark:divide-gray-700">
      <div className="p-4">
        <p className="uppercase">{strings.fees}</p>
      </div>
      <div className="p-4">
        <AlertBox className="mb-4" message={error} />
        <div>{strings.feesToolTip}</div>
      </div>
      <div className="p-4" hidden={!isLoading}>
        <LoaderInline />
      </div>
      {isFeatureAvailable(clinic, ClinicFeature.PRESCRIPTION) && !isLoading && (
        <div className="p-4 flex justify-between items-center space-x-4">
          <div className="flex flex-wrap items-center">
            {strings.prescriptionFee}:
            <b className="ml-2">
              {NumberFormatter.formatPrice(
                CostCalculator.getGrossPrice(
                  prescriptionFee?.amount || 0,
                  prescriptionFee?.vat,
                  countryDetails,
                  clinicResponse
                ),
                countryDetails?.currency
              )}
            </b>
            <span className="ml-2 opacity-50">
              ({NumberFormatter.formatPrice(prescriptionFee?.amount || 0, countryDetails?.currency)} {strings.plusVat})
            </span>
          </div>
          <div>
            <Button variant="icon" onClick={() => setPrescriptionModalOpen(true)} hidden={!canEdit}>
              <span className="sr-only">{strings.edit}</span>
              <Pencil />
            </Button>
          </div>
        </div>
      )}
      {isFeatureAvailable(clinic, ClinicFeature.VIDEO_CONSULTATION) && !isLoading && (
        <div className="p-4 flex justify-between items-center space-x-4">
          <div className="flex flex-wrap items-center">
            {strings.videoConsultationFee}:
            <b className="ml-2">
              {NumberFormatter.formatPrice(
                CostCalculator.getGrossPrice(
                  videoConsultationFee?.amount || 0,
                  videoConsultationFee?.vat,
                  countryDetails,
                  clinicResponse
                ),
                countryDetails?.currency
              )}
            </b>
            <span className="ml-2 opacity-50">
              ({NumberFormatter.formatPrice(videoConsultationFee?.amount || 0, countryDetails?.currency)}{" "}
              {strings.plusVat})
            </span>
            <span className="ml-2">{videoConsultationFee?.unit ? clinicFeeUnits[videoConsultationFee?.unit] : ""}</span>
          </div>
          <div>
            <Button variant="icon" onClick={() => setVideoConsultationModalOpen(true)} hidden={!canEdit}>
              <span className="sr-only">{strings.edit}</span>
              <Pencil />
            </Button>
          </div>
        </div>
      )}
      <PrescriptionFeeModal
        clinic={clinic}
        countryDetails={countryDetails}
        onRefresh={(clinic) => setClinic(clinic)}
        setModalOpen={setPrescriptionModalOpen}
        isModalOpen={isPrescriptionModalOpen}
      />
      <VideoConsultationFeeModal
        clinic={clinic}
        countryDetails={countryDetails}
        onRefresh={(clinic) => setClinic(clinic)}
        setModalOpen={setVideoConsultationModalOpen}
        isModalOpen={isVideoConsultationModalOpen}
      />
    </div>
  );
};

export default FeesTab;
