/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export const diagnoseListHU = [
  { title: "3. szemhéj elöesése" },
  { title: "3. szemhéj mirigyének előesése" },
  { title: "3. szemhéj porcának előesése" },
  { title: "Aberráns a. subclavia sinistra" },
  { title: "Abomasoenteritis" },
  { title: "Abortus" },
  { title: "Abscessus" },
  { title: "Abscessus cerebri" },
  { title: "Abscessus extraduralis" },
  { title: "Abscessus hepatis" },
  { title: "Abscessus interdigitalis" },
  { title: "Abscessus intraperitonealis" },
  { title: "Abscessus lienalis" },
  { title: "Abscessus mesenterialis" },
  { title: "Abscessus oropharyngealis" },
  { title: "Abscessus pancreatis" },
  { title: "Abscessus prostatae" },
  { title: "Abscessus pulmonum" },
  { title: "Abscessus renis" },
  { title: "Abscessus retrobulbaris" },
  { title: "Abscessus retroperitonealis" },
  { title: "Abscessus retropharyngealis" },
  { title: "Acanthoma, kornifikáló" },
  { title: "Acanthosis nigricans" },
  { title: "Acardius" },
  { title: "Acarodermatitis" },
  { title: "Acephalus" },
  { title: "Acetonaemia" },
  { title: "Achalasia" },
  { title: "Achilles ín sérülés" },
  { title: "Achilles ín szakadás" },
  { title: "Acholia" },
  { title: "Acidosis ingestae ruminis" },
  { title: "Acidosis metabolica" },
  { title: "Acidosis respiratorica" },
  { title: "Acidosis tubularis distalis" },
  { title: "Acidosis tubularis proximalis" },
  { title: "Acne" },
  { title: "Acne juvenilis" },
  { title: "Acromegalia" },
  { title: "Acropachia" },
  { title: "Actinobacillosis" },
  { title: "Actinomycosis" },
  { title: "Acut non-compressiv nucleus pulposus extrusion - ANNPE" },
  { title: "Acyclia" },
  { title: "Acyclia gastrica" },
  { title: "Addison-kór" },
  { title: "Addison-kór, atipikus" },
  { title: "Addison-kór, iatrogén" },
  { title: "Addison-kór, tipikus" },
  { title: "Addison krízis" },
  { title: "Adenitis equorum" },
  { title: "Adenocarcinoma" },
  { title: "Adenoma" },
  { title: "Adenoma cholangiosum" },
  { title: "Adenoma hepatocellulare" },
  { title: "Adenoma, faggyúmirigy" },
  { title: "Adenopapillomatosis" },
  { title: "Adhaesio" },
  { title: "Adipocera" },
  { title: "Adipositas" },
  { title: "Adrenalis hyperplasia syndroma" },
  { title: "Adrenalis tumor" },
  { title: "Adrenocorticalis necrosis" },
  { title: "Adverse food reaction" },
  { title: "Aerocystitis" },
  { title: "Aerophagia" },
  { title: "Agalactia" },
  { title: "Agenesia renum" },
  { title: "Agnathia" },
  { title: "Agresszivitás" },
  { title: "Agyi hypoxia" },
  { title: "Agyi nyomás fokozódás" },
  { title: "Agyödéma" },
  { title: "Agysérülés" },
  { title: "AHDH-szerű szindróma" },
  { title: "A-hypervitaminosis" },
  { title: "A-hypovitaminosis" },
  { title: "Akut a krónikuson vesebetegség" },
  { title: "Akut has" },
  { title: "Akut hasi vérzés" },
  { title: "Akut lymphoblastos leukémia" },
  { title: "Akut neutrophiliás cholangitis" },
  { title: "Akut respiraciós distress" },
  { title: "Akut tumor-lízis szindróma" },
  { title: "Akut vérzéses hasmenés szindróma (AHDS)" },
  { title: "Akut vesekárosodás" },
  { title: "Alacsony malignitasú intestinális kis T-sejtes lymphoma" },
  { title: "Alariosis" },
  { title: "Albinismus" },
  { title: "Aleuti-betegség" },
  { title: "Alkalosis ingestae ruminis" },
  { title: "Alkalosis metabolica" },
  { title: "Alkarnövekedési zavar" },
  { title: "Allergia" },
  { title: "Allergodermatitis" },
  { title: "Allotriophagia" },
  { title: "Alopecia" },
  { title: "Alopecia acquisita" },
  { title: "Alopecia areata" },
  { title: "Alopecia congenita" },
  { title: "Alopecia symptomatica" },
  { title: "Alopecia traumát Ica" },
  { title: "Alopecia-X" },
  { title: "Alsó húgyúti infekció (UTI)" },
  { title: "Altatási szövődmény" },
  { title: "Amaurosis" },
  { title: "Amidostomosis" },
  { title: "Amyloidosis" },
  { title: "Amyloidosis renum" },
  { title: "Anaemia" },
  { title: "Anaemia aplastica" },
  { title: "Anaemia haemolytica" },
  { title: "Anaemia immunhaemolitica" },
  { title: "Anaemia nonregeneretiva" },
  { title: "Anaemia of chronic disease" },
  { title: "Anaemia posthaemorrhagica" },
  { title: "Anaemia regenerativa" },
  { title: "Anagen defluxion" },
  { title: "Anagen / telogen defluxion" },
  { title: "Anaphylaxia" },
  { title: "Anaplasmosis" },
  { title: "Anasarca" },
  { title: "Ancylostomosis" },
  { title: "Aneurisma" },
  { title: "Angioedema" },
  { title: "Angiopathia" },
  { title: "Angiostrongylosis" },
  { title: "Angolkór" },
  { title: "Anisocoria" },
  { title: "Ankyloblepharon" },
  { title: "Ankylosis" },
  { title: "Anoestrus" },
  { title: "Anophtalmia" },
  { title: "Anorexia" },
  { title: "Anoxia" },
  { title: "Anthracosis" },
  { title: "Anthrax" },
  { title: "Antibiotic responsive diarrhoea" },
  { title: "Anuria" },
  { title: "Aorta stenosis" },
  { title: "Aorta trombus" },
  { title: "Aphagia" },
  { title: "Aphta epizootica" },
  { title: "Apikális gyökértályog" },
  { title: "Aplasia renis et ureteris" },
  { title: "Aplasia segmentalis v. cavae caudalis" },
  { title: "Apnoe" },
  { title: "Apophyseolisis" },
  { title: "ARDS" },
  { title: "Argulosis" },
  { title: "Arrhytmia" },
  { title: "Arrhytmia absoluta" },
  { title: "Arrhytmia cordia" },
  { title: "Arrhytmia vegetativa" },
  { title: "Arteriitis" },
  { title: "Arteriosclerosis" },
  { title: "Arteriovenosus fistula" },
  { title: "Arthritis" },
  { title: "Arthritis purulenta" },
  { title: "Arthritis reumatica" },
  { title: "Arthrodesis" },
  { title: "Arthrogryposis congenita" },
  { title: "Arthrosis" },
  { title: "Ascariosis" },
  { title: "Ascendáló bénulás" },
  { title: "Ascites" },
  { title: "Aseptikus combcsontfej necrosis (Perthes disease)" },
  { title: "Aspergillosos" },
  { title: "Asphyxia" },
  { title: "Asphyxia intrauterina" }, 
  { title: "Aspiratio" },
  { title: "Aspiratio corporis alieni" },
  { title: "Asthma" },
  { title: "Astrocytoma" },
  { title: "Ataxia" },
  { title: "Ataxia cerebellaris" },
  { title: "Ataxia vestibularis" },
  { title: "Atelectasia pulmonum" },
  { title: "Ateroma" },
  { title: "Atipikus Addison-betegség" },
  { title: "Atipikus Cushing-szindróma" },
  { title: "Atlanto-axialis instabilitás" },
  { title: "Átmeneti sejtes carcinoma" },
  { title: "Atonia proventriculi" },
  { title: "Atonia recti" },
  { title: "Atonia uteri" },
  { title: "Atonia ventriculi" },
  { title: "Atopia" },
  { title: "Atopiás dermatitis" },
  { title: "Atopic like dermatitis (ALD)" },
  { title: "Atresia" },
  { title: "Atresia ani" },
  { title: "Atrichosis congenita" },
  { title: "Atrioventricularis blokk" },
  { title: "Atrioventricularis blokk, I" },
  { title: "Atrioventricularis blokk, II" },
  { title: "Atrioventricularis blokk, III" },
  { title: "Atrophia cerebelli" },
  { title: "Atrophia cerebri" },
  { title: "Atrophia cutis" },
  { title: "Atrophia musculi temporalis" },
  { title: "Aujeszky-féle betegség" },
  { title: "Aurotrichia" },
  { title: "Autoimmun haemolyticus anaemia" },
  { title: "Autoimmun neutropenia" },
  { title: "Autoimmun thrombocytopenia" },
  { title: "Autoimmunopathia" },
  { title: "Autointoxicatio" },
  { title: "Autolysis" },
  { title: "Automutilatio" },
  { title: "Avitaminosis" },
  { title: "Avulsio" },
  { title: "Avulsio crista Tobias" },
  { title: "Azotaemia" },
  { title: "Azotaemia prerenalis" },
  { title: "Babesia gibsoni fertőzés" },
  { title: "Babesiosis" },
  { title: "Babesiosis, szövődményes" },
  { title: "Baby pig disease" },
  { title: "Bacteriaemia" },
  { title: "Bacterial OverGrowth Syndrom (BOG)" },
  { title: "Balanoposthitis" },
  { title: "Bartonellosis" },
  { title: "Basa lipoma" },
  { title: "B-avitaminosis" },
  { title: "Beagle pain" },
  { title: "Begymegterhelés" },
  { title: "Bélférgesség" },
  { title: "Biaditis" },
  { title: "Black hair follicular dysplasia" },
  { title: "Black head" },
  { title: "Blastomycosis" },
  { title: "Blepharitis" },
  { title: "Blepharitis, canthalis medialis" },
  { title: "Blepharoconjunctivitis" },
  { title: "Bluetongue" },
  { title: "Bolha allergia" },
  { title: "Bolhásság" },
  { title: "Borreliosis avium" },
  { title: "Botallo-vezeték okozta nyelőcső-lefüződés" },
  { title: "Bothriocephalosis" },
  { title: "Botulismus" },
  { title: "Bovine spongioform encephalopathy" },
  { title: "Bőr dirofilariosis" },
  { title: "Bőrredő-ekcéma" },
  { title: "Brachignathia" },
  { title: "Brachycephal szindróma" },
  { title: "Brachygnathia inferior" },
  { title: "Bradsot" },
  { title: "Bradycardia" },
  { title: "Branchiomycosis" },
  { title: "Bromethalin toxicosis" },
  { title: "Bronchiectasia" },
  { title: "Bronchiolitis" },
  { title: "Bronchitis" },
  { title: "Bronchitis chronica" },
  { title: "Bronchitis et bronchiolitis" },
  { title: "Bronchitis infectiosa" },
  { title: "Bronchoalveolaris carcinoma" },
  { title: "Bronchomalatia" },
  { title: "Bronchopneumonia" },
  { title: "Bronchopneumonia enzootica" },
  { title: "Brucellosis" },
  { title: "Bulla pulmonis" },
  { title: "Bullosus pemphigoid" },
  { title: "Bunostomosis" },
  { title: "Buphtalmus" },
  { title: "Bursitis" },
  { title: "Bűzmirigy-eltömődés" },
  { title: "Bűzmirigy-gyulladás" },
  { title: "Cachexia" },
  { title: "Calcificatio" },
  { title: "Calcinogranuloma" },
  { title: "Calcinosis circumscipta" },
  { title: "Calcinosis cutis" },
  { title: "Calcinosis enzootica" },
  { title: "Calculus" },
  { title: "Calicivírus" },
  { title: "Callus" },
  { title: "Callus dermatitis et pyoderma" },
  { title: "Calvarial hyperostosis szindróma" },
  { title: "Campylobacteriosis" },
  { title: "Campylognathia" },
  { title: "Canaliculitis" },
  { title: "Candidosis" },
  { title: "Canine geriatric cognitive disfunction" },
  { title: "Canine pattern alopecia" },
  { title: "Canine ulcerative paradental stomatitis" },
  { title: "Caninus persistens" },
  { title: "Capillariosis" },
  { title: "Carcinoma" },
  { title: "Carcinoma adenomatosum" },
  { title: "Carcinoma cholangiocellulare" },
  { title: "Carcinoma hepatocellulare" },
  { title: "Carcinoma inflammatorica mammae" },
  { title: "Carcinoma planocellulare" },
  { title: "Carcinosarcoma" },
  { title: "Cardiaspasmus" },
  { title: "Cardio-Hepatomegalia" },
  { title: "Cardiomyopathia" },
  { title: "Cardiomyopathia dilativa" },
  { title: "Cardiomyopathia hypertrophica" },
  { title: "Cardiomyopathia idiopathica" },
  { title: "Cardiomyopathia restrictiva" },
  { title: "Caries" },
  { title: "Caro luxurians" },
  { title: "Carpitis" },
  { title: "Cataracta" },
  { title: "Cataractia" },
  { title: "Cauda Equina syndroma" },
  { title: "CEA" },
  { title: "Cellulitis" },
  { title: "Centralis diabetes insipidus" },
  { title: "Centralis eredetű hányás" },
  { title: "Centrális vakság" },
  { title: "Centrális vestibularis szindróma" },
  { title: "Cephalalgia" },
  { title: "Cerebellitis" },
  { title: "Cerebellitis idiopathica" },
  { title: "Cervix duplex" },
  { title: "Chalasion" },
  { title: "Cheiloschisis" },
  { title: "Cheledermatitis" },
  { title: "Chemodectomia" },
  { title: "Chemosis" },
  { title: "Cheyletiellosis" },
  { title: "Chiari-malformatio" },
  { title: "Chlamydiosis" },
  { title: "Cholangio hepatitis" },
  { title: "Cholangitis" },
  { title: "Cholangitis, destruktív" },
  { title: "Cholangitis, lymphocytic" },
  { title: "Cholangitis, neutophilic" },
  { title: "Cholesystitis" },
  { title: "Cholelithiasis" },
  { title: "Cholestasis" },
  { title: "Cholestasis of sepsis" },
  { title: "Cholesteatoma" },
  { title: "Chondroma" },
  { title: "Chondromalacia" },
  { title: "Chondromalacia diffusa" },
  { title: "Chondrosarcoma" },
  { title: "Chorioiditis" },
  { title: "Choriorenitis" },
  { title: "CHPG" },
  { title: "Chyloperitoneum" },
  { title: "Chylothorax" },
  { title: "C-hypovitaminosis" },
  { title: "Cicát fix" },
  { title: "Ciliaris dyskinesia" },
  { title: "Cinkhiányos dermatosis" },
  { title: "CIRCI" },
  { title: "Cirrhosis hepatis" },
  { title: "Cirrhosis renum" },
  { title: "Clostridiosis" },
  { title: "Coagulopathia" },
  { title: "Coagulopathia disseminata intravasalis" },
  { title: "Coccidiosis" },
  { title: "Coenurosis" },
  { title: "Cognitive Dysfunction Syndrom" },
  { title: "Colibacillosis" },
  { title: "Colica gastrica" },
  { title: "Coligranulomatosis" },
  { title: "Colitis acuta" },
  { title: "Colitis chronica" },
  { title: "Colitis ulcerosa" },
  { title: "Colitis granulomatosa" },
  { title: "Colitoxaemia" },
  { title: "Collapsus laryngis" },
  { title: "Collapsus tracheae" },
  { title: "Collie Eye Anomaly (CEA)" },
  { title: "Collie nose" },
  { title: "Coloboma" },
  { title: "Color dilution alopecia" },
  { title: "Coma" },
  { title: "Coma hepatis" },
  { title: "Coma hepatis puerperale" },
  { title: "Combustio" },
  { title: "Commotio cerebri" },
  { title: "Commotio medullae spinalis" },
  { title: "Compressio caudae equinae" },
  { title: "Compressio medullae spinalis" },
  { title: "Compressio v. cavae caudalis" },
  { title: "Compressio v. cavae cranialis" },
  { title: "Congelatio" },
  { title: "Conjunctiva idegentest" },
  { title: "Conjunctivitis" },
  { title: "Conjunctivitis acuta" },
  { title: "Conjunctivitis chronica" },
  { title: "Conjunctivitis purulenta" },
  { title: "Conjunctivitis serosa" },
  { title: "Conjunctivitis serosa allergica" },
  { title: "Conri-szindróma" },
  { title: "Conquassatio pedis" },
  { title: "Constipatio" },
  { title: "Contractura" },
  { title: "Contractura tendinis" },
  { title: "Contusio" },
  { title: "Contusio cerebri" },
  { title: "Contusio columnae vertebralis" },
  { title: "Contusio hepatis" },
  { title: "Contusio pulmonis" },
  { title: "Contusio renalis" },
  { title: "COPD" },
  { title: "Coprophagia" },
  { title: "Cor pulmonale" },
  { title: "Cornea degeneratio" },
  { title: "Cornea erosio" },
  { title: "Cornea homály" },
  { title: "Cornea lipidosis" },
  { title: "Cornea sérülés" },
  { title: "Cornea vascularisatio" },
  { title: "Cornu cutaneum" },
  { title: "Corpus alienum" },
  { title: "Corpus alienum auricularis" },
  { title: "Corpus alienum intestini" },
  { title: "Corpus alienum laryngis" },
  { title: "Corpus alienum linearis" },
  { title: "Corpus alienum nasi" },
  { title: "Corpus alienum oesophagi" },
  { title: "Corpus alienum oropharyngealis" },
  { title: "Corpus alienum pharyngis" },
  { title: "Corpus alienum trachei" },
  { title: "Corpus alienum ventriculi" },
  { title: "Coxa valga" },
  { title: "Coxa vara" },
  { title: "Coxarthrosis" },
  { title: "Coxitis" },
  { title: "Craniomandibularis osteopathia" },
  { title: "Cretinismus" },
  { title: "Critical Illness Related Corticosteroid Insuffiency (CIRCI)" },
  { title: "Crycopharyngealis achalasia" },
  { title: "Cryptococcosis" },
  { title: "Cryptorchismus" },
  { title: "Cryptosporidiosis" },
  { title: "Ctenocephalosis" },
  { title: "CTRA" },
  { title: "Cushing-reflex" },
  { title: "Cushing Syndroma" },
  { title: "Cushing Syndroma, atipikus" },
  { title: "Cushing Syndroma, centrális" },
  { title: "Cushing Syndroma, ectopias ACTH szekréció" },
  { title: "Cushing Syndroma, etetéshez kötődő" },
  { title: "Cushing Syndroma, iatrogén" },
  { title: "Cushing Syndroma, perifériás" },
  { title: "Cylindruria" },
  { title: "Cysta" },
  { title: "Cysta abdominis" },
  { title: "Cysta cutis" },
  { title: "Cysta hepatis" },
  { title: "Cysta interdigitalis" },
  { title: "Cysta ovarii" },
  { title: "Cysta pancreatis" },
  { title: "Cysta para-prostatica" },
  { title: "Cysta prostatae" },
  { title: "Cysta renalis" },
  { title: "Cysta salivalis" },
  { title: "Cysta salivalis pharingealis" },
  { title: "Cysta salivalis zygomaticus" },
  { title: "Cysta thyreoidea" },
  { title: "Cysta quadrigeminalis" },
  { title: "Cysticercosis" },
  { title: "Cystitis" },
  { title: "Cystitis chronica" },
  { title: "Cystitis haemorrhagica" },
  { title: "Cystitis necroticans" },
  { title: "Cystolithiasis" },
  { title: "Csonk pyometra" },
  { title: "Csuklás" },
  { title: "Dacryoadenitis" },
  { title: "Dacryocystitis" },
  { title: "Dacryops" },
  { title: "Dalmatian Bronzing Symdrome" },
  { title: "DEA negatív" },
  { title: "DEA pozitív" },
  { title: "Decpensatio cardiaca" },
  { title: "Decubitus" },
  { title: "Defluvium pilorum" },
  { title: "Deformált ízület" },
  { title: "Degeneratio corneae" },
  { title: "Dehydratio" },
  { title: "Delayed union" },
  { title: "Dementia" },
  { title: "Demodicosis" },
  { title: "Depressio" },
  { title: "Dermatitis" },
  { title: "Dermatitis calorica" },
  { title: "Dermatitis crustosa" },
  { title: "Dermatitis erythematosa" },
  { title: "Dermatitis exfoliativa" },
  { title: "Dermatitis interdigitalis" },
  { title: "Dermatitis madidans" },
  { title: "Dermatitis nodosa" },
  { title: "Dermatitis non purulenta" },
  { title: "Dermatitis perianalis" },
  { title: "Dermatitis purulenta" },
  { title: "Dermatitis pustulosa" },
  { title: "Dermatitis scrotalis" },
  { title: "Dermatitis thermica" },
  { title: "Dermatitis toxicosa" },
  { title: "Dermatitis traumatica" },
  { title: "Dermatitis ulcerosa" },
  { title: "Dermatitis vesiculosa" },
  { title: "Dermatofibroma" },
  { title: "Dermatomycosis" },
  { title: "Dermatomyositis" },
  { title: "Dermatomyositis familiaris" },
  { title: "Dermatopathia" },
  { title: "Dermatophytosis" },
  { title: "Dermatosis vegetans" },
  { title: "Dermoid sinus" },
  { title: "Dermoid-cysta" },
  { title: "Derzsy féle betegség" },
  { title: "Descementocele" },
  { title: "Desiccatio ingestae omasi" },
  { title: "Desmopathia inserativa" },
  { title: "D-hypervitaminosis" },
  { title: "D-hypovitaminosis" },
  { title: "Diabetes insipidus" },
  { title: "Diabetes insipidus, centrális" },
  { title: "Diabetes insipidus, nephrogen" },
  { title: "Diabetes mellitus" },
  { title: "Diabéteszes neuropathia" },
  { title: "Diarrhoea" },
  { title: "Diarrhoea ablactarum" },
  { title: "Diathesis exudativa avium" },
  { title: "Diathesis haemorrhagiae" },
  { title: "Dictyocaulosis" },
  { title: "Dikumarol toxicosis" },
  { title: "Dilatatio cordis" },
  { title: "Dilatatio et torsio ventriculi" },
  { title: "Dilatatio intestini" },
  { title: "Dilatatio oesophagi" },
  { title: "Dilatatio recti" },
  { title: "Dilatatio ruminis" },
  { title: "Dilatatio ruminis ab ingesta" },
  { title: "Dilatatio ventriculi" },
  { title: "Dilatatio ventriculi acuta" },
  { title: "Dilatatio vesicae urinariae" },
  { title: "Dioestrus" },
  { title: "Dió-toxicosis" },
  { title: "Dipylidiosis" },
  { title: "Dirofilariosis" },
  { title: "Dirty Face Symdrome" },
  { title: "Discitis" },
  { title: "Discoid Lupus Erythematosus" },
  { title: "Discopathia intervertebralis" },
  { title: "Discospondylitis" },
  { title: "Discus prolapsus" },
  { title: "Discushernia" },
  { title: "Dislocatio abomasi" },
  { title: "Dislocatio abomasi dextra" },
  { title: "Dislocatio abomasi sinistra" },
  { title: "Dislocatio intestini" },
  { title: "Dislocatio ventriculi" },
  { title: "Dislocatio vesicae urinariae" },
  { title: "Districhiasis" },
  { title: "Distorchia" },
  { title: "Destorsio" },
  { title: "Distractio cubiti" },
  { title: "Disszeminált intravazális coagulopátia" },
  { title: "Diverticulum intestini" },
  { title: "Diverticulum recti" },
  { title: "Diverticulum subarachnoidealis" },
  { title: "Diverticulum vesicae urinariae" },
  { title: "Dolores praesignales" },
  { title: "Ductal Plate Malformation (DPM)" },
  { title: "Ductus arteriosus Botalli persistens" },
  { title: "Duodenalis reflux" },
  { title: "Doudenocavalis sönt" },
  { title: "Duodenocolitis" },
  { title: "Duodenojejunitis" },
  { title: "Dysautonomia" },
  { title: "Dysbacteriosis" },
  { title: "Dysbiosis" },
  { title: "Dyscyclia" },
  { title: "Dysenteria balantidica" },
  { title: "Dysenteria suis" },
  { title: "Dysfunctio proventriculorum" },
  { title: "Dysmotilitás" },
  { title: "Dyspepsia" },
  { title: "Dyspepsia gastrica" },
  { title: "Dyspepsia hepatogenetica" },
  { title: "Dyspepsia pancreogenetica" },
  { title: "Dysphagia" },
  { title: "Dysplasia articulationis coxae" },
  { title: "Dysplasia articulationis cubiti" },
  { title: "Dyspnoe" },
  { title: "Dyssexualismus" },
  { title: "Dyssynergia vesicae urinariae" },
  { title: "Dystrophia hepatis" },
  { title: "Dystocia" },
  { title: "Dystrophia adiposogenitalis" },
  { title: "Dystrophia corneae" },
  { title: "Dystrophia hepatis" },
  { title: "Dystrophia musculi" },
  { title: "Dysuria" },
  { title: "Early embrionic death (EED)" },
  { title: "Echinococcosis" },
  { title: "Echinococcosis (alveolaris)" },
  { title: "Eclampsia puerperalis" },
  { title: "Ecthyma contagiosa" },
  { title: "Ectopia cordis" },
  { title: "Ectopia ureteri" },
  { title: "Ectopicus pillaszőr" },
  { title: "Ectropium" },
  { title: "EDTA- dependens thrombocytopenia" },
  { title: "Égési sérülés" },
  { title: "Ehler-Danlos Syndroma" },
  { title: "Ehrlichiosis" },
  { title: "Einschuss phlegmone" },
  { title: "Ekzema" },
  { title: "Ekzema crustosum" },
  { title: "Ekzema papulosum" },
  { title: "Ekzema squamosum" },
  { title: "Ekzema vesiculosum" },
  { title: "Éleség allergia" },
  { title: "Eleség intolerancia" },
  { title: "Elongált szájpadlás" },
  { title: "Embolia" },
  { title: "Embolia pulmonum" },
  { title: "Emphysema" },
  { title: "Emphysema alveolare pulmonum" },
  { title: "Emphysema, subcutan" },
  { title: "Empyema" },
  { title: "Encephalitis" },
  { title: "Encephalitozoonosis" },
  { title: "Encephalokele" },
  { title: "Encephalomalacia avium" },
  { title: "Encephalomyelitis infectiosa avium" },
  { title: "Encephalopathia" },
  { title: "Endocarditis" },
  { title: "Endocarditis fibroplastica" },
  { title: "Endocarditis simplex" },
  { title: "Endocarditis thromboulcerosa" },
  { title: "Endocarditis valvularum" },
  { title: "Endocarditis verrucosa" },
  { title: "Endocardosis" },
  { title: "Endocrinodermatosis" },
  { title: "Endometrialis cysta" },
  { title: "Endometritis acuta" },
  { title: "Endometritis chronica" },
  { title: "Endotoxaemia" },
  { title: "Endometritis subacuta" },
  { title: "Enophtalmus" },
  { title: "Enteralgia catarrhalis" },
  { title: "Enteritis" },
  { title: "Enteritis acuta" },
  { title: "Enteritis catarrhalis" },
  { title: "Enteritis chronica" },
  { title: "Enteritis coronovirosa" },
  { title: "Enteritis haemorrhagica" },
  { title: "Enteritis intestini tenuis" },
  { title: "Enteritis parvovirosa" },
  { title: "Enteritis proximalis" },
  { title: "Enteritis virosa" },
  { title: "Entropium" },
  { title: "Eosinophil granuloma/plaque" },
  { title: "Eosinophil sejtes bronchitis" },
  { title: "Eosinophil sejtes dermatitis oedemával" },
  { title: "Eosinophil sejtes enteritis" },
  { title: "Eosinophil sejtes furunculosis" },
  { title: "Eosinophil sejtes pneumonitis" },
  { title: "Eosinophil sejtes stomatitis" },
  { title: "Eosinophilia" },
  { title: "Epehólyag mucocele" },
  { title: "Epehólyag sludge" },
  { title: "Epeperitonitis" },
  { title: "Eperythrozoonosis suis" },
  { title: "Epesavas hasmenés" },
  { title: "Epeúti perforáció" },
  { title: "Epeúti repedés" },
  { title: "Epidermatomycosis" },
  { title: "Epididymitis" },
  { title: "Epilepsia" },
  { title: "Epilepsia, idiopathicus" },
  { title: "Epilepsia, secundaer" },
  { title: "Epiphora" },
  { title: "Epiphyseolysis" },
  { title: "Epiphysitis" },
  { title: "Epistaxis" },
  { title: "Epithelioma" },
  { title: "Epithelioma, kornifikáló" },
  { title: "Epitheliotrop lymphoma" },
  { title: "Epulis" },
  { title: "Erosio" },
  { title: "Eructatio" },
  { title: "Erysipelas suis" },
  { title: "Erythema" },
  { title: "Erythema migrans" },
  { title: "Erythema multiforme" },
  { title: "Erythrodermatitis" },
  { title: "Etilénglicol toxicosis" },
  { title: "Euthyroid sick syndrome" },
  { title: "Eventratio diaphragmae" },
  { title: "Evertált laryngealis sacculus" },
  { title: "Excitatio" },
  { title: "Excoriatio" },
  { title: "Exitus lethalis" },
  { title: "Exokrin pancreas insufficientia" },
  { title: "Exostosis" },
  { title: "Exsiccosis" },
  { title: "Exsuperantia dentis" },
  { title: "Extrahepatikus epeúti elzáródás" },
  { title: "Extrasystolia" },
  { title: "Extrasystolia auricularis" },
  { title: "Extrasystolia bigeminia" },
  { title: "Extrasystolia proxima" },
  { title: "Extrasystolia tarda" },
  { title: "Extrasystolia trigeminia" },
  { title: "Extrasystolia ventricularis" },
  { title: "Fagopyrismus" },
  { title: "Faggyúciszta" },
  { title: "Fallot-tetralogia" },
  { title: "Familiáris B12-vitamin hiány" },
  { title: "Familiáris macrothrombocytosis" },
  { title: "Fanconi syndroma" },
  { title: "Fantom fájdalom" },
  { title: "Farkasköröm" },
  { title: "Farokbénulás" },
  { title: "Farok deformitás" },
  { title: "Farokmirigy metaplasia" },
  { title: "Fasciolosis" },
  { title: "Fat mobilisation syndrom" },
  { title: "Fatigatio" },
  { title: "Favus" },
  { title: "FCE" },
  { title: "Febris catarrhalis et nervosa" },
  { title: "Febris catarrhalis infectiosa canum" },
  { title: "Fehérjevesztéses enteropathia" },
  { title: "Fehérjevesztéses enteropathia (Crypta betegség)" },
  { title: "Fehérjevesztéses enteropathia (IBD alapú)" },
  { title: "Fehérjevesztéses enteropathia (Lymphangiectasia)" },
  { title: "Fehérjevesztéses nephropathia" },
  { title: "Fejfájás" },
  { title: "Fejlődési rendellenesség" },
  { title: "Fejoldaltartás" },
  { title: "Fejrühösség" },
  { title: "Feline acne" },
  { title: "Feline asztma" },
  { title: "Feline Hyperaesthesia Syndrome" },
  { title: "Feline Idiopathic Cystitis (FIC)" },
  { title: "Feline idiopathic ulceratív dermatitis" },
  { title: "Feline Immundeficiency Virus (FIV)" },
  { title: "Feline Infectious Peritonitis (FIP)" },
  { title: "Feline Injection Site Sarcoma" },
  { title: "Feline Leukaemia Virus (FeLV)" },
  { title: "Feline Lower Urinary Tract Disease (FLUTD)" },
  { title: "Feline Odontoclastic Resorptive Lesion (FORL)" },
  { title: "Feline oropharyngeal inflammation" },
  { title: "Feline paraneoplastic alopecia" },
  { title: "Feline psychogenic alopecia" },
  { title: "Felnőtt kutyák STH hiányos szindrómája" },
  { title: "Fibrillatio atrialis" },
  { title: "Fibrillatio ventricularis" },
  { title: "Fibroepithelialis plakk (s. acrochordonous)" },
  { title: "Fibroma" },
  { title: "Fibrosarcoma" },
  { title: "Fibrosis hepatis" },
  { title: "Fibrosis pulmonum" },
  { title: "Fibrosis renum" },
  { title: "Fissura palati" },
  { title: "Fistula" },
  { title: "Fistula anocutanea" },
  { title: "Fistula oesophagotrachealis" },
  { title: "Fistula para-auricularis" },
  { title: "Fistula perianalis" },
  { title: "Fistula perinealis" },
  { title: "Fistula post operationem" },
  { title: "Fistula retrocutanea" },
  { title: "Fistula rectovaginalis" },
  { title: "Fistula uberis" },
  { title: "Fistula urethrorectalis" },
  { title: "Fistula urethrorectocutanea" },
  { title: "Flatulentia" },
  { title: "Flexio intestini" },
  { title: "Flexor deformitás - carpus" },
  { title: "Flotulatio" },
  { title: "Fogelhalás" },
  { title: "Foghiány" },
  { title: "Fogínysorvadás" },
  { title: "Fogkopás" },
  { title: "Fogkövesség" },
  { title: "Foglazulás" },
  { title: "Fogtorlódás" },
  { title: "Fogyás" },
  { title: "Foggyökér tályog" },
  { title: "Foggyökér gyulladás" },
  { title: "Fold dermatitis" },
  { title: "Follicularis dysplasia" },
  { title: "Follicularis dysplasia, fekete szőr" },
  { title: "Folliculitis mycotica" },
  { title: "Folliculus cysta" },
  { title: "Folliculus dysplasia" },
  { title: "Fonalgennyesedés" },
  { title: "Forgó betegség" },
  { title: "Foszfofruktokináz hiány" },
  { title: "Fractura" },
  { title: "Fractura aperta" },
  { title: "Fractura cranii" },
  { title: "Fractura dentis" },
  { title: "Fractura olecrani" },
  { title: "Fractura ossis carpi accessorii" },
  { title: "Fractura ossis caudae" },
  { title: "Fractura ossis costae" },
  { title: "Fractura ossis femoris" },
  { title: "Fractura ossis fibulae" },
  { title: "Fractura ossis humeri" },
  { title: "Fractura ossis mandibulae" },
  { title: "Fractura ossis maxillae" },
  { title: "Fractura ossis nasi" },
  { title: "Fractura ossis patellae" },
  { title: "Fractura ossis pelvis" },
  { title: "Fractura ossis phalangis prima" },
  { title: "Fractura ossis phalangis secundae" },
  { title: "Fractura ossis phalangis tertii" },
  { title: "Fractura ossis radii" },
  { title: "Fractura ossis radii et ulnae" },
  { title: "Fractura ossis scapulae" },
  { title: "Fractura ossis sesamoidei distale" },
  { title: "Fractura ossis sesamoidei proximale" },
  { title: "Fractura ossis tibiae" },
  { title: "Fractura ossium carpi" },
  { title: "Fractura ossium metacarpalium" },
  { title: "Fractura ossium metatarsalium" },
  { title: "Fractura ossium neurocranii" },
  { title: "Fractura ossium tarsi" },
  { title: "Fractura pathologica" },
  { title: "Fractura proc. anconei" },
  { title: "Fractura SH 1" },
  { title: "Fractura SH 2" },
  { title: "Fractura SH 3" },
  { title: "Fractura SH 4" },
  { title: "Fractura SH 5" },
  { title: "Fractura tuberis calcenei" },
  { title: "Fractura vertabrae cervicalis" },
  { title: "Fractura vertebrae caudalis" },
  { title: "Fractura vertebrae lumbalis" },
  { title: "Fractura vertebrae scar." },
  { title: "Fractura vertebrae thoracalis" },
  { title: "Fragmentált processus coronoideus" },
  { title: "Francia vedlés" },
  { title: "FTMB-szindróma" },
  { title: "Funiculitis" },
  { title: "Funkcionális adrenális tumor" },
  { title: "Funkcionális dyspepsia" },
  { title: "Furunculosis" },
  { title: "Fusariotoxicosis" },
  { title: "Fülkagyló marginális dermatosis" },
  { title: "Fülrühösség" },
  { title: "Füstmérgezés" },
  { title: "Galactophoritis" },
  { title: "Galactorrhea" },
  { title: "Gangliocytoma" },
  { title: "Ganglioneuroma" },
  { title: "Gangraena emphysematosa" },
  { title: "Gastrophilosis" },
  { title: "Gastrinoma" },
  { title: "Gastritis" },
  { title: "Gastritis acuta" },
  { title: "Gastritis alimentaria" },
  { title: "Gastritis catarrhalis" },
  { title: "Gastritis chronica" },
  { title: "Gastritis eosinophilica" },
  { title: "Gastritis erosiva" },
  { title: "Gastro-azygos sönt" },
  { title: "Gastrocavalis shunt" },
  { title: "Gastroduodenocavalis shunt" },
  { title: "Gastroenteritis" },
  { title: "Gastroenteritis eosinophilica" },
  { title: "Gastroenteritis haemorrhagica" },
  { title: "Gastrooesophagealis reflux betegség" },
  { title: "Gastroparesis" },
  { title: "Gége sérülés" },
  { title: "Geosedimentatio intestini coli" },
  { title: "Geosedimentatio ruminis" },
  { title: "Gericvelő infarktus" },
  { title: "Giardiosis" },
  { title: "Gierke-féle kór" },
  { title: "Gingivitis" },
  { title: "Glaucoma" },
  { title: "Glikogén tárolási betegség" },
  { title: "Glomerulonephritis" },
  { title: "Glossitis" },
  { title: "Glossitis allergica serosa" },
  { title: "Glossitis necroticans et ulcerosa" },
  { title: "Glossitis traumatica" },
  { title: "Glottisoedema" },
  { title: "Glucagonoma" },
  { title: "Glutén-szenzitív enteropathia" },
  { title: "Glükokortikoid hepatopathia" },
  { title: "Glycosuria postprandialis" },
  { title: "Glycosuria renalis" },
  { title: "GM1 gangliosidosis" },
  { title: "Gonarthrosis" },
  { title: "Gonitis" },
  { title: "Granuloma/Pyogranuloma Syndrome, Sterile (SGPS)" },
  { title: "Granulomás meningoencephalitis" },
  { title: "Graviditas" },
  { title: "Graviditas extrauterina" },
  { title: "Greyhound corn" },
  { title: "Gumboró i betegség" },
  { title: "Gyengeség" },
  { title: "Gynaecomastia" },
  { title: "Gyógyszer mellékhatás" },
  { title: "Gyógyult" },
  { title: "Gyomor-bél motilitási zavar" },
  { title: "Gyomormegterhelés" },
  { title: "Gyomorrepedés" },
  { title: "Haemangioma" },
  { title: "Haemangiopericytoma" },
  { title: "Haemangiosarcoma" },
  { title: "Haemarthros" },
  { title: "Haematemezis" },
  { title: "Haematochezia" },
  { title: "Haematoma" },
  { title: "Haematoma retrobulbaris" },
  { title: "Haematoma retroperitonealis" },
  { title: "Haematoma vesicae urinariae" },
  { title: "Haematometra" },
  { title: "Haematuria" },
  { title: "Haemoconcentratio" },
  { title: "Haemodialysis" },
  { title: "Haemoglobinaemia" },
  { title: "Haemoglobinuria" },
  { title: "Haemolysis" },
  { title: "Haemolitikus-uraemiás szindróma" },
  { title: "Haemomelasma ilei" },
  { title: "Haemopericardium" },
  { title: "Haemoperitoneum" },
  { title: "Haemophagocytás syndróma" },
  { title: "Haemophilia" },
  { title: "Haemophtalmus" },
  { title: "Haemoplasmosis" },
  { title: "Haemoptoe" },
  { title: "Haemorraghia" },
  { title: "Haemorrhagia abdominis" },
  { title: "Haemorrhagia cerebri" },
  { title: "Haemorrhagia intestinalis" },
  { title: "Haemorrhagia meningis" },
  { title: "Haemorrhagia pulmonum" },
  { title: "Haemorrhagia retroperitonealis" },
  { title: "Haemorrhagia thoracis" },
  { title: "Haemorrhagia ventriculi" },
  { title: "Haemorrhagiás gastroenteritis" },
  { title: "Haemothorax" },
  { title: "Halláskárosodás" },
  { title: "Harder mirigy luxatio" },
  { title: "Hasi folyadékgyülem" },
  { title: "HCMP" },
  { title: "Heg" },
  { title: "Hegkeloid" },
  { title: "Heinz-body anaemia" },
  { title: "Helicobacter gastritis" },
  { title: "Haemangiopericytoma" },
  { title: "Hemiparesis" },
  { title: "Hemiplegia laryngis" },
  { title: "Hemivertebra" },
  { title: "Hepatitis" },
  { title: "Hepatitis apostemosa" },
  { title: "Hepatitis chronica" },
  { title: "Hepatitis contagiosa canis" },
  { title: "Hepatitis purulenta" },
  { title: "Hepatitis suppurativa" },
  { title: "Hepatitis lobular dissecting" },
  { title: "Hepatitis réz okozta" },
  { title: "Hepatocerebralis szindróma" },
  { title: "Hepatocutan szindróma" },
  { title: "Hepatoencephalopathia" },
  { title: "Hepatomegalia" },
  { title: "Hepatopathia" },
  { title: "Hepatopathia glycocorticoida" },
  { title: "Hepatopathia nodularis" },
  { title: "Hepatopathia reaktív" },
  { title: "Hepatopathia vacuolás" },
  { title: "Hepatosis" },
  { title: "Hepatozoonosis" },
  { title: "Hepcidin study" },
  { title: "Hermaphroditismus" },
  { title: "Hermaphroditismus ovarialis" },
  { title: "Hermaphroditismus versus" },
  { title: "Hernia abdomin. cum ut gravido" },
  { title: "Hernia abdominalis" },
  { title: "Hernia abdominalis traumatica" },
  { title: "Hernia diaphragmatica" },
  { title: "Hernia externa" },
  { title: "Hernia foram. epipl." },
  { title: "Hernia incarcerata" },
  { title: "Hernia incisionalis" },
  { title: "Hernia inguina" },
  { title: "Hernia inguinalis" },
  { title: "Hernia inguinalis cumut. grav." },
  { title: "Hernia interna" },
  { title: "Hernia mesenterialis" },
  { title: "Hernia perinealis" },
  { title: "Hernia peritoneo-pericardiale" },
  { title: "Hernia scrotalis" },
  { title: "Hernia supraumbilicalis" },
  { title: "Hernia umbilicalis" },
  { title: "Heterochromia irides" },
  { title: "Heterochromia iridis" },
  { title: "Hiatus hernia" },
  { title: "Hiatus hernia rolling" },
  { title: "Hiatus hernia sliding" },
  { title: "Hidegagglutinációs betegség" },
  { title: "Hiperaktív tojásrakás, kotlás" },
  { title: "Hiperozmózisos szindróma" },
  { title: "Hippomanes" },
  { title: "Histiocytás sarcoma" },
  { title: "Histiocytás ulcerativ colitis" },
  { title: "Histiocytoma" },
  { title: "Histiocytosis Progressive" },
  { title: "Histiocytosis Reactive, Cutaneous" },
  { title: "Histiocytosis Reactive, Systemic" },
  { title: "Histoplasmosis" },
  { title: "Hordeoleum" },
  { title: "Horner syndroma" },
  { title: "Horzsolás" },
  { title: "Hot Spot" },
  { title: "Hőguta" },
  { title: "Húgyphlegmone" },
  { title: "Humoral hypercalcaemia of malignancy" },
  { title: "Hyalinosis" },
  { title: "Hydramnion" },
  { title: "Hydroallantois" },
  { title: "Hydrocephalus" },
  { title: "Hydrocephalus congenitalis" },
  { title: "Hydrokele" },
  { title: "Hydrometra" },
  { title: "Hydromyelia" },
  { title: "Hydronephrosis" },
  { title: "Hydropericardium" },
  { title: "Hydrops peritonei" },
  { title: "Hydrops sinus maxillaris" },
  { title: "Hydrothorax" },
  { title: "Hydroureter" },
  { title: "Hygroma bursa olecrani" },
  { title: "Hyostrongylosis" },
  { title: "Hyperaesthesia cutis" },
  { title: "Hyperaldosteronismus" },
  { title: "Hyperaldosteronismus, primer" },
  { title: "Hyperaldosteronismus, secunder" },
  { title: "Hyperalgesia" },
  { title: "Hyperammonaemia" },
  { title: "Hyperandrogenismus" },
  { title: "Hyperbilirubinaemia" },
  { title: "Hypercalcaemia" },
  { title: "Hypercalcaemia, idiopathikus" },
  { title: "Hypercholesterolaemia" },
  { title: "Hypereosinophiliás szindróma" },
  { title: "Hyperextensiós carpus sérülés" },
  { title: "Hyperglobulinaemia" },
  { title: "Hyperglykaemia" },
  { title: "Hyperhidrosis" },
  { title: "Hypericosis" },
  { title: "Hyperkalaemia" },
  { title: "Hyperkeratosis" },
  { title: "Hyperkeratosis nasalis" },
  { title: "Hyperkeratosis nasodigitalis idiopathicus" },
  { title: "Hyperkeratosis, talppárna" },
  { title: "Hyperlipaemia" },
  { title: "Hyperlipaemia familiaris" },
  { title: "Hypermagnesiaemia" },
  { title: "Hypernatraemia" },
  { title: "Hyperoestrogenismus" },
  { title: "Hyperozmolaris szindróma" },
  { title: "Hyperparathyroidismus" },
  { title: "Hyperparathyroidismus secundaer" },
  { title: "Hyperplasia fibroadenomatosa mammae" },
  { title: "Hyperplasia gingivalis" },
  { title: "Hyperplasia glan. cystica end" },
  { title: "Hyperplasia pancreatica" },
  { title: "Hyperplasia pulposa lienis" },
  { title: "Hyperproteinaemia" },
  { title: "Hypersalivatio" },
  { title: "Hypersomatotropismus" },
  { title: "Hypersplenismus" },
  { title: "Hypersthenuria" },
  { title: "Hypertensio pulmonum" },
  { title: "Hypertenzív encephalopathia" },
  { title: "Hypertestosteronismus" },
  { title: "Hyperthermia" },
  { title: "Hyperthyroid sick syndrome" },
  { title: "Hyperthyroidismus" },
  { title: "Hypertonia" },
  { title: "Hypertriglyceridaemia" },
  { title: "Hypertrophia clitori" },
  { title: "Hypertrophia cordis" },
  { title: "Hypertrophia prostatae" },
  { title: "Hyperviszkozitás szindróma" },
  { title: "Hyphema" },
  { title: "Hypoadrenocorticismus" },
  { title: "Hypoadrenocorticismus eukalaemiás/eunatraemiás" },
  { title: "Hypoadrenocorticismus hyperkalaemiás/hyponatraemiás" },
  { title: "Hypoadrenocorticismus iatrogen" },
  { title: "Hypoadrenocorticismus, kritikus" },
  { title: "Hypoalbuminaemia" },
  { title: "Hypoaldosterinismus" },
  { title: "Hypoaldosterinismus, iatrogén" },
  { title: "Hypocalcaemia" },
  { title: "Hypocobalaminaemia" },
  { title: "Hypodermatosis" },
  { title: "Hypogalactia" },
  { title: "Hypoglykaemia" },
  { title: "Hypoglykaemia porcellorum" },
  { title: "Hypoglykaemia, paraneolastikus" },
  { title: "Hypogonadismus" },
  { title: "Hypokalaemia" },
  { title: "Hypoluteolismus" },
  { title: "Hypomagnesaemia" },
  { title: "Hyponatraemia" },
  { title: "Hypooestrogenismus" },
  { title: "Hypoparathyroidismus" },
  { title: "Hypophosphataemia" },
  { title: "Hypophysectomia" },
  { title: "Hypophysis macroadenoma" },
  { title: "Hypophysis microadenoma" },
  { title: "Hypophysis tumor" },
  { title: "Hypopigmentatio" },
  { title: "Hypopigmentatio postinflammatory" },
  { title: "Hypopion" },
  { title: "Hypopituitarismus" },
  { title: "Hypoplasia cerebellaris" },
  { title: "Hypoplasia ovarii" },
  { title: "Hypoplasia tracheae" },
  { title: "Hypoplasia venae portae" },
  { title: "Hypoproteinaemia" },
  { title: "Hyposomatotropismus" },
  { title: "Hypospadiasis" },
  { title: "Hyposplenismus" },
  { title: "Hypostenuria" },
  { title: "Hypothermia" },
  { title: "Hypothyreosis" },
  { title: "Hypothyreosis, centrális" },
  { title: "Hypothyreosis, congenitális" },
  { title: "Hypothyreosis, iatrogen" },
  { title: "Hypothyreosis, primer" },
  { title: "Hypothyreosis, secundaer" },
  { title: "Hypotonia" },
  { title: "Hypotrichosis areata" },
  { title: "Hypotrichosis congenita" },
  { title: "Hypotrichosis cystica" },
  { title: "Hypovolaemia" },
  { title: "Hypoxaemia" },
  { title: "Hypoxia" },
  { title: "IBD" },
  { title: "Ictyophonosis" },
  { title: "Ictyophthiriasis" },
  { title: "Icterus" },
  { title: "Icterus haemolyticus" },
  { title: "Icterus hepaticus" },
  { title: "Icterus mechanicus" },
  { title: "Idiopathiás cystitis, steril" },
  { title: "Idiopathicus pulmonaris fibrosis" },
  { title: "Idiopathicus steril pyogranuloma" },
  { title: "Idiopatikus vestibularis szindróma" },
  { title: "Idiopathicus intestinalis dysbiosis" },
  { title: "Idiopathicus renalis haematuria" },
  { title: "Időskori cognitiv dysfunktio" },
  { title: "IgA hiány" },
  { title: "Ileus" },
  { title: "Ileus paralytica" },
  { title: "Ileus spastica" },
  { title: "Immundeficiencia primer" },
  { title: "Immundeficiencia szekunder" },
  { title: "Immunmediált neutropenia" },
  { title: "Immunmediált thrombocytopenia" },
  { title: "Impetigo" },
  { title: "Implantátum elégtelenség" },
  { title: "Inanitio" },
  { title: "Incidentaloma" },
  { title: "Incontinentia faeci" },
  { title: "Incontinentia urinae" },
  { title: "Indigestio ingestae ruminis" },
  { title: "Indolens fekély (indolent ulcer)" },
  { title: "Inertia uteri" },
  { title: "Infarceratio intestini" },
  { title: "Infarctus" },
  { title: "Infarctus renis" },
  { title: "Infectio bactrialis" },
  { title: "Infectio puerperalis" },
  { title: "Infectio virosa" },
  { title: "Infertilitas" },
  { title: "Infiltratív lipoma" },
  { title: "Inflammatio" },
  { title: "Inflammatio sacci aerophori" },
  { title: "Inflammatio sinus frontalis" },
  { title: "Inflammatory Bowel Disease (IBD)" },
  { title: "Influenza suis" },
  { title: "Infractio" },
  { title: "Ingluvitis" },
  { title: "Injekció indukált lágyszöveti sarcoma - IISTS" },
  { title: "Inkomplett törésgyógyulás" },
  { title: "Insertiós tendopathia" },
  { title: "Insolatio" },
  { title: "Insufficientia cordis" },
  { title: "Insufficientia hepatis" },
  { title: "Insufficientia renalis acuta" },
  { title: "Insufficientia renalis chronica" },
  { title: "Insufficientia suturae" },
  { title: "Insufficientia valvulae aortae" },
  { title: "Insufficientia valvulae bicuspidalis" },
  { title: "Insufficientia valvulae tricuspidalis" },
  { title: "Insulinoma" },
  { title: "Intermissio cordis" },
  { title: "Intertrigo" },
  { title: "Intoxicatio puerperalis" },
  { title: "Intracraniális nyomásfokozódás" },
  { title: "Intrahepatikus PSS" },
  { title: "Intravasalis haemolysis" },
  { title: "Invaginatio" },
  { title: "Invaginatio gastro-oesophagealis" },
  { title: "Invaginatio intestini" },
  { title: "Invaginatio intestini caeci" },
  { title: "Inversio caeci" },
  { title: "Involutio uteri" },
  { title: "Inzulin rezisztencia" },
  { title: "Ínysorvadás" },
  { title: "Iridocyclitis" },
  { title: "Iris atrophia" },
  { title: "Irritábilis colon szindróma" },
  { title: "Ischaemia" },
  { title: "Ischaemiás dermatopathia, krónikus" },
  { title: "Ismeretlen oktanú láz" },
  { title: "Isosthenuria" },
  { title: "Juvenilis cellulitis" },
  { title: "Juvenilis vaginitis" },
  { title: "Kacsa hepatitis" },
  { title: "Kacsapestis" },
  { title: "Kamrai tachycardia" },
  { title: "Karomágy gyulladás" },
  { title: "Karombenövés" },
  { title: "Kasztrációreszponzív dermatosis" },
  { title: "Kavicshiány betegség" },
  { title: "Kefalgia" },
  { title: "Kék dobermann betegség" },
  { title: "Kennel cough" },
  { title: "Keratitis" },
  { title: "Keratitis superf. chronica" },
  { title: "Keratoconjunctivitis sicca" },
  { title: "Keratomycosis" },
  { title: "Keratouveitis" },
  { title: "Ketoacidosis" },
  { title: "Ketoacidosis euglykaemiás" },
  { title: "Ketonaemia" },
  { title: "Ketonuria" },
  { title: "Ketosis vaccae" },
  { title: "Kognitív Diszfunkció Szindróma" },
  { title: "Koleszterin granuloma" },
  { title: "Kompartment szindróma" },
  { title: "Konglobatum" },
  { title: "Kontakt allergia" },
  { title: "Kontroll" },
  { title: "Konyhasó mérgezés" },
  { title: "Koponyatrauma" },
  { title: "Könnycsatorna elzáródás" },
  { title: "Könnymirigy gyulladás" },
  { title: "Könnymirigy tályog" },
  { title: "Könnytermelési zavar, kvalitatív" },
  { title: "Körömsérülés" },
  { title: "Krónikus enteropathia" },
  { title: "Krónikus enteropathia (antibiotikum reszponzív)" },
  { title: "Krónikus enteropathia (dysbiosis kezelésre reagáló)" },
  { title: "Krónikus enteropathia (eleség reszponzív)" },
  { title: "Krónikus enteropathia (immunoszupresszáns reszponzív)" },
  { title: "Krónikus enteropathia (nem reszponzív)" },
  { title: "Krónikus lymphocytás leukaemia" },
  { title: "Krónikus vesebetegség (Chronic Kidney Disease)" },
  { title: "Kullancs" },
  { title: "Kullancscsípés" },
  { title: "Kullancs encephalitis" },
  { title: "Kyphosis" },
  { title: "Labium leporinum" },
  { title: "Laceratio cervix" },
  { title: "Lactacidaemia" },
  { title: "Lactacidosis" },
  { title: "Lactatio pseudograviditatis" },
  { title: "Lágyszájpadlás hypoplasia" },
  { title: "Lágyszájpadlás elongatio" },
  { title: "Larva migrans" },
  { title: "Laryngeal \"webbing\"" },
  { title: "Laryngitis" },
  { title: "Laryngotracheitis" },
  { title: "Lateroflexio capiti" },
  { title: "Legg-Calve-Perthes kór" },
  { title: "Légzőizmok bénulása" },
  { title: "Légzsák repedés" },
  { title: "Légycsípés" },
  { title: "Légynyűvesség" },
  { title: "Leiomyoma" },
  { title: "Leiomyosarcoma" },
  { title: "Leishmaniosis" },
  { title: "Lencse luxatio" },
  { title: "Lencse sclerosis" },
  { title: "Lencse subluxatio" },
  { title: "Lepra" },
  { title: "Leprosy, feline" },
  { title: "Leptomeningitis" },
  { title: "Leptospirosis" },
  { title: "Lépveseszalag okozta lefűződés" },
  { title: "Leukaemia" },
  { title: "Leukemoid reakció" },
  { title: "Leukocytosis" },
  { title: "Leukoderma" },
  { title: "Leukoencephalitis" },
  { title: "Leukopenia" },
  { title: "Leukosis" },
  { title: "Leukotrichia" },
  { title: "Leydig sejtes heretumor" },
  { title: "Lick granuloma" },
  { title: "Lienitis traumat." },
  { title: "Lig. annulare constr." },
  { title: "Lipaemia" },
  { title: "Lipidosis hepatis" },
  { title: "Lipoma" },
  { title: "Lipomatosis cordis" },
  { title: "Lipomatosis pancreatica" },
  { title: "Liposarcoma" },
  { title: "Lissencephalus" },
  { title: "Listeriosis" },
  { title: "Lobular dissecting hepatitis" },
  { title: "Lordosis" },
  { title: "Louping ill" },
  { title: "Lupinosis" },
  { title: "Lupus erythematosus" },
  { title: "Lupus erythematosus, exfoliativ cutan" },
  { title: "Lupus erythematosus, szisztémás" },
  { title: "Luteoma" },
  { title: "Lux. art. genum" },
  { title: "Luxatio aperta" },
  { title: "Luxatio art. intertarsale" },
  { title: "Luxatio art. carpo-metacarpale" },
  { title: "Luxatio art. humeri" },
  { title: "Luxatio art. interdigitale" },
  { title: "Luxatio art. mandibulae" },
  { title: "Luxatio art. tarsi aperta" },
  { title: "Luxatio art. tarsometatarsale" },
  { title: "Luxatio articulationis anter." },
  { title: "Luxatio articulationis carpi" },
  { title: "Luxatio articulationis coxae" },
  { title: "Luxatio articulationis cubiti" },
  { title: "Luxatio articulationis tarsi" },
  { title: "Luxatio articulationis temp." },
  { title: "Luxatio patellae" },
  { title: "Luxatio sacrococcygealis" },
  { title: "Luxatio sacro-iliaca" },
  { title: "Luxatio vertebrae" },
  { title: "Lyme borreliosis" },
  { title: "Lymphadenitis" },
  { title: "Lymphadenitis (Szteroid reszponzív)" },
  { title: "Lymphadenopathia" },
  { title: "Lymphadenosis" },
  { title: "Lymphangiectasia" },
  { title: "Lymphangioma" },
  { title: "Lymphangiosarcoma" },
  { title: "Lymphangitis" },
  { title: "Lymphocytás cholangitis" },
  { title: "Lymphocytás thyroiditis" },
  { title: "Lymphocytás - plazmasejtes enteritis" },
  { title: "Lymphocytosis" },
  { title: "Lymphoedema" },
  { title: "Lymphoma" },
  { title: "Lymphoma alimentaris" },
  { title: "Lymphoma cutanea" },
  { title: "Lymphoma mediastinalis" },
  { title: "Lymphoma renum" },
  { title: "Lymphoma epitheliotrop" },
  { title: "Lymphoma hepatosplenicus" },
  { title: "Lymphopenia" },
  { title: "Lymphosarcoma" },
  { title: "Lymphosarcomatosis" },
  { title: "Lyssa" },
  { title: "M. gracilis kontraktura" },
  { title: "Maceratio foetus" },
  { title: "Macrobronchitis" },
  { title: "Macroglossia" },
  { title: "Macskakarmolási betegség" },
  { title: "Macskanátha" },
  { title: "Maedi" },
  { title: "Malabsorptio" },
  { title: "Malassezia dermatitis" },
  { title: "Maldigestio" },
  { title: "Malformatio fetus" },
  { title: "Malignus histiocytosis" },
  { title: "Malignus humoralis hypercalcaemia" },
  { title: "Malignus hyperthermia" },
  { title: "Malleus" },
  { title: "Malnutritio" },
  { title: "Malocclusio" },
  { title: "Mammary hyperplasia-fibroadenoma komplex" },
  { title: "Mannosidosis" },
  { title: "Marek féle betegség" },
  { title: "Mastitis" },
  { title: "Mastitis carcinomatosa" },
  { title: "Mastocytoma" },
  { title: "MDR-1 defektus" },
  { title: "Mediastinitis" },
  { title: "Medullary solute washout" },
  { title: "Megacolon" },
  { title: "Megalooesophagus" },
  { title: "Megaoesophagus" },
  { title: "Megnyillalás" },
  { title: "Meibommirigy cysta" },
  { title: "Meibommirigy gyulladás" },
  { title: "Melaena" },
  { title: "Melanocytoma" },
  { title: "Melanoma" },
  { title: "Melanoma malignum" },
  { title: "Mellkasfal sérülés" },
  { title: "Mellkasi folyadék gyülem" },
  { title: "Meningioma" },
  { title: "Meningitis" },
  { title: "Meningoarteritis, szteroid reszponzív (SRMA)" },
  { title: "Meningoencephalitis" },
  { title: "Meningoencephalitis of unknow origin" },
  { title: "Meningoencephalitis, nekrotizáló" },
  { title: "Meningoencephalomyelitis" },
  { title: "Meningomyelitis" },
  { title: "Meniscus sérülés" },
  { title: "Meristoma" },
  { title: "Mesenterio-lienalis sönt" },
  { title: "Mesothelioma" },
  { title: "Metaldehid toxicosis" },
  { title: "Metastasis" },
  { title: "Metastasis hepatis" },
  { title: "Metastasis ln. reg." },
  { title: "Metastasis lnn. mesent." },
  { title: "Metastasis pulmonis" },
  { title: "Meteorismus" },
  { title: "Meteorismus intest. coli. crassi" },
  { title: "Meteorismus intestini" },
  { title: "Meteorismus intestini caeci" },
  { title: "Meteorismus rumini" },
  { title: "Methemoglobinaemia" },
  { title: "Metoestrus" },
  { title: "Metritis" },
  { title: "Metritis septica puerperalis" },
  { title: "Metrorrhagia" },
  { title: "Microangiopathia diaetica" },
  { title: "Microangiopathiás haemolysis" },
  { title: "Microbial OverGrowth Syndrome (MOG)" },
  { title: "Microbronchitis" },
  { title: "Microcardia" },
  { title: "Microphthalmus" },
  { title: "Microsporiasis" },
  { title: "Microvascularis dysplasia" },
  { title: "Miliaris dermatitis" },
  { title: "Mola hydatidosa" },
  { title: "Moneieziozis" },
  { title: "Monocytosis" },
  { title: "Monoplegia" },
  { title: "Monstrum" },
  { title: "Monteggia törés" },
  { title: "Morbus Aukeszky" },
  { title: "Morbus Glasseri" },
  { title: "Morbus haemolyticus" },
  { title: "Morbus maculosus" },
  { title: "Morbus Möller Barlowi" },
  { title: "Morbus Newcastle" },
  { title: "MRSF" },
  { title: "MRSP" },
  { title: "Mucinosis, cutaneous" },
  { title: "Mucocele" },
  { title: "Mucometra" },
  { title: "Mucomycosis" },
  { title: "Multiple Organ Dysfunction Syndrome (MODS)" },
  { title: "Myasthenia gravis" },
  { title: "Mycobacterialis panniculitis" },
  { title: "Mycobacteriosis" },
  { title: "Mycoplasmosis" },
  { title: "Mycosis" },
  { title: "Mycotoxicosis" },
  { title: "Myelitis" },
  { title: "Myelofibrosis" },
  { title: "Myelolipoma lienis" },
  { title: "Myeloma multiplex" },
  { title: "Myeloma plasmocytica" },
  { title: "Myelopathia" },
  { title: "Myelosuppressio" },
  { title: "Myiasis" },
  { title: "Myocardiopathia" },
  { title: "Myocarditis" },
  { title: "Myocardosis" },
  { title: "Myoclonia congenita suis" },
  { title: "Myoclonus" },
  { title: "Myodegeneratio alimentaria" },
  { title: "Myofibrosis" },
  { title: "Myoglobinuria myodegeneretiva" },
  { title: "Myoglobinuria paralytica equorum" },
  { title: "Myoglobinuria paralytica equis" },
  { title: "Myopathia" },
  { title: "Myositis" },
  { title: "Myositis eosinophilica" },
  { title: "Myositis extraocularis" },
  { title: "Myositis inclusion body" },
  { title: "Myositis masticatory" },
  { title: "Myotonia, Morbus Cushing" },
  { title: "Myxoedema" },
  { title: "Myxoedema \"coma\"" },
  { title: "Myxoma" },
  { title: "Myxomatosis" },
  { title: "Myxosarcoma" },
  { title: "N. ischiadicus paresis" },
  { title: "N. radialis bénulás" },
  { title: "N. opticus atrophia" },
  { title: "N. opticus gyulladás" },
  { title: "N. opticus ordema" },
  { title: "N. radialis paresis" },
  { title: "Naevus pigmentosum" },
  { title: "Nasal solar dermatitis" },
  { title: "Nasopharyngealis polyp" },
  { title: "Necrobacillosis" },
  { title: "Necrobacillosis hepatis" },
  { title: "Necrosis" },
  { title: "Necrosis cerebrocortocalis" },
  { title: "Necrosis cutis" },
  { title: "Necrosis humida" },
  { title: "Necrosis multiplex hepatis" },
  { title: "Necrosis sicca" },
  { title: "Németjuhász pyoderma" },
  { title: "Nemi hormon allergia" },
  { title: "Neoarthros" },
  { title: "Neoplasma" },
  { title: "Neoplasma abdominis" },
  { title: "Neoplasma adrenalis" },
  { title: "Neoplasma ani" },
  { title: "Neoplasma auricularis" },
  { title: "Neoplasma caeci" },
  { title: "Neoplasma caudae" },
  { title: "Neoplasma cerebri" },
  { title: "Neoplasma cholecystae" },
  { title: "Neoplasma coli" },
  { title: "Neoplasma cordis" },
  { title: "Neoplasma cutis" },
  { title: "Neoplasma digiti" },
  { title: "Neoplasma epidydimidis" },
  { title: "Neoplasma glandulae hypophysealis" },
  { title: "Neoplasma glandulae paraanalis" },
  { title: "Neoplasma glandulae salivalis" },
  { title: "Neoplasma hepatis" },
  { title: "Neoplasma intestini" },
  { title: "Neoplasma laryngis" },
  { title: "Neoplasma lienis" },
  { title: "Neoplasma linguae" },
  { title: "Neoplasma mammae" },
  { title: "Neoplasma mandibulae" },
  { title: "Neoplasma maxillae" },
  { title: "Neoplasma mediastinalis" },
  { title: "Neoplasma n. ischiadici" },
  { title: "Neoplasma nasi" },
  { title: "Neoplasma oesophagii" },
  { title: "Neoplasma oris" },
  { title: "Neoplasma ovarii" },
  { title: "Neoplasma palati" },
  { title: "Neoplasma palpebrae" },
  { title: "Neoplasma pancreatis" },
  { title: "Neoplasma paraproctalis" },
  { title: "Neoplasma para-rectalis" },
  { title: "Neoplasma parathyroidea" },
  { title: "Neoplasma parotidis" },
  { title: "Neoplasma pelvis" },
  { title: "Neoplasma penis" },
  { title: "Neoplasma perianalis" },
  { title: "Neoplasma perinealis" },
  { title: "Neoplasma pharyngis" },
  { title: "Neoplasma prostatae" },
  { title: "Neoplasma pulmonum" },
  { title: "Neoplasma recti" },
  { title: "Neoplasma reg. column. vertebr." },
  { title: "Neoplasma renum" },
  { title: "Neoplasma retropharyngealis" },
  { title: "Neoplasma salivalis" },
  { title: "Neoplasma scrotalis" },
  { title: "Neoplasma testis" },
  { title: "Neoplasma testis cryptorch." },
  { title: "Neoplasma thoracis" },
  { title: "Neoplasma thyroidea" },
  { title: "Neoplasma tonsillae" },
  { title: "Neoplasma tracheae" },
  { title: "Neoplasma ureteri" },
  { title: "Neoplasma urethrae" },
  { title: "Neoplasma uteri" },
  { title: "Neoplasma vaginae" },
  { title: "Neoplasma ventriculi" },
  { title: "Neoplasma vertebrae" },
  { title: "Neoplasma vesicae urinae" },
  { title: "Neoplasma vulvae" },
  { title: "Neosporosis" },
  { title: "Nephritis chronica" },
  { title: "Nephritis interstitialis chronica" },
  { title: "Nephritis interstitialis acuta" },
  { title: "Nephritis non purulenta" },
  { title: "Nephritis purulenta" },
  { title: "Nephrogen diabetes insipidus" },
  { title: "Nephrolithiasis" },
  { title: "Nephropathia" },
  { title: "Nephrosis acuta" },
  { title: "Nephrosis haemoglobinaemiae" },
  { title: "Nephrosis szindróma" },
  { title: "Neurinoma" },
  { title: "Neuritis" },
  { title: "Neuritis, trigeminal, idiopathic" },
  { title: "Neuritis, trigeminus" },
  { title: "Neuronal ceroid lipofuscinosis" },
  { title: "Neuropathia" },
  { title: "Neutropenia" },
  { title: "Nocardiosis" },
  { title: "Nodularis hyperplasia" },
  { title: "Noma" },
  { title: "Non graviditas" },
  { title: "Non union" },
  { title: "Nonthyroidal Illness Syndrome (NTIS)" },
  { title: "Nyakcsatorna hiányos megnyílás" },
  { title: "Nycturia" },
  { title: "Nyelőcső dysmotilitás" },
  { title: "Nyelőcső motilitási zavar" },
  { title: "Nyelvsérülés" },
  { title: "Nyírás utáni alopecia" },
  { title: "Nyirrothadás" },
  { title: "Nymphania" },
  { title: "Nystagmus" },
  { title: "Nyugat nílusi láz" },
  { title: "Obesitas" },
  { title: "Obstipatio" },
  { title: "Obstipatio caeci" },
  { title: "Obstipatio colon ascendens" },
  { title: "Obstipatio colon descendens" },
  { title: "Obstipatio intestini caeci" },
  { title: "Obstipatio intestini coli" },
  { title: "Obstructio" },
  { title: "Obstructio intestini" },
  { title: "Obstructio intestini crassi" },
  { title: "Obstructio intestini tenuis" },
  { title: "Obstructio urethrae" },
  { title: "Obstructio uteri" },
  { title: "Obstructio uteri" },
  { title: "Obszesszív kompulzív zavar" },
  { title: "Obturatio abomasi" },
  { title: "Obturatio intestini coli tenui" },
  { title: "Obturatio oesophagii" },
  { title: "Obturatio omasi" },
  { title: "Obturatio pulmonale" },
  { title: "Obturatio ureteri" },
  { title: "Obturatio urethrae" },
  { title: "Obturatio ventriculi" },
  { title: "Oedema" },
  { title: "Oedema betegség" },
  { title: "Oedema cerebri" },
  { title: "Oedema laryngis" },
  { title: "Oedema malignum" },
  { title: "Oedema pulmonum" },
  { title: "Oesophagitis" },
  { title: "Oesophagospasmus" },
  { title: "Oesophagostomosis" },
  { title: "Oestrosis" },
  { title: "Oestrus" },
  { title: "Oleogranuloma" },
  { title: "Oligodendroglioma" },
  { title: "Oliguria" },
  { title: "Ólommérgezés" },
  { title: "Oltási reakció" },
  { title: "Omphalophlebitis" },
  { title: "Onychitis" },
  { title: "Onychodystrophia" },
  { title: "Onychodystrophia, lupoid" },
  { title: "Onychogryphosis" },
  { title: "Onycholysis" },
  { title: "Onychomadesis" },
  { title: "Onychomycosis" },
  { title: "Onychopathia" },
  { title: "Onychorrhexis" },
  { title: "Opisthotonus" },
  { title: "Orchitis" },
  { title: "Oronasalis fistula" },
  { title: "Orsóférgesség" },
  { title: "Osteoarthritis" },
  { title: "Osteochondrosis dissecans" },
  { title: "Osteodystrophia" },
  { title: "Osteodystrophia fibrosa" },
  { title: "Osteodystrophia hypertrophica" },
  { title: "Osteogenesis imperfecta" },
  { title: "Osteolysis" },
  { title: "Osteoma" },
  { title: "Osteomalacia" },
  { title: "Osteomyelitis" },
  { title: "Osteopathia" },
  { title: "Osteopathia methaphysica" },
  { title: "Osteopathia nutritiva" },
  { title: "Osteopenia" },
  { title: "Osteophyta" },
  { title: "Osteoporosis" },
  { title: "Osteoporosis juvenilis" },
  { title: "Osteoporosis renalis" },
  { title: "Osteosarcoma" },
  { title: "Ostitis" },
  { title: "Othaematoma" },
  { title: "Otitis externa" },
  { title: "Otitis externa ceruminosa" },
  { title: "Otitis externa chronica" },
  { title: "Otitis externa crustosa" },
  { title: "Otitis externa erythematosa" },
  { title: "Otitis externa mycotica" },
  { title: "Otitis externa parasitaria" },
  { title: "Otitis externa purulenta" },
  { title: "Otitis externa squamosa" },
  { title: "Otitis externa ulcerosa" },
  { title: "Otitis externa verrucosa" },
  { title: "Otitis interna" },
  { title: "Otitis media" },
  { title: "Ovarian imbalance" },
  { title: "Ovarian imbalance type I" },
  { title: "Ovarian imbalance type II" },
  { title: "Ovarian remnant syndroma" },
  { title: "Oxyuridosis" },
  { title: "Ösztogén toxicosis" },
  { title: "Pacheo betegség" },
  { title: "Pachymeningitis" },
  { title: "Palatoschisis" },
  { title: "Panaritium" },
  { title: "Páncéllágyulás" },
  { title: "Pancreas necrosis" },
  { title: "Pancreas abscessus" },
  { title: "Pancreasapoplexia" },
  { title: "Pancreatitis" },
  { title: "Pancreatitis (realtív)" },
  { title: "Pancreatitis acuta" },
  { title: "Pancreatitis chronica" },
  { title: "Pancreatitis haemorrhagica" },
  { title: "Pancreatitis oedematica" },
  { title: "Pancytopenia" },
  { title: "Panhypopituitarismus" },
  { title: "Panleukopenia" },
  { title: "Panmyelitis" },
  { title: "Panniculitis" },
  { title: "Panniculitis, steril nodular" },
  { title: "Pannus corneae" },
  { title: "Panophthalmitis" },
  { title: "Panostitis" },
  { title: "Papillaoedema" },
  { title: "Papilloma" },
  { title: "Papilloma (szemhéj)" },
  { title: "Para-auricularis abscessus" },
  { title: "Para-auricularis fistula" },
  { title: "Paraesthesia cutis" },
  { title: "Parakeratosis" },
  { title: "Parakeratosis, nasal, hereditary" },
  { title: "Paralysis caudae" },
  { title: "Paralysis laryngis" },
  { title: "Paralysis linguae" },
  { title: "Paralysis nervi facialis" },
  { title: "Paralysis nervi femoralis" },
  { title: "Paralysis nervi ischiadici" },
  { title: "Paralysis nervi peronei" },
  { title: "Paralysis nervi radialis" },
  { title: "Paralysis nervi tibialis" },
  { title: "Paralysis nervi trigemini" },
  { title: "Paralysis paralytica" },
  { title: "Paralysis partialis nervi radialis" },
  { title: "Paralysis penis" },
  { title: "Paralysis pharyngis" },
  { title: "Paralysis plexus brachialis" },
  { title: "Paralysis spastica" },
  { title: "Paralysis sphincterocaudalis" },
  { title: "Paralysis vesicae" },
  { title: "Paraneoplasztikus szindróma" },
  { title: "Paraparesis" },
  { title: "Paraphimosis" },
  { title: "Paraplegia" },
  { title: "Paraproctitis" },
  { title: "Paraproteinuria" },
  { title: "Parasitosis" },
  { title: "Parathyreoid adenoma" },
  { title: "Paratuberculosis" },
  { title: "Paratyphus" },
  { title: "Paresis (idegbénulás)" },
  { title: "Paresis laryngis" },
  { title: "Paresis puerperalis" },
  { title: "Parodontitis" },
  { title: "Parotitis" },
  { title: "Partus" },
  { title: "Partus gravis" },
  { title: "Partus immaturus" },
  { title: "Partus maturus" },
  { title: "Pasteurellosis" },
  { title: "Pattern baldness" },
  { title: "Pectus excavatum" },
  { title: "Pelger - Huet anomália" },
  { title: "Pelvic bladder" },
  { title: "Pemphigoid" },
  { title: "Pemphigus" },
  { title: "Pemphigus erythematosus" },
  { title: "Pemphigus foliaceus" },
  { title: "Pemphigus paraneoplasticus" },
  { title: "Pemphigus vegetans" },
  { title: "Pemphigus vulgaris" },
  { title: "Penis sérülés" },
  { title: "Penis-csont törés" },
  { title: "Péniszsérülés" },
  { title: "Perforatio bulbi" },
  { title: "Perforatio cholecystae" },
  { title: "Perforatio intestini" },
  { title: "Perforatio oesophagi" },
  { title: "Perforatio pylori" },
  { title: "Perforatio recti" },
  { title: "Perforatio urethrae" },
  { title: "Perforatio uteri" },
  { title: "Perforatio ventriculi" },
  { title: "Perforatio vesicae urinae" },
  { title: "Perianalis adenoma" },
  { title: "Perianalis fistula" },
  { title: "Peribronchitis" },
  { title: "Pericardiális folyadék gyülem" },
  { title: "Pericardiális tamponád" },
  { title: "Pericarditis fibrinosa" },
  { title: "Pericarditis idiopathica" },
  { title: "Pericarditis purulenta" },
  { title: "Pericarditis restrictiva" },
  { title: "Pericarditis serosa" },
  { title: "Pericarditis sicca" },
  { title: "Pericarditis traumatica" },
  { title: "Perifériás idegsérülés" },
  { title: "Perifériás vestibularis szindróma" },
  { title: "Periodontitis" },
  { title: "Perioesophagitis" },
  { title: "Periostitis" },
  { title: "Periphlebitis" },
  { title: "Peritonealis dialysis" },
  { title: "Peritonitis" },
  { title: "Peritonitis acuta" },
  { title: "Peritonitis chronica" },
  { title: "Peritonitis circumscripta" },
  { title: "Peritonitis diffusa" },
  { title: "Peritonitis purulenta" },
  { title: "Periuria" },
  { title: "Permetrin toxicosis" },
  { title: "Perosis" },
  { title: "Perthes kór" },
  { title: "Perzisztáló porc" },
  { title: "Perzisztáló tejfogak" },
  { title: "Perzisztáló v. cava cranialis sinistra" },
  { title: "Pestis suum" },
  { title: "Pestis suum africana" },
  { title: "Petefészek csonk granuloma/fistula" },
  { title: "Petit mal" },
  { title: "Pharyngealis mucokele" },
  { title: "Pharyngitis" },
  { title: "Pharyngitis acuta" },
  { title: "Pharyngitis chronica" },
  { title: "Pharyngotonsillitis" },
  { title: "Pheochrocytoma" },
  { title: "Phimosis" },
  { title: "Phlegmone" },
  { title: "Phlegmone linguae" },
  { title: "Photodermatitis" },
  { title: "Phtysis bulbi" },
  { title: "Phymosis" },
  { title: "Phytotrichobesoar" },
  { title: "Pica" },
  { title: "Pigmentatio corneae" },
  { title: "Pigmenturia" },
  { title: "Piroplasmosis" },
  { title: "Piscicolosis" },
  { title: "Pityriasis rosea" },
  { title: "Placentitis" },
  { title: "Plasmocytoma" },
  { title: "Pleuritis chronica" },
  { title: "Pleuritis exsudativa" },
  { title: "Pleuritis purulenta" },
  { title: "Pleuritis sicca" },
  { title: "Pleuropneumonia" },
  { title: "Pleuropneumonia actinobacill." },
  { title: "Pleuropneumonia traumatica" },
  { title: "Plexus brachialis tumor" },
  { title: "Pneumomediastinum" },
  { title: "Pneumomycosis" },
  { title: "Pneumonia" },
  { title: "Pneumonia acuta" },
  { title: "Pneumonia aspirata" },
  { title: "Pneumonia aspirationis" },
  { title: "Pneumonia chronica" },
  { title: "Pneumonia crouposa" },
  { title: "Pneumonia fibrinosa" },
  { title: "Pneumonia gangraenosa" },
  { title: "Pneumonia suppurativa" },
  { title: "Pneumoperitoneum" },
  { title: "Pneumothorax" },
  { title: "Pneumovagina" },
  { title: "Podarthritis" },
  { title: "Pododemodicosis" },
  { title: "Pododermatitis" },
  { title: "Pododermatitis, plazmasejtes" },
  { title: "Podotrochlosis" },
  { title: "Polioencephalomyelitis" },
  { title: "Pollakisuria" },
  { title: "Polyarthritis" },
  { title: "Polycystás máj" },
  { title: "Polycystic Kidney Disease" },
  { title: "Polycythaemia" },
  { title: "Polydactylia" },
  { title: "Polydipsia" },
  { title: "Polyencephalomyelitis" },
  { title: "Polyglobulia" },
  { title: "Polymyositis, idiopathicus, magyar vizslákban" },
  { title: "Polyneuropathia" },
  { title: "Polyp" },
  { title: "Polyphagia" },
  { title: "Polypoid cystitis" },
  { title: "Polyradiculoneuritis" },
  { title: "Polyuria" },
  { title: "Pontyfogazat" },
  { title: "Porcine stress syndrome" },
  { title: "Porphyria" },
  { title: "Portalis hypertensio" },
  { title: "Portális hypertensio (idiopathicus nem cirrhoticus)" },
  { title: "Porto-azygos sönt" },
  { title: "Portocavalis sönt" },
  { title: "Portoszisztémás sönt" },
  { title: "Portoszisztémás sönt, szerzett" },
  { title: "Portoszisztémás sönt, veleszületett" },
  { title: "Portoszisztémás vascularis anomália" },
  { title: "Post-attenuation neurogical sings (PANS)" },
  { title: "Post Babesiosis" },
  { title: "Postrenalis azotaemia" },
  { title: "Postresuscitációs állapot" },
  { title: "Posztoperatív ellátás" },
  { title: "PPDH" },
  { title: "PRA" },
  { title: "PRAA" },
  { title: "Praeputium rendellenesség" },
  { title: "Pregnancy disease" },
  { title: "Prekurzor-célzott immunmediált anaemia (PIMA)" },
  { title: "Primer ciliaris dyskinezia" },
  { title: "Proc. coronoid med. levállás" },
  { title: "Processus anconeus isolatus" },
  { title: "Proctitis" },
  { title: "Prognathia" },
  { title: "Progressiv myelopathia" },
  { title: "Prolapsus ani" },
  { title: "Prolapsus bulbi" },
  { title: "Prolapsus cloacae" },
  { title: "Prolapsus intestini" },
  { title: "Prolapsus penis" },
  { title: "Prolapsus recti" },
  { title: "Prolapsus urerthrae" },
  { title: "Prolapsus uteri" },
  { title: "Prolapsus vaginae" },
  { title: "Prolapsus vesicae urinariae" },
  { title: "Prooestrus" },
  { title: "Prostatahyperplasia" },
  { title: "Prostatitis" },
  { title: "Proteinuria" },
  { title: "Protostongylidosos" },
  { title: "Pruritus" },
  { title: "Pseudoarthros" },
  { title: "Pseudoconcrementum" },
  { title: "Pseudocystitis, perinephric" },
  { title: "Pseudodiverticulum intramesenterialis" },
  { title: "Pseudograviditas" },
  { title: "Pseudohyperkalaemia" },
  { title: "Pseudomycetoma" },
  { title: "Pseudopolyglobulia" },
  { title: "Psittacosis" },
  { title: "Psoor" },
  { title: "Pszichogén alopecia" },
  { title: "Pszichogén dermatitis" },
  { title: "Pszichogén polydipsia" },
  { title: "Pszichogén pruritus" },
  { title: "Pszichózis" },
  { title: "Ptyalismus" },
  { title: "Pug-encephalitis" },
  { title: "Pulmonalis hypertensio" },
  { title: "Pulmonalis shunt" },
  { title: "Pulmonalis stenosis" },
  { title: "Pure Red Cell Aplasia (PRCA)" },
  { title: "Pustula" },
  { title: "Putrefactio ingestae ruminis" },
  { title: "Pyaemia puerperalis" },
  { title: "Pyelitis" },
  { title: "Pyelonehritis" },
  { title: "Pyelonehritis bact. bovis" },
  { title: "Pylorusspasmus" },
  { title: "Pylorusstenosis" },
  { title: "Pyoarthros" },
  { title: "Pyoderma" },
  { title: "Pyoderma profunda" },
  { title: "Pyoderma superficialis" },
  { title: "Pyoderma, MRSP" },
  { title: "Pyometra" },
  { title: "Pyonephros" },
  { title: "Pyosepticaemia" },
  { title: "Pyothorax" },
  { title: "Pyuria" },
  { title: "Rabies" },
  { title: "Rachitis" },
  { title: "Radthke cleft" },
  { title: "Rándulás" },
  { title: "Ranula" },
  { title: "Recessus gingivalis" },
  { title: "Recidiva" },
  { title: "Rectocutan fistula" },
  { title: "Rectovagina" },
  { title: "Rectovaginalis fistula" },
  { title: "Red biliary syndrome" },
  { title: "Reflex dyssynergia" },
  { title: "Reflux oesophagitis" },
  { title: "Refluxsyndroma" },
  { title: "Refractura" },
  { title: "Regurgitatio" },
  { title: "Regurgitatio ingestae abomasi" },
  { title: "Repefúziós szindróma" },
  { title: "Részleges szalagszakadás" },
  { title: "Retentio placentae" },
  { title: "Retentio urinae" },
  { title: "Reticulitis circumscripta" },
  { title: "Reticuloperitonitis circumscripta" },
  { title: "Reticuloperitonitis adhaesiva" },
  { title: "Reticuloperitonitis traumatica" },
  { title: "Reticulosarcoma" },
  { title: "Reticulosis diffusa" },
  { title: "Retina bevérzés" },
  { title: "Retina degeneratio" },
  { title: "Retina dysplasia" },
  { title: "Retina levállás" },
  { title: "Retinitis" },
  { title: "Retinopathia" },
  { title: "Retroflexio capiti" },
  { title: "Retroflexio coxae" },
  { title: "Retroflexio flex. pelv." },
  { title: "Retroflexio tarsi" },
  { title: "Retroflexio vesicae urinariae" },
  { title: "Reverse PDA" },
  { title: "Reverse sneezing" },
  { title: "Rézmérgezés" },
  { title: "Réztárolási betegség" },
  { title: "Rhabdomyolysis" },
  { title: "Rhabdomyopathia" },
  { title: "Rhabdomyosarcoma" },
  { title: "Reumatoid arthritis" },
  { title: "Rhinitis" },
  { title: "Rhinitis acuta" },
  { title: "Rhinitis atrophicans suis" },
  { title: "Rhinitis chronica" },
  { title: "Rhinitis purulenta" },
  { title: "Rhinitis serosa" },
  { title: "Rhinopneumonitis" },
  { title: "Rhinorrhagia" },
  { title: "Rhinotracheitis" },
  { title: "Rictus lupinus" },
  { title: "Roeckl granuloma" },
  { title: "Rotatio os ungulae p.a.s." },
  { title: "Rotatio os ungulae p.p.d." },
  { title: "Rous sarcoma" },
  { title: "Rovarcsípés" },
  { title: "Rubarth kór" },
  { title: "Rubber jaw" },
  { title: "Rubtura cholecystae" },
  { title: "Rubtura tracheae" },
  { title: "Ruminitis" },
  { title: "Rupt.tend.flex.dig.prof.part." },
  { title: "Rupt.tend.flex.dig.sup.part." },
  { title: "Rupt.tend.flex.dig.sup.totale" },
  { title: "Ruptura aortae" },
  { title: "Ruptura cholecystae" },
  { title: "Ruptura diaphragmatica" },
  { title: "Ruptura ducti arteriosi Botalli" },
  { title: "Ruptura ducti choledochi" },
  { title: "Ruptura ductus choledochi" },
  { title: "Ruptura epidermis" },
  { title: "Ruptura hepatis" },
  { title: "Ruptura lienis" },
  { title: "Ruptura lig.crutiatum cran.partiale" },
  { title: "Ruptura lig.cruc.post." },
  { title: "Ruptura lig.cruciat.ant." },
  { title: "Ruptura ligamenti" },
  { title: "Ruptura ligamenti collat." },
  { title: "Ruptura ligamenti parciale" },
  { title: "Ruptura m. flex. dig. prof." },
  { title: "Ruptura m. biceps humeri" },
  { title: "Ruptura musculi femoris" },
  { title: "Ruptura oesophagi" },
  { title: "Ruptura perinei" },
  { title: "Ruptura splenis" },
  { title: "Ruptura tend m. recti femoris" },
  { title: "Ruptura tendinis preputialis" },
  { title: "Ruptura tendinitis" },
  { title: "Ruptura tracheae" },
  { title: "Ruptura urethrae" },
  { title: "Ruptura uretri" },
  { title: "Ruptura uteri" },
  { title: "Ruptura vaginae" },
  { title: "Ruptura ventriculi" },
  { title: "Ruptura vesicae urinariae" },
  { title: "Rühösség" },
  { title: "Sacculatio recti" },
  { title: "Sacralisatio" },
  { title: "Salivary staining" },
  { title: "Salivatio" },
  { title: "Salmonellosis" },
  { title: "Sapraemia" },
  { title: "Sarcoid" },
  { title: "Sarcoma" },
  { title: "Sarcopenia" },
  { title: "Sarcoptes" },
  { title: "Sarcosporidiosis" },
  { title: "Satratoxicosis" },
  { title: "Scabies" },
  { title: "Schistosoma reflexum" },
  { title: "Schnauzer Comedo Syndrome" },
  { title: "Scoliosis" },
  { title: "Scrapie" },
  { title: "Scrotitis" },
  { title: "Sebaceous adenitis" },
  { title: "Sebgyógyulási zavar" },
  { title: "Seborrhoea idiopatica" },
  { title: "Seborrhoea oleosa" },
  { title: "Seborrhoea sicca" },
  { title: "Secundaer epilepsia" },
  { title: "Secundaer hepatopathia" },
  { title: "Seminoma" },
  { title: "Sepsis" },
  { title: "Septikaemia" },
  { title: "Septum defectus" },
  { title: "Sequester" },
  { title: "Seroma" },
  { title: "Seroma, szervült" },
  { title: "Sertoli sejtes heretumor" },
  { title: "Sesamoiditis" },
  { title: "Sever féle betegség" },
  { title: "Shar pei fever" },
  { title: "Shearing injury, carpus" },
  { title: "Shearing injury, csánk" },
  { title: "Shearing injury, térd" },
  { title: "Shock" },
  { title: "Short Bowel Syndrome" },
  { title: "Sialadenitis" },
  { title: "Sialismus" },
  { title: "Sialoadenitis" },
  { title: "Sialoadenitis sublingualis" },
  { title: "Sialoadenitis submandibularis" },
  { title: "Sialokele" },
  { title: "Sialolithiasis" },
  { title: "Sick sinus syndrome" },
  { title: "Sine diagnose" },
  { title: "Sine morbo" },
  { title: "Singultus" },
  { title: "Sinusarrhythmia" },
  { title: "Sinusbradycardia" },
  { title: "Sinusitis" },
  { title: "Sinusitis maxillaris" },
  { title: "Sinustachycardia" },
  { title: "Sjögren szindróma" },
  { title: "Somnolentia" },
  { title: "Somogyi jelenség" },
  { title: "Soor" },
  { title: "Sopor" },
  { title: "Spasmus laryngis" },
  { title: "Spasmus oesophagii" },
  { title: "Spasmus pylori" },
  { title: "Spermatocele" },
  { title: "Spirocercosis" },
  { title: "Splenitis" },
  { title: "Sinusitis apostematosa" },
  { title: "Splenoazygos sönt" },
  { title: "Splenocavalis sönt" },
  { title: "Splenomegalia" },
  { title: "Splenosis" },
  { title: "Split oestrus" },
  { title: "Spondylosis" },
  { title: "Spondylosis deformans" },
  { title: "Stachybotryotoxicosis" },
  { title: "Status epilepticus" },
  { title: "Status febrilis" },
  { title: "Status moribundus" },
  { title: "Status post partum" },
  { title: "Status praepartum" },
  { title: "Status thymico-lymphaticus" },
  { title: "Steatosis" },
  { title: "Stenosis ani" },
  { title: "Stenosis auriculae" },
  { title: "Stenosis intestini" },
  { title: "Stenosis laryngis" },
  { title: "Stenosis lumbosacralis" },
  { title: "Stenosis nasi" },
  { title: "Stenosis nasopharyngealis" },
  { title: "Stenosis oesophagii" },
  { title: "Stenosis ostii aortae" },
  { title: "Stenosis ostii arteriae pulm." },
  { title: "Stenosis pelvis" },
  { title: "Stenosis pulmonale" },
  { title: "Stenosis pylori" },
  { title: "Stenosis tracheae" },
  { title: "Stenosis ureteri" },
  { title: "Stenosis urethrae" },
  { title: "Stenosis vertebrae" },
  { title: "Steroid Responsive Meningitis-Arteritis (SRMA)" },
  { title: "Sticker féle sarcoma" },
  { title: "Stomatitis" },
  { title: "Stomatitis aphthosa" },
  { title: "Stomatitis apostematosa" },
  { title: "Stomatitis caudalis" },
  { title: "Stomatitis crouposa" },
  { title: "Stomatitis gangraenosa" },
  { title: "Stomatitis mycotica" },
  { title: "Stomatitis necroticans" },
  { title: "Stomatitis papulosa" },
  { title: "Stomatitis pseudomembranacea" },
  { title: "Stomatitis purulenta" },
  { title: "Stomatitis pustulosa" },
  { title: "Stomatitis simplex" },
  { title: "Stomatitis ulcerosa" },
  { title: "Stomatitis vesiculosa" },
  { title: "Stomatomycosis" },
  { title: "Strabismus" },
  { title: "Stranguláló idegentest" },
  { title: "Strangulatio intestini" },
  { title: "Strangulatio oesophagi" },
  { title: "Stranguria" },
  { title: "Streptococcosis" },
  { title: "Stressz" },
  { title: "Stressz syndroma" },
  { title: "Strictura ani" },
  { title: "Strictura anorectalis" },
  { title: "Strictura oesophagi" },
  { title: "Strictura pelvis" },
  { title: "Strictura recti" },
  { title: "Strictura tracheae" },
  { title: "Strictura urethrae" },
  { title: "Stridor laryngealis" },
  { title: "Stridor nasale" },
  { title: "Stroke" },
  { title: "Strongyloidosis" },
  { title: "Strongylosis" },
  { title: "Struma" },
  { title: "Stupor" },
  { title: "Subfebrilitas" },
  { title: "Subileus" },
  { title: "Subinvolutio uteri" },
  { title: "Subluxatio" },
  { title: "Subluxatio art. carpo-metacarp" },
  { title: "Subluxatio art. coxae" },
  { title: "Subluxatio art. humeri" },
  { title: "Subluxatio art. radio-carpale" },
  { title: "Subluxatio art. tarsi" },
  { title: "Subluxatio articulationis humero-radiale" },
  { title: "Subluxatio vertebrae" },
  { title: "Sudden Aquired Retinal Degeneration" },
  { title: "Süketség" },
  { title: "Swayback" },
  { title: "Swollen hock syndrome" },
  { title: "Sycosis" },
  { title: "Syphysis szétválás" },
  { title: "Synblepharon" },
  { title: "Syncope" },
  { title: "Syndrome of Inappropriate ADH Secretio (SIADH)" },
  { title: "Syngamosis" },
  { title: "Synovialis sarcoma" },
  { title: "Syringiohydromyelia" },
  { title: "Syringomyelia" },
  { title: "Systemic Inflammatory Response Syndrome (SIRS)" },
  { title: "Szájrothadás" },
  { title: "Szarucsorba" },
  { title: "Szaruhártya oedema" },
  { title: "Szegbelépés" },
  { title: "Szemétmérgezés" },
  { title: "Szemgolyó előesés" },
  { title: "Szemhéj daganat" },
  { title: "Szemhéjsérülés" },
  { title: "Szemölcs" },
  { title: "Szeparációs szorongás" },
  { title: "Szerotonin szindróma" },
  { title: "Szezonális alopecia" },
  { title: "Színmutációs alopecia" },
  { title: "Szisztémás lupus erythematosus" },
  { title: "Szívférgesség" },
  { title: "Szívtamponád" },
  { title: "Szteroid hepatopathia" },
  { title: "Szteroid reszponzív meningoarteritis (SRMA)" },
  { title: "Szteroid reszponzív lymphadenitis" },
  { title: "Szűkült orrnyílások" },
  { title: "Tachyarrhythmia" },
  { title: "Tachycardia" },
  { title: "Tachycardia paroxismalis" },
  { title: "Tachycardia superventricularis" },
  { title: "Tachycardia ventricularis" },
  { title: "Tachypnoe" },
  { title: "Taeniosis" },
  { title: "Tágremese helyzetvált. balra" },
  { title: "Tágremese helyzetvált. jobbra" },
  { title: "Talfan betegség" },
  { title: "Talpfekély" },
  { title: "Tarsitis" },
  { title: "Taurin hiány" },
  { title: "Tejfog retenció" },
  { title: "Telogen defluxion" },
  { title: "Telogen effluvium" },
  { title: "Tendinitis" },
  { title: "Tendinitis chronica" },
  { title: "Tendovaginitis" },
  { title: "Tenesmus" },
  { title: "Tescheni betegség" },
  { title: "Tesztoszteron reszponzív dermatopathia" },
  { title: "Tetania" },
  { title: "Tetanus" },
  { title: "Tetanus partialis" },
  { title: "Tetraparesis" },
  { title: "Tetraplegia" },
  { title: "Tetvesség" },
  { title: "Tetvesség, Felicola subrostratus" },
  { title: "Tetvesség, Trichodectes canis" },
  { title: "Téves megnyitás" },
  { title: "Thesaurismosis" },
  { title: "Thiamin hiány" },
  { title: "Thoracophagus" },
  { title: "Thrombocytopathia" },
  { title: "Thrombocytopenia" },
  { title: "Thrombocytosis" },
  { title: "Thromboembolia" },
  { title: "Thromboembolia pulmonum" },
  { title: "Thrombophlebitis" },
  { title: "Thrombosis art. mesent." },
  { title: "Thrombosis arteriarum" },
  { title: "Thrombosis lienis" },
  { title: "Thrombosis venarum" },
  { title: "Thymitis" },
  { title: "Thymoma" },
  { title: "Thymusapoplexia" },
  { title: "Thymusleukosis" },
  { title: "Thyreoditis" },
  { title: "Thyroid storm" },
  { title: "Thyroiditis" },
  { title: "Thyrotoxocosis" },
  { title: "Tick paralysis" },
  { title: "Tojásperitonitis" },
  { title: "Tojásretenció" },
  { title: "Toklász" },
  { title: "Tollcsipkedés" },
  { title: "Tonsillitis" },
  { title: "Tonsillitis acuta" },
  { title: "Tonsillitis chronica" },
  { title: "Toronyház szindróma" },
  { title: "Torpid seb" },
  { title: "Torsio cholecystae" },
  { title: "Torsio colon ascendens 180" },
  { title: "Torsio colon ascendens 360" },
  { title: "Torsio et dilatatio ventriculi" },
  { title: "Torsio intestini" },
  { title: "Torsio intestini caeci" },
  { title: "Torsio jejuni" },
  { title: "Torsio lienis" },
  { title: "Torsio lobi hepatis" },
  { title: "Torsio mesenterialis intestini" },
  { title: "Torsio pulmonum" },
  { title: "Torsio splenis" },
  { title: "Torsio testiculi" },
  { title: "Torsio uteri gravidi" },
  { title: "Torsio ventriculi" },
  { title: "Torticollis" },
  { title: "Toxicosis" },
  { title: "Toxicosis gestationis" },
  { title: "Toxicosis, piretroid" },
  { title: "Toxicosis, xilitol" },
  { title: "Toxikus epidermalis necrolysis" },
  { title: "Toxoplasmosis" },
  { title: "Törési betegség" },
  { title: "Tracheacollapsus" },
  { title: "Tracheahypoplasia" },
  { title: "Trachea sérülés" },
  { title: "Trachea strictura" },
  { title: "Tracheitis" },
  { title: "Tracheobronchitis" },
  { title: "Transient Ischaemic Attack (TIA)" },
  { title: "Transzfúziós reakció" },
  { title: "Tranziens szívizom megvastagodás" },
  { title: "Trauma" },
  { title: "Traumás agysérülés" },
  { title: "Traumás hasfalsérv" },
  { title: "Tremor" },
  { title: "Tremor, orthostaticus" },
  { title: "Triaditis" },
  { title: "Triatrium" },
  { title: "Trichiasis" },
  { title: "Trichinellosis" },
  { title: "Trichoepithelioma" },
  { title: "Trichomonosis" },
  { title: "Trichophytiasys" },
  { title: "Trichorrhexis nodosa" },
  { title: "Trichostrongylidosis" },
  { title: "Trichuridosis" },
  { title: "Tritrichomonosis" },
  { title: "Trombiculosis" },
  { title: "Trombózis" },
  { title: "Tuberculosis" },
  { title: "Tularaemia" },
  { title: "Túlnőtt orrlebeny" },
  { title: "Túlsarjadás" },
  { title: "Tumor dysontogenica" },
  { title: "Tumores hepatis" },
  { title: "Tüdőférgesség" },
  { title: "Tying up syndroma" },
  { title: "Tympania ruminis" },
  { title: "Tympania sacci aerophori" },
  { title: "Tympania ventriculi" },
  { title: "Typhlitis" },
  { title: "Typhus suis" },
  { title: "UCCR + scLDDST + UNCR projekt" },
  { title: "Ulcus" },
  { title: "Ulcus abomasi" },
  { title: "Ulcus corneae" },
  { title: "Ulcus duodeni" },
  { title: "Ulcus intestini" },
  { title: "Ulcus linguae" },
  { title: "Ulcus nasi" },
  { title: "Ulcus ventriculi" },
  { title: "Uncinariosis" },
  { title: "Urachus diverticulum" },
  { title: "Urachus fistula" },
  { title: "Uraemia" },
  { title: "Ureterokele" },
  { title: "Ureterolithiasis" },
  { title: "Ureter sérülés" },
  { title: "Urethral Spinchter Mechanism Incompetence (USMI)" },
  { title: "Urethralgia" },
  { title: "Urethritis" },
  { title: "Urethrolithiasis" },
  { title: "Uricosis avium" },
  { title: "Urolithiasis" },
  { title: "Uroperitoneum" },
  { title: "Urovagina" },
  { title: "Urticaria" },
  { title: "Urticaria pigmentosa" },
  { title: "USMI" },
  { title: "Uveitis" },
  { title: "Uveitis anterior" },
  { title: "Uveodermatological Syndrome" },
  { title: "Überreiter szindróma" },
  { title: "Üvegtest bevérzés" },
  { title: "V. azygos sin. persistens" },
  { title: "V. cava cranialis sin. persistens" },
  { title: "VAFS - ISS" },
  { title: "Vagina duplex" },
  { title: "Vaginitis" },
  { title: "Vaginitis juvenilis" },
  { title: "Vakság" },
  { title: "Variola" },
  { title: "Varratelégtelenség" },
  { title: "Varratszedés" },
  { title: "Vascularis anomália" },
  { title: "Vasculitis" },
  { title: "Vashiányos anaemia" },
  { title: "Vasopathia" },
  { title: "Végtag deformitás" },
  { title: "Vena cava caudalis compressio" },
  { title: "Vena cava caudalis thrombosis" },
  { title: "Vena cava syndroma" },
  { title: "Vena portae hypoplasia" },
  { title: "Ventroflexio capiti" },
  { title: "Ventroflexio uteri" },
  { title: "Ventroversio uteri" },
  { title: "Véradás" },
  { title: "Vérzékenység" },
  { title: "Vesekéreg dysplasia" },
  { title: "Virilismus" },
  { title: "Virus septicaemia inf. cypr." },
  { title: "Virus septicaemia inf. esocium" },
  { title: "Viselkedés zavar" },
  { title: "Vitamin D dependent rickets type I (VDDR-1)" },
  { title: "Vitamin D resistant rickets type II (VDRR-II)" },
  { title: "Vitiligo" },
  { title: "Vitium cordis" },
  { title: "Vogt - Koyanagi - Harada like syndrom" },
  { title: "Volvulus" },
  { title: "Volvulus intestini" },
  { title: "Volvulus mesenterialis" },
  { title: "Volvulus nodosus intestini" },
  { title: "Vomitus" },
  { title: "Von Willebrand betegség" },
  { title: "Vulnus caesum" },
  { title: "Vulnus contusum" },
  { title: "Vulnus lacerum" },
  { title: "Vulnus lacerum" },
  { title: "Vulnus morsum" },
  { title: "Vulnus punctum" },
  { title: "Vulnus scissum" },
  { title: "Vulnus sclopetarium" },
  { title: "White dog shaker syndrome" },
  { title: "Wobbler syndroma" },
  { title: "Xylit mérgezés" },
  { title: "Yersiniosis" },
  { title: "Zenker féle izomelhalás" },
  { title: "Zollinger - Ellison szindróma" },
  { title: "Zootrichobezoar" },
  { title: "Zsírszövetelhalás" },
];
