/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { SiteManagerPage } from "../SiteManagerPage";
import { PageProps } from "../../../models/PageProps";
import { getAddressString, getGeneralError } from "../../../util/helperFunctions";
import logger from "../../../util/logger";
import { dateAndTime, strings } from "../../../common/Strings/Strings";
import { PageResponse } from "../../../models/PageResponse";
import { Link } from "react-router-dom";
import ClinicManagementApi from "../../../api/ClinicManagementApi";
import { useDebouncedState, usePager } from "../../../hooks/hooks";
import SearchInput from "../../../components/InputFieldsSimple/SearchInput";
import PageableTable from "../../../components/PageableTable/PageableTable";
import PageableTableHead from "../../../components/PageableTable/PageableTableHead";
import PageableTableBody from "../../../components/PageableTable/PageableTableBody";
import { ClinicManagementItemResponse } from "../../../models/management/ClinicManagementItemResponse";
import moment from "moment";
import { Option } from "../../../models/Option";
import SortingSelect from "../../../components/SortingSelect";

const clinicSortingOptions: Option[] = [
  { title: strings.sortByRegistrationDate, value: "" },
  { title: strings.sortByName, value: "NAME" },
  { title: strings.sortByLastActivity, value: "LAST_ACTIVITY" },
  { title: strings.sortByLastCharge, value: "LAST_CHARGE" },
];

const ClinicListPage: React.FC<PageProps> = (props: PageProps) => {
  const { setPageLoading } = props;

  const [clinics, setClinics] = useState<PageResponse<ClinicManagementItemResponse>>();

  const pager = usePager(50);
  const searchQuery = useDebouncedState("", 400);
  const [sortingOption, setSortingOption] = useState<string>(clinicSortingOptions[0].value as string);

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const getClinics = async () => {
    setLoading(true);

    try {
      const response = await ClinicManagementApi.getClinics({
        pageNumber: pager.pageNumber,
        pageSize: pager.pageSize,
        query: searchQuery.value,
        sortBy: sortingOption,
      });
      setClinics(response.data);
    } catch (err) {
      setError(await getGeneralError(err));
      logger.error(err);
    } finally {
      setPageLoading(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    void getClinics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pager.pageNumber, pager.pageSize, searchQuery.value, sortingOption]);

  return (
    <div className="px-4 lg:px-6 py-6">
      <div className="flex flex-wrap gap-2 mb-3">
        <div className="flex-grow-1">
          <div className="w-80">
            <SearchInput
              clear={() => {
                searchQuery.setValue("");
                pager.setPageNumber(0);
              }}
              enableCreateButton={false}
              loading={loading}
              onChange={(value) => {
                searchQuery.setValue(value);
                pager.setPageNumber(0);
                setSortingOption("");
              }}
              placeholder={`${strings.search}...`}
              label={strings.search}
              value={searchQuery.value}
            />
          </div>
        </div>
        <div>
          <SortingSelect
            defaultValue={sortingOption}
            options={clinicSortingOptions}
            onChange={(option) => setSortingOption(option)}
          />
        </div>
      </div>
      <PageableTable pager={pager} isLoading={loading} content={clinics} error={error}>
        <PageableTableHead>
          <tr>
            <th className="text-left tw-th">{strings.clinic}</th>
            <th className="text-center tw-th">{strings.country}</th>
            <th className="text-left tw-th">{strings.address}</th>
            <th className="text-left tw-th">{strings.lastActivity}</th>
            <th className="text-left tw-th">{strings.lastCharge}</th>
          </tr>
        </PageableTableHead>
        <PageableTableBody>
          {clinics &&
            clinics.elements.map((clinic, index: number) => (
              <tr key={index}>
                <td className="px-4 py-4 text-sm whitespace-nowrap">
                  <Link className="tw-link-dark" to={`/management/clinics/${clinic.id}`}>
                    {clinic.name}
                  </Link>
                </td>
                <td className="px-4 py-4 text-center text-sm whitespace-nowrap">{clinic.address?.countryCode}</td>
                <td className="px-4 py-4 text-sm whitespace-nowrap">
                  {clinic.address && getAddressString(clinic.address)}
                </td>
                <td className="px-4 py-4 text-sm whitespace-nowrap">
                  <div
                    hidden={!clinic.lastActivity}
                    title={moment(clinic.lastActivity).format(dateAndTime.momentDateTimeFormat)}
                  >
                    {moment(clinic.lastActivity).utc(true).fromNow()}
                  </div>
                </td>
                <td className="px-4 py-4 text-sm whitespace-nowrap">
                  <div
                    hidden={!clinic.lastCharge}
                    title={moment(clinic.lastCharge).format(dateAndTime.momentDateTimeFormat)}
                  >
                    {moment(clinic.lastCharge).utc(true).fromNow()}
                  </div>
                </td>
              </tr>
            ))}
        </PageableTableBody>
      </PageableTable>
    </div>
  );
};

export default SiteManagerPage(ClinicListPage, strings.CLINICS);
