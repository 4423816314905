/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import LocalizedStrings from "react-localization";

// Must load all optional languages
import "moment/locale/hu";
import "moment/locale/en-gb";

import { mainStringsEn } from "./MainStrings/en";
import { mainStringsHu } from "./MainStrings/hu";
import { mainStringsSv } from "./MainStrings/sv";
import { petSpeciesHu } from "./PetSpecies/hu";
import { petSpeciesEn } from "./PetSpecies/en";
import { petSpeciesSv } from "./PetSpecies/sv";
import { petTypesHu } from "./PetTypes/hu";
import { petTypesEn } from "./PetTypes/en";
import { petTypesSv } from "./PetTypes/sv";
import { quantityUnitsSv } from "./QuantityUnits/sv";
import { quantityUnitsEn } from "./QuantityUnits/en";
import { quantityUnitsHu } from "./QuantityUnits/hu";
import { prescriptionWaitingPeriodTypeSv } from "./PrescriptionWaitingPeriodType/sv";
import { prescriptionWaitingPeriodTypeEn } from "./PrescriptionWaitingPeriodType/en";
import { prescriptionWaitingPeriodTypeHu } from "./PrescriptionWaitingPeriodType/hu";
import { prescriptionMethodsHu } from "./PrescriptionMethods/hu";
import { prescriptionMethodsEn } from "./PrescriptionMethods/en";
import { prescriptionMethodsSv } from "./PrescriptionMethods/sv";
import { remindersSv } from "./Reminders/sv";
import { remindersEn } from "./Reminders/en";
import { remindersHu } from "./Reminders/hu";
import { prescriptionIntakeSv } from "./PrescriptionIntake/sv";
import { prescriptionIntakeEn } from "./PrescriptionIntake/en";
import { prescriptionIntakeHu } from "./PrescriptionIntake/hu";
import { prescriptionCancellationReasonsSv } from "./PrescriptionCancellationReasons/sv";
import { prescriptionCancellationReasonsEn } from "./PrescriptionCancellationReasons/en";
import { prescriptionCancellationReasonsHu } from "./PrescriptionCancellationReasons/hu";
import { petGendersSv } from "./PetGenders/sv";
import { petGendersEn } from "./PetGenders/en";
import { petGendersHu } from "./PetGenders/hu";
import { rolesSv } from "./Roles/sv";
import { rolesEn } from "./Roles/en";
import { rolesHu } from "./Roles/hu";
import { chronicDiseasesEn } from "./ChronicDiseases/en";
import { chronicDiseasesSv } from "./ChronicDiseases/sv";
import { chronicDiseasesHu } from "./ChronicDiseases/hu";
import { paymentMethodEn } from "./PaymentMethod/en";
import { paymentMethodSv } from "./PaymentMethod/sv";
import { paymentMethodHu } from "./PaymentMethod/hu";
import { invoiceStatusEn } from "./InvoiceStatus/en";
import { invoiceStatusSv } from "./InvoiceStatus/sv";
import { invoiceStatusHu } from "./InvoiceStatus/hu";
import { costAccountingStatusSv } from "./CostAccountingStatus/sv";
import { costAccountingStatusEn } from "./CostAccountingStatus/en";
import { costAccountingStatusHu } from "./CostAccountingStatus/hu";
import { chargeStatesHu } from "./ChargeStates/hu";
import { chargeStatesEn } from "./ChargeStates/en";
import { chargeStatesSv } from "./ChargeStates/sv";
import { breedsSv } from "./Breeds/sv";
import { breedsEn } from "./Breeds/en";
import { breedsHu } from "./Breeds/hu";
import {
  getActiveUserId,
  getLocaleForActiveUser,
  getSimpleLanguage,
  getSystemLanguageCode,
} from "../../util/LocalStorageVariables";
import { paymentTypeSv } from "./PaymentType/sv";
import { paymentTypeEn } from "./PaymentType/en";
import { paymentTypeHu } from "./PaymentType/hu";
import { treatmentPurposeEn } from "./PrescriptionTreatmentPurpose/en";
import { treatmentPurposeHu } from "./PrescriptionTreatmentPurpose/hu";
import { treatmentPurposeSv } from "./PrescriptionTreatmentPurpose/sv";
import { clinicCostsSv } from "./ClinicCosts/sv";
import { clinicCostsEn } from "./ClinicCosts/en";
import { clinicCostsHu } from "./ClinicCosts/hu";
import { organizationCostsSv } from "./OrganizationCosts/sv";
import { organizationCostsEn } from "./OrganizationCosts/en";
import { organizationCostsHu } from "./OrganizationCosts/hu";
import { statisticsItemsSv } from "./StatisticsItems/sv";
import { statisticsItemsEn } from "./StatisticsItems/en";
import { statisticsItemsHu } from "./StatisticsItems/hu";
import { siteManagerPermissionsSv } from "./SiteManagerPermissions/sv";
import { siteManagerPermissionsEn } from "./SiteManagerPermissions/en";
import { siteManagerPermissionsHu } from "./SiteManagerPermissions/hu";
import { reservationStateSv } from "./ReservationStates/sv";
import { reservationStateEn } from "./ReservationStates/en";
import { reservationStateHu } from "./ReservationStates/hu";
import { dateAndTimeSv } from "./DateAndTime/sv";
import { dateAndTimeHu } from "./DateAndTime/hu";
import { dateAndTimeEn } from "./DateAndTime/en";
import { businessStructuresSv } from "./BusinessStructures/sv";
import { businessStructuresEn } from "./BusinessStructures/en";
import { businessStructuresHu } from "./BusinessStructures/hu";
import { policyTypeSv } from "./PolicyType/sv";
import { policyTypeEn } from "./PolicyType/en";
import { policyTypeHu } from "./PolicyType/hu";
import { clinicAnalyticsEventTypeSv } from "./ClinicAnalyticsEventType/sv";
import { clinicAnalyticsEventTypeEn } from "./ClinicAnalyticsEventType/en";
import { clinicAnalyticsEventTypeHu } from "./ClinicAnalyticsEventType/hu";
import { symptomsSv } from "./Symptoms/sv";
import { symptomsEn } from "./Symptoms/en";
import { symptomsHu } from "./Symptoms/hu";
import { userAnalyticsEventTypeSv } from "./UserAnalyticsEventType/sv";
import { userAnalyticsEventTypeEn } from "./UserAnalyticsEventType/en";
import { userAnalyticsEventTypeHu } from "./UserAnalyticsEventType/hu";
import { clinicFeeUnitsSv } from "./ClinicFeeUnits/sv";
import { clinicFeeUnitsEn } from "./ClinicFeeUnits/en";
import { clinicFeeUnitsHu } from "./ClinicFeeUnits/hu";
import { roundingStrategySv } from "./RoundingStrategy/sv";
import { roundingStrategyEn } from "./RoundingStrategy/en";
import { roundingStrategyHu } from "./RoundingStrategy/hu";
import { vatCalculationStrategySv } from "./VatCalculationStrategy/sv";
import { vatCalculationStrategyEn } from "./VatCalculationStrategy/en";
import { vatCalculationStrategyHu } from "./VatCalculationStrategy/hu";
import { notificationTypeSv } from "./NotificationType/sv";
import { notificationTypeEn } from "./NotificationType/en";
import { notificationTypeHu } from "./NotificationType/hu";
import { targetGroupSv } from "./TargetGroup/sv";
import { targetGroupEn } from "./TargetGroup/en";
import { targetGroupHu } from "./TargetGroup/hu";
import { videoChatRoomEventTypeSv } from "./VideoChatRoomEventType/sv";
import { videoChatRoomEventTypeEn } from "./VideoChatRoomEventType/en";
import { videoChatRoomEventTypeHu } from "./VideoChatRoomEventType/hu";

interface StringMap {
  [key: string]: any;
}

/* All the languages that are supported by GlobalVET */
/* The first element is the default supported language */
const SupportedLanguages = [
  { code: "en", fullCode: "en-US", title: "English" },
  { code: "hu", fullCode: "hu-HU", title: "Magyar" },
  { code: "sv", fullCode: "sv-SE", title: "Svenska" },
];

/* Used as a fallback default language when the user agent uses a not supported language */
const DefaultLanguage = SupportedLanguages[0];

const breeds: StringMap = new LocalizedStrings(
  {
    sv: breedsSv,
    en: breedsEn,
    hu: breedsHu,
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  { logsEnabled: false } /* The values are arrays and intentionally different */
);

const businessStructures: StringMap = new LocalizedStrings({
  sv: businessStructuresSv,
  en: businessStructuresEn,
  hu: businessStructuresHu,
});

const chargeStates: StringMap = new LocalizedStrings({
  sv: chargeStatesSv,
  en: chargeStatesEn,
  hu: chargeStatesHu,
});

const chronicDiseases: StringMap = new LocalizedStrings({
  sv: chronicDiseasesSv,
  en: chronicDiseasesEn,
  hu: chronicDiseasesHu,
});

const clinicAnalyticsEventType: StringMap = new LocalizedStrings({
  sv: clinicAnalyticsEventTypeSv,
  en: clinicAnalyticsEventTypeEn,
  hu: clinicAnalyticsEventTypeHu,
});

const clinicCosts: StringMap = new LocalizedStrings({
  sv: clinicCostsSv,
  en: clinicCostsEn,
  hu: clinicCostsHu,
});

const clinicFeeUnits: StringMap = new LocalizedStrings({
  sv: clinicFeeUnitsSv,
  en: clinicFeeUnitsEn,
  hu: clinicFeeUnitsHu,
});

const costAccountingStatus: StringMap = new LocalizedStrings({
  sv: costAccountingStatusSv,
  en: costAccountingStatusEn,
  hu: costAccountingStatusHu,
});

const dateAndTime: StringMap = new LocalizedStrings({
  sv: dateAndTimeSv,
  en: dateAndTimeEn,
  hu: dateAndTimeHu,
});

const invoiceStatus: StringMap = new LocalizedStrings({
  sv: invoiceStatusSv,
  en: invoiceStatusEn,
  hu: invoiceStatusHu,
});

const strings: StringMap = new LocalizedStrings({
  sv: mainStringsSv,
  en: mainStringsEn,
  hu: mainStringsHu,
});

const notificationType: StringMap = new LocalizedStrings({
  sv: notificationTypeSv,
  en: notificationTypeEn,
  hu: notificationTypeHu,
});

const organizationCosts: StringMap = new LocalizedStrings({
  sv: organizationCostsSv,
  en: organizationCostsEn,
  hu: organizationCostsHu,
});

const paymentMethod: StringMap = new LocalizedStrings({
  sv: paymentMethodSv,
  en: paymentMethodEn,
  hu: paymentMethodHu,
});

const paymentType: StringMap = new LocalizedStrings({
  sv: paymentTypeSv,
  en: paymentTypeEn,
  hu: paymentTypeHu,
});

const petGenders: StringMap = new LocalizedStrings({
  sv: petGendersSv,
  en: petGendersEn,
  hu: petGendersHu,
});

const petSpecies: StringMap = new LocalizedStrings({
  sv: petSpeciesSv,
  en: petSpeciesEn,
  hu: petSpeciesHu,
});

const petTypes: StringMap = new LocalizedStrings({
  sv: petTypesSv,
  en: petTypesEn,
  hu: petTypesHu,
});

const policyType: StringMap = new LocalizedStrings({
  sv: policyTypeSv,
  en: policyTypeEn,
  hu: policyTypeHu,
});

const prescriptionCancellationReasons: StringMap = new LocalizedStrings({
  sv: prescriptionCancellationReasonsSv,
  en: prescriptionCancellationReasonsEn,
  hu: prescriptionCancellationReasonsHu,
});

const prescriptionIntakeKeys = [
  "QD",
  "BID",
  "TID",
  "QID",
  "QOD",
  "SD",
  "SED",
  "OD",
  "NOD",
  "DD",
  "DQD",
  "DBID",
  "QW",
  "BIW",
  "q3D",
  "QM",
  "q3M",
];

const prescriptionIntakes: StringMap = new LocalizedStrings({
  sv: prescriptionIntakeSv,
  en: prescriptionIntakeEn,
  hu: prescriptionIntakeHu,
});

const prescriptionMethodIds = [
  "MOUTH",
  "PO",
  "CUTN",
  "ORAL",
  "RECT",
  "AURI",
  "BIDI",
  "BU",
  "DENT",
  "DIPP",
  "DW",
  "GAEN",
  "GING",
  "IH",
  "INAC",
  "INBU",
  "INCD",
  "INFE",
  "NASA",
  "OCUL",
  "ORMU",
  "PDON",
  "POON",
  "PR",
  "SL",
  "SMUC",
  "SPON",
  "SUCO",
  "SUCU",
  "TEAT",
  "TO",
  "TODR",
  "VAGI",
  "WABO",
];

const prescriptionMethods: StringMap = new LocalizedStrings({
  sv: prescriptionMethodsSv,
  en: prescriptionMethodsEn,
  hu: prescriptionMethodsHu,
});

const prescriptionTreatmentPurpose: StringMap = new LocalizedStrings({
  sv: treatmentPurposeSv,
  en: treatmentPurposeEn,
  hu: treatmentPurposeHu,
});

const prescriptionWaitingPeriodType: StringMap = new LocalizedStrings({
  sv: prescriptionWaitingPeriodTypeSv,
  en: prescriptionWaitingPeriodTypeEn,
  hu: prescriptionWaitingPeriodTypeHu,
});

const quantityUnits: StringMap = new LocalizedStrings({
  sv: quantityUnitsSv,
  en: quantityUnitsEn,
  hu: quantityUnitsHu,
});

const reminders: StringMap = new LocalizedStrings({
  sv: remindersSv,
  en: remindersEn,
  hu: remindersHu,
});

const reservationStates: StringMap = new LocalizedStrings({
  sv: reservationStateSv,
  en: reservationStateEn,
  hu: reservationStateHu,
});

const roles: StringMap = new LocalizedStrings({
  sv: rolesSv,
  en: rolesEn,
  hu: rolesHu,
});

const roundingStrategy: StringMap = new LocalizedStrings({
  sv: roundingStrategySv,
  en: roundingStrategyEn,
  hu: roundingStrategyHu,
});

const statisticsItems: StringMap = new LocalizedStrings({
  sv: statisticsItemsSv,
  en: statisticsItemsEn,
  hu: statisticsItemsHu,
});

const symptoms: StringMap = new LocalizedStrings({
  sv: symptomsSv,
  en: symptomsEn,
  hu: symptomsHu,
});

const siteManagerPermissions: StringMap = new LocalizedStrings({
  sv: siteManagerPermissionsSv,
  en: siteManagerPermissionsEn,
  hu: siteManagerPermissionsHu,
});

const targetGroup: StringMap = new LocalizedStrings({
  sv: targetGroupSv,
  en: targetGroupEn,
  hu: targetGroupHu,
});

const userAnalyticsEventType: StringMap = new LocalizedStrings({
  sv: userAnalyticsEventTypeSv,
  en: userAnalyticsEventTypeEn,
  hu: userAnalyticsEventTypeHu,
});

const videoChatRoomEventType: StringMap = new LocalizedStrings({
  sv: videoChatRoomEventTypeSv,
  en: videoChatRoomEventTypeEn,
  hu: videoChatRoomEventTypeHu,
});

const vatCalculationStrategy: StringMap = new LocalizedStrings({
  sv: vatCalculationStrategySv,
  en: vatCalculationStrategyEn,
  hu: vatCalculationStrategyHu,
});

export function findLocale(): string {
  const userId = getActiveUserId();
  const userLocale = getLocaleForActiveUser(); /* localStorage: {userId}_locale, logged-in user preference */
  const simpleLocale = getSimpleLanguage(); /* localStorage: lan, locale without logged-in user preference */

  const systemLanguage = getSystemLanguageCode();
  const isLanguageSupported = SupportedLanguages.some((lan) => lan.code === systemLanguage);

  if (userId) {
    return userLocale || simpleLocale || (isLanguageSupported ? systemLanguage : "en");
  }

  return simpleLocale || (isLanguageSupported ? systemLanguage : "en");
}

function setLocale(code: string): void {
  breeds.setLanguage(code);
  businessStructures.setLanguage(code);
  chargeStates.setLanguage(code);
  chronicDiseases.setLanguage(code);
  clinicAnalyticsEventType.setLanguage(code);
  clinicCosts.setLanguage(code);
  clinicFeeUnits.setLanguage(code);
  costAccountingStatus.setLanguage(code);
  dateAndTime.setLanguage(code);
  invoiceStatus.setLanguage(code);
  strings.setLanguage(code);
  notificationType.setLanguage(code);
  organizationCosts.setLanguage(code);
  paymentMethod.setLanguage(code);
  paymentType.setLanguage(code);
  petGenders.setLanguage(code);
  petSpecies.setLanguage(code);
  petTypes.setLanguage(code);
  policyType.setLanguage(code);
  prescriptionCancellationReasons.setLanguage(code);
  prescriptionIntakes.setLanguage(code);
  prescriptionMethods.setLanguage(code);
  prescriptionTreatmentPurpose.setLanguage(code);
  prescriptionWaitingPeriodType.setLanguage(code);
  quantityUnits.setLanguage(code);
  reminders.setLanguage(code);
  reservationStates.setLanguage(code);
  roles.setLanguage(code);
  roundingStrategy.setLanguage(code);
  statisticsItems.setLanguage(code);
  symptoms.setLanguage(code);
  siteManagerPermissions.setLanguage(code);
  targetGroup.setLanguage(code);
  userAnalyticsEventType.setLanguage(code);
  vatCalculationStrategy.setLanguage(code);
  videoChatRoomEventType.setLanguage(code);
}

setLocale(findLocale());

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function getStringFromEnumsOrReturn(str: any): string {
  if (businessStructures[str] !== undefined) return businessStructures[str];
  if (chargeStates[str] !== undefined) return chargeStates[str];
  if (clinicCosts[str] !== undefined) return clinicCosts[str];
  if (clinicFeeUnits[str] !== undefined) return clinicFeeUnits[str];
  if (chronicDiseases[str] !== undefined) return chronicDiseases[str];
  if (clinicAnalyticsEventType[str] !== undefined) return clinicAnalyticsEventType[str];
  if (costAccountingStatus[str] !== undefined) return costAccountingStatus[str];
  if (invoiceStatus[str] !== undefined) return invoiceStatus[str];
  if (notificationType[str] !== undefined) return notificationType[str];
  if (organizationCosts[str] !== undefined) return organizationCosts[str];
  if (paymentMethod[str] !== undefined) return paymentMethod[str];
  if (paymentType[str] !== undefined) return paymentType[str];
  if (petGenders[str] !== undefined) return petGenders[str];
  if (petSpecies[str] !== undefined) return petSpecies[str];
  if (petTypes[str] !== undefined) return petTypes[str];
  if (policyType[str] !== undefined) return policyType[str];
  if (prescriptionCancellationReasons[str] !== undefined) return prescriptionCancellationReasons[str];
  if (prescriptionTreatmentPurpose[str] !== undefined) return prescriptionTreatmentPurpose[str];
  if (prescriptionWaitingPeriodType[str] !== undefined) return prescriptionWaitingPeriodType[str];
  if (quantityUnits[str] !== undefined) return quantityUnits[str];
  if (reminders[str] !== undefined) return reminders[str];
  if (reservationStates[str] !== undefined) return reservationStates[str];
  if (roles[str] !== undefined) return roles[str];
  if (roundingStrategy[str] !== undefined) return roundingStrategy[str];
  if (statisticsItems[str] !== undefined) return statisticsItems[str];
  if (symptoms[str] !== undefined) return symptoms[str];
  if (siteManagerPermissions[str] !== undefined) return siteManagerPermissions[str];
  if (targetGroup[str] !== undefined) return targetGroup[str];
  if (userAnalyticsEventType[str] !== undefined) return userAnalyticsEventType[str];
  if (vatCalculationStrategy[str] !== undefined) return vatCalculationStrategy[str];
  if (videoChatRoomEventType[str] !== undefined) return videoChatRoomEventType[str];

  return str;
}

export {
  breeds,
  businessStructures,
  chargeStates,
  clinicCosts,
  clinicFeeUnits,
  chronicDiseases,
  clinicAnalyticsEventType,
  costAccountingStatus,
  dateAndTime,
  invoiceStatus,
  getStringFromEnumsOrReturn,
  SupportedLanguages,
  DefaultLanguage,
  notificationType,
  organizationCosts,
  paymentMethod,
  paymentType,
  petGenders,
  petSpecies,
  petTypes,
  policyType,
  prescriptionCancellationReasons,
  prescriptionMethods,
  prescriptionMethodIds,
  prescriptionIntakes,
  prescriptionIntakeKeys,
  prescriptionTreatmentPurpose,
  prescriptionWaitingPeriodType,
  quantityUnits,
  reminders,
  reservationStates,
  roles,
  roundingStrategy,
  statisticsItems,
  symptoms,
  siteManagerPermissions,
  targetGroup,
  userAnalyticsEventType,
  vatCalculationStrategy,
  videoChatRoomEventType,
  setLocale,
  strings,
};
