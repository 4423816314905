/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { useFormContext } from "react-hook-form";
import { InventoryItemResponse } from "../../../models/inventory/item/Response/InventoryItemResponse";
import CheckBox from "../../../components/ReactHookFormFields/General/CheckBox";
import NumberFormatter from "../../../util/NumberFormatter";
import { calculateProfitMargin } from "../Details/InventoryItemDetails";
import { strings } from "../../../common/Strings/Strings";
import CostCalculator from "../../../util/CostCalculator";

interface Props {
  inventoryItem?: InventoryItemResponse;
}

const UpdateSalePriceCheckBox: React.FC<Props> = ({ inventoryItem }: Props) => {
  const { register, watch } = useFormContext();

  if (inventoryItem?.sellingPrice === null || inventoryItem?.sellingPrice === undefined) {
    return <></>;
  }

  if (inventoryItem?.acquisitionPrice === null || inventoryItem?.acquisitionPrice === undefined) {
    return <></>;
  }

  if(watch("acquisitionPrice") === inventoryItem?.acquisitionPrice){
    return <></>;
  }

  return (
    <CheckBox
      name="isUpdateSalePriceToo"
      label={strings.formatString(
        strings.updateSalePriceFromProfitMargin,
        NumberFormatter.format(calculateProfitMargin(inventoryItem.acquisitionPrice, inventoryItem.sellingPrice)),
        NumberFormatter.formatPrice(watch("newSalePrice") || 0, inventoryItem.sellingCurrency),
        NumberFormatter.formatPrice(
          CostCalculator.getGrossPrice(watch("newSalePrice") || 0, inventoryItem.sellingVat),
          inventoryItem.sellingCurrency
        )
      )}
      register={register}
    />
  );
};

export default UpdateSalePriceCheckBox;
