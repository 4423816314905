/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { useUser } from "../../../contexts/UserContext";
import Picture from "../Picture";

type Props = {
  border?: boolean;
  borderColor?: string;
  borderWidth?: string;
  loggedIn?: boolean;
  onCalculateAverageColor?: (color?: string | null) => void;
  pictureId?: string;
  userId?: string;
};

const UserProfilePicture: React.FC<Props> = ({
  border,
  borderColor,
  borderWidth,
  loggedIn,
  onCalculateAverageColor,
  pictureId,
  userId,
}: Props) => {
  const { user } = useUser();

  /* Currently logged-in user */
  if (loggedIn) {
    return (
      <Picture
        border={border}
        borderColor={borderColor}
        borderWidth={borderWidth}
        onCalculateAverageColor={onCalculateAverageColor}
        ownerId={user.userId}
        ownerType="user"
        pictureId={user.profilePicture}
      />
    );
  }

  /* User with given id */
  return (
    <Picture
      border={border}
      borderColor={borderColor}
      borderWidth={borderWidth}
      onCalculateAverageColor={onCalculateAverageColor}
      ownerId={userId}
      ownerType="user"
      pictureId={pictureId}
    />
  );
};

export default UserProfilePicture;
