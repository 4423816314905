/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { getGeneralError } from "../../../../util/helperFunctions";
import { strings } from "../../../../common/Strings/Strings";
import InvoiceInfos from "./InvoiceInfos";
import UrlTab from "../../../../components/Tabs/UrlTab";
import LocationIndicator from "../../../../components/Tabs/LocationIndicator";
import ChargeManagementApi from "../../../../api/ChargeManagementApi";
import { ClinicChargeResponse } from "../../../../models/charge/ClinicChargeResponse";

interface Props {
  chargeId?: string;
  setPageLoading: (isLoading: boolean) => void;
}

type ChargeDetailsTabKey = "basic";

const ChargeDetails: React.FC<Props> = (props: Props) => {
  const { chargeId, setPageLoading } = props;

  const [key, setKey] = useState<ChargeDetailsTabKey>("basic");
  const [charge, setCharge] = useState<ClinicChargeResponse>();
  const [error, setError] = useState<string | null>(null);
  const [reloadInvoice, setReloadInvoice] = useState<boolean>(false);

  useEffect(() => {
    const getInvoice = async () => {
      if (!chargeId) return;
      try {
        setPageLoading(true);
        const response = await ChargeManagementApi.getClinicCharge(chargeId);
        setCharge(response.data);
        setError(null);
        setPageLoading(false);
      } catch (e) {
        setError(await getGeneralError(e));
        setPageLoading(false);
      }
    };

    void getInvoice();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargeId, reloadInvoice]);

  return (
    <main className="main-signed-in">
      <section>
        <div className="px-4 lg:px-6 py-6 space-y-6">
          <div>
            <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
              {strings.clinicChargeDetails}
            </h1>
          </div>
          <div className="border border-gray-200 rounded-md bg-gray-50 !p-4 text-center text-md font-medium text-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-300">
            <ul className="flex flex-wrap -mb-px">
              <UrlTab useBaseUrlOnly currentTabKey={key} tabKey="basic" title={strings.invoice} />
            </ul>
          </div>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <LocationIndicator
                    location="basic"
                    setLocation={(location: ChargeDetailsTabKey) => {
                      setKey(location);
                    }}
                  />
                  <div>
                    {charge && (
                      <InvoiceInfos
                        charge={charge}
                        error={error}
                        reload={() => {
                          setReloadInvoice(!reloadInvoice);
                        }}
                        setError={setError}
                        onLoad={(loading) => setPageLoading(loading)}
                      />
                    )}
                  </div>
                </>
              }
            />
          </Routes>
        </div>
      </section>
    </main>
  );
};

export default ChargeDetails;
