/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UserPetApi from "../../../../api/UserPetApi";
import { strings } from "../../../../common/Strings/Strings";
import { Loader } from "../../../../components/Loader";
import { UserPetResponse } from "../../../../models/pet/UserPetResponse";
import { useChosenRole } from "../../../Main/Onboarding/ChooseRole";
import PetSearch, { SpeciesFilter } from "./PetSearch";
import UserPetList from "./UserPetList";
import { petIcon } from "../../../../assets/AssetConfig";
import PendingPets from "./PendingPets/PendingPets";
import Button from "../../../../components/Button";
import { PlusIcon } from "../../../../common/Icons/PlusIcon";
import { getGeneralError } from "../../../../util/helperFunctions";
import AlertBox from "../../../../components/AlertBox";

interface Props {
  setPageLoading: (isLoading: boolean) => void;
}

const MyPetsPage: React.FC<Props> = ({ setPageLoading }: Props) => {
  const [myPets, setMyPets] = useState<UserPetResponse[]>([]);
  const [filteredPets, setFilteredPets] = useState<UserPetResponse[]>([]);
  const [reloadMyPets, setReloadMyPets] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useChosenRole("owner");

  useEffect(() => {
    const getMyPets = async () => {
      try {
        const response = await UserPetApi.getUserPets();
        setMyPets(response.data);
        setFilteredPets(response.data);
        setPageLoading(false);
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setPageLoading(false);
      }
    };

    void getMyPets();
  }, [reloadMyPets, setPageLoading]);

  const applyFilters = (speciesFilters?: SpeciesFilter, query?: string) => {
    const newList = myPets.filter(({ userPetDetails }: UserPetResponse) => {
      const isName = query ? userPetDetails.name.toLowerCase().includes(query) : true;
      const isSpecies = speciesFilters
        ? speciesFilters.includes("all") || speciesFilters.includes(userPetDetails.species)
        : true;

      return isName && isSpecies;
    });

    setFilteredPets(newList);
  };

  return (
    <main className="main-signed-in">
      <section>
        <div className="px-4 lg:px-6 py-6 space-y-6">
          <AlertBox message={error} />
          <PendingPets
            limitResults={3}
            myPets={myPets}
            reloadMyPets={() => {
              setReloadMyPets((prevstate) => !prevstate);
            }}
          />
          {myPets.length === 0 ? (
            <div className="flex flex-col items-center justify-center space-y-6 mx-auto px-4 md:px-0 py-10 md:py-14">
              <div className="w-full md:max-w-md md:tw-card space-y-6 md:p-8 text-center">
                <div>
                  <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
                    {strings.addFirstPet}
                  </h1>
                </div>
                <div>
                  <p>{strings.noPetYet}</p>
                </div>
                <div className="flex items-center justify-center">
                  <div className="relative">
                    <img src={petIcon} alt="pet" />
                    <Link to="/add-new-pet">
                      <Button variant="circle-primary" className="absolute bottom-0 right-0 p-4">
                        <PlusIcon />
                        <span className="sr-only">Plus</span>
                      </Button>
                    </Link>
                  </div>
                </div>
                <div>
                  <Link to="/add-new-pet">
                    <Button variant="primary" fullWidth>
                      {strings.addFirstPet}
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <div className="space-y-6">
              <div className="flex justify-between items-center">
                <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">{`${strings.myPets} (${myPets.length})`}</h1>
                <div className="ml-auto">
                  <Link to="/add-new-pet">
                    <Button variant="primary" minW>
                      {strings.addPet}
                    </Button>
                  </Link>
                </div>
              </div>
              <div className="flex items-end flex-wrap">
                <PetSearch returnFilters={applyFilters} />
              </div>
              <UserPetList userPets={filteredPets} onError={(e) => setError(e)} onLoad={setPageLoading} />
            </div>
          )}
        </div>
      </section>
    </main>
  );
};

export default Loader(MyPetsPage);
