/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import moment from "moment";
import PageableTable from "../../../components/PageableTable/PageableTable";
import PageableTableHead from "../../../components/PageableTable/PageableTableHead";
import PageableTableBody from "../../../components/PageableTable/PageableTableBody";
import UserProfilePicture from "../../../components/Pictures/User/UserProfilePicture";
import MediaDevicesState from "./MediaDevicesState";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import LoaderInline from "../../../components/LoaderInline";
import { PagerState } from "../../../hooks/hooks";
import { VideoChatUser } from "../../../models/videoConsultation/VideoChatUser";
import { VideoChatRoom } from "../../../models/videoConsultation/VideoChatRooms";
import { dateAndTime, strings } from "../../../common/Strings/Strings";
import { calculateDurationDate } from "../../VideoConsultation/Utils";
import RoleBadge from "../../VideoConsultation/RoleBadge";
import { Signal } from "../../../common/Icons/Signal";
import { SignalOff } from "../../../common/Icons/SignalOff";
import Tooltip from "../../../components/Tooltip";
import { Desktop } from "../../../common/Icons/Desktop";
import { Phone } from "../../../common/Icons/Phone";
import { Tablet } from "../../../common/Icons/Tablet";

const getLatencyIcon = (ping?: number | null) => {
  if (ping === undefined || ping == null) {
    return null;
  }

  if (ping < 50) {
    // Excellent
    return <Signal className="flex-shrink-0 w-6 h-6 text-green-600 dark:text-green-500" />;
  }
  if (ping < 150) {
    // Medium
    return <Signal className="flex-shrink-0 w-6 h-6 text-yellow-600 dark:text-yellow-500" />;
  }
  if (ping < 500) {
    // Poor
    return <Signal className="flex-shrink-0 w-6 h-6 text-red-600 dark:text-red-500" />;
  }
  if (ping >= 500) {
    // Very poor
    return <SignalOff className="flex-shrink-0 w-6 h-6 text-red-600 dark:text-red-500" />;
  }
  return <Signal className="flex-shrink-0 w-6 h-6 text-green-600 dark:text-green-500" />;
};

type DeviceType = "Mobile" | "Tablet" | "Desktop";

const detectDeviceType = (userAgent: string): DeviceType => {
  if (/mobile/i.test(userAgent)) {
    return "Mobile";
  }
  if (/tablet|ipad|playbook|silk/i.test(userAgent)) {
    return "Tablet";
  }
  return "Desktop";
};

const getDeviceTypeString = (userAgent?: string | null) => {
  if (!userAgent) {
    return null;
  }

  const deviceType = detectDeviceType(userAgent);

  if (deviceType === "Mobile") {
    return strings.mobile;
  }
  if (deviceType === "Tablet") {
    return strings.tablet;
  }

  return strings.desktop;
};

const getDeviceIcon = (userAgent?: string | null) => {
  if (!userAgent) {
    return "?";
  }

  const deviceType = detectDeviceType(userAgent);

  if (deviceType === "Mobile") {
    return (
      <Tooltip content={userAgent}>
        <Phone className="flex-shrink-0 w-6 h-6" variant="outline" />
      </Tooltip>
    );
  }

  if (deviceType === "Tablet") {
    return (
      <Tooltip content={userAgent}>
        <Tablet className="flex-shrink-0 w-6 h-6" variant="outline" />
      </Tooltip>
    );
  }

  return (
    <Tooltip content={userAgent}>
      <Desktop className="flex-shrink-0 w-6 h-6" variant="outline" />
    </Tooltip>
  );
};

interface Props {
  room: VideoChatRoom;
  pager: PagerState;
}

const LiveUserInfo: React.FC<Props> = ({ room, pager }: Props) => {
  const renderUserRow = (user: VideoChatUser) => (
    <tr key={user.id}>
      <td className="px-4 py-4 text-sm whitespace-nowrap">
        <div className="flex flex-row items-center">
          <div className="w-10 h-10 mr-3">
            <UserProfilePicture userId={user.id} />
          </div>
          <p>{user.name}</p>
        </div>
      </td>
      <td className="px-4 py-4 text-sm whitespace-nowrap">
        <div className="flex flex-row justify-start items-center gap-2">
          {user.roles.map((role) => (
            <RoleBadge role={role} />
          ))}
        </div>
      </td>
      <td className="px-4 py-4 text-sm whitespace-nowrap">
        {user.joined ? moment(user.joined).format(dateAndTime.momentDateTimeWithSecondsFormat) : "-"}
      </td>
      <td className="px-4 py-4 text-sm whitespace-nowrap">{user.joined ? calculateDurationDate(user.joined) : "-"}</td>
      <td className="px-4 py-4 text-sm whitespace-nowrap">
        <MediaDevicesState
          camEnabled={user.mediaConfig?.camEnabled || false}
          camMissing={user.mediaConfig?.camMissing || false}
          camError={user.mediaConfig?.camError || false}
          micEnabled={user.mediaConfig?.micEnabled || false}
          micMissing={user.mediaConfig?.micMissing || false}
          micError={user.mediaConfig?.micError || false}
        />
      </td>
      <td className="px-4 py-4 text-sm whitespace-nowrap">
        <div className="flex flex-row justify-start items-center gap-2">
          <span>{getLatencyIcon(user.ping)}</span>
          <span>
            {user.ping ?? "?"} {strings.ms}
          </span>
        </div>
      </td>
      <td className="px-4 py-4 text-sm whitespace-nowrap">
        <div className="flex flex-row justify-start items-center gap-2">
          <span>{getDeviceIcon(user.userAgent)}</span>
          <span>{getDeviceTypeString(user.userAgent)}</span>
        </div>
      </td>
    </tr>
  );

  const renderAlertBox = () => (
    <AlertBox
      closeAble={false}
      message={
        <div className="flex flex-row gap-2">
          <p>{strings.roomIsBeingDeleted}</p>
          <LoaderInline />
        </div>
      }
      type={AlertType.INFO}
    />
  );

  return (
    <>
      {room.isEndingCall && renderAlertBox()}

      <PageableTable content={room.users} hidden={room.users.elements.length === 0} isLoading={false} pager={pager}>
        <PageableTableHead>
          <tr>
            <th className="text-left tw-th">{strings.videoChatUser}</th>
            <th className="text-left tw-th">{strings.roles}</th>
            <th className="text-left tw-th">{strings.latestJoinDate}</th>
            <th className="text-left tw-th">{strings.timeSinceLatestJoin}</th>
            <th className="text-left tw-th">{strings.stateOfMediaDevices}</th>
            <th className="text-left tw-th">{strings.latency}</th>
            <th className="text-left tw-th">{strings.device}</th>
          </tr>
        </PageableTableHead>
        <PageableTableBody>{room.users.elements.map(renderUserRow)}</PageableTableBody>
      </PageableTable>
    </>
  );
};

export default LiveUserInfo;
