/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import moment from "moment";
import { InventoryItemUsageResponse } from "../../../../../models/inventory/item/Response/InventoryItemUsageResponse";
import { quantityUnits, strings } from "../../../../../common/Strings/Strings";
import { InventoryItemUnit } from "../../../../../models/inventory/item/InventoryItemUnit";
import { CountryDetailsResponse } from "../../../../../models/management/CountryDetailsResponse";
import PriceWithVat from "../../../../../components/PriceWithVat";
import MedicalRecordButtons from "../../../../../components/MedicalRecordActions/MedicalRecordButtons";

interface Props {
  acquisitionUnit?: InventoryItemUnit;
  countryDetails?: CountryDetailsResponse;
  usage: InventoryItemUsageResponse | any;
}

const UsageRow: React.FC<Props> = ({
  acquisitionUnit,
  countryDetails,
  usage,
}) => (
    <div className="p-3 grow grid xl:grid-cols-4 gap-4" key={`usage-${usage.id}`}>
      <div>
        <div className="uppercase text-sm">{strings.usageHistoryUnit}</div>
        <div className="font-bold">{moment(usage.dateTime).format("LL")}</div>
      </div>
      <div className="hidden xl:block" />
      <div className="flex space-x-6 flex-wrap">
        <div>
          <div className="text-sm">{strings.salePrice} ({strings.unitPrice.toLowerCase()}):</div>
          <PriceWithVat
            countryDetails={countryDetails}
            netPrice={usage.sellingPrice ? usage.sellingPrice / usage.quantity : 0}
            vat={usage.sellingVat}
          />
        </div>
        <div>
          <div className="text-sm">{strings.salePrice} ({strings.subtotal.toLowerCase()}):</div>
          <PriceWithVat
            countryDetails={countryDetails}
            netPrice={usage.sellingPrice}
            vat={usage.sellingVat}
          />
        </div>
      </div>
      <div className="font-bold flex items-center">
        {usage.quantity * -1} {quantityUnits[acquisitionUnit || ""]}
        <div className="ml-auto">
          <MedicalRecordButtons
            lastMedicalRecordId={usage.medicalRecordId}
          />
        </div>
      </div>
    </div>
  );

export default UsageRow;
