/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useCallback, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import UnauthenticatedDropdown from "./UnauthenticatedDropdown";
import { TailwindResponsiveBreakpoints } from "../../../../util/TailwindResponsiveBreakpoints";
import { simpleLightText } from "../../../../assets/AssetConfig";
import Button from "../../../../components/Button";
import { strings } from "../../../Strings/Strings";
import { Bars3 } from "../../../Icons/Bars3";
import ChooseLanguageMenu from "./ChooseLanguageMenu";

const NavUnauthenticated: React.FC = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prevState) => !prevState);
  };

  const handleResize = useCallback(() => {
    const isMobile = window.innerWidth < TailwindResponsiveBreakpoints.lg;

    if (!isMobile) {
      setIsMobileMenuOpen(false);
    }
  }, []);

  useLayoutEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return (
    <>
      <nav className="fixed z-30 top-0 left-0 w-full h-14 px-4 lg:px-6 flex items-center bg-slate-500 dark:bg-gray-800 dark:border-b dark:border-gray-700">
        <div className="relative container flex flex-wrap items-center justify-between mx-auto px-0">
          <a className="h-14 flex items-center no-underline mr-auto" href="https://globalvet.com">
            <img alt="GlobalVet" className="w-24" src={simpleLightText} />
          </a>
          <div className="flex flex-wrap items-center space-x-4 lg:space-x-5 lg:order-2">
            <Link to="/registration">
              <Button className="min-w-0 lg:mr-2">{strings.signUp}</Button>
            </Link>
            <div className="w-full lg:block lg:w-auto hidden">
              <ul className="lg:relative flex lg:items-center flex-col lg:flex-row lg:space-x-3 lg:space-x-6 lg:text-md lg:font-medium h-full">
                <li>
                  <Link
                    className="block py-3 text-white transition-all duration-300 link font-normal no-underline"
                    to="/login"
                  >
                    {strings.signIn}
                  </Link>
                </li>
                <li>
                  <ChooseLanguageMenu />
                </li>
              </ul>
            </div>
          </div>
          <button
            className="rounded-lg inline-flex lg:hidden items-center p-2 text-sm text-white hover:bg-slate-600 focus:bg-slate-600 focus:outline-none dark:text-white dark:hover:bg-slate-700 dark:focus:bg-slate-700 transition-all duration-300 ml-3"
            onClick={() => toggleMobileMenu()}
            type="button"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3 />
          </button>
        </div>
      </nav>
      <UnauthenticatedDropdown close={() => setIsMobileMenuOpen(false)} isOpen={isMobileMenuOpen} />
    </>
  );
};

export default NavUnauthenticated;
