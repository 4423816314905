/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getGeneralError } from "../../../util/helperFunctions";
import { NewPetRequest } from "../../../models/pet/NewPetRequest";
import UserPetApi from "../../../api/UserPetApi";
import { ChronicDiseaseOption } from "../../../common/ChronicDiseases/ChronicDiseases";
import ClinicPetApi from "../../../api/ClinicPetApi";
import PetOwnerApi from "../../../api/PetOwnerApi";
import { PersonDetailsResponse } from "../../../models/contact/PersonDetailsResponse";
import { PetFormProps } from "./PetForm";
import { addNewPetPlaceholder } from "../../../assets/AssetConfig";
import TabbedPetForm from "./TabbedPetForm";

const AddNewPet: React.FC = () => {
  const { clinicPetOwnerId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPetOwnerLoading, setIsPetOwnerLoading] = useState<boolean>(false);
  const [ownerPictureUserId, setOwnerPictureUserId] = useState<string>();
  const [error, setError] = useState<string | null>(null);
  const [petOwner, setPetOwner] = useState<PersonDetailsResponse>();
  const navigate = useNavigate();

  const fetchPetOwner = async (id?: string) => {
    if (id) {
      setIsPetOwnerLoading(true);
      try {
        const resp = await PetOwnerApi.getPetOwner(id);
        setPetOwner(resp.data.petOwner.petOwnerDetails);
        setOwnerPictureUserId(resp.data.petOwner?.userDetails?.id);
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setIsPetOwnerLoading(false);
      }
    }
  };

  useEffect(() => {
    void fetchPetOwner(clinicPetOwnerId);
  }, [clinicPetOwnerId]);

  const postPet = async (pet: PetFormProps) => {
    setIsLoading(true);

    const { species: s, breed: b, dateOfBirth, dateOfBirthType, insuranceCompany, chronicDiseases, ...rest } = pet;

    if (s.length === 1 && b.length === 1) {
      const request: NewPetRequest = {
        species: s[0].value,
        breed: b[0].name,
        dateOfBirth: dateOfBirthType !== "unknown" ? dateOfBirth : undefined,
        isDateOfBirthEstimated: dateOfBirthType === "estimated",
        insuranceCompanyId: insuranceCompany?.length === 1 ? insuranceCompany[0].id : undefined,
        chronicDiseases: chronicDiseases?.map((disease: ChronicDiseaseOption) => disease.id) || [],
        ...rest,
      };

      if (clinicPetOwnerId) {
        try {
          await ClinicPetApi.createClinicPet(clinicPetOwnerId, request);
          setIsLoading(false);
          navigate(`/pet-owner-profile/${clinicPetOwnerId}`);
        } catch (err) {
          setIsLoading(false);
          setError(await getGeneralError(err));
        }
      } else {
        try {
          const resp = await UserPetApi.createUserPet(request);
          setIsLoading(false);
          navigate(`/pet-profile/${resp.data.userPetDetails.id}`);
        } catch (err) {
          setIsLoading(false);
          setError(await getGeneralError(err));
        }
      }
    }
  };

  return (
    <main className="main-signed-in">
      <section>
        <div className="mx-auto flex flex-col items-center justify-center space-y-6 px-4 py-10 lg:px-0 lg:py-14">
          <div className="w-full space-x-0 space-y-10 lg:grid lg:w-auto lg:grid-cols-2 lg:space-x-6 lg:space-y-0">
            <div>
              <TabbedPetForm
                error={error}
                isLoading={isLoading}
                isPetOwnerLoading={isPetOwnerLoading}
                onSubmit={postPet}
                ownerPictureUserId={ownerPictureUserId}
                petOwner={petOwner}
              />
            </div>
            <div className="flex flex-col w-full items-center justify-center lg:w-aut">
              <img src={addNewPetPlaceholder} className="w-96" alt="Add new pet" />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default AddNewPet;
