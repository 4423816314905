/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PetOwnerResponse } from "../../../models/pet/PetOwnerResponse";
import PetOwnerApi from "../../../api/PetOwnerApi";
import { getGeneralError } from "../../../util/helperFunctions";
import BeforeDeleteModal from "../../../components/Modals/BeforeDeleteModal";
import Button from "../../../components/Button";
import { Trash } from "../../../common/Icons/Trash";

interface Props {
  owner: PetOwnerResponse;
  onError: (error: string | null) => void;
  onLoad: (loading: boolean) => void;
}

const PetOwnerActions = (props: Props): ReactElement => {
  const { owner, onError, onLoad } = props;
  const [isDeleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const navigate = useNavigate();

  const deleteOwner = async () => {
    try {
      onLoad(true);
      onError(null);
      setDeleteModalVisible(false);
      await PetOwnerApi.archivePetOwner(owner.petOwnerDetails.id);
      navigate("/owners");
    } catch (e) {
      onError(await getGeneralError(e));
    } finally {
      onLoad(false);
    }
  };

  return (
    <>
      <Button
        variant="icon"
        onClick={() => {
          setDeleteModalVisible(true);
        }}
      >
        <Trash />
      </Button>
      <BeforeDeleteModal
        open={isDeleteModalVisible}
        onHide={() => setDeleteModalVisible(false)}
        returnYes={deleteOwner}
      />
    </>
  );
};

export default PetOwnerActions;
