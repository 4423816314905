/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useRef, useState } from "react";
import { strings } from "../../../common/Strings/Strings";
import { useDebouncedState } from "../../../hooks/hooks";
import { HighlightedText } from "../../../util/StringUtils";
import LoaderInline from "../../../components/LoaderInline";
import EmptyListText from "../../../components/EmptyListText";
import ClickOutsideDetector from "../../../components/ClickOutsideDetector";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import { MagnifyingGlass } from "../../../common/Icons/MagnifyingGlass";
import { UserManagementItemResponse } from "../../../models/management/UserManagementItemResponse";
import UserManagementApi from "../../../api/UserManagementApi";
import UserProfilePicture from "../../../components/Pictures/User/UserProfilePicture";
import { getGeneralError } from "../../../util/helperFunctions";

interface Props {
  onSelect: (user: UserManagementItemResponse) => void;
  onError: (err: any) => void;
}

const UserSearchInput: React.FC<Props> = ({ onSelect, onError }: Props) => {
  const [isDown, setIsDown] = useState<boolean>(false);
  const [searchResult, setSearchResult] = useState<UserManagementItemResponse[]>([]);
  const { value: searchText, setValue: setSearchText } = useDebouncedState<string>("", 400);
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const searchChange = ({ target: { value } }: any) => {
    setSearchResult([]);
    setSearchText(value);
  };

  useEffect(() => {
    const findUsers = async () => {
      setIsSearchLoading(true);
      setIsDown(true);
      try {
        const response = await UserManagementApi.findUsers({
          pageNumber: 0,
          pageSize: 5,
          query: searchText,
        });
        setSearchResult(response.data.elements);
      } catch (err: any) {
        onError(await getGeneralError(err));
      } finally {
        setIsSearchLoading(false);
      }
    };

    if (searchText.length >= 3) {
      void findUsers();
    }
  }, [searchText]);

  return (
    <ClickOutsideDetector
      listen
      onClickOutside={() => {
        setIsDown(false);
      }}
    >
      <div className="dropdown">
        <div className="relative w-full">
          <label htmlFor="search">{strings.users}</label>
          <div className="relative w-full">
            <button
              className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
              style={{ zIndex: 0 }}
              type="button"
            >
              {isSearchLoading ? (
                <LoaderInline />
              ) : (
                <MagnifyingGlass className="w-5 h-5 text-gray-500 dark:text-gray-400" />
              )}
            </button>
            <input
              autoComplete={AutoCompleteOptions.off}
              className="tw-input pl-10"
              name="search"
              onChange={searchChange}
              ref={inputRef}
              onClick={() => {
                if (searchResult.length > 0 && !isDown) {
                  setIsDown(true);
                }
              }}
              placeholder={strings.search}
              type="text"
            />
          </div>
        </div>
        <ul
          className={`dropdown-menu w-full ${isDown ? "show" : ""}`}
          style={{
            maxHeight: "300px",
            minWidth: 0,
            padding: 0,
            overflowY: "scroll",
          }}
        >
          <div className="divide-y dark:divide-gray-900 text-sm">
            {isSearchLoading ? (
              <div className="px-4 py-3">
                <LoaderInline />
              </div>
            ) : (
              <></>
            )}
            {isSearchLoading ? (
              <></>
            ) : (
              searchResult.map((user: UserManagementItemResponse, index: number) => (
                <div className="px-4 py-3" key={index}>
                  <div
                    className="cursor-pointer mb-1 space-y-2"
                    onClick={() => {
                      onSelect(user);
                      setIsDown(false);
                      if (inputRef.current) {
                        inputRef.current.value = "";
                      }
                      setSearchResult([]);
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <div className="flex flex-wrap items-center space-x-2">
                      <div>
                        <div style={{ width: "30px", height: "30px" }}>
                          <UserProfilePicture userId={user.userId} />
                        </div>
                      </div>
                      <HighlightedText text={user.fullName} highlights={searchText.split(" ")} />
                    </div>
                    <div>
                      {user.email && <HighlightedText text={user.email.value} highlights={searchText.split(" ")} />}
                    </div>
                    <div>
                      {user.phone && <HighlightedText text={user.phone.value} highlights={searchText.split(" ")} />}
                    </div>
                  </div>
                </div>
              ))
            )}
            {searchResult.length === 0 && !isSearchLoading && searchText.length > 0 ? (
              <div className="p-4">
                <EmptyListText />
              </div>
            ) : null}
          </div>
        </ul>
      </div>
    </ClickOutsideDetector>
  );
};

export default UserSearchInput;
