/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { MutableRefObject, useEffect, useRef } from "react";
import logger from "../pages/VideoConsultation/Logger";

/**
 * Hook to listen for the end of audio or video tracks in a media stream.
 *
 * @param {MutableRefObject<HTMLVideoElement | null>} videoRef - A ref to the HTMLVideoElement whose media tracks will be observed.
 * @param {(trackType: "audio" | "video") => void} onTrackEnded - Callback function to handle the "ended" event of a media track.
 */
const useTrackStateListener = (
  videoRef: MutableRefObject<HTMLVideoElement | null>,
  onTrackEnded: (trackType: "audio" | "video") => void
) => {
  const trackStateListenersAdded = useRef(false);

  // Use WeakMap to store track-to-listener associations
  const trackListeners = useRef(new WeakMap<MediaStreamTrack, () => void>());

  useEffect(() => {
    const videoElement = videoRef.current;

    if (!videoElement || trackStateListenersAdded.current) return undefined;

    const addTrackListeners = (tracks: MediaStreamTrack[], trackType: "audio" | "video") => {
      tracks.forEach((track) => {
        const handleEnded = () => {
          logger.warn(`[📹VideoChat] ${trackType === "audio" ? "Audio" : "Video"} track ended!`);
          onTrackEnded(trackType);
        };
        track.addEventListener("ended", handleEnded);
        trackListeners.current.set(track, handleEnded); // Store the listener in WeakMap
      });
    };

    const removeTrackListeners = (tracks: MediaStreamTrack[]) => {
      tracks.forEach((track) => {
        const listener = trackListeners.current.get(track);
        if (listener) {
          track.removeEventListener("ended", listener);
          trackListeners.current.delete(track); // Remove reference from WeakMap
        }
      });
    };

    const mediaStream = videoElement.srcObject as MediaStream | null;
    if (mediaStream) {
      const audioTracks = mediaStream.getAudioTracks();
      const videoTracks = mediaStream.getVideoTracks();

      addTrackListeners(audioTracks, "audio");
      addTrackListeners(videoTracks, "video");

      trackStateListenersAdded.current = true;
    }

    return () => {
      if (mediaStream) {
        removeTrackListeners(mediaStream.getAudioTracks());
        removeTrackListeners(mediaStream.getVideoTracks());
      }
      trackStateListenersAdded.current = false;
    };
  }, [videoRef, onTrackEnded]);
};

export default useTrackStateListener;
