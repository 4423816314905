/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { axiosCommon, axiosPublic } from "../util/axiosSetup";
import { ApiPromise } from "../models/ApiPromise";
import { VersionInfo } from "../models/version/VersionInfo";

export type VersionedService =
  | "auth-service"
  | "clinic-service"
  | "geo-service"
  | "pharmacy-service"
  | "prescription-service"
  | "szamlazzhu-service"
  | "vara-service"
  | "frontend";

const publicVersionEndpoints: VersionedService[] = [
  "auth-service",
  "prescription-service",
  "szamlazzhu-service",
  "frontend",
];

export const serviceHasPublicVersionEndpoint = (service: VersionedService) => publicVersionEndpoints.includes(service);

export default class VersionApi {
  // Retrieves the version info of a service from the public endpoint
  static getPublicVersionInfo(baseUrl: string): ApiPromise<VersionInfo> {
    const url = `${baseUrl}/api/versions`;
    return axiosPublic.get(url);
  }

  // Retrieves the version info of a service, but it's not public
  static getVersionInfo(baseUrl: string): ApiPromise<VersionInfo> {
    const url = `${baseUrl}/api/versions`;

    // Get the version info, but do not trigger a new access token request if the response is 401 Unauthorized
    // If the auth server is run locally and a local JWKS is used this would otherwise cause a problem (like an infinite loop)
    return axiosCommon.get(url, { headers: { "X-Ignore-Unauthorized": "true" } });
  }
}
