/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getStringFromEnumsOrReturn, strings } from "../../../../common/Strings/Strings";
import { getGeneralError } from "../../../../util/helperFunctions";
import UserApi from "../../../../api/UserApi";
import { useUser } from "../../../../contexts/UserContext";
import { UserResponse } from "../../../../models/user/UserResponse";
import AlertBox, { AlertType } from "../../../../components/AlertBox";
import Button from "../../../../components/Button";
import { Card } from "../../../../components/Cards/Card";
import { NotificationType } from "../../../../models/notification/NotificationType";
import { DeliveryType } from "../../../../models/notification/DeliveryType";
import { NotificationSettingsItem } from "../../../../models/user/NotificationSettingsItem";
import CheckBox from "../../../../components/ReactHookFormFields/General/CheckBox";

export interface NotificationSettings {
  email: boolean;
  inApp: boolean;
  push: boolean;
  sms: boolean;
}

export type NotificationSettingsForm = {
  [key in NotificationType]?: NotificationSettings;
};

const defaultFormValues = (user: UserResponse) => {
  const defaultValues: NotificationSettingsForm = {};

  Object.values(NotificationType).forEach((type) => {
    const deliveryTypes = user.notificationSettings.find((i) => i.type == type)?.deliveryTypes || [];

    defaultValues[type] = {
      email: deliveryTypes.includes(DeliveryType.EMAIL),
      inApp: deliveryTypes.includes(DeliveryType.IN_APP),
      push: deliveryTypes.includes(DeliveryType.PUSH),
      sms: deliveryTypes.includes(DeliveryType.SMS),
    };
  });

  return defaultValues;
};

const NotificationsTab: React.FC = () => {
  const { user, setUser } = useUser();

  const [alertMessage, setAlertMessage] = useState<string | null>();
  const [alertType, setAlertType] = useState<AlertType>(AlertType.ERROR);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    reset,
    register,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useForm<NotificationSettingsForm>({
    mode: "onChange",
    defaultValues: defaultFormValues(user),
  });

  useEffect(() => {
    reset(defaultFormValues(user));
  }, [reset, user]);

  const updateNotificationSettings = async (formData: NotificationSettingsForm) => {
    setLoading(true);
    try {
      const notificationSettings: NotificationSettingsItem[] = [];
      Object.values(NotificationType).forEach((type) => {
        const deliveryTypes: DeliveryType[] = [];
        if (formData[type]?.email) deliveryTypes.push(DeliveryType.EMAIL);
        if (formData[type]?.inApp) deliveryTypes.push(DeliveryType.IN_APP);
        if (formData[type]?.push) deliveryTypes.push(DeliveryType.PUSH);
        if (formData[type]?.sms) deliveryTypes.push(DeliveryType.SMS);
        notificationSettings.push({ type, deliveryTypes });
      });

      const request = {
        notificationSettings,
      };
      const response = await UserApi.updateNotificationSettings(request);
      setUser(response.data);
      setAlertMessage(strings.savedSuccessfully);
      setAlertType(AlertType.SUCCESS);
    } catch (e) {
      setAlertMessage(await getGeneralError(e));
      setAlertType(AlertType.ERROR);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card size="lg" type="simple" title={strings.notifications}>
      <form className="space-y-6" onSubmit={handleSubmit(updateNotificationSettings)}>
        <div className="tw-table-container">
          <table className="tw-table">
            <thead className="tw-thead">
              <tr>
                <th className="tw-th">{strings.notification}</th>
                <th className="tw-th text-center">{strings.notificationSettingsEmail}</th>
                <th className="tw-th text-center">{strings.notificationSettingsInApp}</th>
                <th className="tw-th text-center">{strings.notificationSettingsPush}</th>
                <th className="tw-th text-center">{strings.notificationSettingsSms}</th>
              </tr>
            </thead>
            <tbody className="tw-tbody">
              {Object.values(NotificationType).map((notificationType) => (
                <tr key={notificationType}>
                  <td className="px-4 py-3 text-sm font-medium">{getStringFromEnumsOrReturn(notificationType)}</td>
                  <td className="px-4 py-3 text-center">
                    <CheckBox
                      name={`${notificationType}.email`}
                      register={register}
                      className="inline-block"
                      readOnly
                    />
                  </td>
                  <td className="px-4 py-3 text-center">
                    <CheckBox name={`${notificationType}.inApp`} register={register} className="inline-block" />
                  </td>
                  <td className="px-4 py-3 text-center">
                    <CheckBox name={`${notificationType}.push`} register={register} className="inline-block" />
                  </td>
                  <td className="px-4 py-3 text-center">
                    <CheckBox name={`${notificationType}.sms`} register={register} className="inline-block" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <AlertBox message={alertMessage} type={alertType} />
        <div>
          <Button disabled={!isValid || !isDirty} loading={loading} type="submit">
            {strings.save}
          </Button>
        </div>
      </form>
    </Card>
  );
};

export default NotificationsTab;
