/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../../../common/Strings/Strings";
import { canManageClinic, getGeneralError } from "../../../../util/helperFunctions";
import { ClinicResponse } from "../../../../models/clinic/ClinicResponse";
import ClinicApi from "../../../../api/ClinicApi";
import AlertBox, { AlertType } from "../../../../components/AlertBox";
import TimeSelect from "../../../../components/ReactHookFormFields/General/Select/TimeSelect";
import { getClinicUpdateDto } from "../ClinicSettings";
import Button from "../../../../components/Button";

interface Props {
  clinic: ClinicResponse;
  clinicId: string;
  setClinic: (modifiedClinic: ClinicResponse) => void;
}

interface CalendarSettingsForm {
  calendarDayStartTime: string;
  calendarDayEndTime: string;
}

const CalendarSettingsTab: React.FC<Props> = ({ clinic, clinicId, setClinic }: Props) => {
  const [alert, setAlert] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty, isValid },
  } = useForm<CalendarSettingsForm>({
    mode: "onChange",
    defaultValues: {
      calendarDayStartTime: "08:00",
      calendarDayEndTime: "18:00",
    },
  });

  useEffect(() => {
    if (clinic && clinic.calendarDayStartTime && clinic.calendarDayEndTime) {
      reset({
        calendarDayStartTime: clinic.calendarDayStartTime,
        calendarDayEndTime: clinic.calendarDayEndTime,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinic]);

  const saveCalendarTimes = async (calendarStartTime: string, calendarEndTime: string) => {
    setLoading(true);

    const clinicDto = getClinicUpdateDto(clinic);
    clinicDto.calendarDayStartTime = calendarStartTime;
    clinicDto.calendarDayEndTime = calendarEndTime;

    try {
      await ClinicApi.updateClinic(clinicId, clinicDto);
      setClinic({ ...clinic });
      setSuccess(strings.savedSuccessfully);
    } catch (err) {
      setAlert(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  const submit = (values: CalendarSettingsForm) => {
    void saveCalendarTimes(values.calendarDayStartTime, values.calendarDayEndTime);
  };

  const readOnly = !canManageClinic(clinic);

  return (
    <div className="tw-card divide-y divide-gray-200 dark:divide-gray-700">
      <div className="p-4">
        <p className="uppercase">{strings.calendarSettings}</p>
      </div>
      <div className="p-4">{strings.calendarSettingsToolTip}</div>
      <form className="p-4 space-y-4" onSubmit={handleSubmit(submit)}>
        <div className="flex items-end">
          <div className="mr-3">
            <TimeSelect
              className="mb-0"
              control={control}
              name="calendarDayStartTime"
              label={strings.startTime}
              required
              readOnly={readOnly}
            />
          </div>
          <TimeSelect
            className="mb-0"
            control={control}
            name="calendarDayEndTime"
            label={strings.endTime}
            required
            readOnly={readOnly}
          />
          <div className="ml-auto">
            <Button variant="primary" hidden={readOnly} type="submit" loading={loading} disabled={!isValid || !isDirty}>
              {strings.save}
            </Button>
          </div>
        </div>
        <AlertBox message={alert} className="mt-4" />
        <AlertBox message={success} className="mt-4" type={AlertType.SUCCESS} />
      </form>
    </div>
  );
};

export default CalendarSettingsTab;
