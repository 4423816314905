/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { FieldError } from "react-hook-form";
import moment from "moment";
import { dateAndTime, strings } from "../../../common/Strings/Strings";
import { Option } from "../../../models/Option";
import { Colors } from "../../../models/Colors";
import { getSimpleLanguage } from "../../../util/LocalStorageVariables";
import SimpleSelect from "../../InputFieldsSimple/SimpleSelect";
import { XMark } from "../../../common/Icons/XMark";
import Button from "../../Button";

type DateFormatOrder = "DMY" | "YMD" | "MDY";

interface Props {
  onChange(date: string | undefined): void;
  value: string;
  dateFormat?: DateFormatOrder;
  defaultDay?: number;
  defaultMonth?: number;
  defaultYear?: number;
  error?: FieldError;
  label?: string;
  name?: string;
  optional?: boolean;
  pet?: boolean;
  readOnly?: boolean;
  required?: boolean;
  showRequired?: boolean;
}

const BirthDateComponent: React.FC<Props> = ({
  onChange,
  value,
  dateFormat = dateAndTime.dateFormatOrder,
  defaultDay,
  defaultMonth,
  defaultYear,
  error,
  label,
  name = strings.dateOfBirth,
  optional,
  pet,
  readOnly,
  required,
  showRequired,
}: Props) => {
  const [yearsOptions, setYearsOptions] = useState<Option[]>([]);
  const [monthOptions, setMonthOptions] = useState<Option[]>([]);
  const [dayOptions, setDayOptions] = useState<Option[]>([]);

  const [selectedYear, setSelectedYear] = useState<number | undefined>();
  const [selectedMonth, setSelectedMonth] = useState<number | undefined>();
  const [selectedDay, setSelectedDay] = useState<number | undefined>();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [debouncedY, setDebouncedY] = useState<boolean>(false);
  const [debouncedM, setDebouncedM] = useState<boolean>(false);
  const [debouncedD, setDebouncedD] = useState<boolean>(false);

  useEffect(() => {
    if (value) {
      if (selectedDay !== moment(value).date()) {
        setSelectedDay(moment(value).date());
      }
      if (selectedMonth !== moment(value).month() + 1) {
        setSelectedMonth(moment(value).month() + 1);
      }
      if (selectedYear !== moment(value).year()) {
        setSelectedYear(moment(value).year());
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const update = (year?: number, month?: number, day?: number) => {
    setErrorMessage("");

    if (year && month && day) {
      const daysInMonth = moment().set("year", year).set("month", month).daysInMonth();

      if (daysInMonth < day) {
        setErrorMessage(strings.invalidDate);
      }

      const birthDate = moment()
        .set("year", year)
        .set("month", month - 1)
        .set("date", day);

      onChange(birthDate.format("YYYY-MM-DD"));
    }
  };

  useEffect(() => {
    if (defaultDay && !debouncedD) {
      setSelectedDay(defaultDay);
      setDebouncedD(true);
    }
    if (defaultMonth && !debouncedM) {
      setSelectedMonth(defaultMonth);
      setDebouncedM(true);
    }
    if (defaultYear && !debouncedY) {
      setSelectedYear(defaultYear);
      setDebouncedY(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultDay, defaultMonth, defaultYear]);

  useEffect(() => {
    setErrorMessage(error?.message || "");
  }, [error, value]);

  useEffect(() => {
    const currentYear = moment().year();
    const years: Option[] = [];
    const maxYear = pet ? currentYear : currentYear - 14;
    const minYear = currentYear - 110;

    for (let i = maxYear; i >= minYear; i -= 1) {
      years.push({ title: i.toString(), value: i });
    }
    setYearsOptions(years);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const months: Option[] = [];
    const lan = getSimpleLanguage() || "en";

    for (let i = 1; i < 13; i += 1) {
      months.push({
        title: moment()
          .locale(lan)
          .month(i - 1)
          .format("MMMM"),
        value: i,
      });
    }
    setMonthOptions(months);
  }, []);

  useEffect(() => {
    const days: Option[] = [];

    for (let i = 1; i <= 31; i += 1) {
      days.push({ title: i.toString(), value: i });
    }
    setDayOptions(days);
  }, []);

  const clearDate = () => {
    setSelectedYear(undefined);
    setSelectedMonth(undefined);
    setSelectedDay(undefined);

    onChange(undefined);
  };

  const yearElement = (
    <SimpleSelect
      className="mb-0"
      disabledChooseOptionTitle={strings.Year}
      error={error ? { type: "manual" } : undefined}
      labelOff
      name="year"
      onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
        const y = parseInt(event.target.value, 10);

        setSelectedYear(y);
        update(y, selectedMonth, selectedDay);
      }}
      options={yearsOptions}
      readOnly={readOnly}
      required={required}
      value={selectedYear}
    />
  );

  const monthElement = (
    <SimpleSelect
      className="mb-0"
      disabledChooseOptionTitle={strings.Month}
      error={error ? { type: "manual" } : undefined}
      labelOff
      name="month"
      onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
        const m = parseInt(event.target.value, 10);

        setSelectedMonth(m);
        update(selectedYear, m, selectedDay);
      }}
      options={monthOptions}
      readOnly={readOnly}
      required={required}
      value={selectedMonth}
    />
  );

  const dayElement = (
    <SimpleSelect
      className="mb-0"
      disabledChooseOptionTitle={strings.Day}
      error={error ? { type: "manual" } : undefined}
      labelOff
      name="day"
      onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
        const d = parseInt(event.target.value, 10);

        setSelectedDay(d);
        update(selectedYear, selectedMonth, d);
      }}
      options={dayOptions}
      readOnly={readOnly}
      required={required}
      value={selectedDay}
    />
  );

  const orderDateFormat = () => {
    switch (dateFormat) {
      case "DMY":
        return (
          <>
            <div className="flex-auto">{dayElement}</div>
            <div className="flex-auto">{monthElement}</div>
            <div className="flex-auto">{yearElement}</div>
          </>
        );
      case "MDY":
        return (
          <>
            <div className="flex-auto">{monthElement}</div>
            <div className="flex-auto">{dayElement}</div>
            <div className="flex-auto">{yearElement}</div>
          </>
        );
      default:
        return (
          <>
            <div className="flex-auto">{yearElement}</div>
            <div className="flex-auto">{monthElement}</div>
            <div className="flex-auto">{dayElement}</div>
          </>
        );
    }
  };

  return (
    <div>
      <div className="relative w-full">
        <label htmlFor={name}>
          {label}
          {showRequired ? " *" : ""}
          {optional ? <span style={{ color: Colors.INPUTBORDER }}>{` (${strings.optional})`}</span> : <></>}
        </label>
        <div className="absolute right-0 top-0" hidden={!selectedYear || !selectedMonth || !selectedDay || required}>
          <Button onClick={clearDate} variant="basic">
            <XMark />
          </Button>
        </div>
      </div>
      <div className="flex flex-wrap lg:flex-nowrap w-full space-x-3">{orderDateFormat()}</div>
      <p>{errorMessage}</p>
    </div>
  );
};

export default BirthDateComponent;

BirthDateComponent.defaultProps = {
  dateFormat: dateAndTime.dateFormatOrder,
  defaultDay: undefined,
  defaultMonth: undefined,
  defaultYear: undefined,
  name: "dateOfBirth",
  error: undefined,
  label: strings.dateOfBirth,
  optional: false,
  pet: false,
  readOnly: false,
  required: false,
};
