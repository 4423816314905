/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { FieldError, useFormContext } from "react-hook-form";
import moment from "moment";
import { strings } from "../../../common/Strings/Strings";
import { Colors } from "../../../models/Colors";
import SimpleInput from "../../InputFieldsSimple/SimpleInput";
import { PetFormProps } from "../../../pages/Pet/CreateNewPet/PetForm";
import { combineClassNames } from "../../../util/HtmlUtils";

interface Props {
  onChange(estimatedAge: { years: number; months: number }): void;
  value: string;
  defaultMonth?: number;
  defaultYear?: number;
  error?: FieldError;
  label?: string;
  labelOff?: boolean;
  name?: string;
  optional?: boolean;
  readOnly?: boolean;
  required?: boolean;
  showError?: boolean;
  showRequired?: boolean;
}

const EstimatedAgeComponent: React.FC<Props> = ({
  onChange,
  value,
  defaultMonth = 0,
  defaultYear = 0,
  error,
  label = strings.dateOfBirth,
  labelOff = true,
  name = "dateOfBirth",
  optional = false,
  readOnly = false,
  required = false,
  showError = true,
  showRequired,
}: Props) => {
  const [estimatedAgeInYears, setEstimatedAgeInYears] = useState<number | undefined>(defaultYear);
  const [estimatedAgeInMonths, setEstimatedAgeInMonths] = useState<number | undefined>(defaultMonth);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const { setValue, watch } = useFormContext<PetFormProps>();
  const { estimatedAge } = watch();

  const update = (years?: number, months?: number) => {
    setErrorMessage("");

    if (years === undefined || months === undefined) return;

    if (years < 1000 && years >= 0 && months < 12 && months >= 0) {
      const birthDate = moment().subtract(years, "years").subtract(months, "months");
      setValue("dateOfBirth", birthDate.format("YYYY-MM-DD"));
    }

    onChange({ years, months });
  };

  useEffect(() => {
    if (defaultMonth && estimatedAgeInMonths === undefined) {
      setEstimatedAgeInMonths(defaultMonth);
    }
    if (defaultYear && estimatedAgeInYears === undefined) {
      setEstimatedAgeInYears(defaultYear);
    }
  }, [defaultMonth, defaultYear, estimatedAgeInMonths, estimatedAgeInYears]);

  useEffect(() => {
    if (!estimatedAge) return;

    setEstimatedAgeInYears(estimatedAge.years);
    setEstimatedAgeInMonths(estimatedAge.months);
  }, [estimatedAge]);

  useEffect(() => {
    setErrorMessage(error?.message || "");
  }, [error, value]);

  // Set the years input to 0 when it's unfocused (for invalid values)
  const handleBlurForYearsElement = (years: string) => {
    if (!years || Number.isNaN(parseInt(years, 10))) {
      setEstimatedAgeInYears(0);
      update(0, estimatedAgeInMonths);
    }
  };

  // Set the months input to 0 when it's unfocused (for invalid values)
  const handleBlurForMonthsElement = (months: string) => {
    if (!months || Number.isNaN(parseInt(months, 10))) {
      setEstimatedAgeInMonths(0);
      update(estimatedAgeInYears, 0);
    }
  };

  const yearElement = (
    <SimpleInput
      classInput="tw-input w-24 pr-12"
      labelOff
      name="estimatedAge.years"
      onBlur={handleBlurForYearsElement}
      onChange={(year: string) => {
        const y = parseInt(year, 10);
        setEstimatedAgeInYears(y);
        update(y, estimatedAgeInMonths);
      }}
      type="number"
      suffix={strings.year}
      readOnly={readOnly}
      disabled={readOnly}
      disabledWithOpacityChange
      required={required}
      value={estimatedAgeInYears}
      validate={(years: string | undefined) =>
        years === undefined ||
        years === "" ||
        parseInt(years, 10) >= 0 ||
        parseInt(years, 10) < 1000 ||
        strings.invalidAge
      }
    />
  );

  const monthElement = (
    <SimpleInput
      classInput="tw-input ml-3 w-24 pr-12"
      labelOff
      name="estimatedAge.months"
      onBlur={handleBlurForMonthsElement}
      onChange={(month: string) => {
        const m = parseInt(month, 10);
        setEstimatedAgeInMonths(m);
        update(estimatedAgeInYears, m);
      }}
      type="number"
      suffix={strings.month}
      readOnly={readOnly}
      disabled={readOnly}
      disabledWithOpacityChange
      required={required}
      value={estimatedAgeInMonths}
      validate={(months: string | undefined) =>
        months === undefined ||
        months === "" ||
        parseInt(months, 10) >= 0 ||
        parseInt(months, 10) < 12 ||
        strings.invalidAge
      }
    />
  );

  return (
    <div className={combineClassNames("relative w-full", error ? "has-error" : "")}>
      {!labelOff && (
        <label htmlFor={name}>
          {label}
          {showRequired ? " *" : ""}
          {optional ? <span style={{ color: Colors.INPUTBORDER }}>{` (${strings.optional})`}</span> : <></>}
        </label>
      )}
      <div className="flex flex-wrap lg:flex-nowrap w-full">
        <div className="flex-auto">{yearElement}</div>
        <div className="flex-auto">{monthElement}</div>
      </div>
      {showError && errorMessage && <p>{errorMessage}</p>}
    </div>
  );
};

export default EstimatedAgeComponent;
