/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { axiosCommon } from "../util/axiosSetup";
import { ApiPromise } from "../models/ApiPromise";
import { UpdateMedicalRecordRequest } from "../models/medical/UpdateMedicalRecordRequest";
import { MedicalRecordResponse } from "../models/medical/MedicalRecordResponse";
import { DetailedMedicalRecordResponse } from "../models/medical/DetailedMedicalRecordResponse";
import Params from "../common/Params";
import { FreeTextMedicalRecordRequest } from "../models/medical/FreeTextMedicalRecordRequest";
import { FreeTextMedicalRecordResponse } from "../models/medical/FreeTextMedicalRecordResponse";
import { PageResponse } from "../models/PageResponse";
import { MedicalRecordFileType } from "../models/medical/MedicalRecordFileType";

export default class MedicalRecordApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static updateMedicalRecord(
    reservationId: string,
    record: UpdateMedicalRecordRequest
  ): ApiPromise<MedicalRecordResponse> {
    const url = `${this.baseUrl}/reservations/${reservationId}/medical-record`;
    return axiosCommon.put(url, record);
  }

  static signMedicalRecord(reservationId: string): ApiPromise<MedicalRecordResponse> {
    const url = `${this.baseUrl}/reservations/${reservationId}/medical-record/signing`;
    return axiosCommon.post(url);
  }

  static createCorrectiveMedicalRecord(reservationId: string): ApiPromise<MedicalRecordResponse> {
    const url = `${this.baseUrl}/reservations/${reservationId}/medical-record/correction`;
    return axiosCommon.post(url);
  }

  static getMedicalRecordsOfUserPet(petId: string): ApiPromise<MedicalRecordResponse[]> {
    const url = `${this.baseUrl}/user-pets/${petId}/medical-records`;
    return axiosCommon.get(url);
  }

  static getMedicalRecordsOfClinicPet(
    petId: string,
    pageSize?: number,
    pageNumber?: number
  ): ApiPromise<PageResponse<MedicalRecordResponse>> {
    let url = `${this.baseUrl}/clinic-pets/${petId}/medical-records`;
    if (pageSize) {
      url += `?pageSize=${pageSize}&pageNumber=${pageNumber || 0}`;
    }
    return axiosCommon.get(url);
  }

  static getMedicalRecordsOfPetInClinic(
    petId: string,
    clinicId: string,
    pageSize?: number,
    pageNumber?: number
  ): ApiPromise<PageResponse<MedicalRecordResponse>> {
    let url = `${this.baseUrl}/clinics/${clinicId}/pets/${petId}/medical-records`;
    if (pageSize) {
      url += `?pageSize=${pageSize}&pageNumber=${pageNumber || 0}`;
    }
    return axiosCommon.get(url);
  }

  static getMedicalRecordPdf(recordId: string): ApiPromise<BlobPart> {
    const url = `${this.baseUrl}/medical-records/${recordId}/pdf`;
    return axiosCommon.get(url, {
      headers: { Accept: "*/*" },
      responseType: "arraybuffer",
    });
  }

  static getCostCalculationPdf(recordId: string): ApiPromise<BlobPart> {
    const url = `${this.baseUrl}/medical-records/${recordId}/costs/pdf`;
    return axiosCommon.get(url, {
      headers: { Accept: "*/*" },
      responseType: "arraybuffer",
    });
  }

  static getMedicalRecordOfReservation(reservationId: string): ApiPromise<DetailedMedicalRecordResponse> {
    const url = `${this.baseUrl}/reservations/${reservationId}/medical-record`;
    return axiosCommon.get(url);
  }

  static getMedicalRecord(recordId: string): ApiPromise<DetailedMedicalRecordResponse> {
    const url = `${this.baseUrl}/medical-records/${recordId}`;
    return axiosCommon.get(url);
  }

  static getLatestMedicalRecord(universalId: string): ApiPromise<DetailedMedicalRecordResponse> {
    const url = `${this.baseUrl}/medical-records/${universalId}/latest`;
    return axiosCommon.get(url);
  }

  static createInvoice(medicalRecordId: string): ApiPromise<MedicalRecordResponse> {
    const url = `${this.baseUrl}/medical-records/${medicalRecordId}/invoice`;
    return axiosCommon.post(url);
  }

  static addFile(reservationId: string, file: File, type: MedicalRecordFileType): ApiPromise<MedicalRecordResponse> {
    const url = `${this.baseUrl}/reservations/${reservationId}/medical-record/files?type=${type}`;
    const formData = new FormData();
    formData.append("file", file);

    return axiosCommon.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  static deleteFile(reservationId: string, fileId: string): ApiPromise<MedicalRecordResponse> {
    const url = `${this.baseUrl}/reservations/${reservationId}/medical-record/files/${fileId}`;
    return axiosCommon.delete(url);
  }

  static getFile(reservationId: string, fileId: string): ApiPromise<BlobPart> {
    const url = `${this.baseUrl}/reservations/${reservationId}/medical-record/files/${fileId}`;
    return axiosCommon.get(url, {
      headers: { Accept: "*/*" },
      responseType: "arraybuffer",
    });
  }

  static createFreeTextMedicalRecord(
    clinicId: string,
    petId: string,
    record: FreeTextMedicalRecordRequest
  ): ApiPromise<FreeTextMedicalRecordResponse> {
    const url = `${this.baseUrl}/clinics/${clinicId}/pets/${petId}/free-text-medical-records`;
    return axiosCommon.post(url, record);
  }

  static getFreeTextMedicalRecordsForPetInClinic(
    petId: string,
    clinicId: string,
    pageSize?: number,
    pageNumber?: number
  ): ApiPromise<PageResponse<FreeTextMedicalRecordResponse>> {
    let url = `${this.baseUrl}/clinics/${clinicId}/pets/${petId}/free-text-medical-records`;
    if (pageSize) {
      url += `?pageSize=${pageSize}&pageNumber=${pageNumber || 0}`;
    }
    return axiosCommon.get(url);
  }

  static deleteFreeTextMedicalRecord(id: string): ApiPromise<void> {
    const url = `${this.baseUrl}/free-text-medical-records/${id}`;
    return axiosCommon.delete(url);
  }

  static getFreeTextMedicalRecordPdf(id: string): ApiPromise<BlobPart> {
    const url = `${this.baseUrl}/free-text-medical-records/${id}/pdf`;
    return axiosCommon.get(url, {
      headers: { Accept: "*/*" },
      responseType: "arraybuffer",
    });
  }
}
