/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { AudioContext } from "standardized-audio-context";
import logger from "./Logger";

// A singleton reference to the AudioContext which should work in every environment
// This need to be initialized on response to a user action (like a button click) to work in some browsers
let audioContext: AudioContext | null = null;

export const initAudioContext = () => {
  if (!audioContext) {
    audioContext = new AudioContext();
    logger.info("[🎤VideoChat] AudioContext initialized.");
  }

  if (audioContext.state === "suspended") {
    audioContext.resume().then(() => logger.info("[🎤VideoChat] AudioContext resumed."));
  }

  return audioContext;
};

// Call the init function once here, it should work without user interaction in some environments
initAudioContext();

export const getAudioContext = () => {
  if (!audioContext) {
    throw new Error("[🎤VideoChat] AudioContext is not correctly initialized!");
  }
  return audioContext;
};

export const closeAudioContext = () => {
  if (!audioContext) {
    throw new Error("[🎤VideoChat] AudioContext is not correctly initialized!");
  }

  if (audioContext.state !== "closed") {
    audioContext.close().catch((err) => {
      logger.warn("[📹VideoChat] AudioContext close error:", err);
    });
  } else {
    logger.info("[📹VideoChat] AudioContext is already closed.");
  }
};
