/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import qs from "query-string";
import { axiosCommon } from "../util/axiosSetup";
import { ApiPromise } from "../models/ApiPromise";
import Params from "../common/Params";
import { NotificationResponse } from "../models/notification/NotificationResponse";
import { PageResponse } from "../models/PageResponse";
import { BroadcastNotificationResponse } from "../models/notification/BroadcastNotificationResponse";
import { BroadcastNotificationRequest } from "../models/notification/BroadcastNotificationRequest";
import { BroadcastNotificationTargetResponse } from "../models/notification/BroadcastNotificationTargetResponse";

export default class BroadcastNotificationApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static getNotifications(queryParams: {
    pageNumber?: number;
    pageSize?: number;
  }): ApiPromise<PageResponse<BroadcastNotificationResponse>> {
    const query = qs.stringify(queryParams);
    const url = `${this.baseUrl}/management/notifications/broadcasts?${query}`;
    return axiosCommon.get(url);
  }

  static getNotification(broadcastId: string): ApiPromise<BroadcastNotificationResponse> {
    const url = `${this.baseUrl}/management/notifications/broadcasts/${broadcastId}`;
    return axiosCommon.get(url);
  }

  static getNotificationTargets(
    broadcastId: string,
    queryParams: {
      pageNumber?: number;
      pageSize?: number;
    }
  ): ApiPromise<PageResponse<BroadcastNotificationTargetResponse>> {
    const query = qs.stringify(queryParams);
    const url = `${this.baseUrl}/management/notifications/broadcasts/${broadcastId}/targets?${query}`;
    return axiosCommon.get(url);
  }

  static sendNotification(request: BroadcastNotificationRequest): ApiPromise<NotificationResponse> {
    const url = `${this.baseUrl}/management/notifications/broadcasts`;
    return axiosCommon.post(url, request);
  }
}
