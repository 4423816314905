/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export const notificationTypeHu = {
  CLINIC_EMPLOYMENT_REQUEST: "Amikor egy klinikán felkérnek munkatársnak",
  LINKED_UNREGISTERED_PETS: "Amikor az állatom adatait rögzítik egy új klinikán",
  PET_TRANSFER_OLD_OWNER: "Amikor az új gazda elfogadja az állatom átruházását",
  PET_TRANSFER_NEW_OWNER: "Amikor egy gazda átruházza rám az állatát",
  RESERVATION_REMINDER: "Amikor közeleg a foglalásom időpontja",
  RESERVATION_MODIFIED: "Amikor módosítják a foglalásomat",
  RESERVATION_CANCELLED: "Amikor törlik a foglalásomat",

  ANNOUNCEMENT: "Közlemény",
  SYSTEM_INFORMATION: "Rendszerinformáció",
  MARKETING: "Marketing és promóció",
  CUSTOM: "Egyéb üzenetek",
};
