/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import moment from "moment";
import { getGeneralError } from "../../../util/helperFunctions";
import { strings } from "../../../common/Strings/Strings";
import { PageResponse } from "../../../models/PageResponse";
import { usePager } from "../../../hooks/hooks";
import PageableTable from "../../../components/PageableTable/PageableTable";
import PageableTableHead from "../../../components/PageableTable/PageableTableHead";
import PageableTableBody from "../../../components/PageableTable/PageableTableBody";
import { DeliveryType } from "../../../models/notification/DeliveryType";
import { CheckCircle } from "../../../common/Icons/CheckCircle";
import { XCircle } from "../../../common/Icons/XCircle";
import { BroadcastNotificationTargetResponse } from "../../../models/notification/BroadcastNotificationTargetResponse";
import BroadcastNotificationApi from "../../../api/BroadcastNotificationApi";
import { Link } from "react-router-dom";

interface Props {
  broadcastId: string;
}

const BroadcastTargetsTable: React.FC<Props> = (props: Props) => {
  const { broadcastId } = props;

  const [targets, setTargets] = useState<PageResponse<BroadcastNotificationTargetResponse>>();

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const pager = usePager(50);

  const getTargets = async () => {
    setLoading(true);

    try {
      const response = await BroadcastNotificationApi.getNotificationTargets(broadcastId, pager);
      setTargets(response.data);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void getTargets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [broadcastId, pager.pageNumber, pager.pageSize]);

  const getDeliveryTypeState = (notification: BroadcastNotificationTargetResponse, deliveryType: DeliveryType) => {
    return (
      <div className="inline-block">
        <div hidden={!notification.deliveryTypes.includes(deliveryType)} className="color-green">
          <CheckCircle />
        </div>
        <div hidden={notification.deliveryTypes.includes(deliveryType)} className="color-light-grey">
          <XCircle />
        </div>
      </div>
    );
  };

  return (
    <div>
      <PageableTable pager={pager} isLoading={loading} content={targets} error={error}>
        <PageableTableHead>
          <tr>
            <th className="tw-th">{strings.user}</th>
            <th className="tw-th text-center">{strings.notificationSettingsEmail}</th>
            <th className="tw-th text-center">{strings.notificationSettingsInApp}</th>
            <th className="tw-th text-center">{strings.notificationSettingsPush}</th>
            <th className="tw-th text-center">{strings.notificationSettingsSms}</th>
            <th className="tw-th text-right">{strings.notificationReadDate}</th>
          </tr>
        </PageableTableHead>
        <PageableTableBody>
          {targets &&
            targets.elements.map((target, index: number) => (
              <tr key={index}>
                <td className="px-4 py-4 text-sm whitespace-nowrap">
                  <Link className="tw-link-dark" to={`/management/users/${target.userId}`}>
                    {target.fullName || target.userId}
                  </Link>
                </td>
                <td className="px-4 py-4 text-sm text-center">{getDeliveryTypeState(target, DeliveryType.EMAIL)}</td>
                <td className="px-4 py-4 text-sm text-center">{getDeliveryTypeState(target, DeliveryType.IN_APP)}</td>
                <td className="px-4 py-4 text-sm text-center">{getDeliveryTypeState(target, DeliveryType.PUSH)}</td>
                <td className="px-4 py-4 text-sm text-center">{getDeliveryTypeState(target, DeliveryType.SMS)}</td>
                <td className="px-4 py-4 text-sm whitespace-nowrap text-right">
                  <span hidden={!target.read} title={moment(target.read).format("LLL")}>
                    {moment(target.read).fromNow()}
                  </span>
                </td>
              </tr>
            ))}
        </PageableTableBody>
      </PageableTable>
    </div>
  );
};

export default BroadcastTargetsTable;
