/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { Link } from "react-router-dom";
import { Loader } from "../../../../components/Loader";
import { PageProps } from "../../../../models/PageProps";
import { strings } from "../../../../common/Strings/Strings";
import PetOwnerApi from "../../../../api/PetOwnerApi";
import { PetOwnerResponse } from "../../../../models/pet/PetOwnerResponse";
import { PageResponse } from "../../../../models/PageResponse";
import { getAddressString, getGeneralError } from "../../../../util/helperFunctions";
import Paging from "../../../../components/Paging";
import PageSizeSelect from "../../../../components/PageSizeSelect";
import SearchInput from "../../../../components/InputFieldsSimple/SearchInput";
import { useDebouncedState } from "../../../../hooks/hooks";
import { HighlightedText } from "../../../../util/StringUtils";
import Button from "../../../../components/Button";
import { Pencil } from "../../../../common/Icons/Pencil";
import { useClinic } from "../../../../contexts/ClinicContext";
import EmptyListText from "../../../../components/EmptyListText";
import LoaderInline from "../../../../components/LoaderInline";
import { Trash } from "../../../../common/Icons/Trash";
import BeforeDeleteModal from "../../../../components/Modals/BeforeDeleteModal";
import AlertBox from "../../../../components/AlertBox";

const PetOwners = ({ setPageLoading }: PageProps): ReactElement => {
  const [owners, setOwners] = useState<PetOwnerResponse[]>([]);

  const [page, setPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalResults, setTotalResults] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [lastPage, setLastPage] = useState<number>(1);
  const [firstPage, setFirstPage] = useState<number>(1);

  const petOwnerSearchQuery = useDebouncedState("", 400);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [isDeleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [selectedOwner, setSelectedOwner] = useState<PetOwnerResponse>();

  const { clinic } = useClinic();

  const getOwners = async () => {
    if (!clinic) return;
    setLoading(true);
    setError(null);
    try {
      const response: AxiosResponse<PageResponse<PetOwnerResponse>> = await PetOwnerApi.getPetOwners(clinic.id, {
        pageSize,
        pageNumber: page,
        query: petOwnerSearchQuery.value,
      });
      setOwners(response.data.elements);
      setTotalPages(response.data.totalPages);
      setTotalResults(response.data.totalElements);
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setPageLoading(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    void getOwners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, page, petOwnerSearchQuery.value, clinic, setPageLoading]);

  const deleteOwner = async () => {
    if (!selectedOwner) return;
    setLoading(true);
    setError(null);
    setDeleteModalVisible(false);
    try {
      await PetOwnerApi.archivePetOwner(selectedOwner.petOwnerDetails.id);
      await getOwners();
    } catch (e) {
      setError(await getGeneralError(e));
    } finally {
      setPageLoading(false);
      setLoading(false);
    }
  };

  const content = () => {
    if (loading) {
      return (
        <div className="p-4 flex items-start">
          <LoaderInline />
        </div>
      );
    } else if (owners.length === 0) {
      if (petOwnerSearchQuery.value.length > 0) {
        return (
          <div className="p-4">
            <EmptyListText />
          </div>
        );
      } else {
        return (
          <div className="p-4">
            <EmptyListText text={strings.noClinicPetOwnersYet} />
          </div>
        );
      }
    } else if (owners.length > 0) {
      return owners.map((owner: PetOwnerResponse) => (
        <div className="flex items-center p-4">
          <div className="grid grid-cols-4 gap-4 w-full">
            <div className="col-span-4 lg:col-span-1">
              <Link to={`/pet-owner-profile/${owner.petOwnerDetails.id}`}>
                <HighlightedText text={owner.petOwnerDetails.fullName} highlights={[petOwnerSearchQuery.value]} />
              </Link>
              <div>
                {strings.personalId}:{" "}
                <HighlightedText
                  text={owner.petOwnerDetails.personalId || ""}
                  highlights={[petOwnerSearchQuery.value]}
                />
              </div>
              <div>
                {strings.phones}:{" "}
                {owner.petOwnerDetails.phones.map((p, index) => (
                  <span>
                    <HighlightedText text={p.value} highlights={[petOwnerSearchQuery.value]} />
                    {owner.petOwnerDetails.phones[index + 1] ? ", " : ""}
                  </span>
                ))}
              </div>
            </div>
            <div className="col-span-4 lg:col-span-1 flex items-center">
              <div>
                {strings.emails}:{" "}
                {owner.petOwnerDetails.emails.map((e) => (
                  <div>
                    <HighlightedText text={e.value} highlights={[petOwnerSearchQuery.value]} />
                  </div>
                ))}
              </div>
            </div>
            <div className="col-span-4 lg:col-span-2 flex flex-col justify-center">
              <div>
                {strings.addresses}:{" "}
                {owner.petOwnerDetails.addresses.map((a) => (
                  <div>{getAddressString(a)}</div>
                ))}
              </div>
            </div>
          </div>
          <div className="ml-auto flex gap-2">
            <Link to={`/edit-pet-owner-profile/${owner.petOwnerDetails.id}`}>
              <Button variant="icon">
                <Pencil />
              </Button>
            </Link>
            <Button
              variant="icon"
              onClick={() => {
                setSelectedOwner(owner);
                setDeleteModalVisible(true);
              }}
            >
              <Trash />
            </Button>
          </div>
        </div>
      ));
    }
  };

  return (
    <main className="main-signed-in">
      <section>
        <div className="px-4 lg:px-6 py-6">
          <div className="flex flex-col space-y-6">
            <div className="flex justify-between">
              <h1 className="text-xl font-semibold leading-tight text-zinc-800 lg:text-2xl dark:text-white">
                {strings.owners}
              </h1>
              <div className="ml-auto">
                <Link to="/create-pet-owner">
                  <Button variant="primary">{strings.createPetOwner}</Button>
                </Link>
              </div>
            </div>
            <div className="w-80">
              <SearchInput
                clear={() => {
                  petOwnerSearchQuery.setValue("");
                  setPage(0);
                }}
                enableCreateButton={false}
                loading={loading}
                onChange={(value) => {
                  petOwnerSearchQuery.setValue(value);
                  setPage(0);
                }}
                placeholder={`${strings.search}...`}
                label={strings.search}
                toolTipText={strings.searchPetOwnersToolTip}
                value={petOwnerSearchQuery.value}
              />
            </div>
            <AlertBox message={error} />
            <div className="tw-card divide-y dark:divide-gray-700">{content()}</div>
            <div className="flex flex-wrap">
              <div className="flex items-center">
                <PageSizeSelect
                  pageSize={pageSize}
                  setPageSize={(newPageSize: number) => {
                    setPageSize(newPageSize);
                  }}
                  totalPages={Math.ceil(totalPages)}
                  totalResults={totalResults}
                />
              </div>
              <div className="ml-auto">
                <Paging
                  currentPage={page}
                  firstPage={firstPage}
                  lastPage={lastPage}
                  selectPage={(newPageNumber: number) => {
                    setPage(newPageNumber);
                  }}
                  totalPages={Math.ceil(totalPages)}
                  updatePageRange={(first, last) => {
                    setFirstPage(first);
                    setLastPage(last);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <BeforeDeleteModal
        open={isDeleteModalVisible}
        onHide={() => setDeleteModalVisible(false)}
        returnYes={deleteOwner}
      />
    </main>
  );
};

export default Loader(PetOwners);
