/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import logger from "../../../../util/logger";
import {
  getStringFromEnumsOrReturn,
  strings,
} from "../../../../common/Strings/Strings";
import { ClinicEmploymentRequestResponse } from "../../../../models/employment/ClinicEmploymentRequestResponse";
import Button from "../../../../components/Button";
import ClinicEmploymentApi from "../../../../api/ClinicEmploymentApi";
import { getGeneralError } from "../../../../util/helperFunctions";
import AlertBox from "../../../../components/AlertBox";
import { useClinic } from "../../../../contexts/ClinicContext";
import { useNotification } from "../../../../contexts/NotificationContext";
import ClinicLogo from "../../../../components/Pictures/Clinic/ClinicLogo";

const PendingClinics: React.FC = () => {
  const [requests, setRequests] = useState<ClinicEmploymentRequestResponse[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { fetchClinics } = useClinic();
  const { fetchNotifications } = useNotification();

  const getEmploymentRequest = async () => {
    try {
      const response =
        await ClinicEmploymentApi.getUserClinicEmploymentRequests();
      setRequests(response.data);
    } catch (e) {}
  };

  useEffect(() => {
    void getEmploymentRequest();
  }, []);

  const pendingHandling = async (
    isAccepted: boolean,
    notificationRequestId: string
  ) => {
    setLoading(true);

    try {
      if (isAccepted) {
        try {
          await ClinicEmploymentApi.acceptClinicEmploymentRequest(
            notificationRequestId
          );
          await getEmploymentRequest();
          await fetchNotifications();
          await fetchClinics();
        } catch (err) {
          setError(await getGeneralError(err));
          logger.error(err);
        }
      } else {
        try {
          await ClinicEmploymentApi.rejectClinicEmploymentRequest(
            notificationRequestId
          );
          await getEmploymentRequest();
          await fetchNotifications();
        } catch (err) {
          setError(await getGeneralError(err));
          logger.error(err);
        }
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      <div hidden={requests.length === 0}>
        <h1 className="text-xl font-semibold leading-tight text-zinc-800 dark:text-white lg:text-2xl">
          {strings.pendingInvitations}
        </h1>
      </div>
      <div className="grid grid-cols-12 gap-6" hidden={requests.length === 0}>
        {requests.map((not: ClinicEmploymentRequestResponse) => (
          <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 flex flex-col justify-between tw-card shadow divide-y">
            <div className="justify-center flex p-4">
              {strings.formatString(
                strings.pending,
                not.requestingUser.fullName,
                not.clinic.name,
                getStringFromEnumsOrReturn(not.employmentType)
              )}
            </div>
            <div className="flex flex-col items-center p-4 space-y-4">
              <div className="w-40 h-40">
                <ClinicLogo
                  clinicId={not.clinic.id}
                  logoPictureId={not.clinic.logoPictureId}
                />
              </div>
              <div className="text-center font-bold">{not.clinic.name}</div>
            </div>
            <div className="p-4 text-center space-y-4">
              <Button
                disabled={loading}
                loading={loading}
                onClick={() => {
                  void pendingHandling(true, not.id);
                }}
              >
                {strings.accept}
              </Button>
              <Button
                disabled={loading}
                loading={loading}
                variant="link"
                onClick={() => {
                  void pendingHandling(false, not.id);
                }}
              >
                {strings.reject}
              </Button>
            </div>
          </div>
        ))}
      </div>
      <AlertBox message={error} />
    </>
  );
};

export default PendingClinics;
