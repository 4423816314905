/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { Control, Controller } from "react-hook-form";
import { breeds, petSpecies, strings } from "../../../common/Strings/Strings";
import { PetSpecies } from "../../../models/pet/PetSpeciesEnum";
import { enumKeys } from "../../../util/helperFunctions";
import CombinedSelect from "../General/Select/CombinedSelect";
import SimpleInput from "../../InputFieldsSimple/SimpleInput";
import CombinedSelectComponent from "../../InputFieldsSimple/CombinedSelectComponent";

interface FieldProps {
  control: Control<any>;
  classNameBreed?: string;
  classNameSpecies?: string;
  optional?: boolean;
  required?: boolean;
  showRequired?: boolean;
  width?: string;
}

export interface PetSpeciesOption {
  value: PetSpecies;
  name: string;
}

interface BreedOption {
  isOther?: boolean;
  name: string;
}

const fixBreedValues: BreedOption[] = [{ name: strings.mixed }, { name: strings.other, isOther: true }];

const commonPetSpecies = ["DOG", "CAT", "FERRET", "HORSE"];

const otherPetSpecies = ["BIRD", "HAMSTER", "CHINCHILLA", "RABBIT", "FUR_ANIMAL", "MOUSE", "RAT", "OTHER"];

const allPetBreeds: string[] = [];

enumKeys(PetSpecies).forEach((s) => {
  allPetBreeds.push(...(breeds[s] || []));
});

const SelectSpeciesAndBreed = ({
  control,
  classNameBreed,
  classNameSpecies,
  optional,
  required,
  showRequired,
  width,
}: FieldProps): ReactElement => {
  const [selectedSpecies, setSelectedSpecies] = useState<string>();
  const [selectedBreed, setSelectedBreed] = useState<BreedOption[]>();

  const breedOptions = useMemo(() => {
    const values: BreedOption[] = (
      (selectedSpecies && (breeds[selectedSpecies] ? (breeds[selectedSpecies] as []) : [])) ||
      allPetBreeds
    ).map((n) => ({ name: n }));

    return values;
  }, [selectedSpecies]);

  const options = useMemo<PetSpeciesOption[]>(() => {
    const result: PetSpeciesOption[] = [];
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const value in PetSpecies) {
      const speciesEnumValue: PetSpecies = PetSpecies[value as keyof typeof PetSpecies];
      // noinspection JSUnfilteredForInLoop
      result.push({ value: speciesEnumValue, name: petSpecies[value] });
    }
    return result;
  }, []);

  const [commonPets, setCommonPets] = useState<PetSpeciesOption[]>([]);
  const [otherPets, setOtherPets] = useState<PetSpeciesOption[]>([]);
  const [livestockPets, setLivestockPets] = useState<PetSpeciesOption[]>([]);

  useEffect(() => {
    setCommonPets(options.filter((o) => commonPetSpecies.includes(o.value)));

    setOtherPets(options.filter((o) => otherPetSpecies.includes(o.value)));

    setLivestockPets(options.filter((o) => !commonPets.includes(o) && !otherPets.includes(o)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const onChange = (values: PetSpeciesOption[]) => {
    if (values && values.length === 1) {
      setSelectedSpecies(values[0].value);
    } else if (values && values.length === 0) {
      setSelectedSpecies(undefined);
    }
  };

  return (
    <>
      <div className={classNameSpecies}>
        <CombinedSelect
          allowNew={false}
          clearButton
          control={control}
          groupedByOptions={[
            {
              groupOptions: commonPets,
              groupTitle: strings.pets,
            },
            {
              groupOptions: otherPets,
            },
            {
              groupOptions: livestockPets,
              groupTitle: strings.farmAnimals,
            },
          ]}
          isTypeahead
          name="species"
          label={strings.species}
          labelKey="name"
          maxResults={100}
          onChange={onChange}
          optional={optional}
          options={options}
          required={required}
          showRequired={showRequired}
          width={width}
        />
      </div>
      <div className={classNameBreed}>
        <Controller
          control={control}
          name="breed"
          rules={{
            required,
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <>
              <CombinedSelectComponent
                allowNew={false}
                error={error}
                name="breedComponent"
                fixOptions={fixBreedValues}
                label={strings.breed}
                labelKey="name"
                onChange={(v: BreedOption[] | null) => {
                  setSelectedBreed(v || undefined);

                  if (v?.[0]?.isOther) {
                    onChange(null);
                  } else {
                    onChange(v);
                  }
                }}
                optional={optional}
                options={breedOptions}
                placeholder={strings.searchBreed}
                required={required}
                showRequired={showRequired}
                value={selectedBreed?.[0].isOther ? selectedBreed : value}
                width={width}
              />
              <div className="pt-4" hidden={!selectedBreed?.[0].isOther}>
                <SimpleInput
                  error={error ? strings.specifyBreed : undefined}
                  label={strings.specifyBreed}
                  name="otherBreed"
                  onChange={(v: string) => {
                    onChange(v && v.length > 0 ? [{ name: v }] : null);
                  }}
                  value=""
                />
              </div>
            </>
          )}
        />
      </div>
    </>
  );
};

export default SelectSpeciesAndBreed;

SelectSpeciesAndBreed.defaultProps = {
  optional: false,
  required: false,
};
