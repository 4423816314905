/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import moment from "moment";
import { dateAndTime, getStringFromEnumsOrReturn, strings } from "../../../../../common/Strings/Strings";
import NumberFormatter from "../../../../../util/NumberFormatter";
import { ClinicChargeResponse } from "../../../../../models/charge/ClinicChargeResponse";
import ChargeApi from "../../../../../api/ChargeApi";
import { getGeneralError } from "../../../../../util/helperFunctions";
import { downloadInvoicePdfForClinicCharge } from "../../../../../util/FileUtils";
import AlertBox from "../../../../../components/AlertBox";
import MonthlyChargePaymentModal from "./MonthlyChargePaymentModal";
import { ChargeStatus } from "../../../../../models/charge/ChargeStatus";
import Button from "../../../../../components/Button";
import { DocumentArrowDown } from "../../../../../common/Icons/DocumentArrowDown";
import EmptyListText from "../../../../../components/EmptyListText";
import Tag, { TagType } from "../../../../../components/Tag";
import LoaderInline from "../../../../../components/LoaderInline";

interface Props {
  isActiveTab: boolean;
  clinicId: string;
}

const ClinicPayments: React.FC<Props> = ({ isActiveTab, clinicId }: Props) => {
  const [charges, setCharges] = useState<ClinicChargeResponse[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedCharge, setSelectedCharge] = useState<ClinicChargeResponse>();
  const [showCardModal, setShowCardModal] = useState<boolean>(false);

  const getMonthlyCharges = async () => {
    if (!clinicId) {
      return;
    }

    try {
      setLoading(true);
      const response = await ChargeApi.getClinicCharges(clinicId);
      setCharges(response.data);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isActiveTab || !clinicId) {
      return;
    }

    void getMonthlyCharges();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveTab, clinicId]);

  const getPdf = async (charge: ClinicChargeResponse) => {
    try {
      await downloadInvoicePdfForClinicCharge(charge);
    } catch (err) {
      setError(await getGeneralError(err));
    }
  };

  const hideCardModal = () => {
    setShowCardModal(false);
  };

  const openCardModal = (charge: ClinicChargeResponse) => {
    setSelectedCharge(charge);
    setShowCardModal(true);
  };

  const getChargeStatus = (status: ChargeStatus): ReactElement | undefined => {
    if (status === ChargeStatus.COMPLETED) {
      return <Tag type={TagType.success} text={getStringFromEnumsOrReturn(status)} />;
    }
    return <Tag type={TagType.warning} text={getStringFromEnumsOrReturn(ChargeStatus.PENDING)} />;
  };

  return (
    <>
      <div className="tw-card divide-y divide-gray-200 dark:divide-gray-700">
        <div className="p-4">
          <p className="uppercase">{strings.payments}</p>
        </div>
        <div className="space-y-6 p-4">
          <div>{strings.paymentsToolTip}</div>
          <AlertBox message={error} />
          <div className="tw-table-container">
            <table className="tw-table">
              <thead className="bg-gray-50 dark:bg-gray-800 rounded-lg">
                <tr>
                  <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    {strings.period}
                  </th>
                  <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    {strings.status}
                  </th>
                  <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    {strings.paymentDate}
                  </th>
                  <th className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    {strings.amount}
                  </th>
                  <th className="py-3.5 px-4 text-sm font-normal text-center text-gray-500 dark:text-gray-400">
                    {strings.action}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                {charges.length !== 0 ? (
                  <>
                    {charges.map((charge) => (
                      <tr key={charge.id}>
                        <td className="px-4 py-4 text-sm font-medium whitespace-nowrap">
                          {`${moment(charge.startDate).format(dateAndTime.momentDateFormat)} - ${moment(
                            charge.endDate
                          ).format(dateAndTime.momentDateFormat)}`}
                        </td>
                        <td className="px-4 py-4 text-sm font-medium whitespace-nowrap">
                          {getChargeStatus(charge.status)}
                        </td>
                        <td className="px-4 py-4 text-sm font-medium whitespace-nowrap">
                          {charge.paymentDate
                            ? moment(charge.paymentDate).format(dateAndTime.momentDateTimeFormat)
                            : undefined}
                        </td>
                        <td className="px-4 py-4 text-sm font-medium whitespace-nowrap">
                          {NumberFormatter.formatPrice(charge.total, charge.currency)}
                        </td>
                        <td className="px-4 py-4 text-sm font-medium whitespace-nowrap">
                          <div
                            hidden={!charge.invoice?.valid}
                            className="d-flex align-items-end justify-content-end gap-4"
                          >
                            <div hidden={charge.status === ChargeStatus.COMPLETED}>
                              <Button type="button" onClick={() => openCardModal(charge)} variant="primary" small>
                                {strings.pay}
                              </Button>
                            </div>
                            <Button className="mx-0" variant="icon" onClick={() => getPdf(charge)}>
                              <DocumentArrowDown />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td className="px-4 py-3 text-sm font-medium whitespace-nowrap" colSpan={5}>
                      {isLoading ? <LoaderInline /> : <EmptyListText text={strings.noDataYet} />}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {selectedCharge && (
        <MonthlyChargePaymentModal
          isOpen={showCardModal}
          close={hideCardModal}
          onChargeUpdated={getMonthlyCharges}
          monthlyCharge={selectedCharge}
        />
      )}
    </>
  );
};

export default ClinicPayments;
