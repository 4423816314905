/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { strings } from "../../../common/Strings/Strings";
import Button from "../../../components/Button";
import PetFormOptionalTab from "./PetFormOptionalTab";
import AlertBox from "../../../components/AlertBox";
import { PetFormProps } from "./PetForm";
import PetFormMandatoryTab from "./PetFormMandatoryTab";
import { PersonDetailsResponse } from "../../../models/contact/PersonDetailsResponse";
import LoaderInline from "../../../components/LoaderInline";
import UserProfilePicture from "../../../components/Pictures/User/UserProfilePicture";

interface Props {
  error: string | null;
  onSubmit(pet: PetFormProps): void;
  ownerPictureUserId?: string;
  petOwner?: PersonDetailsResponse;
  isLoading: boolean;
  isPetOwnerLoading: boolean;
}

enum Tabs {
  Mandatory,
  Optional,
}

const tabsInOrder = [Tabs.Mandatory, Tabs.Optional];

const TabbedPetForm = ({
  error,
  onSubmit,
  ownerPictureUserId,
  petOwner,
  isLoading,
  isPetOwnerLoading,
}: Props): ReactElement => {
  const useFormValues = useForm<PetFormProps>({
    mode: "onSubmit",
    defaultValues: {
      dateOfBirthType: "unknown",
      estimatedAge: {
        months: 0,
        years: 0,
      },
    },
  });

  const {
    handleSubmit,
    trigger,
    formState: { isValid, isSubmitting },
  } = useFormValues;

  const [activeTab, setActiveTab] = useState<Tabs>(tabsInOrder[0]);
  const [tabIndex, setTabIndex] = useState<number>(0);

  const goToPreviousTab = () => {
    if (tabIndex <= 0) return;
    setActiveTab(tabsInOrder[tabIndex - 1]);
    setTabIndex(tabIndex - 1);
  };

  const goToNextTab = () => {
    if (tabIndex >= tabsInOrder.length - 1) return;
    setActiveTab(tabsInOrder[tabIndex + 1]);
    setTabIndex(tabIndex + 1);
  };

  return (
    <form className="lg:tw-card w-full space-y-6 lg:w-auto lg:p-8" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex justify-between space-x-4">
        <h1 className="text-xl font-semibold leading-tight text-zinc-800 dark:text-white lg:text-2xl">
          {strings.addNewPet}
        </h1>
        <span className="text-gray-500 dark:text-gray-400">
          {tabIndex + 1}/{tabsInOrder.length}
        </span>
      </div>

      {petOwner || isPetOwnerLoading ? (
        <>
          {isPetOwnerLoading ? (
            <div className="flex justify-center">
              <LoaderInline />
            </div>
          ) : (
            <>
              <div className="flex items-center">
                <div style={{ width: "75px", height: "75px" }}>
                  <UserProfilePicture border userId={ownerPictureUserId} />
                </div>
                <div className="flex flex-column ml-3">
                  <Link to={`/pet-owner-profile/${petOwner?.id}`}>{petOwner?.fullName}</Link>
                  {petOwner?.phones[0]?.value}
                </div>
              </div>
              <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700" />
            </>
          )}
        </>
      ) : (
        <></>
      )}

      <AlertBox message={error} />

      <FormProvider {...useFormValues}>
        {(() => {
          switch (activeTab) {
            case Tabs.Mandatory:
              return (
                <>
                  <PetFormMandatoryTab />
                  <Button
                    key="saveAndContinue"
                    onClick={() => {
                      // Important: Don't remove the keys for the buttons!
                      // React can't tell the difference between the two buttons using this structure,
                      // so without keys this button would immediately submit the form
                      // (even though the other onClick() still won't fire).

                      // This only works for now because all required (validated) values
                      // are on the first tab. This allows a simpler 'partial' validation.
                      void trigger();

                      if (isValid) {
                        goToNextTab();
                      }
                    }}
                  >
                    {strings.saveAndContinue}
                  </Button>
                </>
              );
            case Tabs.Optional:
              return (
                <>
                  <PetFormOptionalTab />
                  <Button disabled={isSubmitting} key="saveAndCreate" loading={isLoading} type="submit">
                    {strings.saveAndCreate}
                  </Button>
                </>
              );
            default:
              return <></>;
          }
        })()}
      </FormProvider>
      <Button className="text-left text-sm" hidden={tabIndex <= 0} onClick={goToPreviousTab} variant="link">
        {strings.back}
      </Button>
    </form>
  );
};

export default TabbedPetForm;
