/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported, Messaging, onMessage } from "firebase/messaging";
import { ChildrenProps } from "../models/ChildrenProps";
import { NotificationResponse } from "../models/notification/NotificationResponse";
import NotificationApi from "../api/NotificationApi";
import logger from "../util/logger";
import { useUser } from "./UserContext";
import { loadFirebaseConfig } from "../util/firebaseConfigLoader";
import { useSearchParams } from "../hooks/hooks";
import { getAccessToken, setPushToken } from "../util/LocalStorageVariables";

interface INotificationContext {
  notifications: NotificationResponse[];
  fetchNotifications: () => Promise<void>;
  read: (notificationId: string) => Promise<void>;
}

const NotificationContext = createContext<INotificationContext | null>(null);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const useNotification = () => useContext(NotificationContext)!;

type Props = ChildrenProps & { requiresAuthentication: boolean };

const reloadTimeMillis = 60 * 1000;

export const NotificationProvider: React.FC<Props> = ({ children, requiresAuthentication }: Props) => {
  const [notifications, setNotifications] = useState<NotificationResponse[]>([]);
  const [isReloadNeeded, setReloadNeeded] = useState<boolean>(true);

  const { user } = useUser();
  const { nId } = useSearchParams();

  const [firebaseMessaging, setFirebaseMessaging] = useState<Messaging | null>(null);

  const getNotifications = async () => {
    try {
      const response = await NotificationApi.getUnreadNotifications();
      setNotifications(response.data);
    } catch (e) {
      logger.error(e);
    }
  };

  useEffect(() => {
    if (!isReloadNeeded) {
      const reloadCallback = () => {
        setReloadNeeded(true);
      };
      setTimeout(reloadCallback, reloadTimeMillis);
    }
  }, [isReloadNeeded]);

  useEffect(() => {
    if (requiresAuthentication && !getAccessToken()) return;
    if (user.userId !== undefined && isReloadNeeded) {
      void getNotifications();
      setReloadNeeded(false);
    }
  }, [user, isReloadNeeded, requiresAuthentication]);

  useEffect(() => {
    if (requiresAuthentication && !getAccessToken()) return;
    if (!user) return;

    const initFirebase = async () => {
      if (!firebaseMessaging) return;

      const config = await loadFirebaseConfig();
      const firebaseApp = initializeApp(config);
      if (!(await isSupported())) return;
      setFirebaseMessaging(getMessaging(firebaseApp));

      onMessage(firebaseMessaging, () => {
        void getNotifications();
      });
    };

    const getPushToken = async () => {
      if (!firebaseMessaging) return null;

      const config = await loadFirebaseConfig();
      const options = {
        vapidKey: config.certificateKey,
      };

      return getToken(firebaseMessaging, options);
    };

    const requestPermission = async () => {
      try {
        if (!firebaseMessaging) {
          await initFirebase();
        }

        if (!(await isSupported())) return;
        const permission = await Notification.requestPermission();
        if (permission !== "granted") return;
        const token = await getPushToken();

        if (token) {
          await NotificationApi.savePushToken(token);
          setPushToken(token);
        }
      } catch (e) {
        logger.error(e);
      }
    };

    void requestPermission();
  }, [firebaseMessaging, requiresAuthentication, user]);

  const read = useCallback(async (notificationId: string) => {
    try {
      await NotificationApi.readNotification(notificationId);
      await getNotifications();
    } catch (e) {
      logger.error(e);
    }
  }, []);

  useEffect(() => {
    if (!nId) return;

    void read(nId);
  }, [nId, read]);

  const ctx = useMemo(
    () => ({
      notifications,
      fetchNotifications: getNotifications,
      read,
    }),
    [notifications, read]
  );

  return <NotificationContext.Provider value={ctx}>{children}</NotificationContext.Provider>;
};
