/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import { useFormContext } from "react-hook-form";
import { quantityUnits, strings } from "../../../common/Strings/Strings";
import { getDefaultVat, getVatOptions } from "../../../util/helperFunctions";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import { AutoCompleteOptions } from "../../../models/AutoCompleteOptions";
import PriceInputs from "../../../components/ReactHookFormFields/Specific/PriceInputs";
import ControlledSelect from "../../../components/ReactHookFormFields/General/Controlled/ControlledSelect";
import UnitPriceCalculator from "./UnitPriceCalculator";
import { InventoryItemUnit } from "../../../models/inventory/item/InventoryItemUnit";
import DatePickerInput from "../../../components/ReactHookFormFields/ReactDateAndTimePickers/DatePickerInput";
import IconBubble from "../../../components/IconBubble";
import MultiplicationFields from "../../../components/ReactHookFormFields/Specific/MultiplicationFields";
import { InventoryItemStockRequest } from "../../../models/inventory/item/Request/InventoryItemStockRequest";
import { useClinic } from "../../../contexts/ClinicContext";

interface Props {
  countryDetails?: CountryDetailsResponse;
  inventoryItem?: { name: string; acquisitionUnit?: InventoryItemUnit };
  isPositive: boolean;
  isRequired: boolean;
  setNewSalePrice?(value: number | undefined): void;
}

const UpdateStockForm: React.FC<Props> = ({
  countryDetails,
  inventoryItem,
  isPositive,
  isRequired,
  setNewSalePrice
}: Props) => {
  const { watch, control, getValues, setValue } =
    useFormContext<InventoryItemStockRequest>();
  const [acquisitionVat] = watch(["acquisitionVat"]);
  const { clinic } = useClinic();

  return (
    <>
      <div className="p-4 tw-card">
        <div className="flex items-center">
          <div>
            <h2 className="uppercase lg:normal-case text-lg font-medium lg:font-semibold leading-tight text-zinc-800 lg:text-lg dark:text-white">
              {inventoryItem?.name}
            </h2>
          </div>
          <div className="ml-auto">
            <IconBubble>
              {quantityUnits[inventoryItem?.acquisitionUnit || ""]}
            </IconBubble>
          </div>
        </div>
      </div>
      <div>
        <MultiplicationFields
          control={control}
          fieldOptions={{
            validate: {
              positive: (v) => isPositive ? parseInt(v, 10) > 0 : parseInt(v, 10) >= 0,
            },
          }}
          label={strings.totalQuantityInPackages}
          name="quantity"
          unit={inventoryItem?.acquisitionUnit}
        />
      </div>
      <DatePickerInput
        control={control}
        name="expirationDate"
        label={strings.expirationDate}
        optional
      />
      <div className="flex space-x-4 items-end">
        <div className="w-1/2">
          <ControlledSelect
            control={control}
            name="acquisitionVat"
            options={getVatOptions(countryDetails?.vatRates)}
            autoComplete={AutoCompleteOptions.off}
            defaultValue={getDefaultVat(countryDetails?.vatRates)}
            fieldOptions={{ valueAsNumber: true }}
            isChooseOption={false}
            label={strings.acquisitionVat}
            required={isRequired}
            value={getValues("acquisitionVat")}
          />
        </div>
      </div>
      <UnitPriceCalculator
        countryDetails={countryDetails}
        totalQuantity={watch("quantity")}
        totalQuantityEnabled
        unit={inventoryItem?.acquisitionUnit || InventoryItemUnit.PIECES}
      />
      <div>
        <PriceInputs
          control={control}
          currency={countryDetails?.currency}
          label={strings.acquisitionPrice}
          name="acquisitionPrice"
          onChange={(value: number | undefined) => {
            if(setNewSalePrice){
              setNewSalePrice(value);
            }
          }}
          required={isRequired}
          roundingStrategy={
            clinic?.roundingStrategyItem || countryDetails?.roundingStrategyItem
          }
          vat={acquisitionVat}
        />
      </div>
    </>
  );
};

export default UpdateStockForm;
