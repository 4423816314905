/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export const clinicAnalyticsEventTypeHu = {
  ACCOUNTING_CODES_SYNCED: "Könyvelési kódok szinkronizálva",
  ACCOUNTING_CODE_ADDED: "Könyvelési kód hozzáadva",
  ACCOUNTING_CODE_DELETED: "Könyvelési kód törölve",
  ACCOUNTING_CODE_UPDATED: "Könyvelési kód módosítva",
  ADDRESS_ADDED: "Cím hozzáadva",
  ADDRESS_DELETED: "Cím törölve",
  CHARGE_PAID: "Költségek fizetve",
  CLINIC_DELETED: "Klinika törölve",
  CLINIC_DELETION_STARTED: "Klinika törlés elkezdve",
  CLINIC_FEES_UPDATED: "Klinika díj módosítva",
  CLINIC_REGISTERED: "Klinika regisztrálva",
  CLINIC_UPDATED: "Klinika módosítva",
  CREDIT_CARD_UPDATED: "Bankkártya módosítva",
  EMAIL_ADDED: "Email hozzáadva",
  EMAIL_CONFIRMATION_RESENT: "Email megerősítés újraküldve",
  EMAIL_CONFIRMED: "Email megerősítve",
  EMAIL_DELETED: "Email törölve",
  EMPLOYEE_ADDED: "Alkalmazott hozzáadva",
  EMPLOYEE_DELETED: "Alkalmazott törölve",
  EMPLOYMENT_REQUEST_ADDED: "Alkalmazotti felkérés hozzáadva",
  EMPLOYMENT_REQUEST_DELETED: "Alkalmazotti felkérés törölve",
  EMPLOYMENT_REQUEST_REJECTED: "Alkalmazotti felkérés elutasítva",
  FORTNOX_DISABLED: "Fortnox letiltva",
  FORTNOX_ENABLED: "Fortnox engedélyezve",
  FREE_TEXT_MEDICAL_RECORD_ADDED: "Jegyzet hozzáadva",
  FREE_TEXT_MEDICAL_RECORD_DELETED: "Jegyzet törölve",
  GIRO_ACCOUNTS_UPDATED: "Giro számlák módosítva",
  INVENTORY_CATEGORY_ADDED: "Raktár-kategória hozzáadva",
  INVENTORY_CATEGORY_DELETED: "Raktár-kategória törölve",
  INVENTORY_CATEGORY_UPDATED: "Raktár-kategória módosítva",
  INVENTORY_ITEMS_USED: "Raktárelemek felhasználva",
  INVENTORY_ITEM_ADDED: "Raktárelem hozzáadva",
  INVENTORY_ITEM_DELETED: "Raktárelem törölve",
  INVENTORY_ITEM_STOCK_ADDED: "Raktárelem készlet hozzáadva",
  INVENTORY_ITEM_STOCK_COUNTING_ADDED: "Raktárelem leltár hozzáadva",
  INVENTORY_ITEM_STOCK_COUNTING_DELETED: "Raktárelem leltár törölve",
  INVENTORY_ITEM_STOCK_DELETED: "Raktárelem készlet törölve",
  INVENTORY_ITEM_STOCK_UPDATED: "Raktárelem készlet módosítva",
  INVENTORY_ITEM_UPDATED: "Raktárelem módosítva",
  INVENTORY_ITEM_USAGE_ADDED: "Raktárelem használat hozzáadva",
  INVENTORY_ITEM_USAGE_DELETED: "Raktárelem használat törölve",
  INVENTORY_ITEM_USAGE_UPDATED: "Raktárelem használat módosítva",
  INVENTORY_UPDATED: "Raktárkészlet módosítva",
  INVOICE_ADDED: "Számla hozzáadva",
  INVOICE_BILLED: "Számla kiállítva",
  INVOICE_CANCELLED: "Számla visszavonva",
  INVOICE_DELETED: "Számla törölve",
  INVOICE_PAID: "Számla kifizetve",
  LOGO_ADDED: "Logó hozzáadva",
  LOGO_DELETED: "Logó törölve",
  MEDICAL_RECORD_CREATED: "Kórlap hozzáadva",
  MEDICAL_RECORD_MAIL_SENT: "Kórlap email elküldve",
  MEDICAL_RECORD_SIGNED: "Kórlap aláírva",
  PAYMENT_ADDED: "Fizetés hozzáadva",
  PAYMENT_METHOD_ADDED: "Fizetési mód hozzáadva",
  PAYMENT_METHOD_DELETED: "Fizetési mód törölve",
  PAYMENT_METHOD_UPDATED: "Fizetési mód módosítva",
  PAYMENT_REMINDER_SENT: "Fizetési emlékeztető elküldve",
  PAYMENT_SYNCED: "Fizetés szinkronizálva",
  PAYMENT_WITHDRAWN: "Fizetés visszavonva",
  PET_ADDED: "Állat hozzáadva",
  PET_ARCHIVED: "Állat archiválva",
  PET_OWNER_ADDED: "Gazda hozzáadva",
  PET_OWNER_ARCHIVED: "Gazda archiválva",
  PET_OWNER_UPDATED: "Gazda módosítva",
  PET_TRANSFERRED: "Állat átadva",
  PET_TRANSFER_STARTED: "Állat átadása kezdeményezve",
  PET_UPDATED: "Állat módosítva",
  PHARMACY_ADDED: "Gyógyszertár hozzáadva",
  PHARMACY_DELETED: "Gyógyszertár törölve",
  PHONE_ADDED: "Telefon hozzáadva",
  PHONE_CONFIRMATION_RESENT: "Telefon megerősítés újraküldve",
  PHONE_CONFIRMED: "Telefon megerősítve",
  PHONE_DELETED: "Telefon törölve",
  PRESCRIPTION_CREATED: "Recept hozzáadva",
  PRIMARY_ADDRESS_CHANGED: "Elsődleges cím módosítva",
  PRIMARY_EMAIL_CHANGED: "Elsődleges email módosítva",
  PRIMARY_PHONE_CHANGED: "Elsődleges telefon módosítva",
  RESERVATION_ADDED: "Foglalás hozzáadva",
  RESERVATION_DELETED: "Foglalás törölve",
  RESERVATION_TYPE_ADDED: "Foglalástípus hozzáadva",
  RESERVATION_TYPE_DELETED: "Foglalástípus törölve",
  RESERVATION_TYPE_UPDATED: "Foglalástípus módosítva",
  SERVICE_FEE_ADDED: "Szolgáltatási díj hozzáadva",
  SERVICE_FEE_DELETED: "Szolgáltatási díj törölve",
  SERVICE_FEE_UPDATED: "Szolgáltatási díj módosítva",
  SHIFT_ADDED: "Műszak hozzáadva",
  SHIFT_DELETED: "Műszak törölve",
  SHIFT_UPDATED: "Műszak módosítva",
  SMS_SENT: "SMS elküldve",
  STRIPE_DISABLED: "Stripe letiltva",
  STRIPE_ENABLED: "Stripe engedélyezve",
  SUBSTITUTION_ADDED: "Helyettesítés hozzáadva",
  SZAMLAZZ_DISABLED: "Szamlazz.hu letiltva",
  SZAMLAZZ_ENABLED: "Szamlazz.hu engedélyezve",
  TODO_ADDED: "Teendő hozzáadva",
  TODO_DELETED: "Teendő törölve",
  TODO_UPDATED: "Teendő módosítva",
  TREATMENT_ADDED: "Kezelés hozzáadva",
  TREATMENT_DELETED: "Kezelés törölve",
  TREATMENT_UPDATED: "Kezelés módosítva",
};
