/*
 * Copyright © 2018-2021, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

export const notificationTypeEn = {
  CLINIC_EMPLOYMENT_REQUEST: "When I am invited to work at a clinic",
  LINKED_UNREGISTERED_PETS: "When my pet's data is recorded at a new clinic",
  PET_TRANSFER_OLD_OWNER: "When the new owner accepts the transfer of my pet",
  PET_TRANSFER_NEW_OWNER: "When an owner transfers their pet to me",
  RESERVATION_REMINDER: "When my appointment date is approaching",
  RESERVATION_MODIFIED: "When my appointment is modified",
  RESERVATION_CANCELLED: "When my appointment is cancelled",

  ANNOUNCEMENT: "Announcement",
  SYSTEM_INFORMATION: "System information",
  MARKETING: "Marketing and promotion",
  CUSTOM: "Custom message",
};
