/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import * as process from "process";

// Custom interface that encapsulates process on the browser's window object
interface WindowWithProcess extends Window {
  process: typeof process;
  Buffer: unknown[];
}

const windowWithProcess = window as unknown as WindowWithProcess;

// Makes the global process object (from Node.JS environment) available on the window object in the browser
// This is a workaround for Node.js libraries where process is needed, and it also needs to run in the browser
const setupProcess = () => {
  windowWithProcess.process = process;
  windowWithProcess.Buffer = [];
};

export default setupProcess;
