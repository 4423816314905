/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import Select from "../../../components/ReactHookFormFields/General/Select/Select";
import DatePickerInput from "../../../components/ReactHookFormFields/ReactDateAndTimePickers/DatePickerInput";
import { prescriptionTreatmentPurpose, strings } from "../../../common/Strings/Strings";
import { Antimicrobial } from "../../../models/ePrescription/sending/Antimicrobial";

export interface OtherForm {
  antimicrobial?: Antimicrobial;
  validUntil?: Date;
}

interface Props {
  forEdit?: OtherForm;
  returnIsValid(value: boolean): void;
  returnValues(vales: OtherForm): void;
}

const OtherSettings = ({ forEdit, returnIsValid, returnValues }: Props): ReactElement => {
  const {
    control,
    register,
    formState: { errors, isValid },
    getValues,
    setValue,
  } = useForm<OtherForm>({ mode: "onChange", defaultValues: forEdit });

  const send = () => {
    returnValues(getValues());
  };

  useEffect(() => {
    returnIsValid(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  return (
    <form>
      <div className="flex flex-wrap md:flex-nowrap">
        <div className="mr-3">
          <Select
            name="antimicrobial"
            options={[
              {
                title: strings.notAntimicrobial,
                value: Antimicrobial.NOTANTIMICROBIAL,
              },
              {
                title: `${prescriptionTreatmentPurpose[Antimicrobial.THERAPY]} (${strings.therapyShortHelpText})`,
                value: Antimicrobial.THERAPY,
              },
              {
                title: `${prescriptionTreatmentPurpose[Antimicrobial.PROPHYLAXIS]} (${
                  strings.prophylaxisShortHelpText
                })`,
                value: Antimicrobial.PROPHYLAXIS,
              },
              {
                title: `${prescriptionTreatmentPurpose[Antimicrobial.METAPHYLAXIS]} (${
                  strings.metaphylaxisShortHelpText
                })`,
                value: Antimicrobial.METAPHYLAXIS,
              },
            ]}
            register={register}
            fieldOptions={{ deps: ["validUntil"] }}
            error={errors.antimicrobial}
            isChooseOption={false}
            label={strings.treatmentPurpose}
            onChange={() => {
              send();
            }}
            toolTipText={
              <>
                <b>{prescriptionTreatmentPurpose[Antimicrobial.PROPHYLAXIS]}:</b> {strings.prophylaxisLongHelpText}{" "}
                <b>{prescriptionTreatmentPurpose[Antimicrobial.METAPHYLAXIS]}:</b> {strings.metaphylaxisLongHelpText}
              </>
            }
          />
        </div>
        <div className="mr-3">
          <div className="d-flex justify-content-between">
            <label htmlFor="search" className="mr-3">
              {strings.validUntil}
            </label>
            <div
              role="button"
              className="text-sky-500"
              onClick={() => {
                setValue("validUntil", moment().add(4, "day").toDate(), {
                  shouldValidate: true,
                });
                send();
              }}
              style={{ cursor: "pointer", fontSize: "12px" }}
              tabIndex={0}
            >
              <b>+5</b>
            </div>
          </div>
          <DatePickerInput
            name="validUntil"
            control={control}
            fieldOptions={{
              validate: {
                isPast: (v: Date) => !moment(v).isBefore(moment(), "day"),
                isInAYear: (v: Date | null) => {
                  const oneYearLater = moment().add(1, "year").add(1, "day");
                  const now = moment(v);
                  return now.isBefore(oneYearLater, "day") || !v;
                },
                isAntimicrobial: (v: Date) => {
                  const fiveDaysLater = moment().add(4, "day");
                  const now = moment(v);
                  const antimicrobialValue = getValues("antimicrobial");
                  let result = true;

                  if (antimicrobialValue !== Antimicrobial.NOTANTIMICROBIAL) {
                    result = now.isSame(fiveDaysLater, "day") || !v;
                  }
                  return result;
                },
              },
            }}
            max={moment().add(1, "year")}
            min={moment()}
            toolTipText={strings.validUntilToolTip}
            onChange={() => {
              send();
            }}
          />
        </div>
      </div>
    </form>
  );
};

export default OtherSettings;
