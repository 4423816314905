/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { strings } from "../../../common/Strings/Strings";
import moment from "moment";

interface Props {
  title: string;
  message: string;
}

export default function PushNotificationPreview(props: Props): ReactElement {
  const { title, message } = props;

  return (
    <div
      className=" bg-gray-100 dark:bg-gray-700 shadow-lg rounded-xl p-4 flex space-x-3 transition-all"
      style={{ width: "70%" }}
    >
      <div className="w-12 h-12 flex items-center justify-center bg-blue-500 dark:bg-blue-400 rounded-full text-white">
        <img alt="GlobalVet" className="img-circle" src="/android-chrome-192x192.png" />
      </div>
      <div className="flex-1">
        <div className="text-gray-800 dark:text-gray-200 font-semibold">{title}</div>
        <div className="text-gray-600 dark:text-gray-300 text-sm">{message || strings.message}</div>
      </div>
      <div className="text-gray-500 dark:text-gray-400 text-xs mt-1">{moment().format("HH:mm")}</div>
    </div>
  );
}
