/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { strings } from "../../../common/Strings/Strings";
import moment from "moment";

interface Props {
  title: string;
  message: string;
}

export default function InAppNotificationPreview(props: Props): ReactElement {
  const { message } = props;

  return (
    <div className="tw-card flex justify-center items-center" style={{ width: "70%" }}>
      <div className="w-full flex flex-col py-2 px-3 text-left">
        <a className="text-decoration-none">
          <p className="text-sm font-normal">{message || strings.message}</p>
          <p className="text-xs font-light mt-1">{moment().fromNow()}</p>
        </a>
      </div>
    </div>
  );
}
