/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, RefObject } from "react";
import { useVoiceDetector } from "./useVoiceDetector";
import { Speaker } from "../../common/Icons/Speaker";
import { combineClassNames } from "../../util/HtmlUtils";
import { strings } from "../../common/Strings/Strings";
import { ArrowPath } from "../../common/Icons/ArrowPath";

interface Props {
  canSwitchCameraFacing: boolean;
  showVideo: boolean;
  showVoice: boolean;
  toggleCameraFacing: () => void;
  videoRef: RefObject<HTMLVideoElement>;
}

export const LocalVideoPreview: React.FC<Props> = ({
  canSwitchCameraFacing,
  showVideo,
  showVoice,
  toggleCameraFacing,
  videoRef,
}: Props): ReactElement => {
  const isVoiceActive = useVoiceDetector(videoRef.current);

  return (
    <div
      className={combineClassNames(
        "relative justify-center items-center w-full h-full rounded-2xl aspect-video bg-slate-100 dark:bg-slate-900 overflow-hidden ring-4 ring-inset ring-gray-200 dark:ring-gray-700",
        showVideo || showVoice ? "flex" : "hidden"
      )}
      id="video-wrapper"
    >
      <video
        className="w-full h-full rounded-2xl object-cover"
        autoPlay
        controls={false}
        hidden={!showVideo}
        id="user-video-preview"
        muted
        playsInline
        ref={videoRef}
      />
      <p className="text-xl font-bold text-zinc-800 dark:text-white" hidden={showVideo}>
        {strings.noCamera}
      </p>
      <div
        className="absolute top-0 right-0 w-full h-full rounded-2xl ring-4 ring-inset ring-blue-400 ring-blue-600"
        hidden={!isVoiceActive || !showVoice}
        id="active-voice-overlay"
      />
      <div
        className="absolute top-5 right-5 text-white bg-gray-500 bg-opacity-40 rounded-full p-2"
        hidden={!isVoiceActive || !showVoice}
      >
        <Speaker />
      </div>
      <button
        className="absolute bottom-5 right-5 text-white bg-gray-500 bg-opacity-40 rounded-full p-2"
        hidden={!showVideo || !canSwitchCameraFacing}
        onClick={toggleCameraFacing}
        type="button"
      >
        <ArrowPath />
      </button>
    </div>
  );
};
