/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useLocalStorage } from "@uidotdev/usehooks";
import { strings } from "../../../common/Strings/Strings";
import AlertBox, { AlertType } from "../../../components/AlertBox";
import { SiteManagerPage } from "../SiteManagerPage";
import { PageProps } from "../../../models/PageProps";
import { getGeneralError } from "../../../util/helperFunctions";
import VideoConsultationApi from "../../../api/VideoConsultationApi";
import { VideoChatRoom } from "../../../models/videoConsultation/VideoChatRooms";
import RoomInfo from "./RoomInfo";
import Tabs from "../../../components/Tabs/Tabs";
import { usePager } from "../../../hooks/hooks";
import DeleteRoomsModal from "./Modals/DeleteRoomsModal";
import Button from "../../../components/Button";
import SignalServerLogs from "./Logs/SignalServerLogs";
import RemoteClientLogs from "./Logs/RemoteClientLogs";
import DeleteRemoteClientLogsModal from "./Modals/DeleteRemoteClientLogsModal";

const POLL_INTERVAL = 1000; // 1 second

enum RoomTabs {
  ActiveRooms = strings.activeRooms,
  InactiveRooms = strings.inactiveRooms,
  Logs = strings.logs,
  RemoteClientLogs = strings.remoteClientLogs,
}

const VideoChatMonitor: React.FC<PageProps> = ({ setPageLoading }: PageProps) => {
  const [rooms, setRooms] = useState<VideoChatRoom[]>([]);
  const [currentSession, setCurrentSession] = useState<number>(1);
  const [currentTab, setCurrentTab] = useState<RoomTabs>(RoomTabs.ActiveRooms);
  const [error, setError] = useState<string | null>(null);

  const [roomEventsNewestFirst, _setRoomEventsNewestFirst] = useState<boolean>(true);
  const roomEventsPager = usePager(10);
  const usersPager = usePager(10);

  const [deleteRoomsModalVisible, setDeleteRoomsModalVisible] = useState<boolean>(false);
  const [deleteRemoteClientLogsModalVisible, setDeleteRemoteClientLogsModalVisible] = useState<boolean>(false);

  const [debugMode] = useLocalStorage<boolean>("debugMode");

  useEffect(() => {
    const fetchRooms = async () => {
      setError(null);

      try {
        const response = await VideoConsultationApi.getRooms(
          {
            newestFirst: roomEventsNewestFirst,
            pageNumber: roomEventsPager.pageNumber,
            pageSize: roomEventsPager.pageSize,
          },
          {
            pageNumber: usersPager.pageNumber,
            pageSize: usersPager.pageSize,
          },
          currentSession
        );
        setRooms(response.data);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setPageLoading(false);
      }
    };

    // Set the page loading state
    setPageLoading(true);

    // Fetch the initial data
    void fetchRooms();

    // Poll the server using the POLL_INTERVAL
    const intervalId = setInterval(fetchRooms, POLL_INTERVAL);

    // Cleanup on unmount
    return () => clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentSession,
    roomEventsNewestFirst,
    roomEventsPager.pageNumber,
    roomEventsPager.pageSize,
    usersPager.pageNumber,
    usersPager.pageSize,
  ]);

  const activeRooms = rooms.filter((r) => r.active);
  const inactiveRooms = rooms.filter((r) => !r.active);

  return (
    <>
      <main>
        <section>
          <div className="px-4 lg:px-6 py-6 space-y-6">
            {debugMode && (
              <div className="flex gap-4">
                <Button
                  className="modal-main-button"
                  fullWidth={false}
                  onClick={() => setDeleteRoomsModalVisible(true)}
                  variant="danger"
                >
                  {strings.deleteAllRooms}
                </Button>
                <Button
                  className="modal-main-button"
                  fullWidth={false}
                  onClick={() => setDeleteRemoteClientLogsModalVisible(true)}
                  variant="danger"
                >
                  {strings.deleteRemoteClientLogs}
                </Button>
              </div>
            )}
            <Tabs
              tabs={[
                { title: strings.activeRooms, tab: RoomTabs.ActiveRooms },
                {
                  title: strings.inactiveRooms,
                  tab: RoomTabs.InactiveRooms,
                },
                {
                  title: strings.logs,
                  tab: RoomTabs.Logs,
                },
                {
                  title: strings.remoteClientLogs,
                  tab: RoomTabs.RemoteClientLogs,
                },
              ]}
              currentTabKey={currentTab}
              setTab={setCurrentTab}
            />
            <AlertBox message={error} />
            {currentTab === RoomTabs.ActiveRooms && activeRooms.length === 0 && (
              <AlertBox closeAble={false} hidden={false} message={strings.noActiveRoomsMessage} type={AlertType.INFO} />
            )}
            {currentTab === RoomTabs.ActiveRooms && activeRooms.length > 0 && (
              <div className="flex flex-wrap items-center space-y-6">
                {activeRooms.map((room: VideoChatRoom) => (
                  <RoomInfo
                    currentSession={currentSession}
                    key={room.id}
                    room={room}
                    roomEventsPager={roomEventsPager}
                    setCurrentSession={setCurrentSession}
                    usersPager={usersPager}
                  />
                ))}
              </div>
            )}
            {currentTab === RoomTabs.InactiveRooms && inactiveRooms.length === 0 && (
              <AlertBox
                closeAble={false}
                hidden={false}
                message={strings.noInactiveRoomsMessage}
                type={AlertType.INFO}
              />
            )}
            {currentTab === RoomTabs.InactiveRooms && inactiveRooms.length > 0 && (
              <div className="flex flex-wrap items-center space-y-6">
                {inactiveRooms.map((room: VideoChatRoom) => (
                  <RoomInfo
                    currentSession={currentSession}
                    key={room.id}
                    room={room}
                    roomEventsPager={roomEventsPager}
                    setCurrentSession={setCurrentSession}
                    usersPager={usersPager}
                  />
                ))}
              </div>
            )}
            {currentTab === RoomTabs.Logs && <SignalServerLogs />}
            {currentTab === RoomTabs.RemoteClientLogs && <RemoteClientLogs />}
          </div>
        </section>
      </main>
      <DeleteRoomsModal onHide={() => setDeleteRoomsModalVisible(false)} show={deleteRoomsModalVisible} />
      <DeleteRemoteClientLogsModal
        onHide={() => setDeleteRemoteClientLogsModalVisible(false)}
        show={deleteRemoteClientLogsModalVisible}
      />
    </>
  );
};

export default SiteManagerPage(VideoChatMonitor, strings.videoConsultationRoomMonitor);
