/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React from "react";
import moment from "moment";
import { getStringFromEnumsOrReturn, strings } from "../../../common/Strings/Strings";
import { BroadcastNotificationResponse } from "../../../models/notification/BroadcastNotificationResponse";
import NumberFormatter from "../../../util/NumberFormatter";

interface Props {
  notification: BroadcastNotificationResponse;
}

const BroadcastNotificationDetails: React.FC<Props> = (props: Props) => {
  const { notification } = props;

  return (
    <div className="grid lg:grid-cols-2 gap-4 text-sm">
      <div className="tw-card space-y-3 px-3 py-3">
        <div hidden={!notification.title}>
          <div>{strings.title}</div>
          <div className="bold">{notification.title}</div>
        </div>
        <div hidden={!notification.message}>
          <div>{strings.message}</div>
          <div className="bold">{notification.message}</div>
        </div>
        <div hidden={!notification.email?.title}>
          <div>{strings.emailNotificationSubject}</div>
          <div className="bold">{notification.email?.title}</div>
        </div>
        <div hidden={!notification.email?.message}>
          <div>{strings.emailNotificationMessage}</div>
          <div className="bold">{notification.email?.message}</div>
        </div>
      </div>
      <div className="space-y-3">
        <div className="tw-card space-y-3 px-3 py-3">
          <div className="flex text-lg">
            <div className="flex-grow-1">
              <div>{strings.notificationsSent}</div>
              <div className="bold">{notification.count}</div>
            </div>
            <div className="flex-grow-1">
              <div>{strings.notificationsRead}</div>
              <div className="bold">{notification.read}</div>
            </div>
            <div className="flex-grow-1">
              <div>{strings.notificationSuccessRate}</div>
              <div className="bold">{NumberFormatter.round((notification.read / notification.count) * 100)} %</div>
            </div>
          </div>
        </div>
        <div className="tw-card space-y-3 px-3 py-3">
          <div>
            <div>{strings.notificationTargetGroup}</div>
            <div className="bold">{getStringFromEnumsOrReturn(notification.targetGroup)}</div>
          </div>
          <div hidden={!notification.countryCode}>
            <div>{strings.country}</div>
            <div className="bold">{notification.countryCode}</div>
          </div>
          <div hidden={!notification.locale}>
            <div>{strings.language}</div>
            <div className="bold">{notification.locale}</div>
          </div>
          <div>
            <div>{strings.deliveryType}</div>
            <div className="bold">{notification.deliveryTypes.join(", ")}</div>
          </div>
          <div>
            <div>{strings.notificationSendDate}</div>
            <div className="bold">
              <span title={moment(notification.sent).format("LLL")}>{moment(notification.sent).fromNow()}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BroadcastNotificationDetails;
