/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { PageProps } from "../../../models/PageProps";
import { Loader } from "../../../components/Loader";
import { getGeneralError, howOld } from "../../../util/helperFunctions";
import { getStringFromEnumsOrReturn, petGenders, petSpecies, strings } from "../../../common/Strings/Strings";
import { PetDetailsResponse } from "../../../models/pet/PetDetailsResponse";
import ClinicPetApi from "../../../api/ClinicPetApi";
import PetListRowActions from "../../PetOwner/ClinicPetOwner/PetListRowActions";
import PetHistory from "../History/PetHistory";
import { PetOrOwnerTodos } from "../../Todo/PetOrOwnerTodos";
import { ClinicPetAndOwnerResponse } from "../../../models/pet/ClinicPetAndOwnerResponse";
import { TodoResponse } from "../../../models/todo/TodoResponse";
import TodoApi from "../../../api/TodoApi";
import { useDebouncedState } from "../../../hooks/hooks";
import { PetOwnerResponse } from "../../../models/pet/PetOwnerResponse";
import AlertBox from "../../../components/AlertBox";
import PetProfilePicture from "../../../components/Pictures/Pet/PetProfilePicture";
import Tabs from "../../../components/Tabs/Tabs";
import { Card } from "../../../components/Cards/Card";
import { Heart } from "../../../common/Icons/Heart";
import Tooltip from "../../../components/Tooltip";
import { generatePetAgeText } from "../../PetOwner/UserPetOwner/Pets/PetListRow";

export enum PetProfileTabs {
  basic = "basic",
  todos = "todos",
  history = "history",
}

type Params = {
  clinicPetId?: string;
  tabKey?: string;
};

const ClinicPetProfile: React.FC<PageProps> = ({ setPageLoading }: PageProps) => {
  const navigate = useNavigate();

  const [clinicPet, setClinicPet] = useState<PetDetailsResponse>();
  const [petResponse, setPetResponse] = useState<ClinicPetAndOwnerResponse>();
  const [userPet, setUserPet] = useState<PetDetailsResponse>();
  const [petOwner, setPetOwner] = useState<PetOwnerResponse>();
  const [error, setError] = useState<string | null>(null);
  const { tabKey, clinicPetId } = useParams<Params>();
  const [key, setKey] = useState<PetProfileTabs>((tabKey as PetProfileTabs) || PetProfileTabs.basic);

  const [todos, setTodos] = useState<TodoResponse[]>([]);
  const reloadTodos = useDebouncedState<boolean>(true, 600);
  const [loadingTodos, setLoadingTodos] = useState<boolean>(false);
  const [todoFilter, setTodoFilter] = useState<boolean>(false);
  const [todoError, setTodoError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPet = async (id?: string) => {
      if (id) {
        try {
          const resp = await ClinicPetApi.getClinicPet(id);
          setClinicPet(resp.data.pet.clinicPetDetails);
          setUserPet(resp.data.pet.userPetDetails);
          setPetOwner(resp.data.petOwner);
          setPetResponse(resp.data);
        } catch (e) {
          setError(await getGeneralError(e));
        } finally {
          setPageLoading(false);
        }
      }
    };

    if (clinicPetId) {
      void fetchPet(clinicPetId);
    }
  }, [clinicPetId, setPageLoading]);

  const triggerReload = (completed?: boolean) => {
    if (completed !== undefined && completed !== todoFilter) {
      setTodoFilter(completed);
    }
    setLoadingTodos(true);
    reloadTodos.setValue(!reloadTodos.value);
  };

  useEffect(() => {
    const getTodos = async (petId?: string) => {
      if (petId) {
        setLoadingTodos(true);
        try {
          const response = await TodoApi.getTodosForPet(petId, todoFilter);
          setTodos(response.data);
        } catch (e) {
          setTodoError(await getGeneralError(e));
        } finally {
          setLoadingTodos(false);
        }
      }
    };

    void getTodos(petResponse?.pet.clinicPetDetails.id);
  }, [petResponse?.pet.clinicPetDetails.id, todoFilter]);

  const yearsOld = howOld(moment(clinicPet?.dateOfBirth), "year") || 0;
  const monthsOld = howOld(moment(clinicPet?.dateOfBirth), "month") || 0;

  return (
    <main className="main-signed-in">
      <section>
        <div className="px-4 lg:px-6 py-6 space-y-6">
          <div className="flex flex-wrap items-center">
            <div style={{ width: "100px", height: "100px" }}>
              <PetProfilePicture
                species={clinicPet?.species}
                petId={userPet?.id}
                profilePictureId={userPet?.profilePicture}
              />
            </div>
            <div className="text-xl font-bold lg:text-2xl text-zinc-800 dark:text-white ml-3 flex items-center">
              {clinicPet?.name}
              {clinicPet?.deceased ? (
                <div>
                  <Tooltip content={strings.deceased}>
                    <Heart className="ml-2" variant="solid" />
                  </Tooltip>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="ml-auto">
              <div className="flex">
                {petResponse && (
                  <PetListRowActions
                    pet={petResponse.pet}
                    owner={petOwner}
                    onError={(e) => setError(e)}
                    onLoad={setPageLoading}
                  />
                )}
              </div>
            </div>
          </div>

          <Tabs
            tabs={[
              { title: strings.mainPage, tab: PetProfileTabs.basic },
              {
                title: `${strings.tasks} (${todos.length})`,
                tab: PetProfileTabs.todos,
              },
              { title: strings.history, tab: PetProfileTabs.history },
            ]}
            currentTabKey={key}
            setTab={setKey}
          />

          <AlertBox message={error} />

          {key === PetProfileTabs.basic && (
            <>
              <Card
                titleButtonOnClick={() => navigate(`/edit-clinic-pet/${clinicPet?.id}`)}
                size="max"
                title={strings.basicInfo}
                type="simple"
              >
                <div className="row my-3">
                  <div className="col-6 col-md-2 py-1">
                    <div>{strings.species}</div>
                    <div className="bold">{clinicPet?.species && petSpecies[clinicPet?.species]}</div>
                  </div>
                  <div className="col-6 col-md-2 py-1">
                    <div>{strings.breed}</div>
                    <div className="bold">{clinicPet?.breed}</div>
                  </div>
                  <div className="col-6 col-md-2 py-1">
                    <div>{strings.gender}</div>
                    <div className="bold">{clinicPet?.gender && petGenders[clinicPet?.gender]}</div>
                  </div>
                  <div className="col-6 col-md-2 py-1">
                    <div>{strings.color}</div>
                    <div className="bold">{clinicPet?.color?.toLowerCase()}</div>
                  </div>
                  <div className="col-6 col-md-2 py-1">
                    <div>{strings.dateOfBirth}</div>
                    <div className="bold">
                      {clinicPet?.dateOfBirth ? (
                        <>
                          {clinicPet?.isDateOfBirthEstimated && "~ "}
                          {moment(clinicPet.dateOfBirth).format("ll")}{" "}
                          <div>({generatePetAgeText(yearsOld, monthsOld)})</div>
                        </>
                      ) : (
                        <>-</>
                      )}
                    </div>
                  </div>
                  <div className="col-6 col-md-2 py-1">
                    <div>{strings.chipNumber}</div>
                    <div className="bold">{clinicPet?.chipNumber}</div>
                  </div>
                  <div className="col-6 col-md-2 py-1">
                    <div>{strings.tattooNumber}</div>
                    <div className="bold">{clinicPet?.tattooNumber}</div>
                  </div>
                  <div className="col-6 col-md-2 py-1">
                    <div>{strings.passportNumber}</div>
                    <div className="bold">{clinicPet?.passportNumber}</div>
                  </div>
                  <div className="col-6 col-md-2 py-1">
                    <div>{strings.insuranceCompany}</div>
                    <div className="bold">{clinicPet?.insuranceCompany?.name}</div>
                  </div>
                  <div className="col-6 col-md-2 py-1">
                    <div>{strings.insuranceNumber}</div>
                    <div className="bold">{clinicPet?.insuranceNumber}</div>
                  </div>
                  <div className="col-6 col-md-2 py-1">
                    <div>{strings.chronicDiseases}</div>
                    <div>
                      {clinicPet?.chronicDiseases?.map((disease) => (
                        <span className="badge badge-pill badge-danger mr-2 mb-2">
                          {getStringFromEnumsOrReturn(disease)}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="col-6 col-md-4 py-1">
                    <div>{strings.comment}</div>
                    <div className="bold">{clinicPet?.comment}</div>
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-6 col-md-2 py-1">
                    <div>{strings.petOwner}</div>
                    <Link to={`/pet-owner-profile/${petOwner?.petOwnerDetails.id}`}>
                      {petOwner?.petOwnerDetails.fullName}
                    </Link>
                  </div>
                  <div className="col-6 col-md-2 py-1">
                    <div>{strings.ownerPhone}</div>
                    <div className="bold">{petOwner?.petOwnerDetails.phones[0]?.value}</div>
                  </div>
                </div>
              </Card>
              <Card
                titleButtonContent={strings.seeAllTasks}
                titleButtonOnClick={() => setKey(PetProfileTabs.todos)}
                size="max"
                title={`${strings.tasks} (${todos.length})`}
                type="simple"
              >
                <PetOrOwnerTodos
                  error={todoError}
                  filter={todoFilter}
                  limitResults={4}
                  loading={loadingTodos}
                  petAndOwner={petResponse}
                  reload={triggerReload}
                  todos={todos}
                />
              </Card>
              <Card
                titleButtonContent={strings.seeAllHistory}
                titleButtonOnClick={() => setKey(PetProfileTabs.history)}
                size="max"
                title={strings.history}
                type="simple"
              >
                <PetHistory limitResults={10} petId={clinicPet?.id} pet={petResponse?.pet} owner={petOwner} />
              </Card>
            </>
          )}
          {key === PetProfileTabs.todos && (
            <PetOrOwnerTodos
              error={todoError}
              filter={todoFilter}
              loading={loadingTodos}
              petAndOwner={petResponse}
              reload={triggerReload}
              todos={todos}
            />
          )}
          {key === PetProfileTabs.history && (
            <PetHistory petId={clinicPet?.id} pet={petResponse?.pet} owner={petOwner} />
          )}
        </div>
      </section>
    </main>
  );
};

export default Loader(ClinicPetProfile);
