export function loadFirebaseConfig() {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = `${process.env.PUBLIC_URL}/firebase.js`;
    script.onload = () => {
      // Check if the config is loaded on window
      if (firebaseConfig) {
        resolve(firebaseConfig);
      } else {
        reject(new Error("Config not loaded"));
      }
    };
    script.onerror = () =>
      reject(new Error("Failed to load firebase-config.js"));
    document.body.appendChild(script);
  });
}
