/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PageProps } from "../../../models/PageProps";
import { getAddressString, getGeneralError } from "../../../util/helperFunctions";
import PetOwnerApi from "../../../api/PetOwnerApi";
import { Loader } from "../../../components/Loader";
import { strings } from "../../../common/Strings/Strings";
import { ClinicPetResponse } from "../../../models/pet/ClinicPetResponse";
import PetList from "./PetList";
import { PublicAddressResponse } from "../../../models/contact/PublicAddressResponse";
import { PublicEmailResponse } from "../../../models/contact/PublicEmailResponse";
import { PublicPhoneResponse } from "../../../models/contact/PublicPhoneResponse";
import PetSearch, { SpeciesFilter } from "../UserPetOwner/Pets/PetSearch";
import AlertBox from "../../../components/AlertBox";
import { TodoResponse } from "../../../models/todo/TodoResponse";
import TodoApi from "../../../api/TodoApi";
import { PetOwnerResponse } from "../../../models/pet/PetOwnerResponse";
import { useDebouncedState } from "../../../hooks/hooks";
import { PetOrOwnerTodos } from "../../Todo/PetOrOwnerTodos";
import UserProfilePicture from "../../../components/Pictures/User/UserProfilePicture";
import Tabs from "../../../components/Tabs/Tabs";
import Button from "../../../components/Button";
import { Pencil } from "../../../common/Icons/Pencil";
import { Card } from "../../../components/Cards/Card";
import PetOwnerActions from "./PetOwnerActions";

enum PetOwnerProfileTabs {
  basic = "basic",
  todos = "todos",
  pets = "pets",
  invoices = "invoices",
}

const PetOwnerProfile: React.FC<PageProps> = ({ setPageLoading }: PageProps) => {
  const navigate = useNavigate();

  const [petOwner, setPetOwner] = useState<PetOwnerResponse>();
  const [pets, setPets] = useState<ClinicPetResponse[]>([]);
  const [filteredPets, setFilteredPets] = useState<ClinicPetResponse[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [key, setKey] = useState<PetOwnerProfileTabs>(PetOwnerProfileTabs.basic);
  const [filters, setFilters] = useState<{
    speciesFilters?: SpeciesFilter;
    query?: string;
  }>();

  const [todos, setTodos] = useState<TodoResponse[]>([]);
  const reloadTodos = useDebouncedState<boolean>(true, 600);
  const [loadingTodos, setLoadingTodos] = useState<boolean>(false);
  const [todoFilter, setTodoFilter] = useState<boolean>(false);
  const [todoError, setTodoError] = useState<string | null>(null);

  const { petOwnerId } = useParams<"petOwnerId">();

  useEffect(() => {
    const fetchPetOwner = async (id: string) => {
      try {
        const resp = await PetOwnerApi.getPetOwner(id);
        setPetOwner(resp.data.petOwner);
        setPets(resp.data.pets);
        setFilteredPets(resp.data.pets);
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setPageLoading(false);
      }
    };

    if (petOwnerId) {
      void fetchPetOwner(petOwnerId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petOwnerId]);

  const triggerReload = (completed?: boolean) => {
    if (completed !== undefined && completed !== todoFilter) {
      setTodoFilter(completed);
    }
    setLoadingTodos(true);
    reloadTodos.setValue(!reloadTodos.value);
  };

  useEffect(() => {
    const getTodos = async (id?: string) => {
      if (id) {
        setLoadingTodos(true);
        try {
          const response = await TodoApi.getTodosForPetOwner(id, false);
          setTodos(response.data);
        } catch (e) {
          setTodoError(await getGeneralError(e));
        } finally {
          setLoadingTodos(false);
        }
      }
    };

    void getTodos(petOwnerId);
  }, [petOwnerId, reloadTodos.value]);

  const applyFilters = (speciesFilters?: SpeciesFilter, query?: string) => {
    setFilters({ speciesFilters, query });

    const newList = pets.filter(({ clinicPetDetails }: ClinicPetResponse) => {
      const isName = query ? clinicPetDetails.name.toLowerCase().includes(query) : true;
      const isSpecies = speciesFilters
        ? speciesFilters.includes("all") || speciesFilters.includes(clinicPetDetails.species)
        : true;

      return isName && isSpecies;
    });

    setFilteredPets(newList);
    setKey(PetOwnerProfileTabs.pets);
  };

  return (
    <main className="main-signed-in">
      <section>
        <div className="px-4 lg:px-6 py-6 space-y-6">
          <div className="flex flex-wrap items-center">
            <div style={{ width: "100px", height: "100px" }}>
              <UserProfilePicture userId={petOwner?.userDetails?.id} />
            </div>
            <div className="text-xl font-bold lg:text-2xl text-zinc-800 dark:text-white ml-3">
              {petOwner?.petOwnerDetails.fullName}
            </div>
            <div className="ml-auto">
              {petOwner && <PetOwnerActions owner={petOwner} onError={(e) => setError(e)} onLoad={setPageLoading} />}
            </div>
          </div>

          <Tabs
            tabs={[
              { title: strings.mainPage, tab: PetOwnerProfileTabs.basic },
              {
                title: `${strings.tasks} (${todos.length})`,
                tab: PetOwnerProfileTabs.todos,
              },
              {
                title: `${strings.pets} (${pets.length})`,
                tab: PetOwnerProfileTabs.pets,
              },
            ]}
            currentTabKey={key}
            setTab={setKey}
          />

          <AlertBox message={error} />

          {key === PetOwnerProfileTabs.basic && (
            <>
              <Card
                size="max"
                title={strings.basicInfo}
                titleButtonContent={<Pencil />}
                titleButtonOnClick={() => navigate(`/edit-pet-owner-profile/${petOwner?.petOwnerDetails.id}`)}
                titleButtonVariant="icon"
                type="simple"
              >
                <div className="row my-3">
                  <div className="col-6 col-md-2 py-1">
                    <div>{strings.personalId}</div>
                    <div className="bold">{petOwner?.petOwnerDetails.personalId}</div>
                  </div>
                  <div className="col-6 col-md-2 py-1">
                    <div>{strings.phones}</div>
                    {petOwner?.phones?.map((phone: PublicPhoneResponse) => (
                      <div className="bold" key={phone.value}>
                        {phone.value}
                      </div>
                    ))}
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 py-1">
                    <div>{strings.emails}</div>
                    {petOwner?.emails?.map((email: PublicEmailResponse) => (
                      <div className="bold" key={email.value}>
                        {email.value}
                      </div>
                    ))}
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 py-1">
                    <div>{strings.addresses}</div>
                    {petOwner?.addresses?.map((address: PublicAddressResponse, index) => (
                      <div className="bold" key={index}>
                        {getAddressString(address)}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-12 py-1">
                    <div>{strings.comment}</div>
                    <div className="bold">{petOwner?.comment}</div>
                  </div>
                </div>
              </Card>

              <Card
                size="max"
                title={`${strings.tasks} (${todos.length})`}
                titleButtonContent={strings.seeAllTasks}
                titleButtonOnClick={() => {
                  setKey(PetOwnerProfileTabs.todos);
                }}
                type="simple"
              >
                <PetOrOwnerTodos
                  error={todoError}
                  filter={todoFilter}
                  limitResults={4}
                  loading={loadingTodos}
                  petOwner={petOwner}
                  reload={triggerReload}
                  todos={todos}
                />
              </Card>

              <Card
                size="max"
                title={`${strings.pets} (${pets.length})`}
                titleButtonContent={strings.seeAllPets}
                titleButtonOnClick={() => {
                  setKey(PetOwnerProfileTabs.pets);
                }}
                type="simple"
              >
                <div className="flex flex-wrap items-end">
                  <PetSearch returnFilters={applyFilters} />
                  <div className="ml-auto">
                    <Link to={`/add-new-clinic-pet/${petOwner?.petOwnerDetails.id}`}>
                      <Button variant="primary">{strings.addPet}</Button>
                    </Link>
                  </div>
                </div>
                <PetList
                  petOwner={petOwner}
                  pets={pets}
                  showPageSection={false}
                  onError={(e) => setError(e)}
                  onLoad={setPageLoading}
                />
              </Card>
            </>
          )}

          {key === PetOwnerProfileTabs.todos && (
            <PetOrOwnerTodos
              error={todoError}
              filter={todoFilter}
              limitResults={4}
              loading={loadingTodos}
              petOwner={petOwner}
              reload={triggerReload}
              todos={todos}
            />
          )}

          {key === PetOwnerProfileTabs.pets && (
            <>
              <div className="flex flex-wrap items-end">
                <PetSearch defaultFilters={filters} returnFilters={applyFilters} />
                <div className="ml-auto">
                  <Link to={`/add-new-clinic-pet/${petOwner?.petOwnerDetails.id}`}>
                    <Button variant="primary">{strings.addPet}</Button>
                  </Link>
                </div>
              </div>
              <PetList petOwner={petOwner} pets={filteredPets} onError={(e) => setError(e)} onLoad={setPageLoading} />
            </>
          )}
        </div>
      </section>
    </main>
  );
};

export default Loader(PetOwnerProfile);
