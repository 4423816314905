/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { SavedReservationProgress } from "../models/calendar/SavedReservationProgress";
import { CalendarReservation } from "../models/calendar/CalendarReservation";
import { setLocale, SupportedLanguages } from "../common/Strings/Strings";

/* General */
export const getFromLocalStorage = (key: string): string | null => localStorage.getItem(key);

export const setInLocalStorage = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const deleteFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

/* Authentication */
export const accessTokenKey = "accessToken";
export const refreshTokenKey = "refreshToken";
export const mfaRefreshTokenKey = "mfaRefreshToken";

export const getAccessToken = () => getFromLocalStorage(accessTokenKey);

export const setAccessToken = (value: string) => {
  setInLocalStorage(accessTokenKey, value);
};

export const getRefreshToken = () => getFromLocalStorage(refreshTokenKey);

export const setRefreshToken = (value: string) => {
  setInLocalStorage(refreshTokenKey, value);
};

export const getMfaRefreshToken = () => getFromLocalStorage(mfaRefreshTokenKey);

export const setMfaRefreshToken = (value: string) => {
  setInLocalStorage(mfaRefreshTokenKey, value);
};

/* MFA */
const mfaKey = "mfa_token";
const skipMfaTokenKey = "_skip_mfa_token";

export const getMfaToken = (): string | null => getFromLocalStorage(mfaKey);

export const setMfaToken = (token: string) => {
  setInLocalStorage(mfaKey, token);
};

export const removeMfaToken = () => {
  localStorage.removeItem(mfaKey);
};

export const getSkipMfaTokenForEmail = (email: string): string | null =>
  getFromLocalStorage(`${email}${skipMfaTokenKey}`);

export const setSkipMfaTokenForEmail = (email: string, token: string) => {
  setInLocalStorage(`${email}${skipMfaTokenKey}`, token);
};

/* User Role */
export type UserRole = "owner" | "doctor" | "manager";

/* User */
export const userIdKey = "userId";

export const getActiveUserId = (): string | null => getFromLocalStorage(userIdKey);

export const setActiveUserId = (id: string) => {
  setInLocalStorage(userIdKey, id);
};

export const pushTokenKey = "pushToken";

export const getPushToken = (): string | null => getFromLocalStorage(pushTokenKey);

export const setPushToken = (token: string) => {
  setInLocalStorage(pushTokenKey, token);
};

/* Logged in users */
export interface LoggedInUser {
  activeClinicId?: string;
  fastLoginToken?: string;
  locale?: string | null;
  name?: string;
  email?: string;
  refreshToken?: string;
  role?: UserRole;
  userId: string;
  profilePictureId?: string;
}

const loggedInUsersKey = "loggedInUsers";

export const getLoggedInUsers = (): LoggedInUser[] => {
  const userArray = getFromLocalStorage(loggedInUsersKey);
  return userArray ? (JSON.parse(userArray) as LoggedInUser[]) : [];
};

const getLoggedInUserById = (id: string): LoggedInUser | undefined => {
  const users = getLoggedInUsers();
  return users.find((u) => u.userId === id);
};

export const setLoggedInUsers = (newUsers: LoggedInUser[]): void => {
  setInLocalStorage(loggedInUsersKey, JSON.stringify(newUsers));
};

export const getActiveLoggedInUser = (): LoggedInUser | undefined => {
  const users = getLoggedInUsers();
  const activeUserId = getActiveUserId();

  return users.find((u) => u.userId === activeUserId);
};

export const updateLoggedInUser = (update: (user: LoggedInUser) => LoggedInUser, userId?: string) => {
  const loggedInUsers = getLoggedInUsers();
  const id = userId || getActiveUserId();

  if (id !== undefined && id !== null) {
    const userToUpdate = loggedInUsers.find((u) => u.userId === id);
    if (userToUpdate) {
      const filteredUsers = loggedInUsers.filter((u) => u.userId !== id);
      setLoggedInUsers([...filteredUsers, update(userToUpdate)]);
    }
  }
};

export const addOrUpdateLoggedInUser = (user: LoggedInUser) => {
  const users: LoggedInUser[] = getLoggedInUsers();
  const foundUser = users.find((u: LoggedInUser) => u.userId === user.userId);

  if (!foundUser) {
    users.push(user);
    setLoggedInUsers(users);
  } else if (foundUser) {
    updateLoggedInUser(
      (u) => ({
        ...u,
        ...user,
      }),
      foundUser.userId
    );
  }
};

export const getValueOfActiveUser = (key: keyof LoggedInUser): any | null => {
  const activeUser = getActiveLoggedInUser();
  return activeUser?.[key] ?? null;
};

const getValueOfUserByUserId = (userId: string, key: keyof LoggedInUser): any | null => {
  const user = getLoggedInUserById(userId);
  return user ? user[key] : null;
};

export const setRoleOfActiveUser = (role: UserRole) => {
  updateLoggedInUser((u: LoggedInUser) => ({
    ...u,
    role,
  }));
};

/* Language settings */
export const unauthenticatedLocaleKey = "lan";
export const userLocaleKey = "_locale";

export const getSystemLanguageCode = (): string => {
  const userAgentLanguage = navigator.language.substring(0, 2);
  return SupportedLanguages.find((lang) => lang.code === userAgentLanguage)?.code ?? SupportedLanguages[0].code;
};

// Gets the full official locale code based on the short language code
export const getFullLocale = (shortCode: string): string =>
  SupportedLanguages.find((lang) => lang.code === shortCode)?.fullCode ?? shortCode;

const setDefaultSimpleLanguage = () => {
  setInLocalStorage(unauthenticatedLocaleKey, getSystemLanguageCode());
};

export const setLocaleForActiveUser = (value: string) => {
  updateLoggedInUser((u: LoggedInUser) => ({
    ...u,
    locale: value,
  }));
};

const setDefaultActiveUserLanguage = () => {
  setLocaleForActiveUser(getSystemLanguageCode());
};

// Gets the locale of the currently active user.
// Sets a default locale on the front-end only when the locale is missing.
export const getLocaleForActiveUser = (getFullCode = false): string | null => {
  let userLanguage = getValueOfActiveUser("locale");

  if (!userLanguage) {
    setDefaultActiveUserLanguage();
    userLanguage = getValueOfActiveUser("locale");
  }

  const locale = userLanguage ?? getSystemLanguageCode();
  return getFullCode ? getFullLocale(locale) : locale;
};

export const setSimpleLanguage = (value: string) => {
  setInLocalStorage(unauthenticatedLocaleKey, value);
};

export const getSimpleLanguage = (): string | null => {
  let unauthenticatedLanguage = getFromLocalStorage(unauthenticatedLocaleKey);

  if (!unauthenticatedLanguage) {
    setDefaultSimpleLanguage();
    unauthenticatedLanguage = getFromLocalStorage(unauthenticatedLocaleKey);
  }

  return unauthenticatedLanguage;
};

/* Switch account data */
const emailKey = "email";
const profilePictureIdKey = "profilePictureId";

export const getEmailForUser = (userId: string) => getValueOfUserByUserId(userId, "email");

export const setEmailForActiveUser = (value: string) => {
  updateLoggedInUser((u: LoggedInUser) => ({
    ...u,
    [emailKey]: value,
  }));
};

export const getProfilePictureIdForUser = (userId: string) => getValueOfUserByUserId(userId, "email");

export const setProfilePictureIdForActiveUser = (value: string) => {
  updateLoggedInUser((u: LoggedInUser) => ({
    ...u,
    [profilePictureIdKey]: value,
  }));
};

/* Calendar */
export const appointmentToRescheduleKey = "appointmentToReschedule";
export const newReservationFormDataKey = "newReservationFormData";
export const calendarSelectedDayKey = "calendarSelectedDay";
export const calendarSelectedViewKey = "calendarSelectedView";

export const saveReservationInProgress = (reservation: SavedReservationProgress) => {
  setInLocalStorage(newReservationFormDataKey, JSON.stringify(reservation));
};

export const saveReservationToReschedule = (reservation: CalendarReservation) => {
  setInLocalStorage(appointmentToRescheduleKey, JSON.stringify(reservation));
};

/* Logout */

// The key variables in local storage that should be retained by default when the user logs out.
const DefaultPreservableVariables = ["loggedInUsers", userLocaleKey, "skip_mfa_token", "darkMode", "mutedTooltips"];

// Clears the local and session storages
// Allows preserving variables and the language information too.
export const clearStorages = (preservableVariables = DefaultPreservableVariables, preserveLanguage = true) => {
  // Preserve specific items from localStorage
  const preserved = Object.entries({ ...localStorage })
    .filter(([key]) => preservableVariables.includes(key))
    .map(([key, value]) => ({ key, value }));

  // Preserve language information if available
  const lan = getSimpleLanguage();
  if (lan && preserveLanguage) {
    preserved.push({ key: unauthenticatedLocaleKey, value: lan });
  }

  // Clear storages
  sessionStorage.clear();
  localStorage.clear();

  // Restore the preserved items to localStorage
  preserved.forEach(({ key, value }) => {
    if (value !== undefined && value !== null) {
      localStorage.setItem(key, value);
    }
  });

  // Restore the preserved language if available
  if (lan && preserveLanguage) {
    setLocale(lan);
  }
};
