/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { howOld } from "../../../../util/helperFunctions";
import { getStringFromEnumsOrReturn, petGenders, petSpecies, strings } from "../../../../common/Strings/Strings";
import { PetDetailsResponse } from "../../../../models/pet/PetDetailsResponse";
import PetListRowActions from "../../ClinicPetOwner/PetListRowActions";
import UserPetListRowActions from "./UserPetListRowActions";
import { ChronicDisease } from "../../../../models/medical/ChronicDisease";
import { PetProfileTabs } from "../../../Pet/PetProfile/ClinicPetProfile";
import { PetOwnerResponse } from "../../../../models/pet/PetOwnerResponse";
import PetProfilePicture from "../../../../components/Pictures/Pet/PetProfilePicture";
import Tooltip from "../../../../components/Tooltip";
import { Heart } from "../../../../common/Icons/Heart";

interface Props {
  isUserSide: boolean;
  petDetails: PetDetailsResponse;
  petOwner?: PetOwnerResponse;
  userPet?: PetDetailsResponse;
  onError: (error: string | null) => void;
  onLoad: (loading: boolean) => void;
}

export const generatePetAgeText = (yearsOld: number, monthsOld: number): string => {
  if (yearsOld > 0) {
    if (monthsOld > 0) {
      return `${yearsOld} ${strings.years} ${monthsOld} ${strings.monthCnt}`;
    } else {
      return `${yearsOld} ${strings.years}`;
    }
  }
  if (monthsOld > 0) {
    return `${monthsOld} ${strings.monthCnt}`;
  }
  return `< 1 ${strings.monthCnt}`;
};

export const basicPetDetails = (pet: PetDetailsResponse): string =>
  [
    petSpecies[pet.species],
    petGenders[pet.gender],
    pet.breed,
    pet.color?.toLowerCase(),
    pet.lastMedicalRecordWeight ? `${pet.lastMedicalRecordWeight} kg` : "",
  ]
    .filter((p) => p)
    .join(", ");

const PetListRow = (props: Props): ReactElement => {
  const { isUserSide, petDetails, petOwner, userPet, onError, onLoad } = props;
  const yearsOld = howOld(moment(isUserSide ? userPet?.dateOfBirth : petDetails.dateOfBirth), "year") || 0;
  const monthsOld = howOld(moment(isUserSide ? userPet?.dateOfBirth : petDetails.dateOfBirth), "month") || 0;

  return (
    <div className="flex p-4 items-center flex-wrap">
      <div className="mr-4 w-16 h-16 lg:w-24 lg:h-24">
        <PetProfilePicture
          petId={userPet?.id}
          profilePictureId={userPet?.profilePicture}
          species={petDetails.species}
        />
      </div>
      <div>
        <div className="flex items-center">
          <Link
            to={
              isUserSide
                ? `/pet-profile/${petDetails.id}`
                : `/clinic-pet-profile/${petDetails.id}/${PetProfileTabs.basic}`
            }
          >
            {petDetails.name}
          </Link>
          {petDetails.deceased ? (
            <div>
              <Tooltip content={strings.deceased}>
                <Heart className="ml-2" variant="solid" />
              </Tooltip>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div>{basicPetDetails(petDetails)}</div>
        {petDetails.dateOfBirth ? (
          <div className="flex flex-wrap space-x-2">
            <div>{strings.dateOfBirth}:</div>
            <div className="flex">
              {petDetails?.isDateOfBirthEstimated && "~ "}
              {moment(petDetails.dateOfBirth).format("ll")}
              <div className="ml-2">({generatePetAgeText(yearsOld, monthsOld)})</div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div>
          {petDetails.chronicDiseases?.map((disease: ChronicDisease, index) => (
            <span className="badge badge-pill badge-danger mr-2 mb-2" key={index}>
              {getStringFromEnumsOrReturn(disease)}
            </span>
          ))}
        </div>
        <div className="font-bold">
          {petDetails.comment ? (
            <>
              {strings.comment}: {petDetails.comment}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className="ml-auto flex">
        {isUserSide ? (
          <UserPetListRowActions petId={petDetails.id} />
        ) : (
          <PetListRowActions
            pet={{ clinicPetDetails: petDetails, userPetDetails: userPet }}
            owner={petOwner}
            onError={onError}
            onLoad={onLoad}
          />
        )}
      </div>
    </div>
  );
};

export default PetListRow;
