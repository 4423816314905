/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import VideoConsultationApi from "../../../../api/VideoConsultationApi";
import { getGeneralError } from "../../../../util/helperFunctions";
import AlertBox, { AlertType } from "../../../../components/AlertBox";
import { strings } from "../../../../common/Strings/Strings";
import Modal from "../../../../components/Modal/Modal";
import Button from "../../../../components/Button";
import CloseButton from "../../../../components/CloseButton";

interface Props {
  onHide: () => void;
  show: boolean;
}

const DeleteRemoteClientLogsModal: React.FC<Props> = ({ onHide, show }: Props) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const deleteRemoteClientLogs = async () => {
    setError(null);
    setLoading(true);
    try {
      await VideoConsultationApi.deleteRemoteClientLogs();
      onHide();
      window.location.reload();
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal handleClose={onHide} show={show}>
      <Modal.Header title={strings.deleteRemoteClientLogs} />
      <Modal.Body>
        <AlertBox closeAble={false} type={AlertType.WARNING} message={strings.deleteRemoteClientLogsWarning} />
        <AlertBox message={error} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="modal-main-button"
          fullWidth={false}
          loading={loading}
          onClick={deleteRemoteClientLogs}
          variant="danger"
        >
          {strings.deleteRemoteClientLogs}
        </Button>
        <CloseButton onClick={onHide} text={strings.cancel} />
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteRemoteClientLogsModal;
