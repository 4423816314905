/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { reminders, strings } from "../../../../common/Strings/Strings";
import { canManageClinic, getGeneralError } from "../../../../util/helperFunctions";
import { ClinicResponse } from "../../../../models/clinic/ClinicResponse";
import ClinicApi from "../../../../api/ClinicApi";
import LoaderInline from "../../../../components/LoaderInline";
import Select from "../../../../components/ReactHookFormFields/General/Select/Select";
import { getClinicUpdateDto, Notification } from "../ClinicSettings";
import AlertBox, { AlertType } from "../../../../components/AlertBox";
import Button from "../../../../components/Button";
import Switch2 from "../../../../components/ReactHookFormFields/General/Switch2";

const reminderOptions = [
  { value: 168, title: reminders["1 week earlier"] },
  { value: 72, title: reminders["3 days earlier"] },
  { value: 24, title: reminders["1 day earlier"] },
  { value: 8, title: reminders["8 hours earlier"] },
];

const defaultNotificationValues = {
  emailReminder: false,
  emailReminderTime: "",
  smsReminder: false,
  smsReminderTime: "",
  emailOnReservationCreate: false,
  smsOnReservationCancel: false,
  smsOnReservationReschedule: false,
};

const defaultFormValues = (notification?: Notification) => {
  if (!notification) return defaultNotificationValues;
  return notification;
};

interface Props {
  clinic: ClinicResponse;
  clinicId: string;
  notification?: Notification;
}

const Notifications: React.FC<Props> = ({ clinic, clinicId, notification }: Props) => {
  const [alert, setAlert] = useState<string | null>(null);
  const [alertType, setAlertType] = useState<AlertType>(AlertType.SUCCESS);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    control,
    reset,
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm<Notification>({
    mode: "onChange",
    defaultValues: defaultFormValues(notification),
  });

  const updateNotificationSettings = async (newNotification: Notification) => {
    setLoading(true);

    const { emailReminder, smsReminder } = newNotification;

    const emailBefore =
      emailReminder && newNotification.emailReminderTime ? parseInt(newNotification.emailReminderTime, 10) : null;

    const smsBefore =
      smsReminder && newNotification.smsReminderTime ? parseInt(newNotification.smsReminderTime, 10) : null;

    const clinicUpdateDto = getClinicUpdateDto(clinic);
    clinicUpdateDto.smsBefore = smsBefore;
    clinicUpdateDto.emailBefore = emailBefore;
    clinicUpdateDto.emailOnReservationCreate = newNotification.emailOnReservationCreate || false;
    clinicUpdateDto.smsOnReservationCancel = newNotification.smsOnReservationCancel || false;
    clinicUpdateDto.smsOnReservationReschedule = newNotification.smsOnReservationReschedule || false;

    try {
      await ClinicApi.updateClinic(clinicId, clinicUpdateDto);
      setAlertType(AlertType.SUCCESS);
      setAlert(strings.savedSuccessfully);
      // The isDirty flag will become false again after this reset
      reset(defaultFormValues(newNotification));
    } catch (err) {
      setAlertType(AlertType.ERROR);
      setAlert(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    reset(defaultFormValues(notification));
  }, [notification, reset]);

  // This is needed to trigger a rerender to be able to conditionally display the Selects
  const [smsReminder, emailReminder] = watch(["smsReminder", "emailReminder"]);

  const readOnly = !canManageClinic(clinic);

  return (
    <div className="w-full md:tw-card md:max-w-md space-y-6 md:p-8">
      <div>
        <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex">
          {strings.notifications}
          <div className="ml-auto">{loading && <LoaderInline />}</div>
        </h2>
      </div>
      <div>{strings.notificationToolTip}</div>
      <form className="space-y-4" onSubmit={handleSubmit(updateNotificationSettings)} id="updateNotificationsForm">
        <div className="divide-y divide-gray-200 dark:divide-gray-500">
          <div className="flex items-start pb-4">
            <div>
              {strings.formatString(strings.sendRemindersVia, <b>{strings.sendRemindersViaEmail}</b>)}
              {emailReminder ? (
                <div className="pt-4">
                  <Select
                    defaultValue={notification?.emailReminderTime}
                    label={strings.options}
                    name="emailReminderTime"
                    options={reminderOptions}
                    register={register}
                    error={errors.emailReminderTime}
                    required
                    readOnly={readOnly}
                  />
                </div>
              ) : null}
            </div>
            <div className="ml-auto">
              <Switch2
                control={control}
                defaultChecked={notification?.emailReminder}
                name="emailReminder"
                readOnly={readOnly}
              />
            </div>
          </div>
          <div className="py-4">
            <div className="flex items-start pb-4">
              <div>
                <div>{strings.formatString(strings.sendRemindersVia, <b>{strings.sendRemindersViaSms}</b>)}</div>
                {smsReminder ? (
                  <div className="pt-4">
                    <Select
                      defaultValue={notification?.smsReminderTime}
                      label={strings.options}
                      name="smsReminderTime"
                      options={reminderOptions}
                      register={register}
                      error={errors.smsReminderTime}
                      required
                      readOnly={readOnly}
                    />
                  </div>
                ) : null}
              </div>
              <div className="ml-auto">
                <Switch2
                  control={control}
                  defaultChecked={notification?.smsReminder}
                  name="smsReminder"
                  readOnly={readOnly}
                />
              </div>
            </div>
            <AlertBox type={AlertType.WARNING} hidden={!smsReminder} message={strings.smsWarning} />
          </div>
          <div className="flex items-start py-4">
            <div>
              {strings.formatString(
                strings.notificationOnReservationCreate,
                <b>{strings.notificationOnReservationCreateEmail}</b>
              )}
            </div>
            <div className="ml-auto">
              <Switch2
                control={control}
                defaultChecked={notification?.emailOnReservationCreate}
                name="emailOnReservationCreate"
                readOnly={readOnly}
              />
            </div>
          </div>
          <div className="flex items-start py-4">
            <div>
              {strings.formatString(
                strings.notificationOnReservationCancel,
                <b>{strings.notificationOnReservationCancelSms}</b>
              )}
            </div>
            <div className="ml-auto">
              <Switch2
                control={control}
                defaultChecked={notification?.smsOnReservationCancel}
                name="smsOnReservationCancel"
                readOnly={readOnly}
              />
            </div>
          </div>
          <div className="flex items-center py-4">
            <div>
              {strings.formatString(
                strings.notificationOnReservationReschedule,
                <b>{strings.notificationOnReservationRescheduleSms}</b>
              )}
            </div>
            <div className="ml-auto">
              <Switch2
                defaultChecked={notification?.smsOnReservationReschedule}
                control={control}
                name="smsOnReservationReschedule"
                readOnly={readOnly}
              />
            </div>
          </div>
        </div>
        <AlertBox type={alertType} message={alert} />
        <Button
          loading={loading}
          variant="primary"
          type="submit"
          form="updateNotificationsForm"
          disabled={!isValid || !isDirty || loading}
          hidden={readOnly}
        >
          {strings.saveChanges}
        </Button>
      </form>
    </div>
  );
};

export default Notifications;
