/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useCallback, useEffect, useState } from "react";
import VideoConsultationApi from "../../../../api/VideoConsultationApi";
import { getGeneralError } from "../../../../util/helperFunctions";
import { SignalServerLogEntry } from "../../../../models/videoConsultation/SignalServerLogEntry";
import { Card } from "../../../../components/Cards/Card";
import AlertBox, { AlertType } from "../../../../components/AlertBox";
import LoaderInline from "../../../../components/LoaderInline";
import { strings } from "../../../../common/Strings/Strings";
import SimpleSelect from "../../../../components/InputFieldsSimple/SimpleSelect";
import LogEntry from "./LogEntry";
import Button from "../../../../components/Button";
import { ArrowPath } from "../../../../common/Icons/ArrowPath";

const RemoteClientLogs: React.FC = () => {
  const [names, setNames] = useState<string[]>([]);
  const [selectedName, setSelectedName] = useState<string>();
  const [logs, setLogs] = useState<SignalServerLogEntry[]>([]);

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchNames = useCallback(async () => {
    setLoading(true);
    try {
      const response = await VideoConsultationApi.getRemoteClientLogNames();
      setNames(response.data);

      if (!selectedName) {
        setSelectedName(response.data[0]);
      }
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  }, [selectedName]);

  const fetchLogs = useCallback(async () => {
    if (!selectedName) return;

    setLoading(true);
    try {
      const response = await VideoConsultationApi.getRemoteClientLogs(selectedName);
      setLogs(response.data.logs);
    } catch (err) {
      setError(await getGeneralError(err));
    } finally {
      setLoading(false);
    }
  }, [selectedName]);

  const fetchNamesAndLogs = useCallback(async () => {
    await fetchNames();
    await fetchLogs();
  }, [fetchLogs, fetchNames]);

  useEffect(() => {
    void fetchNamesAndLogs();
  }, [fetchNamesAndLogs]);

  if (loading) {
    return <LoaderInline />;
  }

  if (error) {
    return <AlertBox message={error} />;
  }

  if (names.length === 0) {
    return (
      <AlertBox closeAble={false} hidden={false} message={strings.noRemoteClientLogsMessage} type={AlertType.INFO} />
    );
  }

  return (
    <div className="flex flex-wrap items-center space-y-6">
      <SimpleSelect
        name="name"
        label={strings.user}
        options={names.map((name: string) => ({ value: name, title: name }))}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          setSelectedName(e.target.value);
        }}
        isChooseOption={false}
        value={selectedName}
      />
      <Button className="tw-link" onClick={fetchNamesAndLogs} variant="icon">
        <ArrowPath />
      </Button>
      <Card type="simple" size="max">
        <div className="h-[600px] overflow-y-auto">
          {logs.map((entry: SignalServerLogEntry) => (
            <LogEntry entry={entry} />
          ))}
        </div>
      </Card>
    </div>
  );
};

export default RemoteClientLogs;
