/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { strings } from "../../../common/Strings/Strings";
import Params from "../../../common/Params";

interface Props {
  title: string;
  message: string;
}

export default function SmsNotificationPreview(props: Props): ReactElement {
  const { message } = props;

  return (
    <div
      className="text-gray-900 rounded-lg bg-emerald-200 py-2 px-3 text-sm font-normal dark:bg-emerald-600 dark:text-white"
      style={{ width: "50%" }}
    >
      {message || strings.message}
      <br />
      GlobalVet
      <br />
      <a className="text-inherit font-normal underline" href={Params.frontendBaseURL}>
        {Params.frontendBaseURL}
      </a>
    </div>
  );
}
