/*
 * Copyright © 2018-2023, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import Params from "../common/Params";
import { ApiPromise } from "../models/ApiPromise";
import { axiosCommon } from "../util/axiosSetup";
import { PageResponse } from "../models/PageResponse";
import qs from "query-string";
import { BasicClinicResponse } from "../models/clinic/BasicClinicResponse";
import { ClinicDetailsResponse } from "../models/management/ClinicDetailsResponse";
import { DataExportRequest } from "../models/management/DataExportRequest";
import { ClinicManagementItemResponse } from "../models/management/ClinicManagementItemResponse";

export default class ClinicManagementApi {
  private static baseUrl = Params.clinicServiceBaseURL;

  static getClinics(queryParams: {
    query?: string;
    pageNumber?: number;
    pageSize?: number;
    sortBy?: string;
  }): ApiPromise<PageResponse<ClinicManagementItemResponse>> {
    const queryString = qs.stringify(queryParams);
    const url = `${this.baseUrl}/management/clinics?${queryString}`;
    return axiosCommon.get(url);
  }

  static getClinicDetails(clinicId: string): ApiPromise<ClinicDetailsResponse> {
    const url = `${this.baseUrl}/management/clinics/${clinicId}`;
    return axiosCommon.get(url);
  }

  static sendClinicExportMail(clinicId: string, request: DataExportRequest): ApiPromise<void> {
    const url = `${this.baseUrl}/management/clinics/${clinicId}/export`;
    return axiosCommon.post(url, request);
  }
}
