/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { MutableRefObject, ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { useLocalStorage } from "@uidotdev/usehooks";
import UserProfilePicture from "../../components/Pictures/User/UserProfilePicture";
import { SpinnerSize } from "../../common/Icons/Spinner";
import LoaderInline from "../../components/LoaderInline";
import {
  calculateProfilePictureSize,
  countMediaTracksForVideo,
  displayMediaDebug,
  getVideoSizeConstraints,
  isAudioLive,
  isVideoLive,
  LoadState,
  MediaFeatures,
  playVideo,
} from "./Utils";
import { Speaker } from "../../common/Icons/Speaker";
import { combineClassNames } from "../../util/HtmlUtils";
import { MicrophoneOff } from "../../common/Icons/MicrophoneOff";
import { Fullscreen } from "../../common/Icons/Fullscreen";
import { FullscreenExit } from "../../common/Icons/FullscreenExit";
import HoverChecker from "../../util/HoverChecker";
import { useFullscreen } from "../../hooks/useFullscreen";
import { VideoConsultationRole } from "../../models/videoConsultation/VideoConsultationRole";
import RoleBadge from "./RoleBadge";
import logger from "./Logger";
import { useVoiceDetector } from "./useVoiceDetector";
import { ArrowPath } from "../../common/Icons/ArrowPath";

interface Props {
  callControls: ReactElement;
  camEnabled: boolean;
  canSwitchCameraFacing: boolean;
  facingMode: "environment" | "user";
  joinRoom(): void;
  mediaFeatures: MediaFeatures;
  micEnabled: boolean;
  ping: number | null;
  roles: VideoConsultationRole[];
  updateVideoRef: () => void;
  toggleCam: (setTo?: boolean) => void;
  toggleCameraFacing: () => void;
  toggleMic: (setTo?: boolean) => void;
  userId: string;
  userName: string;
  videoHeight: number;
  videoWidth: number;
  videoRef: MutableRefObject<HTMLVideoElement | null>;
}

const TRANSPARENT_BORDER = true;

/*
 * Video component for the local user.
 */
export const LocalVideo: React.FC<Props> = ({
  callControls,
  camEnabled,
  canSwitchCameraFacing,
  facingMode,
  joinRoom,
  mediaFeatures,
  micEnabled,
  ping,
  roles,
  updateVideoRef,
  toggleCam,
  toggleCameraFacing,
  toggleMic,
  userId,
  userName,
  videoHeight,
  videoWidth,
  videoRef,
}: Props): ReactElement => {
  const joinedRoomRef = useRef<boolean>(false);

  const micMissing = !mediaFeatures.hasMicrophonePermission || !mediaFeatures.hasMicrophone;
  const camMissing = !mediaFeatures.hasWebcamPermission || !mediaFeatures.hasWebcam;

  const [videoLoadState, setVideoLoadState] = useState<LoadState>(
    camMissing || !camEnabled ? LoadState.MISSING : LoadState.INIT
  );
  const [placeholderLoadState, setPlaceholderLoadState] = useState<LoadState>(LoadState.INIT);

  const timeoutRef = useRef<NodeJS.Timeout | undefined>();

  const [showVideo, setShowVideo] = useState<boolean>();

  useEffect(() => {
    setShowVideo(camEnabled && videoLoadState === LoadState.LOADED && isVideoLive(videoRef));
  }, [camEnabled, videoLoadState, videoRef, facingMode]);

  const [showLoader, setShowLoader] = useState<boolean>(false);
  useEffect(() => {
    setShowLoader(placeholderLoadState === LoadState.INIT || videoLoadState === LoadState.LOADING);
  }, [placeholderLoadState, videoLoadState]);

  const isVoiceActive = useVoiceDetector(videoRef.current, micEnabled, micMissing);

  const [bgColor, setBgColor] = useState<string>("rgb(100 116 139)"); // bg-slate-500
  const { isFullscreen, enterFullscreen, exitFullscreen } = useFullscreen();

  const containerRef = useRef<HTMLDivElement | null>(null);

  const [debugMode] = useLocalStorage<boolean>("debugMode");

  const toggleFullscreen = async () => {
    if (!containerRef.current) {
      return;
    }

    try {
      if (!isFullscreen) {
        await enterFullscreen(containerRef.current);
      } else {
        await exitFullscreen();
      }
    } catch (error) {
      logger.error("[📹VideoChat] Error toggling fullscreen:", error);
    }
  };

  useEffect(() => {
    const setupVideoAndJoinRoom = async () => {
      // Assign the stream ref to the user video (from the preview video)
      updateVideoRef();

      // Join the videochat room
      joinRoom();
      joinedRoomRef.current = true;
    };

    // Guard condition to prevent double join event
    if (!joinedRoomRef.current) {
      void setupVideoAndJoinRoom();
    }
  }, [videoRef, toggleCam, toggleMic, updateVideoRef, joinRoom]);

  useEffect(() => {
    if (camEnabled) {
      setVideoLoadState(LoadState.INIT);
    }
  }, [camEnabled]);

  const handleCalculateAverageColor = useCallback((color?: string | null) => {
    if (color !== null) {
      setPlaceholderLoadState(LoadState.LOADED);
    }

    color && setBgColor(color);
  }, []);

  // Autoplay the video
  playVideo(videoRef, true);

  const profilePictureSize = calculateProfilePictureSize(videoHeight, 1 / 2);

  const constraints = getVideoSizeConstraints(videoWidth);

  const handleLoadStart = () => {
    if (!camEnabled) return;

    logger.info("[📹VideoChat] Local video started loading...");
    setVideoLoadState(LoadState.LOADING);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setVideoLoadState(LoadState.FAILED);
    }, 10000);
  };

  const handleCanPlay = () => {
    logger.info("[📹VideoChat] Local video can play.");
    setVideoLoadState(LoadState.LOADED);
    clearTimeout(timeoutRef.current);
  };

  const handleStalled = () => {
    logger.info("[📹VideoChat] Local video stalled (possible network issue).");
    setVideoLoadState(LoadState.FAILED);
    clearTimeout(timeoutRef.current);
  };

  const handleError = () => {
    logger.info("[📹VideoChat] Local video error (invalid source or playback issue).");
    setVideoLoadState(LoadState.FAILED);
    clearTimeout(timeoutRef.current);
  };

  useEffect(
    () => () => {
      clearTimeout(timeoutRef.current);
    },
    []
  );

  return (
    <HoverChecker className="w-full h-full select-none" hoverTimeout={2000}>
      {(_isHovered, isRecentlyHovered) => (
        <div className="relative w-full h-full flex justify-center items-center rounded-2xl" ref={containerRef}>
          {/* User video */}
          <div className="relative w-full h-full rounded-2xl" id="video-wrapper">
            <video
              className={combineClassNames(
                "w-full h-full rounded-2xl object-cover",
                isFullscreen ? "fullscreen-video" : ""
              )}
              autoPlay
              controls={false}
              id="user-video"
              muted
              loop
              preload="auto"
              onLoadStart={handleLoadStart}
              onCanPlay={handleCanPlay}
              onStalled={handleStalled}
              onError={handleError}
              playsInline
              ref={videoRef}
            />
            <div
              className="absolute top-0 right-0 w-full h-full rounded-2xl ring-4 ring-inset ring-blue-400 ring-blue-600"
              hidden={!micEnabled || !isVoiceActive}
              id="active-voice-overlay"
            />
          </div>

          {/* User video placeholder: Profile picture and loader */}
          <div
            className={combineClassNames(
              !isVideoLive(videoRef) || !showVideo ? "flex justify-center items-center" : "hidden",
              "absolute w-full h-full border-4 rounded-xl",
              micEnabled && isVoiceActive
                ? "border-blue-600 dark:border-blue-400"
                : TRANSPARENT_BORDER
                ? "border-transparent"
                : "border-gray-400 dark:border-gray-700"
            )}
            style={{ backgroundColor: bgColor }}
          >
            <div
              className="absolute"
              hidden={showLoader}
              style={{
                width: profilePictureSize.width,
                height: profilePictureSize.height,
              }}
            >
              <UserProfilePicture onCalculateAverageColor={handleCalculateAverageColor} userId={userId} />
            </div>
            <div
              style={{
                transform: `scale(${constraints.uiScale})`,
              }}
            >
              <LoaderInline hidden={!showLoader} size={SpinnerSize.VideoChat} />
            </div>
          </div>

          {/* User information */}
          <p
            className="text-white absolute origin-top-left bg-gray-500 bg-opacity-40 rounded-full p-2"
            style={{ top: constraints.gap, left: constraints.gap, transform: `scale(${constraints.uiScale})` }}
          >
            {userName}
          </p>

          {/* Debug information (only in debug mode) */}
          {debugMode &&
            displayMediaDebug({
              audioTracks: countMediaTracksForVideo(videoRef, "audio"),
              camEnabled,
              camMissing,
              liveAudioTracks: countMediaTracksForVideo(videoRef, "audio", true),
              liveVideoTracks: countMediaTracksForVideo(videoRef, "video", true),
              micEnabled,
              micMissing,
              ping,
              placeholderLoadState,
              streamId: (videoRef.current?.srcObject as MediaStream)?.id ?? "No stream",
              audioIsLive: isAudioLive(videoRef),
              videoIsLive: isVideoLive(videoRef),
              videoIsMuted: videoRef?.current?.muted ?? "Unknown",
              videoIsPaused: videoRef?.current?.paused ?? "Unknown",
              videoLoadState,
              videoReadyState: videoRef?.current?.readyState ?? "Unknown",
              videoTracks: countMediaTracksForVideo(videoRef, "video"),
            })}

          {/* Disabled microphone icon */}
          {!micEnabled && (
            <div
              className="absolute origin-top-right text-white bg-gray-500 bg-opacity-40 rounded-full p-2"
              style={{ top: constraints.gap, right: constraints.gap, transform: `scale(${constraints.uiScale})` }}
            >
              <MicrophoneOff variant="outline" />
            </div>
          )}

          {/* Voice activity icon */}
          {micEnabled && isVoiceActive && (
            <div
              className="absolute origin-top-right text-white bg-gray-500 bg-opacity-40 rounded-full p-2"
              style={{ top: constraints.gap, right: constraints.gap, transform: `scale(${constraints.uiScale})` }}
            >
              <Speaker />
            </div>
          )}

          {/* Camera facing toggle button */}
          <button
            className="absolute origin-bottom-right text-white bg-gray-500 bg-opacity-40 rounded-full p-2"
            hidden={!showVideo || !canSwitchCameraFacing}
            onClick={toggleCameraFacing}
            style={{ bottom: constraints.gap, right: constraints.gap, transform: `scale(${constraints.uiScale})` }}
            type="button"
          >
            <ArrowPath />
          </button>

          {/* Roles */}
          {roles.length > 0 && (
            <div
              className="absolute origin-bottom-left text-white bg-gray-500 bg-opacity-40 rounded-full p-2 duration-200 ease-in-out"
              style={{
                opacity: isRecentlyHovered ? 1 : 0,
                bottom: constraints.gap,
                left: constraints.gap,
                transform: `scale(${constraints.uiScale})`,
              }}
            >
              <div className="flex flex-row justify-center items-center gap-2">
                {roles.map((role) => (
                  <RoleBadge key={role.toString()} role={role} />
                ))}
              </div>
            </div>
          )}

          {/* Fullscreen button / Hidden for the local video (limited uses) */}
          <div
            className="absolute origin-bottom-right text-white bg-gray-500 bg-opacity-40 rounded-full p-2 duration-200 ease-in-out"
            hidden
            style={{
              opacity: isRecentlyHovered ? 1 : 0,
              bottom: constraints.gap,
              right: constraints.gap,
              transform: `scale(${constraints.uiScale})`,
            }}
            onClick={toggleFullscreen}
            role="button"
            tabIndex={0}
          >
            {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
          </div>

          {/* Call control buttons */}
          {isFullscreen && (
            <div
              className="absolute bottom-5 text-white bg-gray-500 bg-opacity-40 rounded-full p-2 duration-200 ease-in-out"
              style={{ opacity: isRecentlyHovered ? 1 : 0 }}
            >
              {callControls}
            </div>
          )}
        </div>
      )}
    </HoverChecker>
  );
};
