/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import { useCallback, useEffect, useRef, useState } from "react";
import logger from "../pages/VideoConsultation/Logger";

interface UseMediaPermissionsOptions {
  simulateMissingCamera?: boolean;
  simulateMissingMicrophone?: boolean;
}

const useMediaPermissions = ({
  simulateMissingCamera = false,
  simulateMissingMicrophone = false,
}: UseMediaPermissionsOptions = {}) => {
  const [isPermissionApiAvailable, setPermissionApiAvailable] = useState<boolean | "unknown">("unknown");
  const [micPermission, setMicPermission] = useState<PermissionState | "unknown">("unknown");
  const [camPermission, setCamPermission] = useState<PermissionState | "unknown">("unknown");
  const [hasMicrophone, setHasMicrophone] = useState<boolean | "unknown">("unknown");
  const [hasCamera, setHasCamera] = useState<boolean | "unknown">("unknown");

  const micPermRef = useRef<PermissionStatus | null>(null);
  const camPermRef = useRef<PermissionStatus | null>(null);

  // Check permissions using the Permissions API or fallback
  const checkPermissions = useCallback(async () => {
    try {
      if (navigator.permissions) {
        setPermissionApiAvailable(true);

        micPermRef.current = await navigator.permissions.query({ name: "microphone" as PermissionName });
        camPermRef.current = await navigator.permissions.query({ name: "camera" as PermissionName });

        setMicPermission(micPermRef.current.state);
        setCamPermission(camPermRef.current.state);

        micPermRef.current.onchange = () => {
          if (!micPermRef.current) return;
          setMicPermission(micPermRef.current.state);
          logger.info(`[📹VideoChat] Microphone permission: ${micPermRef.current.state}`);
        };

        camPermRef.current.onchange = () => {
          if (!camPermRef.current) return;
          setCamPermission(camPermRef.current.state);
          logger.info(`[📹VideoChat] Camera permission: ${camPermRef.current.state}`);
        };
      } else {
        setPermissionApiAvailable(false);
        logger.warn("[📹VideoChat] Permissions API not supported.");
      }
    } catch (error) {
      logger.error("[📹VideoChat] Error checking permissions:", error);
    }
  }, []);

  // Check available media devices (camera and microphone)
  const checkAvailableDevices = useCallback(async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((device) => device.kind === "videoinput");
      const audioDevices = devices.filter((device) => device.kind === "audioinput");

      // Update state only if values change
      setHasCamera((prev) =>
        (simulateMissingCamera ? false : videoDevices.length > 0) !== prev ? videoDevices.length > 0 : prev
      );
      setHasMicrophone((prev) =>
        (simulateMissingMicrophone ? false : audioDevices.length > 0) !== prev ? audioDevices.length > 0 : prev
      );
    } catch (error) {
      logger.error("[📹VideoChat] Error checking available devices:", error);
      setHasCamera("unknown");
      setHasMicrophone("unknown");
    }
  }, [simulateMissingCamera, simulateMissingMicrophone]);

  const handleDeviceChange = useCallback(() => {
    void checkAvailableDevices();
  }, [checkAvailableDevices]);

  useEffect(() => {
    // Initial permissions and devices check
    void checkPermissions();
    void checkAvailableDevices();

    navigator.mediaDevices.addEventListener("devicechange", handleDeviceChange);

    return () => {
      navigator.mediaDevices.removeEventListener("devicechange", handleDeviceChange);
      if (micPermRef.current) micPermRef.current.onchange = null;
      if (camPermRef.current) camPermRef.current.onchange = null;
    };
  }, [checkAvailableDevices, checkPermissions, handleDeviceChange]);

  return { isPermissionApiAvailable, micPermission, camPermission, hasMicrophone, hasCamera };
};

export default useMediaPermissions;
