/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { useLocalStorage } from "@uidotdev/usehooks";
import UserProfilePicture from "../../components/Pictures/User/UserProfilePicture";
import { SpinnerSize } from "../../common/Icons/Spinner";
import LoaderInline from "../../components/LoaderInline";
import { combineClassNames } from "../../util/HtmlUtils";
import {
  AudioVideoToggleData,
  calculateProfilePictureSize,
  countMediaTracksForVideo,
  displayMediaDebug,
  getVideoSizeConstraints,
  isAudioLive,
  isVideoLive,
  LoadState,
  playAudio,
  playVideo,
  toggleMediaTrack,
  toggleMediaTrackForVideo,
  VideoChatUserConnection,
} from "./Utils";
import { Speaker } from "../../common/Icons/Speaker";
import { MicrophoneOff } from "../../common/Icons/MicrophoneOff";
import HoverChecker from "../../util/HoverChecker";
import { FullscreenExit } from "../../common/Icons/FullscreenExit";
import { Fullscreen } from "../../common/Icons/Fullscreen";
import { useFullscreen } from "../../hooks/useFullscreen";
import { SpeakerOff } from "../../common/Icons/SpeakerOff";
import RoleBadge from "./RoleBadge";
import logger from "./Logger";
import AutoPlayModal from "./AutoPlayModal";
import { useVoiceDetector } from "./useVoiceDetector";

interface Props {
  callControls: ReactElement;
  remoteUser: VideoChatUserConnection;
  videoHeight: number;
  videoWidth: number;
}

const TRANSPARENT_BORDER = true;

/*
 * Video component for remote users.
 */
export const RemoteVideo: React.FC<Props> = ({
  callControls,
  remoteUser,
  videoHeight,
  videoWidth,
}: Props): ReactElement => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const [camEnabled, setCamEnabled] = useState(remoteUser.mediaConfig?.camEnabled || false);
  const [micEnabled, setMicEnabled] = useState(remoteUser.mediaConfig?.micEnabled || false);

  const [videoLoadState, setVideoLoadState] = useState<LoadState>(LoadState.INIT);
  const [placeholderLoadState, setPlaceholderLoadState] = useState<LoadState>(LoadState.INIT);

  const timeoutRef = useRef<NodeJS.Timeout | undefined>();

  const showVideo = camEnabled && videoLoadState === LoadState.LOADED && isVideoLive(videoRef);

  const [showLoader, setShowLoader] = useState<boolean>(false);
  useEffect(() => {
    setShowLoader(placeholderLoadState === LoadState.INIT || videoLoadState === LoadState.LOADING);
  }, [placeholderLoadState, videoLoadState]);

  const isVoiceActive = useVoiceDetector(audioRef.current, micEnabled);

  const [bgColor, setBgColor] = useState<string>("rgb(100 116 139)"); // bg-slate-500
  const { isFullscreen, enterFullscreen, exitFullscreen } = useFullscreen();

  const containerRef = useRef<HTMLDivElement | null>(null);

  const [volume, setVolume] = useState(1); // Default volume set to maximum (100%)
  const [savedVolume, setSavedVolume] = useState(1);
  const [isMuted, setIsMuted] = useState<boolean>(false);

  const sliderRef = useRef<HTMLInputElement | null>(null);
  const isAdjustingVolume = useRef(false);

  const remoteUserName = remoteUser.user?.name || remoteUser.id;

  const [debugMode] = useLocalStorage<boolean>("debugMode");

  const [showAutoPlayModal, setShowAutoPlayModal] = useState<boolean>(false);

  const toggleFullscreen = async () => {
    if (!containerRef.current) {
      return;
    }

    try {
      if (!isFullscreen) {
        await enterFullscreen(containerRef.current);
      } else {
        await exitFullscreen();
      }
    } catch (error) {
      logger.error("[📹VideoChat] Error toggling fullscreen:", error);
    }
  };

  const loadMediaStream = useCallback(() => {
    if (videoRef.current && !videoRef.current.srcObject && remoteUser.stream) {
      videoRef.current.srcObject = remoteUser.stream;
      logger.info(`[📹VideoChat] Video stream has been loaded for ${remoteUserName}.`);
    }
    if (audioRef.current && !audioRef.current.srcObject && remoteUser.stream) {
      audioRef.current.srcObject = remoteUser.stream;
      logger.info(`[📹VideoChat] Audio stream has been loaded for ${remoteUserName}.`);
    }
  }, [remoteUser.stream, remoteUserName]);

  useEffect(() => {
    loadMediaStream();
  }, [loadMediaStream]);

  useEffect(() => {
    const mediaConfig = remoteUser.mediaConfig;
    const remoteStream = remoteUser.stream;

    if (!mediaConfig || !remoteStream) {
      return;
    }

    // Initial setup
    if (mediaConfig) {
      // Set microphone state and toggle the audio track
      setCamEnabled(mediaConfig?.camEnabled);
      toggleMediaTrack(remoteStream, "audio", mediaConfig?.micEnabled, false);

      // Set camera state and toggle the video track
      setMicEnabled(mediaConfig?.micEnabled);
      toggleMediaTrack(remoteStream, "video", mediaConfig?.camEnabled, false);
    }

    loadMediaStream();
  }, [loadMediaStream, remoteUser.mediaConfig, remoteUser.stream]);

  useEffect(() => {
    if (!remoteUser.dataConnection) {
      return undefined;
    }

    // Synchronize the state to the remote peer's state
    const handleData = (message: any) => {
      const data: AudioVideoToggleData = JSON.parse(message);

      // Set microphone state and toggle the audio track
      setMicEnabled(data.micEnabled);
      toggleMediaTrackForVideo(videoRef, "audio", data.micEnabled, false);

      // Set camera state and toggle the video track
      setCamEnabled(data.camEnabled);
      toggleMediaTrackForVideo(videoRef, "video", data.camEnabled, false);
    };

    // Add peer callbacks
    remoteUser.dataConnection.on("data", handleData);

    logger.info(`[📹VideoChat] Added peer data callback for ${remoteUserName}.`);

    // Remove peer callback in the cleanup function
    return () => {
      remoteUser.dataConnection?.off("data", handleData);
      logger.info(`[📹VideoChat] Removed peer data callback for ${remoteUserName}.`);
    };
  }, [remoteUser.dataConnection, remoteUserName]);

  const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(event.target.value);
    setVolume(newVolume);
    setSavedVolume(newVolume);

    if (newVolume === 0) {
      setIsMuted(true);
    } else {
      setIsMuted(false);
    }
  };

  const handleVolumeSeekStart = () => {
    isAdjustingVolume.current = true;
  };

  const handleVolumeSeekEnd = () => {
    isAdjustingVolume.current = false;
  };

  const handleVolumeClick = () => {
    if (isMuted) {
      // Unmute
      setIsMuted(false);
      setVolume(savedVolume || 1);
    } else {
      // Mute
      setIsMuted(true);
      setSavedVolume(volume);
      setVolume(0);
    }
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]);

  const calculateSoundSliderBackground = () => {
    const min = parseFloat(sliderRef.current?.min || "0");
    const max = parseFloat(sliderRef.current?.max || "1");

    const sliderValue = ((volume - min) / (max - min)) * 100;

    return `linear-gradient(to right, #fff ${sliderValue}%, #999 ${sliderValue}%)`;
  };

  const handleCalculateAverageColor = useCallback((color?: string | null) => {
    if (color !== null) {
      setPlaceholderLoadState(LoadState.LOADED);
    }

    color && setBgColor(color);
  }, []);

  // Autoplay the video
  playVideo(videoRef, false);

  const profilePictureSize = calculateProfilePictureSize(videoHeight, 1 / 2);

  const constraints = getVideoSizeConstraints(videoWidth);

  const handleLoadStart = () => {
    if (!camEnabled) return;
    if (videoLoadState !== LoadState.LOADED) {
      logger.info(`[📹VideoChat] Video of ${remoteUserName} started loading...`);
      setVideoLoadState(LoadState.LOADING);
    }

    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setVideoLoadState(LoadState.FAILED);
    }, 10000);
  };

  const handleCanPlay = () => {
    logger.info(`[📹VideoChat] Video of ${remoteUserName} can play.`);
    setVideoLoadState(LoadState.LOADED);
    clearTimeout(timeoutRef.current);
  };

  const handleStalled = () => {
    logger.info(`[📹VideoChat] Video of ${remoteUserName} stalled (possible network issue).`);
    setVideoLoadState(LoadState.FAILED);
    clearTimeout(timeoutRef.current);
  };

  const handleError = () => {
    logger.info(`[📹VideoChat] Video error for ${remoteUserName} (invalid source or playback issue).`);
    setVideoLoadState(LoadState.FAILED);
    clearTimeout(timeoutRef.current);
  };

  useEffect(
    () => () => {
      clearTimeout(timeoutRef.current);
    },
    []
  );

  return (
    <>
      <HoverChecker className="w-full h-full select-none" hoverTimeout={2000}>
        {(_isHovered, isRecentlyHovered) => (
          <div className="relative w-full h-full flex justify-center items-center rounded-2xl" ref={containerRef}>
            {/* Remote user video, only plays the video track */}
            {/* Reason: Sometimes the video (and hence the sound) does not load properly without a real, live track. */}
            <div className="relative w-full h-full rounded-2xl" id="video-wrapper">
              <video
                className={combineClassNames(
                  "w-full h-full rounded-2xl object-cover",
                  isFullscreen ? "fullscreen-video" : ""
                )}
                autoPlay
                id="peer-video"
                muted
                loop
                preload="auto"
                onLoadStart={handleLoadStart}
                onCanPlay={handleCanPlay}
                onStalled={handleStalled}
                onError={handleError}
                playsInline
                ref={videoRef}
              />
              <div
                className="absolute top-0 right-0 w-full h-full rounded-2xl ring-4 ring-inset ring-blue-400 ring-blue-600"
                hidden={!micEnabled || !isVoiceActive}
                id="active-voice-overlay"
              />
            </div>

            {/* Remote user audio, only plays the audio track */}
            <audio
              autoPlay
              hidden
              muted={!micEnabled}
              loop
              onCanPlay={() => {
                setShowAutoPlayModal(micEnabled && !isAudioLive(audioRef));
              }}
              preload="auto"
              ref={audioRef}
              onError={() => logger.error(`[📹VideoChat] Error loading audio for ${remoteUserName}`)}
            />

            {/* Remote user video placeholder: Profile picture and loader */}
            <div
              className={combineClassNames(
                !showVideo ? "flex justify-center items-center" : "hidden",
                "absolute w-full h-full border-4 rounded-xl",
                micEnabled && isVoiceActive
                  ? "border-blue-600 dark:border-blue-400"
                  : TRANSPARENT_BORDER
                  ? "border-transparent"
                  : "border-gray-400 dark:border-gray-700"
              )}
              style={{ backgroundColor: bgColor }}
            >
              <div
                className="absolute"
                hidden={showLoader}
                style={{
                  width: profilePictureSize.width,
                  height: profilePictureSize.height,
                }}
              >
                <UserProfilePicture
                  onCalculateAverageColor={handleCalculateAverageColor}
                  userId={remoteUser.user?.id}
                />
              </div>
              <div
                style={{
                  transform: `scale(${constraints.uiScale})`,
                }}
              >
                <LoaderInline hidden={!showLoader} size={SpinnerSize.VideoChat} />
              </div>
            </div>

            {/* User information */}
            <p
              className="text-white absolute origin-top-left bg-gray-500 bg-opacity-40 rounded-full p-2"
              style={{ top: constraints.gap, left: constraints.gap, transform: `scale(${constraints.uiScale})` }}
            >
              {remoteUserName}
            </p>

            {/* Debug information (only in debug mode) */}
            {/* For live tracks we count enabled tracks, since due to the limitations of PeerJS the tracks are always live */}
            {debugMode &&
              displayMediaDebug({
                audioTracks: countMediaTracksForVideo(videoRef, "audio"),
                camEnabled,
                camMissing: !camEnabled,
                liveAudioTracks: countMediaTracksForVideo(videoRef, "audio", true, true),
                liveVideoTracks: countMediaTracksForVideo(videoRef, "video", true, true),
                micEnabled,
                micMissing: !micEnabled,
                placeholderLoadState,
                streamId: (videoRef.current?.srcObject as MediaStream)?.id ?? "No stream",
                audioIsLive: isAudioLive(audioRef),
                audioIsMuted: audioRef?.current?.muted ?? "Unknown",
                audioIsPaused: audioRef?.current?.paused ?? "Unknown",
                audioReadyState: audioRef?.current?.readyState ?? "Unknown",
                videoIsLive: isVideoLive(videoRef),
                videoIsMuted: videoRef?.current?.muted ?? "Unknown",
                videoIsPaused: videoRef?.current?.paused ?? "Unknown",
                videoLoadState,
                videoReadyState: videoRef?.current?.readyState ?? "Unknown",
                videoTracks: countMediaTracksForVideo(videoRef, "video"),
              })}

            {/* Disabled microphone icon */}
            {!micEnabled && (
              <div
                className="absolute origin-top-right text-white bg-gray-500 bg-opacity-40 rounded-full p-2"
                style={{ top: constraints.gap, right: constraints.gap, transform: `scale(${constraints.uiScale})` }}
              >
                <MicrophoneOff variant="outline" />
              </div>
            )}

            {/* Voice activity icon */}
            {micEnabled && isVoiceActive && (
              <div
                className="absolute origin-top-right text-white bg-gray-500 bg-opacity-40 rounded-full p-2"
                style={{ top: constraints.gap, right: constraints.gap, transform: `scale(${constraints.uiScale})` }}
              >
                <Speaker />
              </div>
            )}

            {/* Sound slider */}
            <div
              className="absolute origin-bottom-right text-white bg-gray-500 bg-opacity-40 rounded-full p-2 duration-200 ease-in-out"
              style={{
                opacity: isRecentlyHovered ? 1 : 0,
                bottom: constraints.gap,
                right: constraints.sliderGap,
                transform: `scale(${constraints.uiScale})`,
              }}
            >
              <div className="flex justify-center items-center gap-2">
                <div onClick={handleVolumeClick} role="button" tabIndex={0}>
                  {isMuted ? <SpeakerOff /> : <Speaker />}
                </div>
                <input
                  className="volume-slider"
                  max="1"
                  min="0"
                  onChange={handleVolumeChange}
                  onMouseDown={handleVolumeSeekStart}
                  onMouseUp={handleVolumeSeekEnd}
                  onTouchEnd={handleVolumeSeekEnd}
                  onTouchStart={handleVolumeSeekStart}
                  ref={sliderRef}
                  step="0.01"
                  style={{
                    background: calculateSoundSliderBackground(),
                  }}
                  type="range"
                  value={volume}
                />
              </div>
            </div>

            {/* Roles */}
            {remoteUser.user?.roles && remoteUser.user.roles.length > 0 && (
              <div
                className="absolute origin-bottom-left text-white bg-gray-500 bg-opacity-40 rounded-full p-2 duration-200 ease-in-out"
                style={{
                  opacity: isRecentlyHovered ? 1 : 0,
                  bottom: constraints.gap,
                  left: constraints.gap,
                  transform: `scale(${constraints.uiScale})`,
                }}
              >
                <div className="flex flex-row justify-center items-center gap-2">
                  {remoteUser.user.roles.map((role) => (
                    <RoleBadge key={role.toString()} role={role} />
                  ))}
                </div>
              </div>
            )}

            {/* Fullscreen button */}
            <div
              className="absolute origin-bottom-right text-white bg-gray-500 bg-opacity-40 rounded-full p-2 duration-200 ease-in-out"
              style={{
                opacity: isRecentlyHovered ? 1 : 0,
                bottom: constraints.gap,
                right: constraints.gap,
                transform: `scale(${constraints.uiScale})`,
              }}
              onClick={toggleFullscreen}
              role="button"
              tabIndex={0}
            >
              {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
            </div>

            {/* Call control buttons */}
            {isFullscreen && (
              <div
                className="absolute bottom-10 text-white bg-gray-500 bg-opacity-40 rounded-full p-2 duration-200 ease-in-out"
                style={{ opacity: isRecentlyHovered ? 1 : 0 }}
              >
                {callControls}
              </div>
            )}
          </div>
        )}
      </HoverChecker>
      <AutoPlayModal
        autoPlay={() => {
          playAudio(audioRef);
        }}
        show={showAutoPlayModal}
        onHide={() => setShowAutoPlayModal(false)}
      />
    </>
  );
};
