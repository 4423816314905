/*
 * Copyright © 2018-2020, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement, useEffect, useState } from "react";
import { getGeneralError } from "../../../util/helperFunctions";
import { strings } from "../../../common/Strings/Strings";
import { AnalyticsOverviewResponse } from "../../../models/analytics/AnalyticsOverviewResponse";
import AnalyticsApi from "../../../api/AnalyticsApi";
import LoaderInline from "../../../components/LoaderInline";
import AlertBox from "../../../components/AlertBox";
import { Link } from "react-router-dom";

function AnalyticsOverview(): ReactElement {
  const [overview, setOverview] = useState<AnalyticsOverviewResponse>();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const getOverview = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await AnalyticsApi.getOverview();
        setOverview(response.data);
      } catch (e) {
        setError(await getGeneralError(e));
      } finally {
        setLoading(false);
      }
    };

    void getOverview();
  }, []);

  return (
    <>
      <div className="tw-card p-4">
        <div className=" mb-4">
          <Link
            to="/management/analytics/events"
            className="font-bold text-inherit"
          >
            {strings.ANALYTICS}
          </Link>
        </div>
        <div hidden={!loading} className="text-center">
          <LoaderInline className="m-4" />
        </div>
        <div hidden={loading || !error}>
          <AlertBox message={error} className="m-3" closeAble={false} />
        </div>
        {overview && !loading && !error && (
          <div className="tw-table-container">
            <table className="tw-table">
              <thead className="tw-thead">
                <tr>
                  <th className="tw-th">{strings.overviewActivity}</th>
                  <th className="tw-th text-right">
                    {strings.overviewLast24hours}
                  </th>
                  <th className="tw-th text-right">
                    {strings.overviewLast7days}
                  </th>
                  <th className="tw-th text-right">
                    {strings.overviewLast30days}
                  </th>
                </tr>
              </thead>
              <tbody className="tw-tbody">
                <tr>
                  <td className="px-4 py-3 text-sm font-bold whitespace-nowrap">
                    {strings.overviewTotalEvents}
                  </td>
                  <td className="px-4 py-3 text-sm text-right">
                    {overview.last24hours.events}
                  </td>
                  <td className="px-4 py-3 text-sm text-right">
                    {overview.last7days.events}
                  </td>
                  <td className="px-4 py-3 text-sm text-right">
                    {overview.last30days.events}
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-3 text-sm font-bold whitespace-nowrap">
                    {strings.overviewActiveUsers}
                  </td>
                  <td className="px-4 py-3 text-sm text-right">
                    {overview.last24hours.activeUsers}
                  </td>
                  <td className="px-4 py-3 text-sm text-right">
                    {overview.last7days.activeUsers}
                  </td>
                  <td className="px-4 py-3 text-sm text-right">
                    {overview.last30days.activeUsers}
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-3 text-sm font-bold whitespace-nowrap">
                    {strings.overviewActiveClinics}
                  </td>
                  <td className="px-4 py-3 text-sm text-right">
                    {overview.last24hours.activeClinics}
                  </td>
                  <td className="px-4 py-3 text-sm text-right">
                    {overview.last7days.activeClinics}
                  </td>
                  <td className="px-4 py-3 text-sm text-right">
                    {overview.last30days.activeClinics}
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-3 text-sm font-bold whitespace-nowrap">
                    {strings.overviewNewUsers}
                  </td>
                  <td className="px-4 py-3 text-sm text-right">
                    {overview.last24hours.newUsers}
                  </td>
                  <td className="px-4 py-3 text-sm text-right">
                    {overview.last7days.newUsers}
                  </td>
                  <td className="px-4 py-3 text-sm text-right">
                    {overview.last30days.newUsers}
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-3 text-sm font-bold whitespace-nowrap">
                    {strings.overviewNewClinics}
                  </td>
                  <td className="px-4 py-3 text-sm text-right">
                    {overview.last24hours.newClinics}
                  </td>
                  <td className="px-4 py-3 text-sm text-right">
                    {overview.last7days.newClinics}
                  </td>
                  <td className="px-4 py-3 text-sm text-right">
                    {overview.last30days.newClinics}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
}

export default AnalyticsOverview;
