/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { CountryDetailsResponse } from "../../../models/management/CountryDetailsResponse";
import Field from "../../../components/ReactHookFormFields/General/Field";
import Switch from "../../../components/ReactHookFormFields/General/Switch";
import { strings } from "../../../common/Strings/Strings";
import NumberFormatter from "../../../util/NumberFormatter";
import RadioButtonGroup from "../../../components/ReactHookFormFields/General/RadioButton/RadioButtonGroup";
import { DiscountType } from "../../../models/invoice/DiscountType";
import CombinedSelect from "../../../components/ReactHookFormFields/General/Select/CombinedSelect";
import { AccountingCodeResponse } from "../../../models/accounting/AccountingCodeResponse";
import CostCalculator, { InvoiceItem } from "../../../util/CostCalculator";
import { CartItem } from "../../../util/InvoiceCartUtils";
import { useClinic } from "../../../contexts/ClinicContext";
import { useDebouncedState } from "../../../hooks/hooks";
import { generateUUId } from "../../ePrescription/sections/finish/sendingRequestProcesser";

export interface TotalDiscountForm {
  discountAccountingCode?: AccountingCodeResponse[];
  discountName: string;
  discountPriceValue: number;
  discountPercentValue: number;
  discountType: DiscountType;
  discountOn: boolean;
  sumValue: number;
}

interface Props {
  cart: CartItem[];
  countryDetails?: CountryDetailsResponse;
  sum: number;
}

const calculateDiscount = (type: DiscountType, priceValue: number, percentValue: number, sum: number): number => {
  if (type === DiscountType.GROSS_PRICE) {
    return priceValue;
  } 
  return (sum / 100) * percentValue;
  
};

const TotalDiscount: React.FC<Props> = ({ cart, countryDetails, sum }: Props) => {
  const {
    register,
    control,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useFormContext<TotalDiscountForm>();
  const [discountItems, setDiscountItems] = useState<InvoiceItem[]>([]);
  const discountChanged = useDebouncedState<string>("", 400);

  const { clinic } = useClinic();

  const { discountOn, discountPercentValue, discountPriceValue, discountType } = watch();

  useEffect(() => {
    /* For Field validation */
    register("sumValue");
  }, [register]);

  useEffect(() => {
    setValue("sumValue", sum);
  }, [setValue, sum]);

  const required = (v: string | undefined | []) => {
    const on = getValues("discountOn");

    if (!on) {
      return true;
    }
    return (!!v && v.length > 0) || strings.pleaseFillRequiredFields;
  };

  useEffect(() => {   
    const updateDiscountTotal = () => {
      setDiscountItems(CostCalculator.getTotalDiscountAsInvoiceItems(calculateDiscount(
        discountType,
        discountPriceValue,
        discountPercentValue,
        sum
      ), cart));
    };

    if(discountOn){
      updateDiscountTotal();
    }

  }, [cart, discountChanged.value, discountOn, discountPercentValue, discountPriceValue, discountType, sum]);

  const triggerDiscountCalculation = () => discountChanged.setValue(generateUUId());

  return (
    <>
      <div className="tw-card mt-4">
        <div>
          <h2 className="text-lg font-semibold leading-tight text-zinc-800 lg:text-xl dark:text-white flex p-4">
            {strings.discount}
            <div className="ml-auto">
              <Switch control={control} name="discountOn" />
            </div>
          </h2>
        </div>
        <div hidden={!discountOn}>
          <div className="p-4 space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <Field
                error={errors.discountName}
                fieldOptions={{
                  validate: {
                    isRequired: required,
                  },
                }}
                label={strings.discountName}
                name="discountName"
                register={register}
                showRequired
              />
              <CombinedSelect
                control={control}
                label={strings.accountingCode}
                labelKey="name"
                name="discountAccountingCode"
                options={countryDetails?.accountingCodes}
              />
            </div>
            <div>
              <label>{strings.discountTypeLabel} *</label>
              <RadioButtonGroup
                control={control}
                name="discountType"
                fieldOptions={{
                  validate: {
                    isRequired: required,
                  },
                }}
                onChange={triggerDiscountCalculation}
                options={[
                  {
                    value: DiscountType.GROSS_PRICE,
                    content: (
                      <div className="flex items-center">
                        <Field
                          fieldOptions={{
                            validate: {
                              isRequired: (v, values) => {
                                const on = values.discountOn;
                                const type = values.discountType;

                                if (!on || type !== DiscountType.GROSS_PRICE) {
                                  return true;
                                }
                                return (v !== undefined && v !== null && v >= 0) || strings.pleaseFillRequiredFields;
                              },
                              isLesserThanSum: (v, values) => {
                                const on = values.discountOn;
                                const type = values.discountType;

                                if (!on || type !== DiscountType.GROSS_PRICE) {
                                  return true;
                                }
                                return (
                                  v <= values.sumValue ||
                                  strings.discountMustBeLessThanTotal
                                );
                              },
                            },
                          }}
                          name="discountPriceValue"
                          onChange={triggerDiscountCalculation}
                          readOnly={discountType !== DiscountType.GROSS_PRICE}
                          register={register}
                          suffix={countryDetails?.currency}
                          type="number"
                        />
                      </div>
                    ),
                    error: errors.discountPriceValue?.message,
                    label: strings.fixDiscount,
                  },
                  {
                    value: DiscountType.PERCENT,
                    content: (
                      <div className="flex items-center">
                        <Field
                          fieldOptions={{
                            validate: {
                              isRequired: (v, values) => {
                                const on = values.discountOn;
                                const type = getValues("discountType");
                                if (!on || type !== DiscountType.PERCENT) {
                                  return true;
                                }

                                return (v !== undefined && v !== null && v >= 0) || strings.pleaseFillRequiredFields;
                              },
                              isLesserThan100: (v, values) => {
                                const on = values.discountOn;
                                const type = values.discountType;
                                if (!on || type !== DiscountType.PERCENT) {
                                  return true;
                                }
                                return v <= 100 || strings.discountMustBeLessThanTotal;
                              },
                            },
                          }}
                          name="discountPercentValue"
                          onChange={triggerDiscountCalculation}
                          readOnly={discountType !== DiscountType.PERCENT}
                          register={register}
                          suffix="%"
                          type="number"
                        />
                      </div>
                    ),
                    error: errors.discountPercentValue?.message,
                    label: strings.percentDiscount,
                  },
                ]}
                verticalCenter
              />
            </div>
          </div>
          <div className="flex border-t p-4">
            <div>
              <div>
                {strings.total} ({strings.gross})
              </div>
              <div>{strings.discount}</div>
              <div className="font-bold">
                {strings.total} ({strings.gross})
              </div>
            </div>
            <div className="ml-auto text-right">
              <div>{NumberFormatter.formatPrice(sum, countryDetails?.currency)}</div>
              <div>
                {NumberFormatter.formatPrice(
                  0 - CostCalculator.getTotalGrossPriceOfInvoiceItems(discountItems, countryDetails, clinic || undefined),
                  countryDetails?.currency
                )}
              </div>
              <div className="font-bold">
                {NumberFormatter.formatPrice(
                  CostCalculator.getTotalGrossPriceOfInvoiceItems([...cart, ...discountItems], countryDetails, clinic || undefined),
                  countryDetails?.currency
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TotalDiscount;
