/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useEffect, useState } from "react";
import VideoConsultationApi from "../../../../api/VideoConsultationApi";
import { getGeneralError } from "../../../../util/helperFunctions";
import { SignalServerLogEntry } from "../../../../models/videoConsultation/SignalServerLogEntry";
import { Card } from "../../../../components/Cards/Card";
import AlertBox from "../../../../components/AlertBox";
import LoaderInline from "../../../../components/LoaderInline";
import LogEntry from "./LogEntry";

const SignalServerLogs: React.FC = () => {
  const [logs, setLogs] = useState<SignalServerLogEntry[]>([]);

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchLogs = async () => {
      setLoading(true);
      try {
        const response = await VideoConsultationApi.getLogs();
        setLogs(response.data);
      } catch (err) {
        setError(await getGeneralError(err));
      } finally {
        setLoading(false);
      }
    };

    void fetchLogs();
  }, []);

  if (loading) {
    return <LoaderInline />;
  }

  if (error) {
    return <AlertBox message={error} />;
  }

  return (
    <div className="flex flex-wrap items-center space-y-6">
      <Card type="simple" size="max">
        <div className="h-[600px] overflow-y-auto">
          {logs.map((entry: SignalServerLogEntry) => (
            <LogEntry entry={entry} />
          ))}
        </div>
      </Card>
    </div>
  );
};

export default SignalServerLogs;
