/*
 * Copyright © 2018-2024, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import { generateUUId } from "../../../../pages/ePrescription/sections/finish/sendingRequestProcesser";
import { combineClassNames } from "../../../../util/HtmlUtils";

interface Props {
  content?: ReactElement | ReactElement[];
  id: string;
  checked?: boolean;
  defaultValue?: boolean;
  disabled?: boolean;
  error?: string;
  helperText?: string;
  label?: string;
  onChange?(checked: boolean): void;
  verticalCenter?: boolean;
}

const RadioButton: React.FC<Props> = ({
  content,
  id,
  defaultValue = false,
  checked = defaultValue,
  disabled,
  error,
  helperText,
  label,
  onChange,
  verticalCenter = false,
}: Props) => {
  const handleOnChange = () => {
    if (onChange) {
      onChange(!checked);
    }
  };

  const cursorClass = checked ? "cursor-default" : "cursor-pointer";

  return (
    <div className={error ? "has-error" : undefined}>
      <div className={combineClassNames("flex space-x-3", verticalCenter ? "items-center" : "")}>
        <input
          checked={checked}
          className="cursor-pointer"
          disabled={disabled}
          id={`radio-button-${id}`}
          name={generateUUId()}
          onChange={handleOnChange}
          readOnly={disabled}
          type="radio"
        />
        {label && (
          <label className={cursorClass} htmlFor={`radio-button-${id}`}>
            {label}
            {helperText && <p id={`radio-button-helper-text-${id}`}>{helperText}</p>}
          </label>
        )}
        {Array.isArray(content) ? content.map((e: ReactElement) => e) : content}
      </div>
      {error && <p className="flex w-full">{error}</p>}
    </div>
  );
};

export default RadioButton;
