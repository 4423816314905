/*
 * Copyright © 2018-2022, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { ReactElement } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { strings } from "../../../common/Strings/Strings";
import { downloadInvoicePdfForClinicCharge } from "../../../util/FileUtils";
import { getGeneralError } from "../../../util/helperFunctions";
import { ElipsisVertical } from "../../../common/Icons/ElipsisVertical";
import { ClinicChargeResponse } from "../../../models/charge/ClinicChargeResponse";
import ChargeManagementApi from "../../../api/ChargeManagementApi";
import { ChargeStatus } from "../../../models/charge/ChargeStatus";

interface Props {
  charge: ClinicChargeResponse;
  reload: () => void;
  onError: (error: string | null) => void;
  onLoad: (loading: boolean) => void;
}

const ClinicChargeActions = (props: Props): ReactElement => {
  const { charge, reload, onError, onLoad } = props;

  const cancelCharge = async () => {
    onLoad(true);

    try {
      await ChargeManagementApi.cancelClinicCharge(charge.id);
      reload();
    } catch (err) {
      onError(await getGeneralError(err));
      onLoad(false);
    }
  };

  const retryCharge = async () => {
    onLoad(true);

    try {
      await ChargeManagementApi.retryClinicCharge(charge.id);
      reload();
    } catch (err) {
      onError(await getGeneralError(err));
      onLoad(false);
    }
  };

  return (
    <div>
      <Dropdown className="rounded-dropdown">
        <Dropdown.Toggle as="div" bsPrefix="tw-icon cursor-pointer m-0 p-0" id="dropdown-pets-more">
          <ElipsisVertical />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end" className="mt-1 p-0">
          <Dropdown.Item
            hidden={!charge.invoice?.valid}
            bsPrefix="calendar-menu dropdown-item"
            onClick={async () => {
              try {
                await downloadInvoicePdfForClinicCharge(charge);
              } catch (err) {
                onError(await getGeneralError(err));
              }
            }}
          >
            {strings.downloadInvoicePDF}
          </Dropdown.Item>
          <Dropdown.Item
            bsPrefix="calendar-menu dropdown-item"
            as="button"
            onClick={retryCharge}
            hidden={charge.status == ChargeStatus.COMPLETED || charge.status == ChargeStatus.CANCELLED}
          >
            {strings.retryClinicCharge}
          </Dropdown.Item>
          <Dropdown.Item
            bsPrefix="calendar-menu dropdown-item"
            as="button"
            onClick={cancelCharge}
            hidden={charge.status == ChargeStatus.COMPLETED || charge.status == ChargeStatus.CANCELLED}
          >
            {strings.cancelClinicCharge}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default ClinicChargeActions;
