/*
 * Copyright © 2018-2025, GlobalVET AB
 *
 * All rights reserved. No part or the whole of this source code and the compiled program
 * may be reproduced, copied, distributed, disseminated to the public, adapted or transmitted
 * in any form or by any means, including photocopying, recording, or other electronic or
 * mechanical methods, without the prior written permission of GlobalVET AB. This source code
 * and the compiled program may only be used for the purposes of GlobalVET AB. This source code
 * and the compiled program shall be kept confidential and shall not be made public or made
 * available or disclosed to any unauthorized person. Any dispute or claim arising out of the
 * breach of these provisions shall be governed by and construed in accordance with the
 * laws of Sweden.
 */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { strings } from "../../../Strings/Strings";
import { combineClassNames } from "../../../../util/HtmlUtils";
import { copyrightStatement } from "../../NavBar";
import { Globe } from "../../../Icons/Globe";
import { getSimpleLanguage } from "../../../../util/LocalStorageVariables";
import { QuestionMarkCircle } from "../../../Icons/QuestionMarkCircle";
import ChooseLanguageMenu from "./ChooseLanguageModal";

interface Props {
  close(): void;
  isOpen: boolean;
}

const UnauthenticatedDropdown: React.FC<Props> = ({ close, isOpen }: Props) => {
  const currentLanguageCode = getSimpleLanguage();

  const [isChooseLanguageMenuOpen, setChooseLanguageMenuOpen] = useState<boolean>(false);

  const openChooseLanguageMenu = () => {
    setChooseLanguageMenuOpen(true);
  };

  const closeChooseLanguageMenu = () => {
    setChooseLanguageMenuOpen(false);
  };

  return (
    <>
      <div
        className={combineClassNames(
          "fixed z-40 top-14 left-0 w-full h-screen pb-14 pt-4 px-4 lg:px-6 flex-col justify-between bg-slate-500 dark:bg-slate-800",
          isOpen ? "flex" : "hidden"
        )}
        id="dropdownNavbarMenu"
      >
        <ul className="flex flex-col">
          <li>
            <a href="/login" className="block py-3 text-white transition-all duration-300 font-normal no-underline">
              {strings.signIn}
            </a>
          </li>
          <li>
            <button
              className="block py-3 text-white transition-all duration-300"
              onClick={openChooseLanguageMenu}
              type="button"
            >
              <div className="flex items-center space-x-2 w-full font-normal no-underline">
                <Globe className="w-6 h-6 flex-shrink-0" variant="outline" />
                <span>{currentLanguageCode?.toUpperCase()}</span>
              </div>
            </button>
          </li>
          <li>
            <Link
              className="block py-3 text-white transition-all duration-300 font-normal no-underline"
              to="/help"
              onClick={close}
            >
              <div className="flex items-center space-x-2 w-full">
                <QuestionMarkCircle className="w-6 h-6 flex-shrink-0" variant="outline" />
                <span>{strings.helpCenter}</span>
              </div>
            </Link>
          </li>
          {/* TODO: Terms of Service */}
          <li hidden>
            <Link
              className="block py-3 text-white transition-all duration-300 font-normal no-underline"
              to="/help"
              onClick={close}
            >
              <div className="flex items-center space-x-2 w-full">
                <span>Terms of Service</span>
              </div>
            </Link>
          </li>
          <li>
            <Link
              className="block py-3 text-white transition-all duration-300 font-normal no-underline"
              to="/privacy-policy"
              onClick={close}
            >
              <div className="flex items-center space-x-2 w-full">
                <span>{strings.privacyPolicy}</span>
              </div>
            </Link>
          </li>
          <li>
            <Link
              className="block py-3 text-white transition-all duration-300 font-normal no-underline"
              to="/cookies"
              onClick={close}
            >
              <div className="flex items-center space-x-2 w-full">
                <span>{strings.manageCookies}</span>
              </div>
            </Link>
          </li>
        </ul>
        <ul className="flex flex-col pb-4">
          <div className="text-slate-300 text-sm">{copyrightStatement}</div>
        </ul>
      </div>
      <ChooseLanguageMenu close={closeChooseLanguageMenu} isOpen={isChooseLanguageMenuOpen} />
    </>
  );
};

export default UnauthenticatedDropdown;
